import { createFileRoute } from "@tanstack/react-router";

import { AnomalyDetectorEditorPage } from "@/features/anomaly/editor/AnomalyDetectorEditorPage";
import AppMainLayout from "@/layouts/AppMainLayout";

export const Route = createFileRoute("/anomaly-detector/$detectorId/edit")({
  component: () => (
    <AppMainLayout>
      <AnomalyDetectorEditorPage />
    </AppMainLayout>
  ),
});
