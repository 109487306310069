import { EventType, RepairEventType } from "@/api/customerApi";
import { EventAnalyzerContainer } from "@/features/event/EventAnalyzer.styled";
import { isHonkdaCustomer } from "@/utils/customers";

import { NewPatternButton } from "../anomaly/editor/NewPatternButton";
import EventAnalyzerStateWrapper from "./EventAnalyzerStateWrapper";

export type EventAnalyzerProps = {
  eventType: EventType;
  repairType?: RepairEventType;
};

export default function EventAnalyzer(props: EventAnalyzerProps) {
  const isHonkda = isHonkdaCustomer();

  return (
    <EventAnalyzerContainer>
      {isHonkda && <NewPatternButton />}
      <EventAnalyzerStateWrapper {...props} />
    </EventAnalyzerContainer>
  );
}
