import { getSignificantNumber } from "@lib/src/numberUtils";
import { Bezier } from "bezier-js";

interface PercentageFromValueParams {
  value?: number;
  min: number;
  max: number;
  significantDigits?: number;
}

export const toPercentage = ({ value, min, max, significantDigits = 2 }: PercentageFromValueParams): string => {
  if (value === undefined || max - min == 0) return "-";
  return `${getSignificantNumber((100 * value) / (max - min), significantDigits)}%`;
};

/**
 * Temporarily borrowing this logic from echarts to interpolate the bezier segments
 * TODO: Simplify
 */
function* generateBezierSegments(
  points: [number, number][],
  smooth: number
): Generator<[number, number, number, number, number, number, number, number]> {
  let prevX = 0;
  let prevY = 0;
  let cpx0 = 0;
  let cpy0 = 0;
  let cpx1 = 0;
  let cpy1 = 0;
  for (let idx = 0; idx < points.length; idx++) {
    let [x, y] = points[idx];

    if (idx < 0) {
      break;
    }

    if (idx === 0) {
      cpx0 = x;
      cpy0 = y;
    } else {
      let ratioNextSeg = 0.5;
      let vx: number = 0;
      let vy: number = 0;
      let nextCpx0 = 0;
      let nextCpy0 = 0;
      // Is last point
      if (idx >= points.length - 1) {
        cpx1 = x;
        cpy1 = y;
      } else {
        let [nextX, nextY] = points[idx + 1];

        // Ignore duplicate points
        while (nextX === x && nextY === y && idx < points.length - 1) {
          [x, y] = points[idx];
          [nextX, nextY] = points[idx + 1];
          idx += 1;
        }

        vx = nextX - prevX;
        vy = nextY - prevY;

        const dx0 = x - prevX;
        const dx1 = nextX - x;
        const dy0 = y - prevY;
        const dy1 = nextY - y;
        let lenPrevSeg;
        let lenNextSeg;
        lenPrevSeg = Math.sqrt(dx0 * dx0 + dy0 * dy0);
        lenNextSeg = Math.sqrt(dx1 * dx1 + dy1 * dy1);

        // Use ratio of seg length
        ratioNextSeg = lenNextSeg / (lenNextSeg + lenPrevSeg);

        cpx1 = x - vx * smooth * (1 - ratioNextSeg);
        cpy1 = y - vy * smooth * (1 - ratioNextSeg);

        // cp0 of next segment
        nextCpx0 = x + vx * smooth * ratioNextSeg;
        nextCpy0 = y + vy * smooth * ratioNextSeg;

        // Smooth constraint between point and next point.
        // Avoid exceeding extreme after smoothing.
        nextCpx0 = Math.min(nextCpx0, Math.max(nextX, x));
        nextCpy0 = Math.min(nextCpy0, Math.max(nextY, y));
        nextCpx0 = Math.max(nextCpx0, Math.min(nextX, x));
        nextCpy0 = Math.max(nextCpy0, Math.min(nextY, y));
        // Reclaculate cp1 based on the adjusted cp0 of next seg.
        vx = nextCpx0 - x;
        vy = nextCpy0 - y;

        cpx1 = x - (vx * lenPrevSeg) / lenNextSeg;
        cpy1 = y - (vy * lenPrevSeg) / lenNextSeg;

        // Smooth constraint between point and prev point.
        // Avoid exceeding extreme after smoothing.
        cpx1 = Math.min(cpx1, Math.max(prevX, x));
        cpy1 = Math.min(cpy1, Math.max(prevY, y));
        cpx1 = Math.max(cpx1, Math.min(prevX, x));
        cpy1 = Math.max(cpy1, Math.min(prevY, y));

        // Adjust next cp0 again.
        vx = x - cpx1;
        vy = y - cpy1;
        nextCpx0 = x + (vx * lenNextSeg) / lenPrevSeg;
        nextCpy0 = y + (vy * lenNextSeg) / lenPrevSeg;
      }

      yield [prevX, prevY, cpx0, cpy0, cpx1, cpy1, x, y];

      cpx0 = nextCpx0;
      cpy0 = nextCpy0;
    }

    prevX = x;
    prevY = y;
  }
}

function findBezierSegment(points: [number, number][], smooth: number, x: number): Bezier | undefined {
  for (let [x0, y0, cpx0, cpy0, cpx1, cpy1, x1, y1] of generateBezierSegments(points, smooth)) {
    if (x0 <= x && x < x1) {
      return new Bezier([x0, y0, cpx0, cpy0, cpx1, cpy1, x1, y1]);
    }
  }
}

/**
 * Calculates the y-intercept of an interpolated cubic spline curve at a specified x value.
 * @param points A series of intersection points describing the curve. All points lie on the curve. Bezier
 * control points are inferred from the smoothing parameter. Assumes that the points are ordered with
 * non-decreasing x-values such that only one interception exists at every x-value.
 * @param smoothness A value in [0,1] for the smoothness of the interpolated curve.
 * @param x The x-value of the intercept to be calculated.
 */
export function calculateCubicSplineIntercept(
  points: [number, number][],
  smoothness: number,
  x: number
): number | undefined {
  const bezier = findBezierSegment(points, smoothness, x);
  if (bezier) {
    // Find intersection with a vertical line at the desired x value
    const t = bezier.intersects({ p1: { x, y: -10000 }, p2: { x, y: 10000 } })[0] as number;
    const { y } = bezier.compute(t);
    return y;
  }
}

/**
 * Calculates the y-values for an array of intervals of a specified width under the interpolated
 * Bezier curve. The precise y-value is sampled at each half-interval.
 * @param points A series of intersection points describing the curve. All points lie on the curve. Bezier
 * control points are inferred from the smoothing parameter. Assumes that the points are ordered with
 * non-decreasing x-values such that only one interception exists at every x-value.
 * @param smoothness A value in [0,1] for the smoothness of the interpolated curve.
 * @param intervalWidth The difference in x-values to be calculated.
 */
export function calculateCubicSplineInterceptArray(
  points: [number, number][],
  smoothness: number,
  intervalWidth: number
): number[] {
  let maxX: number = points[points.length - 1][0];
  // The number of intervals
  let intervals = Math.floor((maxX + intervalWidth / 2) / intervalWidth);
  // Calculate the y points by sampling the bezier curve at each half-interval
  return (
    [...Array<number>(intervals)]
      .map((_, i) => i * intervalWidth)
      // TODO: Handle x outside of the bezier points
      .map((x) => calculateCubicSplineIntercept(points, smoothness, x + intervalWidth / 2) ?? 0)
  );
}

/**
 * Approximately integrates an interpolated Bezier curve from 0 to the last point defining the curve.
 * @param points A series of intersection points describing the curve. All points lie on the curve. Bezier
 * control points are inferred from the smoothing parameter. Assumes that the points are ordered with
 * non-decreasing x-values such that only one interception exists at every x-value.
 * @param smoothness A value in [0,1] for the smoothness of the interpolated curve.
 * @param precision The difference in x-values used for the approximate integration. A smaller value
 * will give higher precision.
 */
export function integrateCubicSpline(points: [number, number][], smoothness: number, precision: number): number {
  // Calculate the y-values and then simply sum them up
  let y = calculateCubicSplineInterceptArray(points, smoothness, precision);
  return y.reduce((tot, y) => tot + y, 0);
}

/**
 * Adds arrays like this
 * [
 *      [ 1, 2, 3         ],
 *      [    4, 5,  6     ],
 *      [       7,  8,  9 ]
 * ] => [ 1, 6, 15, 14, 9 ]
 */
export function addDiagonal(arr: number[][]): number[] {
  return arr.reduce((accum, arr, i) => {
    arr.forEach((c, j) => {
      while (accum.length <= i + j) {
        accum.push(0);
      }
      accum[i + j] += c;
    });
    return accum;
  }, []);
}
