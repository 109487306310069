import styled from "styled-components";
export const GlobalHeaderStyled = styled.div`
  height: ${({ theme }) => theme.sizes.header};
  padding: 1rem 2.5rem;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.colors.defaultWhite};
  border-bottom: 1px solid #ccc;
`;
