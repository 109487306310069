import styled from "styled-components";

export const ForecastPickerContainer = styled.div`
  height: 350px;
  overflow-y: auto;
  .search-forecast {
    width: 100%;
    border-radius: 5px;
  }
  .no-forecasts-found {
    margin: 1rem 0;
    text-align: center;
  }
`;
