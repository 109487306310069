import { BooleanExpression, CaseExpression } from "@lib/src/expression/types";
import { Col, Flex } from "antd";
import _ from "lodash";
import styled from "styled-components";

import { ExpressionInput, ExpressionInputProps } from "./ExpressionInput";

const ParametersContainer = styled.div`
  height: 56px;
  display: flex;
  align-items: center;
`;

export const CaseExpressionInput = (props: ExpressionInputProps<CaseExpression>) => {
  const { expression, disabled } = props;

  const caseIndexes = _.range(expression.when?.length ?? 0);

  return (
    <Flex vertical>
      {caseIndexes.map((i) => (
        <Col span={24} key={i.toString()}>
          <Flex flex={1}>
            <Col span={4}>
              <ParametersContainer>Case {i} when</ParametersContainer>
            </Col>
            <Col span={20}>
              <ExpressionInput
                expression={expression.when![i] as BooleanExpression}
                path={[...props.path, "when", i.toString()]}
                onChange={props.onChange}
                disabled={disabled}
              />
            </Col>
          </Flex>
          <Flex flex={1}>
            <Col span={4}>
              <ParametersContainer>Case {i} then</ParametersContainer>
            </Col>
            <Col span={20}>
              <ExpressionInput
                expression={expression.then![i]!}
                path={[...props.path, "then", i.toString()]}
                onChange={props.onChange}
                disabled={disabled}
              />
            </Col>
          </Flex>
        </Col>
      ))}
      <Col span={24}>
        <Flex flex={1}>
          <Col span={4}>
            <ParametersContainer>else</ParametersContainer>
          </Col>
          <Col span={20}>
            <ExpressionInput
              expression={expression.else!}
              path={[...props.path, "else"]}
              onChange={props.onChange}
              disabled={disabled}
            />
          </Col>
        </Flex>
      </Col>
    </Flex>
  );
};
