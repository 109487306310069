import { Button, Flex, Form, Input, Select, Typography } from "antd";
import { useEffect, useState } from "react";

import {
  CalculatedStatus,
  ExpressionEventOperator,
  SavedExpression,
  useGetSavedExpressionsForEventFormQuery,
} from "@/api";
import { compareText } from "@/components/tables/sorting";
import { QUERY_SETTINGS } from "@/constants";
import { requireCurrentCustomerId } from "@/utils/customers";

const operatorLabels = {
  [ExpressionEventOperator.Gt]: "Greater than",
  [ExpressionEventOperator.Gte]: "Greater than or equal",
  [ExpressionEventOperator.Lt]: "Less than",
  [ExpressionEventOperator.Lte]: "Less than or equal",
};
const operatorOptions = Object.values(ExpressionEventOperator).map((op) => ({ label: operatorLabels[op], value: op }));

type ExpressionEventFormProps = {
  isEditing?: boolean;
  disabled?: boolean;
  onFormSubmit: (values: FormEventsAnalysisValues) => void;
  onFormChange: (values: FormEventsAnalysisValues) => void;
};

export type FormEventsAnalysisValues = {
  name: string;
  category: string;
  expression: string;
  status: CalculatedStatus;
  operator: ExpressionEventOperator;
  threshold: number;
};

export const ExpressionEventForm = (props: ExpressionEventFormProps) => {
  const { disabled, onFormChange, isEditing, onFormSubmit } = props;

  const [submittable, setSubmittable] = useState(false);
  const [form] = Form.useForm<FormEventsAnalysisValues>();

  const values = Form.useWatch([], form);

  useEffect(() => {
    form
      .validateFields({
        validateOnly: true,
      })
      .then(() => {
        setSubmittable(true);
        onFormChange(values);
      })
      .catch(() => setSubmittable(false));
  }, [form, onFormChange, values]);

  const { isLoading: loadingSavedExpressions, data: savedExpressionsData } = useGetSavedExpressionsForEventFormQuery(
    { customerId: requireCurrentCustomerId(), status: CalculatedStatus.Available },
    { staleTime: QUERY_SETTINGS.SHORT_STALE_TIME, cacheTime: 0 }
  );

  const expressionOptions = (savedExpressionsData?.savedExpressions?.savedExpressions?.items ?? [])
    .map((expression) => ({
      label: expression.name,
      value: expression.id,
      status: expression.status,
      creator: expression.creator?.name,
    }))
    .sort((a, b) => compareText(a.label, b.label));

  const onPatternchange = (value: string) => {
    const newSelectedExpression = savedExpressionsData?.savedExpressions?.savedExpressions?.items?.find(
      (expression) => expression.id === value
    ) as SavedExpression;
    form.setFieldValue("expression", newSelectedExpression?.id);
    form.setFieldValue("status", newSelectedExpression?.status);
  };

  const resetFormData = () => {
    form.setFieldsValue({
      name: "",
      expression: "",
      operator: ExpressionEventOperator.Gt,
      status: CalculatedStatus.Draft,
      threshold: 0,
    });
  };

  return (
    <Form layout="vertical" name="basic" form={form}>
      <Flex vertical gap={8}>
        <Form.Item label="Event name" name="name" rules={[{ required: true, message: "Please enter the event name" }]}>
          <Input placeholder="Name" name="name" disabled={disabled} />
        </Form.Item>
        <Form.Item label="Pattern" name="expression" rules={[{ required: true }]}>
          <Select
            placeholder="Pattern"
            onChange={onPatternchange}
            disabled={disabled || loadingSavedExpressions}
            loading={loadingSavedExpressions}
          >
            {expressionOptions.map((option) => (
              <Select.Option key={option.value} value={option.value} label={option.label}>
                <Flex justify="space-between">
                  <Typography.Text>{option.label}</Typography.Text>
                  <Typography.Text>{option.creator}</Typography.Text>
                </Flex>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Operator" name="operator" rules={[{ required: true }]}>
          <Select
            placeholder="Operator"
            options={operatorOptions}
            disabled={disabled || loadingSavedExpressions}
            loading={loadingSavedExpressions}
          />
        </Form.Item>
        <Form.Item label="Threshold" name="threshold" rules={[{ required: true, message: "Please enter a value" }]}>
          <Input placeholder="Threshold" name="threshold" type="number" disabled={disabled} />
        </Form.Item>
        <Flex align="flex-end" justify="flex-end">
          <Button type="link" color="#000000" onClick={resetFormData}>
            Clear form
          </Button>
          <Button type="primary" onClick={() => onFormSubmit(values)} disabled={!submittable} loading={disabled}>
            {isEditing ? "Update" : "Create"}
          </Button>
        </Flex>
      </Flex>
    </Form>
  );
};
