import { TimeSeriesFieldExpression } from "@lib/src/expression/types";
import { throwError } from "@lib/src/throwError";
import { Flex, Select, Typography } from "antd";
import styled from "styled-components";

import { TimeSeriesFieldFamily } from "@/api";
import { caseInsensitiveContains } from "@/utils/caseInsensitiveContains";
import { stopPropagation } from "@/utils/stopPropagation";

import { ExpressionInputProps } from "./ExpressionInput";
import { useTimeSeriesOptionsForSavedExpression } from "./hooks/useTimeSeriesOptionsForSavedExpression";

const SelectField = styled(Select<string>)`
  font-weight: 400;
  .ant-select-selection-item {
    .info {
      display: none;
    }
  }
`;

type SelectOptionType = {
  disabled: boolean;
};

const SelectOptionContent = styled.div<SelectOptionType>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
  gap: 24px;
  .content {
    white-space: pre-line;
  }
  .label,
  .info,
  .owner {
    color: ${({ disabled, theme }) => (disabled ? theme.colors.darkOpacity : theme.colors.darkGray)};
  }
`;

type TimeSeriesFieldExpressionInputProps = ExpressionInputProps<TimeSeriesFieldExpression> & {
  typeFilter: TimeSeriesFieldFamily;
  hasSearch?: boolean;
};

export const TimeSeriesFieldExpressionInput = (props: TimeSeriesFieldExpressionInputProps) => {
  const { expression, path, onChange, typeFilter, disabled = false, hasSearch = false } = props;

  const { isLoading, timeSeriesOptions } = useTimeSeriesOptionsForSavedExpression({
    typeFilter: typeFilter,
  });

  const filterOptionByValue = (input: string, value: string) => {
    const filteredValues = timeSeriesOptions
      .filter((e) => caseInsensitiveContains(input, e.label ?? ""))
      .map((m) => m.value);
    const includesValues = filteredValues.includes(value?.toString() ?? "");
    return includesValues;
  };

  return (
    <SelectField
      showSearch={hasSearch}
      filterOption={(input, option) => filterOptionByValue(input, option?.value?.toString() ?? "")}
      value={isLoading ? "Loading ..." : expression.value || undefined}
      onSelect={(value) => {
        if (value) onChange?.({ ...expression, value }, path);
      }}
      placeholder={`Select ${toFriendlyName(typeFilter)}`}
      disabled={!onChange || disabled || isLoading}
      loading={isLoading}
      onKeyDown={(e) => stopPropagation(e, "Enter")}
    >
      {timeSeriesOptions.map((option) => (
        <Select.Option key={option.value}>
          <SelectOptionContent disabled={disabled}>
            <Flex vertical wrap="wrap" className="content">
              <Typography.Text className="label">{option.label}</Typography.Text>
              <Typography.Text className="info">
                {option.disabled ? "Disabled to prevent circular formula" : ""}
              </Typography.Text>
            </Flex>
            <Typography.Text className="owner">{option.creator}</Typography.Text>
          </SelectOptionContent>
        </Select.Option>
      ))}
    </SelectField>
  );
};

const toFriendlyName = (f: TimeSeriesFieldFamily) =>
  f == TimeSeriesFieldFamily.AnomalyScore
    ? "anomaly detector"
    : f == TimeSeriesFieldFamily.Expression
    ? "pattern"
    : f == TimeSeriesFieldFamily.Signal
    ? "signal"
    : throwError(`invalid TimeSeriesFieldFamily ${f}`);
