import styled from "styled-components";

export const EditorContentWrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1fr) minmax(0, auto);
  gap: 0.25rem;
  overflow: hidden;
  .active-chart-container {
    flex-grow: 1;
  }
  .amaya-inline-drawer {
    height: 100%;
    .ant-tabs {
      .ant-tabs-content-holder {
        max-height: 912px;
        overflow-y: auto;
      }
    }
  }
  .library-chart-wrapper-with-padding {
    padding-right: 8px;
  }
`;
