import { useAuth0 } from "@auth0/auth0-react";
import { Button, Modal, Select } from "antd";
import _ from "lodash";
import { useContext, useEffect, useState } from "react";

import { useAllFilterValuesQuery, VehicleFilterValues } from "@/api/customerApi";
import { QUERY_SETTINGS } from "@/constants";
import GlobalFilterContext, { GlobalFilterTypes } from "@/contexts/GlobalFilterContext";
import { useCustomerIdentifier } from "@/hooks/useCustomerIdentifier";
import { addToLocalStorage } from "@/utils/localStorageUtils";
import { camelCaseToWords } from "@/utils/randomUtils";

import Loading from "../loading";
import { consolidateFilterOptions, GlobalFilterOption, onFilterSelectChange } from "./GlobalFilter.helpers";
import { FooterWrapper, GlobalFilterContainer, SelectContainers } from "./GlobalFilter.styled";

type vehicleValues = Omit<VehicleFilterValues, "__typename">;
const localStorageKey = "GLOBAL_FILTER";

export const GlobalFilter = () => {
  const { customerIdentifier } = useCustomerIdentifier();

  const { user } = useAuth0();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { globalFilter, setGlobalFilter, emptyFilter } = useContext(GlobalFilterContext);
  const [localFilter, setLocalFilter] = useState(_.cloneDeep(globalFilter));

  const { data, isLoading } = useAllFilterValuesQuery({}, { staleTime: QUERY_SETTINGS.DEFAULT_STALE_TIME });

  useEffect(() => {
    setLocalFilter(_.cloneDeep(globalFilter));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLocalFilter(globalFilter);
  }, [isModalOpen, globalFilter]);

  const handleSelectChange = (
    filterEntity: keyof GlobalFilterTypes,
    filterKey: string,
    values: (string | number)[]
  ) => {
    const newFilters = onFilterSelectChange(
      customerIdentifier.models?.model!,
      localFilter,
      filterEntity,
      filterKey,
      values
    );

    setLocalFilter(newFilters);
  };

  const handleApplyFilter = () => {
    const newFilters = {
      ...localFilter,
    };
    setGlobalFilter(newFilters);
    addToLocalStorage(`${localStorageKey}${user?.email ?? ""}`, JSON.stringify(newFilters));
    setIsModalOpen(false);
  };

  function handleClearFilters() {
    const newFilter = _.cloneDeep(emptyFilter);
    setLocalFilter(newFilter);
    setGlobalFilter(newFilter);
    addToLocalStorage(`${localStorageKey}${user?.email ?? ""}`, JSON.stringify(newFilter));
    setIsModalOpen(false);
  }

  const countFilters = () => {
    let count = 0;
    Object.values(globalFilter["vehicle"]).forEach((filter) => {
      count += filter.length;
    });
    return count;
  };

  const translateVehicleType = (vehicleType: string) => {
    return customerIdentifier.models?.model[vehicleType]?.default ?? vehicleType;
  };
  // will have to be changed if we add more filter values
  const globalFilterApplied = Object.values(globalFilter["vehicle"]).some((arr) => !_.isEmpty(arr));
  const isLocalFilterDifferent = _.isEqual(globalFilter, localFilter);

  const selectItem = (entity: "vehicle", filterType: string, mappedValues: GlobalFilterOption[]) => {
    const isDisabled = filterType === "modelStyle" && localFilter["vehicle"]["model"].length === 0;
    return (
      <div className="select-container" key={filterType}>
        <Select
          mode={"multiple"}
          options={mappedValues.sort((a, b) =>
            typeof a.label === "number" ? a.label - (b.label as number) : a.label.localeCompare(b.label as string)
          )}
          disabled={isDisabled}
          style={{ width: "100%" }}
          placeholder={`Select ${camelCaseToWords(filterType)}`}
          onChange={(changed: (string | number)[] | (string | number)) => {
            let arrayValue = changed;
            if (!_.isArray(arrayValue)) {
              arrayValue = [changed as string | number];
            }
            handleSelectChange(entity, filterType, arrayValue);
          }}
          value={localFilter[entity as "vehicle"][filterType as keyof vehicleValues]}
        />
      </div>
    );
  };

  const filtercount = countFilters();

  const containerClassName = `body-medium${globalFilterApplied ? " global-filter-active" : " global-filter-inactive"}`;

  const mappedValues = Object.entries((data?.allFilterValues.vehicle as vehicleValues) ?? {});

  return (
    <GlobalFilterContainer>
      <div onClick={() => setIsModalOpen(true)} className={containerClassName}>
        {globalFilterApplied
          ? `${filtercount > 10 ? "+10" : filtercount} Vehicle Filter${filtercount > 1 ? "s" : ""}`
          : "No Vehicle Filter"}
      </div>
      <Modal
        open={isModalOpen}
        title="Vehicle Filters"
        footer={
          <FooterWrapper>
            <Button onClick={handleClearFilters}>Clear Filter</Button>
            <Button type="primary" onClick={handleApplyFilter} disabled={isLocalFilterDifferent}>
              Apply Filters
            </Button>
          </FooterWrapper>
        }
        onCancel={() => setIsModalOpen(false)}
        destroyOnClose
      >
        {!isLoading && (
          <SelectContainers>
            {/* If more types of Filters get added to the global filter we can separate them by type here */}
            <div className="form-inputs">
              {mappedValues.map(([filterType, value]) => {
                if (filterType !== "modelStyle") {
                  const mappedValues = value.map((v) => {
                    let shouldLabelBeTranslated = filterType === "model";
                    return {
                      label: shouldLabelBeTranslated ? translateVehicleType(v as string) : v,
                      value: v,
                    };
                  });
                  return selectItem(
                    "vehicle",
                    filterType,
                    _.uniqBy(mappedValues, (item) => item.value)
                  );
                } else {
                  const possibleStyles = localFilter.vehicle.model
                    .map((model) => {
                      return customerIdentifier.models?.model[model]?.style ?? [];
                    })
                    .flat();

                  const mappedValues = possibleStyles.flatMap((styleRecord) => {
                    return Object.keys(styleRecord).map((styleKey) => {
                      return { label: styleRecord[styleKey] ?? styleKey, value: styleKey };
                    });
                  });

                  const uniqueValMappedValues = consolidateFilterOptions(mappedValues);

                  return selectItem(
                    "vehicle",
                    filterType,
                    _.uniqBy(uniqueValMappedValues, (item) => item.value)
                  );
                }
              })}
            </div>
          </SelectContainers>
        )}
        {isLoading && <Loading />}
      </Modal>
    </GlobalFilterContainer>
  );
};
