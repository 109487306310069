import { creatorPullSystems } from "@lib/src/creatorPullSystems";
import { Flex } from "antd";

import { ReadOnlyUser } from "@/api";

import CustomerIcon from "../customerIcon/CustomerIcon";

type EntityCreatorElementProps = {
  creator?: ReadOnlyUser | null;
};

const EntityCreatorElement = ({ creator }: EntityCreatorElementProps) => {
  const isPullSystemCreator = !creator || creator?.name == creatorPullSystems.name;
  return (
    <Flex align="center" gap={4}>
      {isPullSystemCreator && <CustomerIcon appLogo={isPullSystemCreator} size={24} />}
      <span>{creator?.name ?? creatorPullSystems.name}</span>
    </Flex>
  );
};

export default EntityCreatorElement;
