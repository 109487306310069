import TableNoData from "@amayaIcons/table_no_data.svg";
import { Empty, Typography } from "antd";
import { EmptyProps } from "antd/lib";
import React from "react";

type Props = EmptyProps & {
  isLoading?: boolean;
};

export const EmptyComponent: React.FC<Props> = ({ isLoading, description = "No Data", ...args }) => {
  return (
    <Empty
      image={!isLoading && <TableNoData />}
      imageStyle={{
        height: 56,
        width: 56,
      }}
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
      }}
      description={
        !isLoading && (
          <Typography.Title level={5} type="secondary">
            {description}
          </Typography.Title>
        )
      }
      {...args}
    />
  );
};
