import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";
import TextArea from "antd/lib/input/TextArea";

import { useHealthCheckQuery } from "@/api/customerApi";
import Loading from "@/components/loading";
import { Route } from "@/routes/health-check";

import { HealthCheckBodyContainer, HealthCheckHeaderContainer } from "./HealthCheck.styled";

export const HealthCheck = () => {
  const { pvin, patternId, anomalyDetectorId } = Route.useSearch();
  const enabled = [anomalyDetectorId, patternId, pvin].every((n) => !!n);

  const { data, isLoading } = useHealthCheckQuery(
    { testVariables: { anomalyDetectorId, patternId, pvin } },
    { enabled }
  );

  const wasSuccessful = data?.healthCheck.summary.success;

  const heading = (
    <HealthCheckHeaderContainer>
      <h2 className="header-label heading-normal">Health Check</h2>
      <div className="health-check-inputs">
        <p className="body-normal">
          <strong>PVIN=</strong>
          {pvin}
        </p>
        <p className="body-normal">
          <strong>patternId=</strong>
          {patternId}
        </p>
        <p className="body-normal">
          <strong>anomalyDetectorId=</strong>
          {anomalyDetectorId}
        </p>
      </div>

      {isLoading ? undefined : wasSuccessful ? (
        <CheckCircleOutlined className="health-check-result success" />
      ) : (
        <CloseCircleOutlined className="health-check-result error" />
      )}
    </HealthCheckHeaderContainer>
  );

  const jsonResult = JSON.stringify(data?.healthCheck, null, 3);

  const body = (
    <HealthCheckBodyContainer>
      {isLoading ? <Loading label="Running health check..." /> : undefined}
      {isLoading ? undefined : (
        <TextArea
          id="resultJSON"
          className="json-result-textarea"
          autoSize={{ minRows: 10, maxRows: 30 }}
          value={jsonResult}
        />
      )}
    </HealthCheckBodyContainer>
  );

  return (
    <>
      {heading}
      {body}
    </>
  );
};
