import { ANOMALY_CAUTION_COLOR, ANOMALY_SAFE_COLOR, ANOMALY_WARNING_COLOR } from "./anomalyColors";

export const anomalyScoreToColor = (
  score: number | undefined | null,
  cautionThreshold: number,
  warningThreshold: number
) => {
  if (score === null || score === undefined) return undefined;
  return score >= warningThreshold
    ? ANOMALY_WARNING_COLOR
    : score >= cautionThreshold
    ? ANOMALY_CAUTION_COLOR
    : ANOMALY_SAFE_COLOR;
};
