import ErrorIcon from "@amayaIcons/icon-unplugged.svg";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { Divider, Popover } from "antd";
import React from "react";

import * as BasicWidgetStyled from "@/components/widget/BasicWidget.styled";
import { FallbackPageReloadContainer } from "@/pages/FallbackPage.styled";

import { ErrorFallBackContainer } from "./ErrorFallBack.styled";
import { ErrorMessage } from "./ErrorMessage";

type ErrorFallbackProps = {
  fallbackTitle?: React.ReactNode;
  resetErrorBoundary?: () => void;
  error: { message: string };
};

export const ErrorFallback = ({ error, resetErrorBoundary, fallbackTitle }: ErrorFallbackProps) => {
  return (
    <BasicWidgetStyled.WidgetStyled size="small" className="fallback-container" title={fallbackTitle}>
      <ErrorFallBackContainer>
        <div style={{ height: "48xp", width: "78px" }}>
          <ErrorIcon />
        </div>
        <Divider type="vertical" />
        <div>
          <p className="fallback-larger-text">Oops!</p>
          <p>
            An Error Occured.{" "}
            <Popover content={<ErrorMessage message={error.message} />}>
              <QuestionCircleOutlined />
            </Popover>
          </p>
          {resetErrorBoundary && (
            <FallbackPageReloadContainer onClick={() => resetErrorBoundary()}>
              <p>Click here to reload</p>
            </FallbackPageReloadContainer>
          )}
        </div>
      </ErrorFallBackContainer>
    </BasicWidgetStyled.WidgetStyled>
  );
};
