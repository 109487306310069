import styled from "styled-components";

export const AnomalyDetectorSummaryContainer = styled.div`
  padding-top: 2px;
  width: 100%;
  display: flex;
  flex-direction: column;
  .detector-status-container {
    height: 170px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }
  .detector-data-container {
    flex-grow: 1;
    .detector-data-item {
      padding-bottom: 0.75rem;
      margin-bottom: 0.75rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &:not(:last-of-type) {
        border-bottom: 1px solid #dedede;
      }
    }
    .status-label {
      color: #5f5f5f;
      &.bold {
        font-weight: 600;
      }
    }
  }
`;
