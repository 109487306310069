import { useContext } from "react";

import { EventFilter, useGetEventStatsQuery } from "@/api/customerApi";
import { QUERY_SETTINGS } from "@/constants";
import GlobalFilterContext from "@/contexts/GlobalFilterContext";
import { formatNumber } from "@/utils/numberUtils";
import { toPercent } from "@/utils/toPercent";

import { contentWithInlineLoading } from "../ui/JsxUtils";

type EventStatsProps = {
  event: EventFilter;
  isUnavailable?: boolean;
};

export default function EventStats({ event, isUnavailable = false }: EventStatsProps) {
  const { globalFilter } = useContext(GlobalFilterContext);
  const { data, isLoading: isAllTimeDataLoading } = useGetEventStatsQuery(
    { eventFilter: event, filter: globalFilter },
    { staleTime: QUERY_SETTINGS.DEFAULT_STALE_TIME, enabled: !isUnavailable }
  );
  const isLoading = isUnavailable ? false : isAllTimeDataLoading;

  const totalOccurrences = isUnavailable ? "-" : formatNumber(data?.filteredQuery?.eventStats.totalOccurrences);
  const totalUniqueVehicles = isUnavailable ? "-" : formatNumber(data?.filteredQuery?.eventStats.uniqueVehicles);

  return (
    <div className="event-summary-stats">
      <div className="header-data-section">
        <p className="body-small stat-label">{"Event \n Occurrences"}</p>
        <div className="body-medium dark">{contentWithInlineLoading(isLoading, totalOccurrences)}</div>
      </div>
      <div className="header-data-section">
        <p className="body-small stat-label">{"Unique Vehicles \n All Time"}</p>
        <div className="body-medium dark">{contentWithInlineLoading(isLoading, totalUniqueVehicles)}</div>
      </div>
      <div className="header-data-section">
        <p className="body-small stat-label">{"Partial Repair \n Correlation"}</p>
        <div className="body-medium dark">
          {contentWithInlineLoading(
            isLoading,
            isUnavailable ? "-" : toPercent(data?.filteredQuery?.eventStats.partialRepairCorrelation, 0)
          )}
        </div>
      </div>
      <div className="header-data-section">
        <p className="body-small stat-label">{"Full Repair \n Correlation"}</p>
        <div className="body-medium dark">
          {contentWithInlineLoading(
            isLoading,
            isUnavailable ? "-" : toPercent(data?.filteredQuery?.eventStats.fullRepairCorrelation, 0)
          )}
        </div>
      </div>
    </div>
  );
}
