import styled from "styled-components";

export const ForecastListContainer = styled.div`
  .forecast-list-item {
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 0.5rem;
    margin: 0.5rem 0;
    .forecast-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    .forecast-data {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
    cursor: pointer;
    &:hover {
      background-color: #f9fafe;
    }
    &.unavailable {
      opacity: 0.5;
      cursor: not-allowed;
      pointer-events: none;
    }
  }
`;
