// Detect user locale
let userLocale = navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language;

// If detected locale does not work then fallback to "en-US"
try {
  new Intl.DateTimeFormat(userLocale).format(new Date());
} catch {
  userLocale = "en-US";
}

export default userLocale;
