import { findCircularDependence } from "@lib/src/expression/circularity/findCircularDependence";
import { formatToPermanentId } from "@lib/src/expression/formatExpressionIds";
import { useParams } from "@tanstack/react-router";
import { useMemo } from "react";

import { TimeSeriesField, TimeSeriesFieldFamily } from "@/api";
import { useExpressionSelectorOptions } from "@/hooks/useExpressionSelectorOptions";
import useTimeSeriesOptions from "@/hooks/useTimeSeriesOptionsForCharting";

type TimeSeriesOptionsForSavedExpression = {
  typeFilter?: TimeSeriesFieldFamily | undefined;
};

export const useTimeSeriesOptionsForSavedExpression = ({ typeFilter }: TimeSeriesOptionsForSavedExpression) => {
  const { patternId } = useParams({ strict: false });

  const { isLoading, expressionSelectOptions } = useExpressionSelectorOptions();

  const { timeSeriesOptions, isLoading: isTimeSeriesLoading } = useTimeSeriesOptions({ typeFilter });

  const isTimeSeriesOptionDisable = (field: TimeSeriesField) => {
    return patternId
      ? field.id.includes(patternId) || findCircularDependence(timeSeriesOptions, field.inputIds, patternId)
      : false;
  };

  const timeSeriesOptionsForSavedExpressions = useMemo(() => {
    if (!isLoading && !isTimeSeriesLoading && timeSeriesOptions.length) {
      return timeSeriesOptions.map((field) => {
        const expression = expressionSelectOptions.find((e) => field.id.includes(e.value));
        const disabled = isTimeSeriesOptionDisable(field);
        const creator = expression ? expression.creator : "";
        return {
          label: field.displayName,
          value: field.entityId ?? field.id,
          creator,
          disabled,
        };
      });
    }
    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expressionSelectOptions, typeFilter, timeSeriesOptions]);

  return { isLoading: isLoading || isTimeSeriesLoading, timeSeriesOptions: timeSeriesOptionsForSavedExpressions ?? [] };
};

/**
 * Finds is there are any pattern/anomaly that not exists in the timeSeriesOptions
 * list, If at least one does not exists it will return true
 *
 * @param {string} id - The id of the expression to find.
 * @return {boolean} Returns true if the expression is not found, otherwise false.
 */
export const useFoundDependenciesWithStatusCalculating = ({ id }: { id?: string }) => {
  const { timeSeriesOptions, isLoading: isTimeSeriesLoading } = useTimeSeriesOptions({ typeFilter: undefined });
  if (!isTimeSeriesLoading && id && id !== "") {
    return findExpressionInTimeSeriesOptions({
      id,
      timeSeriesOptions,
    });
  }
  return false;
};

/**
 * Finds expressions that not exists in the timeSeriesOptions list,
 * create a array of them and return the size, if the size is more than 1 that means
 * some expression and child expression was delete or is calculating
 *
 * @param {string} id - The id of the expression to find.
 * @param {TimeSeriesField[]} timeSeriesOptions - The array of time series options to search in.
 * @return {boolean} Returns true if the expression is not found, otherwise false.
 */
export const findExpressionInTimeSeriesOptions = ({
  id,
  timeSeriesOptions = [],
}: {
  id: string;
  timeSeriesOptions: TimeSeriesField[];
}) => {
  let result: string[] = [];
  const loopToFindExpression = (id: string) => {
    const expressionExists = timeSeriesOptions.find((ts) => ts.id.includes(id));
    if (!expressionExists) {
      result.push(id);
    } else if (expressionExists.inputIds.length) {
      expressionExists.inputIds.forEach((id) => {
        loopToFindExpression(formatToPermanentId(id));
      });
    }
  };
  loopToFindExpression(id);
  return result.length > 0;
};
