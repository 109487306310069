import styled from "styled-components";

export const NewRepairConceptCostContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  .cost-distribution-header {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .initial-curve-value-input-contariner {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1rem;
    }
  }
  .density-curve-chart {
    margin: 1rem 0;
    width: 100% !important;
    border: 1px solid #eee;
    border-radius: 5px;
  }
  .cost-distribution-footer {
    .total-cost-input {
      max-width: 250px;
    }
  }
`;
