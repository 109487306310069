const TITLE_EXCEPTIONS = [
  "by",
  "and",
  "or",
  "the",
  "a",
  "an",
  "in",
  "on",
  "with",
  "at",
  "to",
  "from",
  "of",
  "vs",
  "vs.",
];

/**
 * Converts a string to title case by capitalizing the first letter of each word,
 * but lowercase for words in the TITLE_EXCEPTIONS array
 * @param  {string} str The string to convert to title case
 * @return {string}     The title cased string
 */
export const toTitleCase = (str: string): string => {
  return str
    .split(" ")
    .map((word, index) => {
      if (index === 0 || !TITLE_EXCEPTIONS.includes(word.toLowerCase())) {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      }
      return word.toLowerCase();
    })
    .join(" ");
};
