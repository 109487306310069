import { useAuth0 } from "@auth0/auth0-react";
import { Layout } from "antd";
import { PropsWithChildren, useCallback } from "react";
import { ErrorBoundary } from "react-error-boundary";

import { GlobalHeader } from "@/components/globalHeader/GlobalHeader";
import SideNav from "@/components/sideNav";
import { RouteChageDetector } from "@/features/RouteChangeDetector";
import useI18nInit from "@/hooks/useI18nInit";
import FallbackPage from "@/pages/FallbackPage";

import { MainContainer, MainLayout } from "./AppMainLayout.styled";

const AppMainLayout = ({ children }: PropsWithChildren<{}>) => {
  const { logout, isAuthenticated, isLoading, user } = useAuth0();
  useI18nInit();

  const handleLogout = useCallback(() => {
    logout({ logoutParams: { returnTo: window.location.origin } });
  }, [logout]);

  if (isLoading) {
    return <div>Loading</div>;
  }

  return (
    <Layout>
      <SideNav name={user?.name} isLoading={isLoading} onLogout={handleLogout} isAuthenticated={isAuthenticated} />
      {isLoading ? (
        <div>Loading</div>
      ) : (
        <MainLayout>
          <GlobalHeader />
          <MainContainer id="main-layout">
            <ErrorBoundary fallbackRender={(fallbackProps) => <FallbackPage {...fallbackProps} />}>
              {children}
              <RouteChageDetector />
            </ErrorBoundary>
          </MainContainer>
        </MainLayout>
      )}
    </Layout>
  );
};

export default AppMainLayout;
