import { TimeSeriesField } from "../../generated/graphql";
import { findItemsWithCircularInputs, formatSeriesInputs } from "./findItemsWithCircularInputs";

export const findCircularDependence = (
  timeSeriesFields: TimeSeriesField[],
  currentInputIds: string[],
  expressionId?: string
) => {
  if (!expressionId) return false;
  const itemsWithInputs = formatSeriesInputs(timeSeriesFields, expressionId, currentInputIds);
  const itemsWithCircularInputs = findItemsWithCircularInputs(itemsWithInputs);
  return itemsWithCircularInputs.length > 0;
};

export const getAllCircularDependencies = (
  timeSeriesFields: TimeSeriesField[],
  currentInputIds: string[],
  expressionId: string = ""
) => {
  const itemsWithInputs = formatSeriesInputs(timeSeriesFields, expressionId, currentInputIds);
  return itemsWithInputs;
};
