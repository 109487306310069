import { SearchOutlined } from "@ant-design/icons";
import { Input } from "antd";
import _ from "lodash";
import { useState } from "react";

import { RepairConcept, useRepairConceptsQuery } from "@/api";
import BlockingLoading from "@/components/ui/BlockLoading";
import { QUERY_SETTINGS, UI_SETTINGS } from "@/constants";
import { requireCurrentCustomerId } from "@/utils/customers";

import RepairConceptList from "../repairConcept/RepairConceptList";
import { RepairConceptPickerContainer } from "./RepairConceptPicker.styled";

interface RepairConceptPickerProps {
  onRepairConceptSelected: (repair: RepairConcept) => void;
  disabledElementIds?: string[];
}

const RepairConceptPicker = ({ onRepairConceptSelected, disabledElementIds = [] }: RepairConceptPickerProps) => {
  const { isLoading, isRefetching, isError, data } = useRepairConceptsQuery(
    { customerId: requireCurrentCustomerId() },
    { staleTime: QUERY_SETTINGS.SHORT_STALE_TIME }
  );

  const [searchTerm, setSearchTerm] = useState("");

  let repairList = (data?.repairConcepts?.repairConcepts || []) as RepairConcept[];

  const onRepairConceptSearch = _.debounce((searchTerm: string) => {
    setSearchTerm(searchTerm);
  }, UI_SETTINGS.SEARCH_INPUT_DEBOUNCE);

  if (searchTerm.length > 0) {
    repairList = repairList.filter((r) => {
      return r.name.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0;
    });
  }

  if (isLoading) return <BlockingLoading className="line" />;

  if (isError) return <p>RepairConceptList unavailable</p>;

  return (
    <RepairConceptPickerContainer>
      <Input
        className="search-repair-concept"
        placeholder="Search for repair concepts"
        onChange={(e) => onRepairConceptSearch(e.target.value)}
        suffix={<SearchOutlined />}
      />
      <RepairConceptList
        repairs={repairList}
        onElementSelected={onRepairConceptSelected}
        alreadySelectedIds={disabledElementIds}
      />
      {isRefetching && <BlockingLoading className="line" />}
    </RepairConceptPickerContainer>
  );
};
export default RepairConceptPicker;
