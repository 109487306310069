import { Flex, Typography } from "antd";
import { ReactElement } from "react";

import { TitleHeaderContainer } from "./PageContent.styled";

export type TopHeadingWithButtonsProps = {
  title: ReactElement | string | undefined | null | false;
  buttons?: ReactElement | string | undefined | null | false;
  description?: ReactElement | string | undefined | null | false;
};
export const TopHeadingWithButtons = ({ title, buttons, description }: TopHeadingWithButtonsProps) => {
  return (
    <TitleHeaderContainer>
      <Flex justify="space-between" vertical gap={"0.5rem"}>
        <Typography.Title className="heading-large" level={2}>
          {title}
        </Typography.Title>
        {description && <Typography.Text type="secondary">{description}</Typography.Text>}
      </Flex>
      <Flex justify="flex-end" align="center" gap={"0.5rem"}>
        {buttons}
      </Flex>
    </TitleHeaderContainer>
  );
};
