import { withAuthenticationRequired } from "@auth0/auth0-react";
import isExternalAuthProvider from "@lib/src/auth/isExternalAuthProvider";
import { useLocation, useSearch } from "@tanstack/react-router";
import { Result } from "antd";
import React, { ComponentType, useMemo } from "react";

import Loading from "@/components/loading";
import { usePendo } from "@/hooks/usePendo";
import { useSessionId } from "@/hooks/useSessionId";

export default function RequireAuth<Props extends object>(
  props: { component: ComponentType<Props>; title?: string; path?: string } & Props
) {
  const pathname = useLocation({
    select: (location) => location.pathname,
  });
  const searchParams = useSearch({ strict: false });
  const ProtectedComponent = useMemo(() => {
    return withAuthenticationRequired<Props>(props.component, {
      onRedirecting: () => <Loading fullHeight />,
    });
  }, [props.component]);

  usePendo();

  useSessionId();

  // For frontend tests: do not require Auth0
  if (isExternalAuthProvider()) {
    return React.createElement(props.component, props);
  }

  // Special case here for an authentication error
  if (pathname == "/auth" && Object.keys(searchParams).includes("error")) {
    return (
      <Result
        status="error"
        title="Sorry, something went wrong."
        subTitle={Object.keys(searchParams).includes("error_description")}
      />
    );
  }
  return <ProtectedComponent {...props} />;
}
