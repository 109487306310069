import { InfoCircleOutlined } from "@ant-design/icons";
import { Form } from "antd";
import styled from "styled-components";

export const StyledFormItem = styled(Form.Item)`
  margin-bottom: 15px;
  label {
    font-size: 12px;
  }
  .form-input-container {
    position: relative;
    .form-input-error {
      position: absolute;
      left: 0;
      top: calc(100% - 2px);
    }
  }
`;

export const StyledInfoCircleOutlined = styled(InfoCircleOutlined)`
  margin-left: 10px;
`;
