interface AppVersionProps {
  onSelected?: (version: string) => void;
}

const AppVersion = ({ onSelected }: AppVersionProps) => {
  const versionLabel = `${import.meta.env.VITE_APP_VERSION}` || "Unknown Version";
  return (
    <span className="app-version-label" onClick={() => (onSelected ? onSelected(versionLabel) : undefined)}>
      {versionLabel}
    </span>
  );
};

export default AppVersion;
