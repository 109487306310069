import { LiteralNumericExpression } from "@lib/src/expression/types";
import { Flex, InputNumber } from "antd";

import { ExpressionInputProps } from "./ExpressionInput";

export const LiteralNumericExpressionInput = (props: ExpressionInputProps<LiteralNumericExpression>) => {
  const { expression, path, onChange, disabled } = props;

  return (
    <Flex vertical>
      <InputNumber
        style={{
          width: "100%",
        }}
        type="number"
        defaultValue={Number(expression.value)}
        onChange={(value) => onChange?.({ ...expression, value: value ?? 0 }, path)}
        value={expression.value}
        disabled={!onChange || disabled}
      />
    </Flex>
  );
};
