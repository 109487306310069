import styled from "styled-components";

export const ThresholdTitle = styled.div`
  display: flex;
  align-items: center;
  .ThresholdTitle-Title,
  .ThresholdTitle-Info {
    margin-right: 10px;
  }
`;
