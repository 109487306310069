import styled from "styled-components";

export const FaultCategoryElementsContainer = styled.div`
  display: flex;
  flex-direction: column;

  .fault-item-container {
    padding: 0.5rem 1rem;
    :not(:first-child) {
      border-top-style: solid;
      border-top-width: 1px;
      border-top-color: ${({ theme }) => theme.colors.lightGray};
    }
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    .library-item-switch {
      width: 2rem;
    }
    .label,
    .description {
      font-size: 13px;
    }
  }
`;
