import { DeleteOutlined } from "@ant-design/icons";
import { Button, Popconfirm } from "antd";
import { NotificationInstance } from "antd/lib/notification/interface";
import { useState } from "react";

import { useDeleteClusterMutation } from "@/api";
import { getCurrentCustomerId } from "@/utils/customers";

export type DeleteClusterModalProps = {
  api: NotificationInstance;
  clusterId: string;
  onSuccess: () => void;
};
export const DeleteClusterModal = ({ api, onSuccess, clusterId }: DeleteClusterModalProps) => {
  const [isDeletingCluster, setIsDeletingCluster] = useState(false);

  const deleteCluster = useDeleteClusterMutation();

  const handleDelete = () => {
    setIsDeletingCluster(true);
    deleteCluster.mutate(
      {
        customerId: getCurrentCustomerId() ?? "",
        clusterId,
      },
      {
        onSuccess: (data) => {
          const idDeleted = data.clusters?.deleteCluster;
          if (idDeleted) {
            api.success({ message: "Cluster successfully deleted" });
          }
          onSuccess();
        },
        onSettled: () => {
          setIsDeletingCluster(false);
        },
      }
    );
  };

  return (
    <Popconfirm
      title="Are you sure to delete this cluster?"
      okText="Yes"
      cancelText="No"
      onConfirm={handleDelete}
      disabled={isDeletingCluster}
    >
      <Button icon={<DeleteOutlined />} disabled={isDeletingCluster} danger style={{ marginRight: "auto" }} />
    </Popconfirm>
  );
};
