import { InfoCircleOutlined } from "@ant-design/icons";
import { DEFAULT_PAGE_SIZE } from "@lib/src/DEFAULT_PAGE_SIZE";
import { useNavigate, useSearch } from "@tanstack/react-router";
import { Button, Flex, Form, Select } from "antd";
import { ColumnType } from "antd/lib/table";
import _ from "lodash";
import { useContext, useEffect, useId, useRef, useState } from "react";

import { Pagination } from "@/api";
import {
  Fault,
  FaultHistoryFilterData,
  useFaultHistoryColumnsFilterQuery,
  useVehicleFaultHistoryQuery,
} from "@/api/customerApi";
import { BasicTable } from "@/components/tables/BasicTable";
import StyledPopover from "@/components/ui/StyledPopover";
import StyledTooltip from "@/components/ui/StyledTooltip";
import { BasicWidget } from "@/components/widget/BasicWidget";
import { QUERY_SETTINGS } from "@/constants";
import SCVContext from "@/contexts/SCVContext";
import { useCustomerIdentifier } from "@/hooks/useCustomerIdentifier";
import { useTablePagination } from "@/hooks/useTablePagination";
import { getFaultDescription } from "@/utils/fault/getFaultDescription";
import { formatNumber } from "@/utils/numberUtils";
import { CommonDataDictionary } from "@/utils/types/commonDataDictionary";

import FaultDetailsModal from "./FaultDetailsModal";
import FaultEventAnalysisLink from "./FaultEventAnalysisLink";

export const INFERRED_DATE_JSX_MSG = (
  <span>
    Fault timestamp unavailable <br /> FAP timestamp is used
  </span>
);

type FilterOptions = {
  ecu: string[];
  troubleCode: string[];
  faultCode: string[];
};

const getFilterCount = (filterOptions?: FilterOptions): number => {
  if (!filterOptions) return 0;
  return Object.values(filterOptions).reduce((acc, curr) => {
    return acc + (curr?.length || 0);
  }, 0);
};

const getFilterLabel = (count: number): string => {
  return count ? (count === 1 ? `1 Filter Applied` : `${count} Filters Applied`) : "No Filters";
};

interface FaultHistoryFiltersProps {
  options: FilterOptions | undefined;
  onApplyFilters: (props: FilterOptions | undefined) => void;
  onResetFilters: () => void;
  faultFilterOptions: FaultHistoryFilterData[];
}

const formatOptions = (
  input: string[] | undefined,
  customerIdentifier: CommonDataDictionary | undefined = undefined
): { label: string; value: string }[] =>
  [...new Set(input)].map((v) => {
    const faultName = customerIdentifier ? getFaultDescription(customerIdentifier, v)?.name : undefined;
    return {
      label: customerIdentifier ? `${v}${faultName ? ` - ${faultName}` : ""}` : v,
      value: v,
    };
  });

const FaultHistoryFilters = ({ options, onApplyFilters, onResetFilters }: FaultHistoryFiltersProps) => {
  const { customerIdentifier } = useCustomerIdentifier();
  const [form] = Form.useForm();

  const resetFilters = () => {
    form.resetFields();
    onResetFilters();
  };

  useEffect(() => {
    if (options) {
      form.setFieldValue("ecu", _.intersection(options.ecu, form.getFieldValue("ecu")));
      form.setFieldValue("faultCode", _.intersection(options.faultCode, form.getFieldValue("faultCode")));
      form.setFieldValue("troubleCode", _.intersection(options.troubleCode, form.getFieldValue("troubleCode")));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  return (
    <BasicWidget bordered={false} className="fault-history-filters" title="Fault History Filters">
      <Form<FilterOptions>
        form={form}
        layout="vertical"
        name="basic"
        onFinish={onApplyFilters}
        style={{ padding: "1.5rem" }}
        className="body-medium"
      >
        <Form.Item label="ECU" name="ecu">
          <Select
            maxTagCount={"responsive"}
            mode="multiple"
            options={formatOptions(options?.ecu)}
            placeholder="Filter by ECU"
            onDeselect={() => console.log("onDeselect")}
          ></Select>
        </Form.Item>
        <Form.Item label="Fault Code" name="faultCode">
          <Select
            maxTagCount={"responsive"}
            mode="multiple"
            options={formatOptions(options?.faultCode, customerIdentifier)}
            placeholder="Filter by Fault codes"
          ></Select>
        </Form.Item>
        <Form.Item label="Trouble Code" name="troubleCode">
          <Select
            maxTagCount={"responsive"}
            mode="multiple"
            options={formatOptions(options?.troubleCode)}
            placeholder="Filter by Trouble codes"
          ></Select>
        </Form.Item>
        <Form.Item>
          <Flex justify="end" gap={"1rem"}>
            <Button onClick={resetFilters} type="text">
              Reset Filters
            </Button>
            <Button type="primary" htmlType="submit">
              Apply Filters
            </Button>
          </Flex>
        </Form.Item>
      </Form>
    </BasicWidget>
  );
};

const buildFaultStartLabel = (fault: Fault) => {
  const { startTime } = fault;
  if (!startTime) return "N/A";
  return new Date(startTime).toLocaleString();
};

const FaultHistoryByVehicle = () => {
  const popoverRef = useRef<HTMLDivElement>(null);
  const { pvin } = useContext(SCVContext);
  const { customerIdentifier } = useCustomerIdentifier();
  const { sortersInput, onChange, resetPagination } = useTablePagination<Fault>();
  const [showFilter, setShowFilter] = useState(false);
  const [filterOptions, setFilterOptions] = useState<FilterOptions | undefined>(undefined);
  const [selectedFaultId, setSelectedFaultId] = useState("");
  // const [currentOptions, setCurrentOptions] = useState<FilterOptions | undefined>(undefined);

  const tableId = useId();

  const currentPage = useSearch({ strict: false, select: (search) => search.faultHistoryPage });
  const navigate = useNavigate();

  const pagination: Pagination = {
    currentPage: currentPage || 1,
    pageSize: DEFAULT_PAGE_SIZE,
  };

  const handlePaginationChange = (page: number) => {
    navigate({ search: (prev) => ({ ...prev, faultHistoryPage: page }) });
  };

  const { data, isFetching } = useVehicleFaultHistoryQuery(
    {
      id: pvin,
      pagination,
      filter: filterOptions,
      sort: sortersInput,
    },
    { staleTime: QUERY_SETTINGS.DEFAULT_STALE_TIME }
  );

  const { data: faultFilterData, isFetching: isFetchingFaultFilterData } = useFaultHistoryColumnsFilterQuery(
    {
      pvin,
    },
    { staleTime: QUERY_SETTINGS.DEFAULT_STALE_TIME }
  );

  const faultFilterOptions = faultFilterData?.faultHistoryByUserInput ?? [];

  const filterByFilterKey = (key: string, option: string): boolean => {
    if (!filterOptions) return true;
    const values = filterOptions[key as keyof FilterOptions];
    if (_.isEmpty(values)) return true;
    return values.includes(option);
  };

  const filterEcu = () =>
    _.uniq(
      faultFilterOptions
        .filter((options) => filterByFilterKey("faultCode", options.faultCode!))
        .filter((options) => filterByFilterKey("troubleCode", options.troubleCode!))
        .filter((options) => filterByFilterKey("ecu", options.ecu!))
        .map(({ ecu }) => ecu) as string[]
    ) ?? [];

  const filterFaultCodes = () =>
    _.uniq(
      faultFilterOptions
        .filter((options) => filterByFilterKey("ecu", options.ecu!))
        .filter((options) => filterByFilterKey("troubleCode", options.troubleCode!))
        .filter((options) => filterByFilterKey("faultCode", options.faultCode!))
        .map(({ faultCode }) => faultCode) as string[]
    ) ?? [];

  const filterTroubleCodes = () =>
    _.uniq(
      faultFilterOptions
        .filter((options) => filterByFilterKey("ecu", options.ecu!))
        .filter((options) => filterByFilterKey("faultCode", options.faultCode!))
        .filter((options) => filterByFilterKey("troubleCode", options.troubleCode!))
        .map(({ troubleCode }) => troubleCode) as string[]
    ) ?? [];

  const availableOptions: FilterOptions | undefined = !_.isEmpty(faultFilterOptions)
    ? {
        ecu: filterEcu(),
        faultCode: filterFaultCodes(),
        troubleCode: filterTroubleCodes(),
      }
    : undefined;

  // New object with the intersections between the filter and the results in the table
  const cleanFilterOptions = {
    ecu: _.intersection(filterOptions?.ecu, availableOptions?.ecu),
    faultCode: _.intersection(filterOptions?.faultCode, availableOptions?.faultCode),
    troubleCode: _.intersection(filterOptions?.troubleCode, availableOptions?.troubleCode),
  };

  const filtersAppliedCount = getFilterCount(cleanFilterOptions);

  const faultData = data?.vehicleFaultHistory.data ?? [];

  const columns: ColumnType<Fault>[] = [
    {
      key: "isDateInferred",
      title: " ",
      dataIndex: "isDateInferred",
      render: (_: any, row: Fault) => {
        if (row.isDateInferred)
          return (
            <StyledTooltip title={INFERRED_DATE_JSX_MSG} showArrow={false}>
              <span className="fault-start-date error">
                <InfoCircleOutlined />
              </span>
            </StyledTooltip>
          );
      },
      width: 48,
    },
    {
      key: "startTime",
      title: "Fault Start",
      dataIndex: "startTime",
      render: (_: any, row: Fault) => <span>{buildFaultStartLabel(row)}</span>,
      // Ellipsis dots should be red if isDateInferred
      onCell: (row: Fault) => ({ className: row.isDateInferred ? "error" : "" }),
      tooltipMessage: (_: any, row: Fault) => buildFaultStartLabel(row),
      sorter: true,
      defaultSortOrder: "descend",
      align: "start",
    },

    {
      key: "odometer",
      title: "Odometer (km)",
      dataIndex: "odometer",
      render: (_: any, row: Fault) => formatNumber(row.odometer),
      sorter: true,
    },
    {
      key: "faultCode",
      title: "Fault Code",
      dataIndex: "faultCode",
      sorter: true,
      render: (_: any, row: Fault) => row.code,
      tooltipMessage(_value, record) {
        return getFaultDescription(customerIdentifier, record.code)?.name ?? "";
      },
    },
    {
      key: "troubleCode",
      title: "Trouble Code",
      dataIndex: "troubleCode",
      sorter: true,
    },
    {
      key: "source",
      title: "ECU",
      dataIndex: "source",
    },
    {
      key: "troubleCodeDescription",
      title: "Trouble Code Description",
      dataIndex: "troubleCodeDesc",
      tooltipMessage: (_: any, row: Fault) => {
        const val = customerIdentifier?.faultCodes?.find((v) => v.troubleCode === row.troubleCode);
        return val?.name ?? "N/A";
      },
      render: (_: any, row: Fault) => {
        const val = customerIdentifier?.faultCodes?.find((v) => v.troubleCode === row.troubleCode);
        return val?.name ?? "N/A";
      },
    },
    {
      key: "softwareVersion",
      title: "Software Version",
      dataIndex: "softwareVersion",
      sorter: true,
    },
    {
      key: "hardwareVersion",
      title: "Hardware Version",
      dataIndex: "hardwareVersion",
      sorter: true,
    },
    {
      key: "softwarePartNumber",
      title: "Software Part Number",
      dataIndex: "softwarePartNumber",
      sorter: true,
    },
    {
      key: "hardwarePartNumber",
      title: "Hardware Part ",
      dataIndex: "hardwarePartNumber",
      sorter: true,
      ellipsis: true,
    },
    {
      key: "toEventAnalysis",
      render: (_: any, row: Fault) => <FaultEventAnalysisLink fault={row} rootElementId={tableId} />,
      tooltipMessage: () => "",
      width: 72,
    },
  ];

  const handleClickOutside = (event: MouseEvent) => {
    if (popoverRef.current && !popoverRef.current.contains(event.target as Node)) {
      setShowFilter(false);
    }
  };

  useEffect(() => {
    if (popoverRef.current) {
      document.addEventListener("click", handleClickOutside);
    }
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const onFilterOptions = (props: FilterOptions | undefined) => {
    setFilterOptions(props);
    setShowFilter(false);
    handlePaginationChange(1);
    resetPagination();
  };

  const onResetFilters = () => {
    setFilterOptions(undefined);
    setShowFilter(false);
    handlePaginationChange(1);
    resetPagination();
  };

  const selectedFault = data?.vehicleFaultHistory.data.find((f) => f.id === selectedFaultId) satisfies
    | Fault
    | undefined as Fault | undefined;

  return (
    <BasicWidget
      title="Fault History"
      titleRightElement={
        <StyledPopover
          ref={popoverRef}
          open={showFilter}
          content={
            <FaultHistoryFilters
              onApplyFilters={onFilterOptions}
              onResetFilters={onResetFilters}
              options={availableOptions}
              faultFilterOptions={faultFilterOptions}
            />
          }
          placement="bottomLeft"
          trigger="click"
          arrow={false}
        >
          <Button
            loading={isFetchingFaultFilterData}
            disabled={isFetchingFaultFilterData}
            onClick={() => setShowFilter(true)}
          >
            {getFilterLabel(filtersAppliedCount)}
          </Button>
        </StyledPopover>
      }
    >
      <BasicTable<Fault>
        id={tableId}
        columns={columns}
        rowKey={(v) => v.id}
        dataSource={faultData}
        loading={isFetching}
        onChange={(pagination, _filter, sorter) => {
          onChange(pagination, {}, sorter);
          handlePaginationChange(pagination.current || 1);
        }}
        pagination={{
          current: pagination.currentPage,
          total: data?.vehicleFaultHistory.pagination?.totalCount,
          showSizeChanger: false,
        }}
        style={{ minHeight: 400 }}
        rowClassName={(fault) => `cta-row${fault.id === selectedFaultId ? " highlighted" : ""}`}
        onRow={(fault) => {
          return {
            onClick: () => setSelectedFaultId(fault.id),
          };
        }}
      />
      {selectedFault ? <FaultDetailsModal fault={selectedFault} onClose={() => setSelectedFaultId("")} /> : undefined}
    </BasicWidget>
  );
};

export default FaultHistoryByVehicle;
