import { DEFAULT_PAGE_SIZE } from "@lib/src/DEFAULT_PAGE_SIZE";
import { SorterResult, SortOrder } from "antd/lib/table/interface";

import { Pagination } from "@/api";
import {
  AnomaliesWithVehicleCountSortField,
  AnomaliesWithVehicleCountSortInput,
  AnomalyDetectorWithVehicleData,
  useAnomalySummaryQuery,
} from "@/api/customerApi";
import { useRemoteSorting } from "@/components/tables/useRemoteSorting";
import { Route } from "@/routes/anomaly-detector.index";

import { BasicTable } from "../../../components/tables/BasicTable";
import { useAnomalySummaryQueryParams } from "../hooks/useAnomalySummaryQueryparams";
import { AnomalyDetectorListContainer } from "./AnomalyDetectorHome.styled";
import { anomalySummaryCommonColumns } from "./anomalySummaryCommonColumns";
import { anomalySummaryExtraColumns } from "./anomalySummaryExtraColumns";

export const AnomalySummaryTable = () => {
  const { filter, onChange: handleTableChangeForPagination } = useAnomalySummaryQueryParams();

  const { sorting, handleTableChangeForSorting, toMaybeColumnWithInitialSorting } = useRemoteSorting({
    fieldsEnumOptions: Object.values(AnomaliesWithVehicleCountSortField),
    initialValue: { field: AnomaliesWithVehicleCountSortField.AnomalyVehicleCount, order: "descend" as SortOrder },
  });

  const currentPage = Route.useSearch({ select: (search) => search.anomaliesPage });
  const anomalyNavigate = Route.useNavigate();

  const pagination: Pagination = { currentPage: currentPage || 1, pageSize: DEFAULT_PAGE_SIZE };

  const handlePaginationChange = (page: number) => {
    anomalyNavigate({ search: (prev) => ({ ...prev, anomaliesPage: page }) });
  };

  const { data, isFetching } = useAnomalySummaryQuery({
    filter: filter,
    pagination,
    sorting: sorting as AnomaliesWithVehicleCountSortInput,
  });

  const columns = [...anomalySummaryCommonColumns, ...anomalySummaryExtraColumns].map(toMaybeColumnWithInitialSorting);
  const total = data?.filteredQuery?.anomaliesWithVehicleCount?.pagination?.totalCount;
  const anomalies = (data?.filteredQuery?.anomaliesWithVehicleCount?.items || []) as AnomalyDetectorWithVehicleData[];

  const handleTableChange: typeof handleTableChangeForPagination = (
    changingPagination,
    changingFilters,
    changingSorter
  ) => {
    handleTableChangeForSorting(changingPagination, changingFilters, changingSorter as SorterResult<unknown>);
  };

  return (
    <AnomalyDetectorListContainer>
      <BasicTable
        dataSource={anomalies}
        columns={columns}
        onChange={handleTableChange}
        pagination={{
          current: pagination.currentPage,
          total,
          onChange: handlePaginationChange,
          showSizeChanger: false,
        }}
        loading={isFetching}
        rowKey={(row) => row.detector.id}
      />
    </AnomalyDetectorListContainer>
  );
};
