import styled from "styled-components";

export const LibraryChartPreviewListContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.lightGray};
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1px;
  // show only one chart per row if the space is too narrow (<1646px)
  @media (max-width: calc(${({ theme }) => theme.breakpoints.md} + 560px/2)) {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  &.one-row {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }

  .preview-chart-container {
    background-color: ${({ theme }) => theme.colors.smoothGray};
    .library-chart-preview-item {
      min-width: 560px;
      .library-preview-chart {
        height: 300px !important;
      }
    }
  }
`;

export const LibraryChartPreviewHeaderContainer = styled.div`
  padding: 1rem 1.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  .header-tools {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    svg {
      width: 1rem;
    }
  }
`;
