const honkda = "honkda";

const hostnameRegex = new RegExp(`(?:([^\\.]+)\\.)?${import.meta.env.VITE_DOMAIN_NAME}`);

export function requireCurrentCustomerId(): string {
  const customerId = getCurrentCustomerId();
  if (!customerId) {
    throw new Error("Customer id required");
  }
  return customerId;
}

export function getCurrentCustomerId(): string | undefined {
  let url = new URL(window.location.href);
  return hostnameRegex.exec(url.hostname)?.[1];
}

export function isHonkdaCustomer(): Boolean {
  return getCurrentCustomerId() === honkda;
}

export function constructBaseAppUrl(customerId?: String) {
  let url = new URL(window.location.href);
  return `${url.protocol}//${customerId ? `${customerId}.` : ""}${import.meta.env.VITE_DOMAIN_NAME}${
    url.port ? `:${url.port}` : ""
  }`;
}
