import { Col, Form, Input, Modal } from "antd";
import { useEffect, useState } from "react";

export type SaveAsModalProps = {
  title: string;
  originalData: SaveCopy;
  isVisible: boolean;
  isLoading: boolean;
  onOk: (savedCopy: SaveCopy) => any;
  onCancel: () => any;
};

interface SaveCopy {
  name: string;
  description?: string;
}

export const SaveAsModal = ({ title, isVisible, onOk, onCancel, isLoading, originalData }: SaveAsModalProps) => {
  const [form] = Form.useForm<SaveCopy>();
  const [submittable, setSubmittable] = useState(false);

  const values = Form.useWatch([], form);

  useEffect(() => {
    form
      .validateFields({
        validateOnly: true,
      })
      .then(() => {
        setSubmittable(values.name != originalData.name);
      })
      .catch(() => setSubmittable(false));
  }, [form, values, originalData]);

  const onSubmit = () => {
    const formValues = form.getFieldsValue();
    onOk(formValues);
  };

  return (
    <Modal
      open={isVisible}
      title={title}
      okText="Create"
      okButtonProps={{
        disabled: !submittable,
      }}
      closable={!isLoading}
      destroyOnClose
      onOk={onSubmit}
      onCancel={onCancel}
      confirmLoading={isLoading}
      maskClosable={!isLoading}
    >
      <Form
        layout="vertical"
        form={form}
        initialValues={{
          name: `${originalData.name} - copy`,
          description: originalData.description ?? "",
        }}
      >
        <Col span={24}>
          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <Input placeholder="Pattern name copy" autoFocus name="name" disabled={isLoading} />
          </Form.Item>
          <Form.Item label="Description" name="description">
            <Input placeholder="Pattern description copy" autoFocus name="description" disabled={isLoading} />
          </Form.Item>
        </Col>
      </Form>
    </Modal>
  );
};
