import styled from "styled-components";

export const ExpressionEventModalHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  .modal-header-title {
    font-family: "Hubot Sans";
    font-size: 18px;
    font-weight: 700;
  }
  .modal-header-description {
    font-family: "Inter";
    font-size: 14px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.darkGray};
  }
`;

export const ExpressionEventModalContent = styled.div`
  display: flex;
  gap: 12px;
`;

export const ExpressionEventModalFormContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 35%;
`;

export const ExpressionEventModalFormActions = styled.div`
  display: flex;
  gap: 8px;
  justify-content: end;
`;

export const ExpressionEventPreviewContent = styled.div`
  width: 65%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .echarts {
    width: 100%;
    height: 100% !important;
    margin: 0;
  }
`;

export const ExpressionEventPreviewContentEmpty = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  .pullsystems-result {
    padding: 0;
  }
`;
