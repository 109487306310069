import chunk from "lodash/chunk";
import reduce from "lodash/reduce";
import type { Dispatch, SetStateAction } from "react";

import { ClusterRedshift, ClusterRedshiftCreateInput, ClusterUpdateAction, useUpdateClusterMutation } from "@/api";

export interface AddVehiclesToClusterInBatchParams {
  customerId: string;
  clusterId: string;
  clusterVersion: number;
  updateCluster: ReturnType<typeof useUpdateClusterMutation>;
  input: ClusterRedshiftCreateInput[];
  onStateChange: Dispatch<SetStateAction<BatchAddVehiclesToClusterState>>;
  onResult: (resulting: {
    isAllSuccessfullyDone: boolean;
    accumulatedMissingPvins: string[];
    accumulatedRepeatedCombinations: ClusterRedshift[];
  }) => void;
}

export function addVehiclesToClusterInBatch(params: AddVehiclesToClusterInBatchParams): void {
  const run = async (): Promise<void> => {
    const batchs = chunk(params.input, ADD_VEHICLES_TO_CLUSTER_BATCH_SIZE);

    let accumulatedMissingPvins: string[] = [];
    let accumulatedRepeatedCombinations: ClusterRedshift[] = [];

    const chainedPromisedBatchs: Promise<void> = reduce(
      batchs,
      (chained, batch, batchIndex) =>
        chained.then(
          () =>
            new Promise((resolve, reject) => {
              params.updateCluster.mutate(
                {
                  customerId: params.customerId,
                  clusterId: params.clusterId,
                  action: ClusterUpdateAction.AddPvins,
                  version: params.clusterVersion + batchIndex,
                  input: batch,
                },
                {
                  onSuccess: (data) => {
                    const result = data.clusters?.updateClusterPvins;
                    if (result) {
                      params.onStateChange((state) => ({
                        ...state,
                        currentBatchIndex: batchIndex + 1,
                        successPvins: [...state.successPvins, ...batch.map((it) => it.pvin)],
                      }));
                      accumulatedMissingPvins.push(...result.missingPvins);
                      accumulatedRepeatedCombinations.push(...result.repeatedCombinations);
                      resolve();
                    } else {
                      console.error("Unknown error while updating batch", batch);
                      params.onStateChange((state) => ({
                        ...state,
                        currentBatchIndex: batchIndex + 1,
                        failedPvins: [...state.failedPvins, ...batch.map((it) => it.pvin)],
                      }));
                      reject();
                    }
                  },
                  onError: () => {
                    console.error("Unexpected error while updating batch", batch);
                    params.onStateChange((state) => ({
                      ...state,
                      currentBatchIndex: batchIndex + 1,
                      failedPvins: [...state.failedPvins, ...batch.map((it) => it.pvin)],
                    }));
                    reject();
                  },
                }
              );
            })
        ),
      Promise.resolve()
    );

    try {
      params.onStateChange({
        ...makeInitialBatchAddVehiclesToClusterState(),
        isUpdating: true,
        totalBatchesQtt: batchs.length,
      });

      await chainedPromisedBatchs;

      params.onResult({
        isAllSuccessfullyDone: true,
        accumulatedMissingPvins,
        accumulatedRepeatedCombinations,
      });
    } catch (error) {
      params.onResult({
        isAllSuccessfullyDone: false,
        accumulatedMissingPvins,
        accumulatedRepeatedCombinations,
      });
    } finally {
      params.onStateChange((state) => ({
        ...state,
        isUpdating: false,
      }));
    }
  };

  // fire and forget, being monitored by the callbacks.
  run();
}

export const ADD_VEHICLES_TO_CLUSTER_BATCH_SIZE = 30;

export interface BatchAddVehiclesToClusterState {
  isUpdating: boolean;
  currentBatchIndex: number;
  totalBatchesQtt: number;
  successPvins: string[];
  failedPvins: string[];
}

export function makeInitialBatchAddVehiclesToClusterState(): BatchAddVehiclesToClusterState {
  return {
    isUpdating: false,
    currentBatchIndex: 0,
    totalBatchesQtt: 0,
    successPvins: [],
    failedPvins: [],
  };
}
