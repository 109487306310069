export const downsizeArray = (input: (number | null)[], scale: number): number[] => {
  if (!input || input.length === 0) {
    return [];
  }
  return input.filter((_e, i) => i % scale === 0).map((e) => (e ? e : 0));
};

export const findClosest = (pool: number[], value?: number): number => {
  return pool.reduce((best: number, current: number) => {
    return current >= (value || 0) && (!best || current < best) ? current : best;
  }, 0);
};

/**
 * converts input to an array if input is not an array already
 * @param input T | T[]
 * @returns T[]
 */
export const toArray = <T>(input: T | T[]): T[] => {
  if (!Array.isArray(input)) return [input];
  return input;
};

/**
 *
 * @param availableIntervals
 * @param range
 * @param maxIntervals
 * @returns the smallest interval in availableIntervals such that range / interval <= maxIntervals
 */
export const findIntervalSize = (availableIntervals: number[], range: number, maxIntervals: number): number =>
  availableIntervals.find((i) => range / i <= maxIntervals) ?? availableIntervals.at(-1) ?? availableIntervals[0];
