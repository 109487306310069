import styled from "styled-components";

export const LoadingContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  .loading-mask {
    z-index: 2;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: ${({ theme }) => theme.colors.defaultWhite};
    opacity: 0.8;
  }
  .loading-spinner {
    z-index: 3;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`;
