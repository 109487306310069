import { useSelector } from "react-redux";

import BlockingLoading from "@/components/ui/BlockLoading";
import { BasicWidget } from "@/components/widget/BasicWidget";
import { selectEventFilter } from "@/contexts/EventAnalysisChartStore";

import { RelatedEventsHeaderContainer } from "./RelatedEvents.styled";
import RelatedEventTable from "./RelatedEventTable";

const RelatedEvents = () => {
  const { selectedEventFilter: eventFilter } = useSelector(selectEventFilter);

  if (!eventFilter) {
    return <BlockingLoading className="line" />;
  }

  const relatedEventsHeader = (
    <RelatedEventsHeaderContainer>
      <div>
        <h3 className="heading-x-small">Related Events</h3>
      </div>
    </RelatedEventsHeaderContainer>
  );

  return (
    <BasicWidget className="table related-event-table" title={relatedEventsHeader}>
      <RelatedEventTable eventFilter={eventFilter} />
    </BasicWidget>
  );
};

export default RelatedEvents;
