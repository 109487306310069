import { EChartsReactProps } from "echarts-for-react";
import { MutableRefObject } from "react";

import { toArray } from "@/utils/arrayUtils";
import { SingleOrArray } from "@/utils/SingleOrArray";
import { toPercent } from "@/utils/toPercent";

import { labelCodec } from "./labelCodec";
import { InputWeightData } from "./SignalData";

export const buildAnomalySignalsWeightsChartConfig = (
  signalList: InputWeightData[],
  isAnomalous: boolean,
  axisLabelRef?: MutableRefObject<string | undefined> | undefined
): EChartsReactProps => {
  const leftLabelWidth = 175;
  const leftMarginWidth = -35;
  const barWidth = 15;
  const barGap = 15;
  const valueFormatter = (p: { value: number }) => toPercent((p.value || 0) / 100);
  return {
    option: {
      animation: false,
      textStyle: {
        fontFamily: "Hubot Sans",
      },
      tooltip: {
        trigger: "axis",
        show: true,
        axisPointer: {
          type: "shadow",
        },
        formatter: (params: SingleOrArray<{ value: number; axisValueLabel: string }>) => {
          if (axisLabelRef?.current) return axisLabelRef.current;
          return toArray(params)
            .map((p) =>
              isAnomalous
                ? `${labelCodec.decodeFriendlyName(p.axisValueLabel)}<br>${valueFormatter(p)}`
                : "Relevant only when anomalous"
            )
            .join("\n");
        },
        textStyle: {
          align: "right",
        },
      },
      grid: {
        top: 10,
        left: leftLabelWidth,
        containLabel: false,
        height: (barWidth + barGap) * signalList.length,
      },
      xAxis: {
        type: "value",
        splitLine: { show: false },
        axisLine: { show: false },
        axisLabel: { show: false },
        axisTick: { show: false },
      },
      yAxis: {
        type: "category",
        axisLine: { show: isAnomalous },
        axisTick: { show: false },
        splitLine: { show: true },
        verticalAlign: "top",
        axisLabel: {
          fontWeight: "bold",
          formatter: labelCodec.decodeFriendlyName,
          margin: leftLabelWidth,
          splitArea: "show",
          width: leftLabelWidth + leftMarginWidth,
          overflow: "truncate",
          ellipsis: "...",
          color: "#0094ff",
          align: "left",
        },
        triggerEvent: true,
        data: signalList.map(labelCodec.encode),
      },
      series: [
        {
          type: "bar",
          barWidth,
          barGap: 5,
          itemStyle: {
            borderRadius: 4,
            color: isAnomalous ? "#3A404A" : "#d4d5da",
          },
          emphasis: {
            itemStyle: {
              color: "#BD9A64",
            },
          },
          label: {
            show: isAnomalous,
            formatter: valueFormatter,
            position: "outside",
          },
          data: signalList.map((s) => (isAnomalous ? s.value : 100 / signalList.length)),
        },
      ],
    },
  };
};
