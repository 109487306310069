import { TimeSeriesField, TimeSeriesFieldFamily } from "@/api";
import { useTimeSeriesFields } from "@/hooks/useTimeSeriesFields";
import { isFieldTypeSupported } from "@/utils/types/fieldTypeSupported";

export const filterTimeSeriesOptions = (
  timeSeriesFields: TimeSeriesField[] | undefined,
  typeFilter: TimeSeriesFieldFamily | undefined
) => {
  if (!timeSeriesFields) return [];
  return timeSeriesFields
    ?.filter((tf) => (typeFilter ? tf.family == typeFilter : true) && tf.inputIds && isFieldTypeSupported(tf))
    .flatMap((field) => ({
      ...field,
    }));
};

export default ({ typeFilter }: { typeFilter: TimeSeriesFieldFamily | undefined }) => {
  const { all: timeSeriesFields, isLoading: isTimeSeriesLoading } = useTimeSeriesFields();
  return {
    isLoading: isTimeSeriesLoading,
    timeSeriesOptions: filterTimeSeriesOptions(timeSeriesFields, typeFilter),
  };
};
