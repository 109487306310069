import { TimeSeriesField, TimeSeriesFieldFamily, useTimeSeriesFieldsQuery } from "@/api";
import { QUERY_SETTINGS } from "@/constants";
import { getCurrentCustomerId } from "@/utils/customers";

export type ByFieldId = (fieldId: string) => TimeSeriesField | undefined;
export type ById = (id: string) => TimeSeriesField | undefined;
export type ByIdAndFamily = (id: string, family: TimeSeriesFieldFamily) => TimeSeriesField | undefined;

export interface TimeSeriesFields {
  readonly all: TimeSeriesField[];
  readonly isLoading: boolean;
  byId: ById;
  byFieldId: ByFieldId;
  byFieldIdAndFamily: ByIdAndFamily;
}

export function useTimeSeriesFields(): TimeSeriesFields {
  const { data: timeSeriesFields, isLoading } = useTimeSeriesFieldsQuery(
    { customerId: getCurrentCustomerId() ?? "" },
    { staleTime: QUERY_SETTINGS.DEFAULT_STALE_TIME }
  );
  return {
    isLoading: isLoading,
    all: timeSeriesFields?.timeSeriesFields ?? [],
    byId: function (id: string): TimeSeriesField | undefined {
      return timeSeriesFields?.timeSeriesFields.find(
        (f: TimeSeriesField) => f.id.toLocaleLowerCase() === id.toLocaleLowerCase()
      );
    },
    byFieldId: function (id: string): TimeSeriesField | undefined {
      return timeSeriesFields?.timeSeriesFields.find((f: TimeSeriesField) => f.id == id);
    },
    byFieldIdAndFamily: function (fieldId: string, family: TimeSeriesFieldFamily): TimeSeriesField | undefined {
      const familyMatches = timeSeriesFields?.timeSeriesFields.filter((field) => field.family === family);
      return familyMatches?.find((f: TimeSeriesField) => f.id === fieldId || f.entityId === fieldId);
    },
  };
}
