import { Tooltip, Typography } from "antd";
import React from "react";

import { getCountryName } from "@/utils/countries";

type Props = {
  countryCode: string;
};

const FriendlyCountryLabel: React.FC<Props> = ({ countryCode }) => {
  const countryName = getCountryName(countryCode);
  return (
    <Tooltip title={countryName}>
      <Typography.Text>{countryCode}</Typography.Text>
    </Tooltip>
  );
};

export default FriendlyCountryLabel;
