import EChartsReact from "echarts-for-react";
import { RefObject, useContext, useRef } from "react";

import { EventFilter, useEventOccurrencesRateQuery } from "@/api/customerApi";
import { QUERY_SETTINGS } from "@/constants";
import GlobalFilterContext from "@/contexts/GlobalFilterContext";

import { BaseEChart } from "../ui/BaseEChart";
import { buildEventsSummaryChartConfig } from "./ChartConfigHelper";

interface EventsSummaryChartProps {
  eventFilter: EventFilter;
}

const MinOdometerInterval = 0;
const MaxOdometerInterval = 100000;
const IntervalSize = 1000;
const WindowSize = 5000;

// TODO refactor queryEventOccurrencesRate to support user-defined events
const EventsSummaryChart = ({ eventFilter }: EventsSummaryChartProps) => {
  const { globalFilter } = useContext(GlobalFilterContext);
  const echartRef = useRef<EChartsReact>();
  const { data, isLoading } = useEventOccurrencesRateQuery(
    {
      eventFilter,
      filter: globalFilter,
      min: MinOdometerInterval,
      max: MaxOdometerInterval,
      intervalSize: IntervalSize,
      windowSize: WindowSize,
    },
    { staleTime: QUERY_SETTINGS.DEFAULT_STALE_TIME, retry: false }
  );

  const chartOptions = buildEventsSummaryChartConfig(WindowSize, data?.filteredQuery?.eventOccurrencesRate);

  return (
    <BaseEChart
      className="events-summary-chart"
      {...chartOptions}
      showLoading={isLoading}
      ref={echartRef as RefObject<EChartsReact>}
    />
  );
};

export default EventsSummaryChart;
