import { AnomalyBarValues } from "@/features/anomaly/editor/AnomalyHistogramPreview/AnomalyHistogramPreview";

export const getBucketsWithoutNulls = (
  minIncludingNulls: (number | null)[],
  maxIncludingNulls: (number | null)[],
  pctIncludingNulls: number[]
) => {
  const min: number[] = [];
  const max: number[] = [];
  let pct: number[] = [];
  let total: number = 0;

  pct = pct.map((n) => n / total);

  const minWithOutNulls = minIncludingNulls.filter((v): v is number => v !== null);
  const maxWithOutNulls = maxIncludingNulls.filter((v): v is number => v !== null);

  minWithOutNulls.forEach((_, index) => {
    if (!isNaN(minWithOutNulls[index]) && !isNaN(maxWithOutNulls[index])) {
      min.push(minWithOutNulls[index] as number);
      max.push(maxWithOutNulls[index] as number);
      pct.push((pctIncludingNulls[index] ?? 0) as number);
      total += pctIncludingNulls[index] ?? 0;
    }
  });
  return { min, max, pct };
};

export const setDefaultThresholds = (max: number[], min: number[]): AnomalyBarValues => {
  // places the starting thresholds depending on if there are an even or odd number
  // of bars. Tries to put the caution and warning in the middle
  const midIndex = Math.floor(max.length / 2);
  const warningIndex = Math.min(min.length - 1, midIndex + 1);
  if (max.length % 2 === 0) {
    const cautionIndex = Math.max(midIndex - 1, 0);
    return {
      lowerNormal: min[0],
      cautionBar: min[cautionIndex],
      warningBar: min[warningIndex],
      upperNormal: max[max.length - 1],
    };
  } else {
    return {
      lowerNormal: min[0],
      cautionBar: min[midIndex],
      warningBar: min[warningIndex],
      upperNormal: max[max.length - 1],
    };
  }
};
