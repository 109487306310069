import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime);

interface FromNowFormatterProps {
  value: Date;
}

/**
 * Returns a text element with the relative time from now until or since {@link value}.
 */
export default function FromNowFormatter({ value }: FromNowFormatterProps) {
  return <>{dayjs(value).fromNow()}</>;
}
