import i18next from "i18next";
import ChainedBackend from "i18next-chained-backend";
import HttpBackend from "i18next-http-backend";
import LocalStorageBackend from "i18next-localstorage-backend";
import { initReactI18next } from "react-i18next";

export const initI18n = (url: string) =>
  i18next
    .use(ChainedBackend)
    .use(initReactI18next)
    .init({
      fallbackLng: "en",
      // ... your i18next config
      backend: {
        backends: [LocalStorageBackend, HttpBackend],
        backendOptions: [
          {
            expirationTime: 7 * 24 * 60 * 60 * 1000, // 7 days
          },
          {
            loadPath: `/${url}/{{lng}}.json`,
          },
        ],
      },
    });

export default i18next;
