import { useContext } from "react";

import { useVehicleChargingSessionDurationStatsQuery } from "@/api/customerApi";
import GlobalFilterContext from "@/contexts/GlobalFilterContext";
import SCVContext from "@/contexts/SCVContext";

import { BaseEChart } from "../ui/BaseEChart";
import { BasicWidget } from "../widget/BasicWidget";
import { createBoxPlotOptions } from "./BoxPlotHelpers";

export const VehicleChargingSessionDurationBoxPlot = () => {
  const { globalFilter } = useContext(GlobalFilterContext);
  const { pvin } = useContext(SCVContext);
  const { data, isLoading } = useVehicleChargingSessionDurationStatsQuery({
    vehicleId: pvin,
    filter: globalFilter,
  });

  return (
    <BasicWidget title="Vehicle Charging Duration vs. Fleet">
      <BaseEChart
        showLoading={isLoading}
        option={createBoxPlotOptions(data?.filteredQuery.vehicleChargingSessionDurationStats, isLoading, "minutes")}
      />
    </BasicWidget>
  );
};
