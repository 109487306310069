import { TimeSeriesFieldFamily } from "@/api";
import { AggregateType, ErrorType } from "@/api/customerApi";
import { StyledSelect } from "@/components/form/input/BasicSelect.styled";
import { AnalysisSignal } from "@/contexts/EventAnalysisChartStore";
import {
  getAggregateOptionValue,
  getAvailableEventAggregationsOptions,
} from "@/features/chartElementLibrary/FieldPlotterHelper";
import { FieldPlotterContainer } from "@/features/chartElementLibrary/LibraryFieldPlotter.styled";

const availableAggregations = getAvailableEventAggregationsOptions();
const errorTypeDictionary = {
  Quartile: "Top & Bottom Quartiles",
  MinMax: "Min & Max",
  StdDev: "+/- Std. Deviation",
  Vigintile: "Top & Bottom 5 percent",
};
const errorType = Object.keys(ErrorType).map((e) => ({ value: e, label: errorTypeDictionary[e as "Quartile"] }));

interface LibraryFieldPlotterProps {
  name: string;
  id: string;
  fieldId: string;
  plottedData?: AnalysisSignal;
  fieldDisplayName?: string;
  signalType: TimeSeriesFieldFamily;
  unit: string;
  onPlotOptionsChange: (signalsData: AnalysisSignal) => void;
}

export const EventFieldPlotter = ({
  name,
  id,
  fieldId,
  plottedData,
  signalType,
  unit,
  onPlotOptionsChange,
}: LibraryFieldPlotterProps) => {
  const selectedAggregation = plottedData?.fieldInput.aggregateType || AggregateType.Median;
  const selectedComparisons = plottedData?.fieldInput.errorType ? plottedData.fieldInput.errorType : ErrorType.Quartile;

  const onAggregationSelect = (aggOption?: AggregateType) => {
    if (aggOption) {
      onPlotOptionsChange({
        name,
        id,
        fieldInput: {
          id: fieldId,
          aggregateType: aggOption,
          errorType: selectedComparisons,
          family: signalType,
        },
        unit,
      });
    }
  };

  const onAreaComparisonSelect = (areaComparisonOption?: ErrorType) => {
    if (areaComparisonOption) {
      onPlotOptionsChange({
        name,
        id,
        fieldInput: {
          id: fieldId,
          aggregateType: selectedAggregation,
          errorType: areaComparisonOption,
          family: signalType,
        },
        unit,
      });
    }
  };

  return (
    <FieldPlotterContainer>
      <div className="field-control">
        <p className="body-small">Aggregation</p>
        <StyledSelect
          placeholder="Select Aggregation"
          options={availableAggregations}
          value={selectedAggregation}
          onSelect={(value) => onAggregationSelect(getAggregateOptionValue(value as string))}
        />
      </div>
      <div className="field-control">
        <div />
        <StyledSelect
          placeholder="Select Error Type"
          options={errorType}
          value={selectedComparisons}
          onSelect={(value) => onAreaComparisonSelect(ErrorType[value as "MinMax"])}
        />
      </div>
      {/* {signalType === signalType.Expression && <div className="field-control"></div>} */}
    </FieldPlotterContainer>
  );
};
