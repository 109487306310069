import { FieldError } from "react-hook-form";

export const errorMessageFromError = (error: FieldError) => {
  if (error.message) {
    return error.message;
  }

  if (error.type === "required") {
    return "Field is required.";
  }
};
