import styled from "styled-components";

export const ForecastHomeContainer = styled.div.attrs({ className: "card" })`
  margin: 1rem;
  padding: 1rem;
  .forecasts-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .forecasts-body {
    margin: 1rem 0;
  }
`;
