import { useNavigate } from "@tanstack/react-router";
import { Button, Form, Input } from "antd";
import { NotificationInstance } from "antd/es/notification/interface";
import { useState } from "react";

import { useCopyClusterMutation } from "@/api";
import { getCurrentCustomerId } from "@/utils/customers";

type CopyClusterProps = {
  api: NotificationInstance;
  clusterId: string;
  onSuccess: () => void;
  onCancel: () => void;
};

type FormField = {
  name: string;
};

export const CopyCluster = ({ api, clusterId, onSuccess, onCancel }: CopyClusterProps) => {
  const [form] = Form.useForm();
  const [isCopyingCluster, setIsCopyingCluster] = useState(false);

  const copyCluster = useCopyClusterMutation();
  const navigate = useNavigate();

  const handleClose = () => {
    form.resetFields();
    onCancel();
  };

  const handleCopy = (args: FormField) => {
    setIsCopyingCluster(true);
    copyCluster.mutate(
      {
        clusterId,
        customerId: getCurrentCustomerId() ?? "",
        name: args.name,
      },
      {
        onSuccess: (data) => {
          const clusterId = data.clusters?.copyCluster;
          if (clusterId) {
            api.success({ message: "Cluster copied succesfully" });
            handleClose();
            onSuccess();
            navigate({ to: "/cluster/$clusterId", params: { clusterId: clusterId } });
          }
        },
        onSettled: () => {
          setIsCopyingCluster(false);
        },
        onError: (error: any) => {
          api.error({
            message: error.message,
          });
        },
      }
    );
  };

  return (
    <Form
      form={form}
      layout="vertical"
      name="basic"
      onFinish={(v: FormField) => {
        handleCopy(v);
      }}
      autoComplete="off"
    >
      <h4 className="heading-x-small">Provide a name for the cluster copy</h4>
      <Form.Item<FormField>
        label={"Name"}
        name={"name"}
        rules={[{ required: true, message: "Please input a name for the cluster copy!" }]}
      >
        <Input placeholder="Enter a name for the new cluster" />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" loading={isCopyingCluster}>
          Create Copy
        </Button>
      </Form.Item>
    </Form>
  );
};
