import styled from "styled-components";

export const FaultDetailsIcon = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  stroke: black;
  path {
    fill: black;
  }
`;
