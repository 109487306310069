import { MoreOutlined } from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Button, Dropdown } from "antd";
import { SizeType } from "antd/lib/config-provider/SizeContext";
import React, { useRef } from "react";

export type DropdownWidgetItem = {
  label: string;
  key: string;
};

export type DropdownPlacement = "bottom" | "bottomLeft" | "bottomRight" | "top" | "topLeft" | "topRight";

interface DropdownWidgetProps {
  elements: MenuProps["items"];
  onElementSelected: (key: string) => void;
  preselectedKey?: string;
  label?: React.JSX.Element;
  buttonSize?: SizeType;
  icon?: React.JSX.Element;
  hideIcon?: boolean;
  showArrow?: boolean;
  placement?: DropdownPlacement;
  renderElement?: Element | null;
  destroyOnHide?: boolean;
}

const DropdownWidget = ({
  elements,
  onElementSelected,
  preselectedKey,
  label,
  buttonSize = "middle",
  icon = <MoreOutlined />,
  hideIcon,
  showArrow = true,
  placement = "bottom",
  renderElement = null,
  destroyOnHide = false,
}: DropdownWidgetProps) => {
  const widgetContainerRef = useRef<Element>(renderElement);

  const onClick: MenuProps["onClick"] = ({ domEvent, key }) => {
    domEvent.stopPropagation();
    onElementSelected(key);
  };

  const defaultSelected = elements && elements[0] && elements[0].key ? elements[0].key.toString() : "";
  const preselectedKeys = preselectedKey === undefined ? [] : [defaultSelected];

  return (
    <div className="dropdown-widget-container">
      <Dropdown
        menu={{ items: elements, onClick, selectable: true, defaultSelectedKeys: preselectedKeys }}
        placement={placement}
        arrow={showArrow}
        getPopupContainer={() => widgetContainerRef.current! as HTMLDivElement}
        destroyPopupOnHide={destroyOnHide}
      >
        <Button className="text-button" type="text" size={buttonSize}>
          {label}
          {hideIcon ? undefined : icon}
        </Button>
      </Dropdown>
    </div>
  );
};

export default DropdownWidget;
