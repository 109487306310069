import { CircularPathsForItem } from "./findItemsWithCircularInputs";
import { InputIds, ItemsWithInputs, ItemWithInputs, PathsOfCircularInputs } from "./types";

export const circularPathsForItem: CircularPathsForItem = (item, allItems) => {
  const circularPathsForItemR = (
    visited: InputIds,
    item: ItemWithInputs,
    allItems: ItemsWithInputs
  ): PathsOfCircularInputs => {
    const newVisited = [...visited, item.id];
    return visited.includes(item.id)
      ? [newVisited]
      : item.inputIds
          .filter((childInputId) => childInputId)
          .flatMap((childInputId) =>
            circularPathsForItemR(newVisited, allItems.find((i) => i.id == childInputId)!, allItems)
          );
  };
  return circularPathsForItemR([], item, allItems);
};
