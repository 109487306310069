import { useSelector } from "react-redux";

import { TimeSeriesFieldFamily } from "@/api";
import StyledInlineDrawer from "@/components/ui/StyledInlineDrawer";
import { selectActive, selectCharts, selectFaultCodes } from "@/contexts/ChartLibraryStore";

import ChartLibraryTabs from "./ChartLibraryTabs";

export type PlottedSignalsCount = {
  expression: number;
  signal: number;
  anomaly: number;
  fault?: number;
};

const ChartLibrarySettings = () => {
  const { activeChartId, isSettingsOpen } = useSelector(selectActive);
  const { faultCodes: selectedFaults } = useSelector(selectFaultCodes);
  const { charts: availableCharts } = useSelector(selectCharts);
  const currentChart = availableCharts.find((chart) => chart.chartId === activeChartId);

  let plottedCount: PlottedSignalsCount = {
    expression: 0,
    signal: 0,
    anomaly: 0,
    fault: selectedFaults.length,
  };

  if (currentChart) {
    currentChart.series.forEach((s) => {
      if (s.TimeSeriesFieldInput.family === TimeSeriesFieldFamily.Signal) {
        plottedCount.signal += 1;
      } else if (s.TimeSeriesFieldInput.family === TimeSeriesFieldFamily.Expression) {
        plottedCount.expression += 1;
      } else if (s.TimeSeriesFieldInput.family === TimeSeriesFieldFamily.AnomalyScore) {
        plottedCount.anomaly += 1;
      }
    });
  }

  return (
    <StyledInlineDrawer open={isSettingsOpen}>
      <ChartLibraryTabs plottedCount={plottedCount} activeChart={currentChart} />
    </StyledInlineDrawer>
  );
};

export default ChartLibrarySettings;
