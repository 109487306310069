import { Alert, Flex, Switch, Typography } from "antd";
import _ from "lodash";
import { useContext, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Fault, useVehicleHistoricalDataAnalysisQuery } from "@/api/customerApi";
import BlockingLoading from "@/components/ui/BlockLoading";
import { QUERY_SETTINGS } from "@/constants";
import { ChartLibraryActions } from "@/contexts/ChartLibrarySlice";
import { selectFaultCodes } from "@/contexts/ChartLibraryStore";
import SCVContext from "@/contexts/SCVContext";
import { useCustomerIdentifier } from "@/hooks/useCustomerIdentifier";
import { getFaultDescription } from "@/utils/fault/getFaultDescription";

import { FaultCategoryElementsContainer } from "./LibraryFaultListSelector.styled";
import { LibraryItem } from "./LibraryItem";
import LibraryListWithSearch from "./LibraryListWithSearch";

interface FaultCategoryElementsProps {
  faults: Fault[];
  selectedFaultCodes: string[];
  onFaultToggle: (active: boolean, fault: Fault) => void;
}

const FaultCategoryElements = ({ faults, selectedFaultCodes, onFaultToggle }: FaultCategoryElementsProps) => {
  const { customerIdentifier } = useCustomerIdentifier();

  return (
    <FaultCategoryElementsContainer>
      {faults.map((f) => {
        const isFaultSelected = selectedFaultCodes.includes(f.code);
        const faultDescription = getFaultDescription(customerIdentifier, f.code);
        return (
          <div className="fault-item-container" key={f.code}>
            <Flex gap={8} className="fault-info">
              <Flex vertical>
                <Typography.Text className="label">Fault Code:</Typography.Text>
                <Typography.Text className="label">Trouble Code:</Typography.Text>
                <Typography.Text className="label">Name:</Typography.Text>
              </Flex>
              <Flex vertical className="fault-description">
                <Typography.Text className="description" code>
                  {f.code}
                </Typography.Text>
                <Typography.Text className="description" type="secondary">
                  {faultDescription?.troubleCode ?? "-"}
                </Typography.Text>
                <Typography.Text className="description" type="secondary">
                  {faultDescription?.name ?? "-"}
                </Typography.Text>
              </Flex>
            </Flex>
            <Switch
              className="library-item-switch"
              checked={isFaultSelected}
              onChange={(checked) => onFaultToggle(checked, f)}
            />
          </div>
        );
      })}
    </FaultCategoryElementsContainer>
  );
};

const LibraryFaultListSelector = () => {
  const { pvin } = useContext(SCVContext);
  const dispatch = useDispatch();
  const { faultCodes: selectedFaults } = useSelector(selectFaultCodes);
  const [searchTerm, setSearchTerm] = useState("");

  const { data, isLoading } = useVehicleHistoricalDataAnalysisQuery(
    { vehicleId: pvin },
    { staleTime: QUERY_SETTINGS.DEFAULT_STALE_TIME }
  );

  const icSearchTerm = searchTerm.toLowerCase();

  const faultList = (data?.vehicleFaultHistory?.data ?? []) as Fault[];
  const filteredFaultList = faultList.filter((f) => f.code.toLowerCase().includes(icSearchTerm));

  const faultECU = _.groupBy(filteredFaultList, "source");
  const faultECUCategories = Object.keys(faultECU).reduce((prev, next) => {
    const uniques = _.uniqBy(faultECU[next], "code");
    return {
      ...prev,
      [next]: uniques,
    };
  }, {});

  const handleSearch = (searchTerm: string) => {
    setSearchTerm(searchTerm);
  };

  const handleFaultCategoryToggle = (selected: boolean, source: string) => {
    const faultCodes = faultList.filter((f) => f.source === source).map((f) => f.code);
    if (selected) {
      return dispatch(ChartLibraryActions.addFaults({ faultCodes: faultCodes }));
    }
    dispatch(ChartLibraryActions.removeFaults({ faultCodes: faultCodes }));
  };

  const handleFaultToggle = (selected: boolean, fault: Fault) => {
    if (selected) {
      return dispatch(ChartLibraryActions.addFaults({ faultCodes: [fault.code] }));
    }
    dispatch(ChartLibraryActions.removeFaults({ faultCodes: [fault.code] }));
  };

  if (isLoading) return <BlockingLoading className="line with-margin" />;

  const ecuToggleItems = !_.isEmpty(faultECUCategories) ? (
    Object.keys(faultECUCategories).map((ecu) => {
      const faults = faultECUCategories[ecu as keyof typeof faultECUCategories] as Fault[];

      const selectedItems = selectedFaults.filter((f) => faults.map((cf) => cf.code).includes(f));
      const plottedLabel = `${selectedItems.length}/${faults.length}`;
      const isSelected = selectedItems.length === faults.length;
      return (
        <LibraryItem
          key={ecu}
          label={ecu}
          subLabel={plottedLabel}
          plottedIndex={-1}
          onToggle={(selected) => handleFaultCategoryToggle(selected, ecu)}
          isSelected={isSelected}
        >
          <FaultCategoryElements faults={faults} selectedFaultCodes={selectedItems} onFaultToggle={handleFaultToggle} />
        </LibraryItem>
      );
    })
  ) : (
    <Alert
      style={{ marginTop: "1rem" }}
      showIcon
      message={searchTerm.length ? `No faults founded with your criteria` : `There is not data to show`}
      type="info"
    />
  );

  return (
    <LibraryListWithSearch searchPlaceholder="Search by the fault code" onSearch={handleSearch}>
      {ecuToggleItems}
    </LibraryListWithSearch>
  );
};

export default LibraryFaultListSelector;
