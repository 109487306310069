import styled from "styled-components";

export const NewRepairConceptDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  .repair-input-section {
    display: flex;
    flex-direction: column;
  }
  .repair-concept-expression-container {
    .ant-radio-wrapper {
      margin: 1rem 0;
      &:not(.ant-radio-wrapper-checked) {
        .trigger-label {
          opacity: 0.75;
        }
      }
    }
    .repair-trigger-selector {
      min-width: 350px;
      margin: 1rem 1.5rem;
    }
  }
  .available-expressions-container {
    margin: 0 1.5rem;
  }
`;
