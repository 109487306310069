import { Link } from "@tanstack/react-router";
import { Button } from "antd";

import { SavedExpressionList } from "@/components/savedExpression/SavedExpressionList";

import { TopHeadingWithButtons } from "../../components/pageContent/TopHeadingWithButtons";

export const SavedExpressionHome = () => {
  return (
    <>
      <TopHeadingWithButtons
        title="Patterns"
        buttons={
          <Link to="/pattern/new">
            <Button type="primary">Create New Pattern</Button>
          </Link>
        }
      />
      <SavedExpressionList />
    </>
  );
};
