import { Link } from "@tanstack/react-router";
import { SortOrder } from "antd/lib/table/interface";
import capitalize from "lodash/capitalize";

import { EventType, RepairEventType, useRecentEventsQuery } from "@/api";
import Loading from "@/components/loading";
import { BasicTable } from "@/components/tables/BasicTable";
import { compareStrTimestamps, compareText } from "@/components/tables/sorting";
import FromNowFormatter from "@/components/ui/FromNowFormatter";
import { getCurrentCustomerId } from "@/utils/customers";

export const RecentlyViewedEventsTable = () => {
  const { data, isLoading } = useRecentEventsQuery({});
  const events =
    data?.userSettings?.customerSettings?.find((customer) => customer.customerId === getCurrentCustomerId())
      ?.recentEvents ?? [];

  if (isLoading) {
    return <Loading height={256} />;
  }

  type RecentlyViewedEvent = (typeof events)[number];

  const getEventTypeBaseUrl = (event: RecentlyViewedEvent): string => {
    switch (event.type) {
      case EventType.Fault:
        return "/fault";
      case EventType.Expression:
        return "/pattern-event";
      case EventType.Repair:
        return "/repair";
      default:
        return "/404";
    }
  };

  const getEventUrl = (event: RecentlyViewedEvent): string => {
    const ecu = event.ecu ? `/?ecu=${event.ecu}` : "";
    let id =
      event.type === EventType.Repair
        ? event.eventId == RepairEventType.BatteryFullReplacement
          ? "full"
          : "partial"
        : event.eventId;
    return `${getEventTypeBaseUrl(event)}/${id}${ecu}`;
  };

  const getEventName = (event: RecentlyViewedEvent): string => {
    return event.name && event.name != "" ? event.name : event.eventId;
  };

  const columns = [
    {
      key: "name",
      title: "Name",
      dataIndex: "name",
      sorter: (a: RecentlyViewedEvent, b: RecentlyViewedEvent) => compareText(getEventName(a), getEventName(b)),
      render: (_: string, row: RecentlyViewedEvent) => {
        return <Link to={getEventUrl(row)}>{getEventName(row)}</Link>;
      },
    },
    {
      key: "ecu",
      title: "ECU",
      dataIndex: "ecu",
      sorter: (a: RecentlyViewedEvent, b: RecentlyViewedEvent) => compareText(a.ecu ?? "", b.ecu ?? ""),
      render: (_: string, row: (typeof events)[number]) => {
        return row.ecu !== "" ? row.ecu : "-";
      },
    },
    {
      key: "type",
      title: "Type",
      dataIndex: "type",
      width: 120,
      sorter: (a: RecentlyViewedEvent, b: RecentlyViewedEvent) => compareText(a.type, b.type),
      render: (_: string, row: (typeof events)[number]) => {
        return capitalize(row.type);
      },
    },
    {
      key: "lastViewed",
      title: "Last Viewed",
      dataIndex: "timestamp",
      width: 180,
      defaultSortOrder: "descend" as SortOrder,
      sorter: (a: RecentlyViewedEvent, b: RecentlyViewedEvent) => compareStrTimestamps(a.timestamp, b.timestamp),
      render: (_: string, row: (typeof events)[number]) => {
        return <FromNowFormatter value={new Date(row.timestamp)} />;
      },
    },
  ];

  return <BasicTable dataSource={events} loading={isLoading} columns={columns} rowKey="eventId" pagination={false} />;
};
