import { createRootRouteWithContext, Outlet, ScrollRestoration } from "@tanstack/react-router";
import { ThemeProvider } from "styled-components";

import RequireAuth from "@/components/auth/RequireAuth";
import SupportChatWidget from "@/components/support/SupportChatWidget";
import CustomerProvider from "@/contexts/CustomerProvider";
import { GlobalFilterProvider } from "@/contexts/GlobalFilterContext";
import { AntdConfigProvider } from "@/integrations/antd";
import Page404 from "@/pages/404";
import { defaultTheme } from "@/styles/Global.styled";

export type AppRouteContext = {
  noLayout?: boolean;
};

const RootComponent = () => {
  return (
    <RequireAuth component={CustomerProvider}>
      <GlobalFilterProvider>
        <AntdConfigProvider>
          <ThemeProvider theme={defaultTheme}>
            <ScrollRestoration getKey={(location) => location.pathname} />
            <Outlet />
            <SupportChatWidget />
          </ThemeProvider>
        </AntdConfigProvider>
      </GlobalFilterProvider>
    </RequireAuth>
  );
};

export const Route = createRootRouteWithContext<AppRouteContext>()({
  component: RootComponent,
  notFoundComponent: Page404,
});
