import { Flex, SpinProps, Typography } from "antd";

import CustomerIcon from "../customerIcon/CustomerIcon";
import { Container } from "./Loading.styled";

type LoadingProps = {
  size?: SpinProps["size"];
  label?: string;
  fullHeight?: boolean;
  height?: number;
};

function Loading({ fullHeight, label, height }: LoadingProps) {
  return (
    <Container className={fullHeight ? "full-height" : ""} height={height}>
      <Flex className="loading-indicator" vertical gap={4} justify="center" align="center">
        <div className="loading-logo">
          <CustomerIcon size={32} alt="Pull Systems" />
        </div>
        <Typography.Text className="loading-tip" type="secondary">
          {label || "Loading"}
        </Typography.Text>
      </Flex>
    </Container>
  );
}

export default Loading;
