import { notification } from "antd";

import { ErrorMessageContainer } from "./ErrorMessage.styled";

type ErrorMessageProps = {
  message: string;
};
export const ErrorMessage = ({ message }: ErrorMessageProps) => {
  const [notificationApi, notificationContextHolder] = notification.useNotification();

  const handleClick = () => {
    navigator.clipboard.writeText(message);
    notificationApi.success({ message: "Copied to clipboard" });
  };
  return (
    <>
      {notificationContextHolder}
      <ErrorMessageContainer>
        <div>
          <b>Error Info</b>
          <div className="error-message-copy" onClick={handleClick}>
            Click to copy
          </div>
        </div>
        <br />
        <div>{message}</div>
      </ErrorMessageContainer>
    </>
  );
};
