import ReactECharts, { EChartsReactProps } from "echarts-for-react";

import { DensityFunction } from "@/api";

interface DensityCurveThumbnailProps {
  densityFunction: DensityFunction;
  onClick: () => void;
}

export default function DensityCurveThumbnail({ densityFunction, onClick }: DensityCurveThumbnailProps) {
  let points = [{ x: 0, y: densityFunction.y0 }, ...(densityFunction?.points || [])];

  const chartConfig: EChartsReactProps = {
    option: {
      backgroundColor: "#f9fafe",
      animationDuration: 200,
      xAxis: {
        min: 0,
        max: Math.round(Math.max(...(densityFunction?.points || []).map(({ x }) => x))),
        type: "value",
        axisTick: { show: false },
        axisLabel: { show: false },
        splitLine: { show: false },
      },
      yAxis: {
        min: 0,
        max: 1,
        type: "value",
        axisTick: { show: false },
        axisLabel: { show: false },
        splitLine: { show: false },
      },
      series: [
        {
          type: "line",
          smooth: 0.5,
          symbolSize: 0,
          data: points.map(({ x, y }) => [x, y]),
        },
      ],
    },
  };

  // TODO: Style the whole component with a pointer cursor
  return (
    <div className="chart-thumbnail density-curve-thumbnail" onClick={onClick}>
      <ReactECharts className="echarts" {...chartConfig} style={{ height: "100%", width: "100%" }} />
    </div>
  );
}
