import { TableProps, Tooltip } from "antd";
import { SortOrder } from "antd/es/table/interface";
import { ColumnType } from "antd/lib/table";
import _ from "lodash";
import { useRef, useState } from "react";

import IconSortDown from "../icons/icon-sort-down.svg";
import IconSortUp from "../icons/icon-sort-up.svg";
import { EmptyComponent } from "../ui/EmptyComponent";
import { LoadingWrapper } from "../ui/LoadingWrapper";
import { StyledTable, TableCellWrapper, TableWrapper } from "./BasicTable.styled";
import { DEFAULT_SORT_OPTIONS } from "./sorting";

type TableSortIconProps = {
  sortOrder: SortOrder;
};

export const TableSortIcon = ({ sortOrder }: TableSortIconProps) => {
  if (!sortOrder) return <></>;
  return sortOrder === "ascend" ? <IconSortUp /> : <IconSortDown />;
};

function addSortIcon<T>(props: BasicTableProps<T>): BasicTableProps<T> {
  return {
    ...props,
    columns: props.columns?.map((c) => ({
      ...c,
      sortIcon: c.sortIcon || (c.sorter ? ({ sortOrder }) => <TableSortIcon sortOrder={sortOrder} /> : undefined),
    })),
  };
}

type BasicTableProps<T> = TableProps<T> & {
  noDataText?: string;
};

/**
 * Adds tooltips to the columns of a table.
 */
function addTooltipToColumns<T>(props: TableProps<T>): TableProps<T> {
  return {
    ...props,
    columns: props.columns?.map((c: ColumnType<T>) => {
      const isStringTitle = typeof c.title == "string";
      return {
        ...c,
        ellipsis: { showTitle: false },
        render: (value: any, record: T) => {
          return <BasicTableCell column={c} record={record} value={value} />;
        },
        title: isStringTitle && c.title ? <BasicTableHeaderCell title={c.title.toString()} width={c.width} /> : c.title,
      };
    }),
  };
}

type CellRendererProps = {
  value: any;
  record: any;
  column: ColumnType<any>;
};

const BasicTableHeaderCell = ({ title, width }: { title: string; width: string | number | undefined }) => {
  const headerCellRef = useRef<HTMLTableCellElement>(null);
  const [headerEllipsis, setHeaderEllipsis] = useState(true);

  const isEllipsisActive = () => {
    if (headerCellRef.current) {
      return headerCellRef.current.offsetWidth < headerCellRef.current.scrollWidth;
    }
    return false;
  };

  // Really hacky way to achive what we want. While having title = "" will disable the tooltip
  // () => "" will cause the tooltip to render an empty div. This will prevent that.
  const getToolTipTitle = () => {
    if (isEllipsisActive()) {
      setHeaderEllipsis(true);
      return title;
    } else {
      setHeaderEllipsis(false);
      return "";
    }
  };

  return (
    <Tooltip overlayStyle={headerEllipsis ? {} : { display: "none" }} title={() => getToolTipTitle()}>
      <TableCellWrapper size={width} ref={headerCellRef}>
        {title}
      </TableCellWrapper>
    </Tooltip>
  );
};

/**
 * Renders the content of a basic table cell with tooltip and ellipsis.
 */
const BasicTableCell = (props: CellRendererProps) => {
  const { column, record, value } = props;
  const cellRef = useRef<HTMLTableCellElement>(null);
  const [ellipsis, setEllipsis] = useState(true);

  const isEllipsisActive = () => {
    if (cellRef.current) {
      return cellRef.current.offsetWidth < cellRef.current.scrollWidth;
    }
    return false;
  };

  const columnDataIndex = (column.dataIndex || column.key) as string;
  const keys = _.split(columnDataIndex, ".") ?? [];

  const renderContent = column.render ? column.render(value, record, 0) : _.get(record, keys);
  if (column.tooltipMessage) {
    return (
      <Tooltip title={column.tooltipMessage(value, record)} placement="topLeft">
        <TableCellWrapper size={column.width}>{renderContent}</TableCellWrapper>
      </Tooltip>
    );
  }
  // Really hacky way to achive what we want. While having title = "" will disable the tooltip
  // () => "" will cause the tooltip to render an empty div. This will prevent that.
  const getToolTipTitle = () => {
    if (isEllipsisActive()) {
      setEllipsis(true);
      return renderContent;
    } else {
      setEllipsis(false);
      return "";
    }
  };

  return (
    <Tooltip overlayStyle={ellipsis ? {} : { display: "none" }} title={() => getToolTipTitle()} placement="topLeft">
      <TableCellWrapper size={column.width} ref={cellRef}>
        {renderContent}
      </TableCellWrapper>
    </Tooltip>
  );
};

export const BasicTable = <T extends object>(props: BasicTableProps<T>) => {
  const refinedTableProps = addTooltipToColumns(props);
  const { loading, ...args } = addSortIcon(refinedTableProps);
  return (
    <TableWrapper className="basic-table-wrapper">
      <LoadingWrapper isLoading={!!loading}>
        <StyledTable
          id={props.id}
          {...args}
          locale={{ emptyText: <EmptyComponent isLoading={!!loading} description={props.noDataText} /> }}
          showSorterTooltip={false}
          sortDirections={DEFAULT_SORT_OPTIONS}
        />
      </LoadingWrapper>
    </TableWrapper>
  );
};
