import { CardProps } from "antd";
import React, { ReactNode } from "react";
import { ErrorBoundary } from "react-error-boundary";

import { ErrorFallback } from "../error/ErrorFallBack";
import * as BasicWidgetStyled from "./BasicWidget.styled";

type BasicWidgetProps = CardProps & {
  titleRightElement?: ReactNode;
  noHeaderStyling?: boolean;
  subtitle?: string;
};

export const BasicWidget: React.FC<BasicWidgetProps> = (props) => {
  const { titleRightElement, noHeaderStyling, subtitle, ...cardProps } = props;

  const classNameResult = `${props.className ?? ""}${noHeaderStyling ? " no-header-styling" : ""}`;

  const titleElement = titleRightElement ? (
    <BasicWidgetStyled.TitleContainer>
      <BasicWidgetStyled.RightElementContainer>
        <div>{cardProps.title}</div>
        <span>{titleRightElement}</span>
      </BasicWidgetStyled.RightElementContainer>
    </BasicWidgetStyled.TitleContainer>
  ) : (
    <BasicWidgetStyled.TitleContainer>
      <div>{cardProps.title}</div>
      {subtitle && <BasicWidgetStyled.SubtitleText className="body-large">{subtitle}</BasicWidgetStyled.SubtitleText>}
    </BasicWidgetStyled.TitleContainer>
  );

  const fallbackTitle = typeof cardProps.title === "string" ? cardProps.title : "An error has occurred";

  return (
    <ErrorBoundary fallbackRender={(props) => <ErrorFallback {...props} fallbackTitle={fallbackTitle} />}>
      <BasicWidgetStyled.WidgetStyled size="small" {...cardProps} title={titleElement} className={classNameResult} />
    </ErrorBoundary>
  );
};
