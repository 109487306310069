import { Forecast } from "@/api";
import FromNowFormatter from "@/components/ui/FromNowFormatter";
import { ForecastListContainer } from "@/features/forecast/ForecastList.styled";

interface ForecastListProps {
  forecasts: Forecast[];
  onElementSelected: (element: Forecast) => void;
}

export default function ForecastList({ forecasts, onElementSelected }: ForecastListProps) {
  if (forecasts.length === 0) {
    return <p className="body-medium no-forecasts-found">No forecasts found!</p>;
  }

  return (
    <ForecastListContainer>
      {forecasts.map((f) => {
        const itemClass = `forecast-list-item`;
        return (
          <div key={f.id} className={itemClass} onClick={() => onElementSelected(f)}>
            <div className="forecast-header">
              <p className="label-normal">{f.name}</p>
              <p className="body-medium">{f.creator.name}</p>
            </div>
            <div className="forecast-data">
              <p className="body-small">
                Last modified <FromNowFormatter value={new Date(Date.parse(f.lastModified!))} />
              </p>
            </div>
          </div>
        );
      })}
    </ForecastListContainer>
  );
}
