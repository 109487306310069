import "core-js/actual";
import "cross-fetch/polyfill";

(window as any).global = window;

/**
 * Monkeypatch BigInt.toJSON
 * https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/BigInt#use_within_json
 */
(BigInt.prototype as any).toJSON = function () {
  return this.toString();
};
