import { PlusCircleOutlined } from "@ant-design/icons";
import { ValuesLength, valuesLength } from "@lib/src/expression/expressionValidator";
import { CaseExpression, Expression, ExpressionType } from "@lib/src/expression/types";
import { Tooltip } from "antd";
import { MouseEventHandler } from "react";
import styled from "styled-components";

import { stopPropagation } from "@/utils/stopPropagation";

import { expressionBuilderMap } from "./expressionBuilderMap";
import { ExpressionInputProps } from "./ExpressionInput";
import { ExpressionTypeSelect } from "./ExpressionTypeSelect";

const StyledHeader = styled.div`
  display: flex;
  gap: 8px;
  .edit-button {
    margin-left: 10px;
  }

  .edit-button {
    font-size: 16px;
  }

  .add-parameter {
    margin-left: auto;
  }

  .add-parameter,
  .edit-button {
    margin-top: auto;
    margin-bottom: auto;
  }
`;

const arbitraryLengthParameterTypes = Object.entries(valuesLength).filter(
  ([, value]) => value == ValuesLength.Arbitrary
);

type ExpressionInputHeaderProps = ExpressionInputProps<Expression>;

export const ExpressionInputHeader = (props: ExpressionInputHeaderProps) => {
  const { onChange, expression, path, disabled = false } = props;

  const addParameter = () => {
    if (expression.type === ExpressionType.Case) {
      onChange?.(
        {
          ...expression,
          when: [...((expression as CaseExpression).when ?? []), {}],
          then: [...((expression as CaseExpression).then ?? []), {}],
        } as Expression,
        path
      );
    } else {
      onChange?.(
        {
          ...expression,
          values: [...((expression as any).values ?? []), {}],
        } as Expression,
        path
      );
    }
  };

  const blockOnClickPropagation =
    (handler?: MouseEventHandler<HTMLElement>): MouseEventHandler<HTMLElement> =>
    (e) => {
      /* Avoid click on header collapsing Antd Panel*/
      e.preventDefault();
      e.stopPropagation();
      handler?.(e);
    };

  const displayAddParameter =
    !!onChange &&
    expression.type !== undefined &&
    (arbitraryLengthParameterTypes.includes(expression.type as any) || expression.type === ExpressionType.Case);

  return (
    <StyledHeader>
      <ExpressionTypeSelect
        value={expression.type}
        autoFocus
        onSelect={(value) => {
          if (!expressionBuilderMap[value]) {
            throw "Expression builder undefined.";
          }
          onChange?.(expressionBuilderMap[value], path);
        }}
        onClick={blockOnClickPropagation()}
        disabled={!onChange || disabled}
        onKeyDown={(e) => stopPropagation(e, "Enter")}
      />
      {displayAddParameter && (
        <>
          <Tooltip title="Add a new parameter">
            <PlusCircleOutlined className="add-parameter" onClick={blockOnClickPropagation(addParameter)} />
          </Tooltip>
        </>
      )}
    </StyledHeader>
  );
};
