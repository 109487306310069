import { VehicleModels } from "@/utils/types/commonDataDictionary";

export function toModelDescription(
  vehicle?: { model?: string | null; modelStyle?: string | null } | null,
  models?: VehicleModels
): string | undefined {
  const styleDictionary = (vehicle?.model && models?.model[vehicle?.model]) || models?.default;
  if (styleDictionary) {
    const styleName = (vehicle?.modelStyle && styleDictionary.style[vehicle?.modelStyle]) ?? styleDictionary.default;
    if (styleName) return styleName;
    return `${vehicle?.model}`;
  }
  return "Unknown";
}
