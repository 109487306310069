import { countExpressionsFunctions } from "@lib/src/expression/expressionCountFunctions";
import { Expression, ExpressionType } from "@lib/src/expression/types";
import { generateRandomNumber } from "@lib/src/numberUtils";
import { DeepPartial } from "@reduxjs/toolkit";
import { Collapse, Input, Typography } from "antd";
import { useMemo, useState } from "react";

import { TimeSeriesFieldFamily } from "@/api";

import { AggregationExpressionInput } from "./AggregationExpressionInput";
import { CaseExpressionInput } from "./CaseExpressionInput";
import { ExpressionInputContainer } from "./ExpressionInput.styled";
import { ExpressionInputHeader } from "./ExpressionInputHeader";
import { ExpressionParametersInput } from "./ExpressionParametersInput";
import { LiteralNumericExpressionInput } from "./LiteralNumericExpressionInput";
import { NestedItems, StyledExpressionCollapse } from "./StyledExpressionCollapse";
import { TimeSeriesFieldExpressionInput } from "./TimeSeriesFieldExpressionInput";

export interface ExpressionInputProps<T extends Expression> {
  disabled?: boolean;
  expression: DeepPartial<T>;
  path: string[];
  onChange?: (newValue: DeepPartial<Expression>, path: string[]) => void;
}

export const ExpressionInput = (props: ExpressionInputProps<Expression>) => {
  const { onChange, expression, path, disabled = false } = props;

  const generateKey = useMemo(() => generateRandomNumber(1, 999).toString(), []);
  const [activeKey, setActiveKey] = useState<string | string[]>([generateKey]);

  if (!expression.type) {
    return (
      <StyledExpressionCollapse defaultActiveKey="1">
        <Collapse.Panel
          header={<ExpressionInputHeader expression={expression} path={path} onChange={onChange} disabled={disabled} />}
          key="1"
        />
      </StyledExpressionCollapse>
    );
  }

  let inputElement = null;
  if (expression.type === ExpressionType.LiteralNumeric) {
    inputElement = (
      <LiteralNumericExpressionInput expression={expression} onChange={onChange} path={path} disabled={disabled} />
    );
  } else if (expression.type === ExpressionType.Aggregation) {
    inputElement = (
      <AggregationExpressionInput expression={expression} onChange={onChange} path={path} disabled={disabled} />
    );
  } else if (expression.type === ExpressionType.Signal) {
    inputElement = (
      <TimeSeriesFieldExpressionInput
        typeFilter={TimeSeriesFieldFamily.Signal}
        expression={expression}
        onChange={onChange}
        path={path}
        disabled={disabled}
        hasSearch
      />
    );
  } else if (expression.type === ExpressionType.AnomalyScore) {
    inputElement = inputElement = (
      <TimeSeriesFieldExpressionInput
        typeFilter={TimeSeriesFieldFamily.AnomalyScore}
        expression={expression}
        onChange={onChange}
        path={path}
        disabled={disabled}
        hasSearch
      />
    );
  } else if (expression.type === ExpressionType.Pattern) {
    inputElement = inputElement = (
      <TimeSeriesFieldExpressionInput
        typeFilter={TimeSeriesFieldFamily.Expression}
        expression={expression}
        onChange={onChange}
        path={path}
        disabled={disabled}
        hasSearch
      />
    );
  } else if (expression.type === ExpressionType.Case) {
    inputElement = <CaseExpressionInput expression={expression} onChange={onChange} path={path} disabled={disabled} />;
  } else if (expression.type === ExpressionType.Null) {
    inputElement = <Input defaultValue={"Null"} disabled={true} />;
  } else {
    inputElement = (
      <ExpressionParametersInput
        expression={expression}
        onChange={onChange}
        path={path}
        valuesPath={"values"}
        disabled={disabled}
      />
    );
  }

  const _onCollapseChange = (key: string | string[]) => setActiveKey(key);

  const _generateNestedLabel = () => {
    return `${expression.type!} + ${count} nested functions`;
  };

  const count = countExpressionsFunctions(expression) ?? 0;

  return (
    <StyledExpressionCollapse defaultActiveKey={activeKey} activeKey={activeKey} onChange={_onCollapseChange}>
      <Collapse.Panel
        className="pullsystems-collapse-panel"
        header={<ExpressionInputHeader expression={expression} path={path} disabled={disabled} onChange={onChange} />}
        key={generateKey}
      >
        <ExpressionInputContainer>{inputElement}</ExpressionInputContainer>
      </Collapse.Panel>
      {!activeKey.length && (
        <NestedItems onClick={() => _onCollapseChange([generateKey])}>
          <Typography.Link>{_generateNestedLabel()}</Typography.Link>
        </NestedItems>
      )}
    </StyledExpressionCollapse>
  );
};
