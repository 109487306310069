import { CheckCircleFilled } from "@ant-design/icons";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { useRouter } from "@tanstack/react-router";
import { Button, Modal } from "antd";
import { useContext, useState } from "react";
import uuid from "react-uuid";

import {
  RepairConceptInput,
  useCreateRepairConceptMutation,
  useRepairConceptsQuery,
  useUpdateRepairConceptMutation,
} from "@/api";
import UIZard from "@/components/ui/UIzard";
import EditRepairConceptContext from "@/contexts/EditRepairConceptProvider";
import { requireCurrentCustomerId } from "@/utils/customers";

import NewRepairConceptCost from "./NewRepairConceptCost";
import NewRepairConceptDetails from "./NewRepairConceptDetails";

const newRepairConceptSteps = [
  {
    id: uuid(),
    title: "Repair Concept",
    label: "Details",
    jsx: <NewRepairConceptDetails />,
  },
  {
    id: uuid(),
    title: "Repair Concept Costs",
    label: "Cost",
    jsx: <NewRepairConceptCost />,
  },
];

// TODO handle creation/edit fail
const EditRepairConcept = () => {
  const { history } = useRouter();
  const navigate = useNavigate();

  const [creationFinished, setCreationFinished] = useState(false);
  const newRepairCtx = useContext(EditRepairConceptContext);

  const queryClient = useQueryClient();
  const createRepairConceptMutation = useCreateRepairConceptMutation({
    onSuccess: () => repairConceptMutationSettledHandler(),
  });

  const updateRepairConceptMutation = useUpdateRepairConceptMutation({
    onSuccess: () => repairConceptMutationSettledHandler(),
  });

  const repairConceptMutationSettledHandler = () => {
    setCreationFinished(true);
    queryClient.invalidateQueries({
      queryKey: useRepairConceptsQuery.getKey({ customerId: requireCurrentCustomerId() }),
    });
  };

  const cancelRepairConceptMutationHanlder = () => {
    history.go(-1);
  };

  const finishRepairConceptMutationHandler = () => {
    navigate({ to: "/repair-concept" });
  };

  const updateRepairConceptHandler = () => {
    const repairConceptInput: RepairConceptInput = {
      name: newRepairCtx.newRepair.name,
      cost: newRepairCtx.newRepair.cost,
      repairEventType: newRepairCtx.newRepair.repairEventType,
      triggerExpressionEventId: newRepairCtx.newRepair.triggerExpressionEventId,
    };
    if (newRepairCtx.newRepair.id) {
      return updateRepairConceptMutation.mutate({
        customerId: requireCurrentCustomerId(),
        id: newRepairCtx.newRepair.id,
        version: newRepairCtx.newRepair.version,
        repairConcept: repairConceptInput,
      });
    }
    return createRepairConceptMutation.mutate({
      customerId: requireCurrentCustomerId(),
      repairConcept: repairConceptInput,
    });
  };

  const repairConceptMutationLoading = createRepairConceptMutation.isLoading || updateRepairConceptMutation.isLoading;

  return (
    <>
      <UIZard
        stepList={newRepairConceptSteps}
        onCancel={cancelRepairConceptMutationHanlder}
        onFinish={updateRepairConceptHandler}
        finishIsLoading={repairConceptMutationLoading}
      />
      {/* TODO use a more generic UI modal */}
      <Modal centered open={creationFinished} footer={false} closable={false}>
        <div className="centered-content-modal-container">
          <CheckCircleFilled />
          <p className="heading-x-small dark">Repair Concept</p>
          <p className="heading-normal dark">Created Succesfully</p>
          <p className="body-nomal-alt dark">The concept is already up and running</p>
          <Button className="primary-button" onClick={finishRepairConceptMutationHandler}>
            Go back to repair concepts
          </Button>
        </div>
      </Modal>
    </>
  );
};

export default EditRepairConcept;
