import { RepairEventType } from "@/api";

export const RepairEventNames = {
  [RepairEventType.BatteryModuleReplacement]: "Module Repair",
  [RepairEventType.BatteryFullReplacement]: "Full Pack Replacement",
};

export const RepairEventDescriptions = {
  [RepairEventType.BatteryModuleReplacement]: "Repair of one or more modules",
  [RepairEventType.BatteryFullReplacement]: "Replacement of the entire battery pack",
};
