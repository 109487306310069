import { useParams } from "@tanstack/react-router";
import { Provider as ForecastProvider } from "react-redux";

import { useForecastsQuery } from "@/api";
import BlockingLoading from "@/components/ui/BlockLoading";
import configureForecastStore, { ForecastModel } from "@/contexts/ForecastStore";
import { requireCurrentCustomerId } from "@/utils/customers";

import Forecast from "./Forecast";

export interface EditForecastParams {
  id: string;
}

const EditForecast = () => {
  const { forecastId } = useParams({ strict: false });
  const { data, isLoading } = useForecastsQuery(
    { customerId: requireCurrentCustomerId(), ids: [forecastId ?? ""] },
    { enabled: !!forecastId }
  );

  let initialState: ForecastModel | undefined;

  if (isLoading && forecastId) {
    return <BlockingLoading className="line with-margin" />;
  }

  if (data && forecastId) {
    const loadedForecast = data.forecasts?.forecasts[0];
    initialState = {
      forecast: loadedForecast,
    } as ForecastModel;
  }

  return (
    <ForecastProvider store={configureForecastStore(initialState)}>
      <Forecast />
    </ForecastProvider>
  );
};

export default EditForecast;
