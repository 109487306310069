import { checkIfIsObject, checkIfIssArray } from "./../typeValidators";

/**
 * Count the number of functions inside a function in expression editor
 * @param expressions A expression input with all the functions selected by the user
 * @returns the number of function for each node
 */
export const countExpressionsFunctions = (expressions: any) => {
  let count = 0;
  const _formatExpressions = (expressions: any) => {
    if (checkIfIsObject(expressions)) {
      const keys = Object.keys(expressions);
      for (const key of keys) {
        if (key !== "type") {
          const values = expressions[key];
          if (checkIfIssArray(values)) {
            count += values.length;
            for (const value of values) {
              _formatExpressions(value);
            }
          } else {
            count += 1;
          }
        }
      }
    }
  };
  _formatExpressions(expressions);
  return count;
};
