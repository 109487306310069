import { Flex, Select, Typography } from "antd";

import { Maybe, SavedExpression } from "@/api";
import { useExpressionSelectorOptions } from "@/hooks/useExpressionSelectorOptions";
import { caseInsensitiveContains } from "@/utils/caseInsensitiveContains";

type SeriesOptions = {
  label: Maybe<string> | undefined;
  value: string;
  creator: string;
};

export type ExistingPatternSelectorProps = {
  expression?: SavedExpression;
  onExpressionChanged: (expression: SavedExpression) => any;
  disabled?: boolean;
  hasSearch?: boolean;
};

export const ExistingPatternSelector = ({
  onExpressionChanged,
  disabled,
  expression,
  hasSearch = false,
}: ExistingPatternSelectorProps) => {
  const { isLoading, expressionSelectOptions, savedExpressions } = useExpressionSelectorOptions();

  const filterOptionByValue = (input: string, value: string) => {
    const filteredValues = expressionSelectOptions
      .filter((e) => caseInsensitiveContains(input, e.label))
      // .filter((e) => e.label.toLowerCase().includes(input.toLowerCase()))
      .map((m) => m.value);
    const includesValues = filteredValues.includes(value?.toString() ?? "");
    return includesValues;
  };

  return (
    <Select
      showSearch={hasSearch}
      filterOption={(input, option) => filterOptionByValue(input, option?.value?.toString() ?? "")}
      onChange={(id) => {
        const matchingExpression = savedExpressions?.items?.find((e) => e.id == id);
        if (matchingExpression) onExpressionChanged(matchingExpression as SavedExpression);
      }}
      value={expression?.id}
      disabled={disabled || isLoading}
      placeholder={"Select an Existing Pattern"}
      loading={isLoading}
    >
      {expressionSelectOptions.map((r: SeriesOptions) => {
        return (
          <Select.Option key={r.value}>
            <Flex justify="space-between">
              <Typography.Text>{r.label}</Typography.Text>
              <Typography.Text>{r.creator}</Typography.Text>
            </Flex>
          </Select.Option>
        );
      })}
    </Select>
  );
};
