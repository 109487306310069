import styled from "styled-components";

export const ParameterSeparator = styled.div`
  height: 56px;
  display: flex;
  align-items: center;
  font-weight: 400;
`;

export const ParametersWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: start;
  position: relative;
  &::before,
  &::after {
    content: "";
    width: 12px;
    position: absolute;
    left: -18px;
    top: 0;
    border: 1px;
    border-color: ${({ theme }) => theme.colors.simpleGray};
    border-left-style: solid;
  }
  &::before {
    height: 28px;
    bottom: 50%;
    border-bottom-style: solid;
  }

  &:not(:last-child)::after {
    bottom: 0;
  }
`;
