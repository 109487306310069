import { createContext, ReactNode, useState } from "react";

import { CostDensityFunctionInput, DensityFunctionIndependentVariable, RepairEventType } from "@/api";

export interface RepairConceptFields {
  id?: string;
  name: string;
  triggerExpressionEventId?: string;
  repairEventType?: RepairEventType;
  cost: CostDensityFunctionInput;
  version: number;
}

export interface EditRepairConceptContextValue {
  newRepair: RepairConceptFields;
  setNewRepairData: (data: RepairConceptFields) => void;
}

export const defaultState = {
  newRepair: {
    name: "",
    triggerExpressionEventId: undefined,
    repairEventType: undefined,
    cost: {
      densityUnit: DensityFunctionIndependentVariable.OdometerKm,
      distribution: {
        y0: 0.25,
        points: [
          { x: 1000, y: 0.5 },
          { x: 2500, y: 0.25 },
          { x: 5000, y: 0.125 },
          { x: 10000, y: 0.0625 },
          { x: 20000, y: 0 },
        ],
      },
      totalCost: 1000,
    },
    version: 1,
  },
  // TODO this function lowers the % Funcs test coverage of this provider
  setNewRepairData: () => {},
};

const EditRepairConceptContext = createContext<EditRepairConceptContextValue>(defaultState);

interface EditRepairConceptProviderProps {
  children: ReactNode;
  initialState?: RepairConceptFields;
}

export const EditRepairConceptProvider = ({
  children,
  initialState = defaultState.newRepair,
}: EditRepairConceptProviderProps) => {
  const [newRepairConcept, setNewRepairConcept] = useState<RepairConceptFields>(initialState);

  const setRepairConceptData = (repairConceptData: RepairConceptFields) => {
    const newRepairConceptData = { ...repairConceptData };
    const points = [...newRepairConceptData.cost.distribution.points];
    // automatically sets the last points y value to be 0
    points[points.length - 1].y = 0;
    newRepairConceptData.cost.distribution.points = points;
    setNewRepairConcept(newRepairConceptData);
  };

  return (
    <EditRepairConceptContext.Provider
      value={{ newRepair: newRepairConcept, setNewRepairData: setRepairConceptData }}
      key="EditRepairConceptContext"
    >
      {children}
    </EditRepairConceptContext.Provider>
  );
};

export default EditRepairConceptContext;
