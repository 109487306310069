import { TablePaginationConfig } from "antd";
import { FilterValue, SorterResult } from "antd/lib/table/interface";
import { useMemo, useState } from "react";

import { SortFilter } from "@/api";
import { defaultPagination } from "@/components/tables/defaultPagination";

export const useTablePagination = <T,>() => {
  type Sorters = SorterResult<T> | SorterResult<T>[];
  const [pagination, setPagination] = useState<TablePaginationConfig>(defaultPagination);
  const [sorters, setSorters] = useState<Sorters>([]);

  const sortersInput = useMemo(() => {
    return (Array.isArray(sorters) ? sorters : [sorters])
      .filter((s) => !!s.order)
      .map((s) => ({
        field: s.columnKey,
        order: s.order,
      })) as SortFilter[];
  }, [sorters]);

  const sortersInputHash = useMemo(() => {
    return (Array.isArray(sorters) ? sorters : [sorters])
      .filter((s) => !!s.order)
      .reduce(
        (acc, s) => ({
          ...acc,
          [s.columnKey!]: s.order,
        }),
        {}
      ) as Record<string, SortFilter>;
  }, [sorters]);

  const resetPagination = () => {
    setPagination(defaultPagination);
    setSorters([]);
  };

  return {
    pagination,
    sorters,
    sortersInput,
    sortersInputHash,
    resetPagination,
    onChange: (pagination: TablePaginationConfig, _: Record<string, FilterValue | null>, sorters: Sorters) => {
      setPagination(pagination);
      setSorters(sorters);
    },
  };
};
