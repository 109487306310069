import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";

import { getCurrentCustomerId } from "@/utils/customers";

export const usePendo = () => {
  const { user } = useAuth0();
  useEffect(() => {
    if ("pendo" in window && user) {
      const pendoConfig = {
        visitor: {
          id: user.sub,
          email: user.email,
          firstName: user.given_name,
          lastName: user.family_name,
        },
        account: {
          id: getCurrentCustomerId(),
        },
      };
      (window as any).pendo.initialize(pendoConfig);
    }
  }, [user]);
};
