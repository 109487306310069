import { formatNumber } from "@/utils/numberUtils";

const baseDiv = '<div style="max-width: 260px; border-radius: 5px">';

export const getBarTooltip = (min: number, max: number, percent: number, normalValue: string) => {
  const header = `<b>
    From ${min.toPrecision(5)} to ${max.toPrecision(5)}
  </b>`;
  const table = `<table>
        <tr>
          <td>Values In this Interval</td>
          <td style="text-align: right">${percent.toFixed(2)}%</td>
        </tr>
        <tr>
          <td>Anomaly scores</td>
          <td style="text-align: right">${normalValue}</td>
        </tr>
      </table>`;
  return `${baseDiv}${header}<hr />${table}</div>`;
};

export const getThresholdTooltip = (name: string, value: number, normalValue: number) => {
  const header = `<b>${name}</b>`;
  const table = `<table>
                    <tr>
                        <td>Value</td>
                        <td style="text-align: right; padding-left: 24px;">${formatNumber(value)}</td>
                    </tr>
                    <tr>
                        <td>Anomaly score</td>
                        <td style="text-align: right; padding-left: 24px;">${formatNumber(normalValue)}%</td>
                    </tr>
                </table>`;
  const footer = `<div style="font-size: 12px; color: gray; text-align: center">Click to edit</div>`;
  return `${baseDiv}${header}<hr />${table}${footer}</div>`;
};
