import styled from "styled-components";

export const EventTimeSeriesContainer = styled.div`
  display: flex;
  .chart-and-legend {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .time-series-chart {
    flex-grow: 1;
  }
  .amaya-inline-drawer {
    height: 600px;
    z-index: 10;
  }
`;
