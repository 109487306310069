import { formatNumber } from "@/utils/numberUtils";

export type BoxPlotData = { fleet?: IBoxPlotValues | null; vehicle?: IBoxPlotValues | null } | undefined;
interface IBoxPlotValues {
  p5: number;
  p25: number;
  median: number;
  p75: number;
  p95: number;
}

export const createToolTipTable = (data: BoxPlotData) => {
  const vehicle = data?.vehicle;
  const fleet = data?.fleet;
  const vehicleHead = `<th style='color: rgb(41.2%, 16.1%, 77.3%'); padding: 8px;'>This Vehicle</th>`;
  const fleetHead = `<th style='color: rgb(100%,60%,0%); padding: 8px;'>Fleet</th>`;
  const percentileHead = "<th style='padding: 8px'>Percentile</th>";
  const td = (value: number | string) => `<td style='padding: 8px;'>${value}</td>`;
  const tableHead = `<tr>${percentileHead}${vehicleHead}${fleetHead}</tr>`;
  const trOpen = `<tr style="border-bottom: 1px solid #ebebeb">`;
  const dataOrDash = (data: IBoxPlotValues | undefined | null, percentage: keyof IBoxPlotValues) => {
    return data ? formatNumber(data[percentage]) : "-";
  };

  const p5 = `${trOpen}${td("5th")}${td(dataOrDash(vehicle, "p5"))}${td(dataOrDash(fleet, "p5"))}</tr>`;
  const p25 = `${trOpen}${td("25th")}${td(dataOrDash(vehicle, "p25"))}${td(dataOrDash(fleet, "p25"))}</tr>`;
  const median = `${trOpen}${td("Median")}${td(dataOrDash(vehicle, "median"))}${td(dataOrDash(fleet, "median"))}</tr>`;
  const p75 = `${trOpen}${td("75th")}${td(dataOrDash(vehicle, "p75"))}${td(dataOrDash(fleet, "p75"))}</tr>`;
  const p95 = `${trOpen}${td("95th")}${td(dataOrDash(vehicle, "p95"))}${td(dataOrDash(fleet, "p25"))}</tr>`;

  return `<table class="box-plot-table">${tableHead}${p5}${p25}${median}${p75}${p95}</table`;
};

export const ensureTooltipIsInChart = (
  point: [number, number],
  size: { contentSize: [number, number]; viewSize: [number, number] }
) => {
  let posX = point[0];
  let posY = point[1];
  const chartWidth = size.viewSize[0];
  const chartHeight = size.viewSize[1];
  const tooltipWidth = size.contentSize[0];
  const tooltipHeight = size.contentSize[1];
  const offsetX = 10;
  const offsetY = 10;

  if (posX + tooltipWidth + offsetX > chartWidth) {
    posX = chartWidth - tooltipWidth - offsetX;
  } else if (posX - offsetX < 0) {
    posX = offsetX;
  }

  if (posY + tooltipHeight + offsetY > chartHeight) {
    posY = chartHeight - tooltipHeight - offsetY;
  } else if (posY - offsetY < 0) {
    posY = offsetY;
  }

  return [posX, posY];
};

export const createBoxPlotOptions = (data: BoxPlotData, isLoading: boolean, label: string) => {
  {
    if (isLoading) return {};
    return {
      tooltip: {
        trigger: "item",
        axisPointer: {
          type: "shadow",
        },
        position(
          point: [number, number],
          _params: any,
          _dom: any,
          _rect: any,
          size: { contentSize: [number, number]; viewSize: [number, number] }
        ) {
          return ensureTooltipIsInChart(point, size);
        },
        formatter: function () {
          return createToolTipTable(data);
        },
      },
      grid: {
        left: "5%",
        right: "5%",
        containLabel: true,
      },
      yAxis: {
        type: "category",
        data: ["Fleet", "Vehicle"],
        axisLine: {
          onZero: false,
        },
      },
      xAxis: {
        name: label,
        nameLocation: "middle",
        nameGap: 30,
        axisLabel: {
          margin: 5,
        },
      },
      series: isLoading
        ? []
        : [
            {
              type: "boxplot",
              data: [
                {
                  value: Object.values(data?.fleet ?? {}),
                  itemStyle: { color: "rgba(100%,60%,0%, 15%)", borderColor: "rgba(100%,60%,0%, 100%)" },
                  name: "Fleet",
                },
                {
                  value: Object.values(data?.vehicle ?? {}),
                  itemStyle: {
                    color: "rgba(41.2%, 16.1%, 77.3%, 15%)",
                    borderColor: "rgba(41.2%, 16.1%, 77.3%, 100%)",
                  },
                  name: "This Vehicle",
                },
              ],
              boxWidth: [25, 25],
            },
          ],
    };
  }
};
