import { Link } from "@tanstack/react-router";
import { useMemo } from "react";

import { useHomeQuery } from "@/api";
import { useHomeCustomerQuery } from "@/api/customerApi";
import DatabaseStatus from "@/components/databaseStatus/DatabaseStatus";
import { FleetIntel } from "@/components/fleetIntel/FleetIntel";
import VehicleTable from "@/components/tables/VehicleTable";
import { BasicWidget } from "@/components/widget/BasicWidget";
import { QUERY_SETTINGS } from "@/constants";
import { AnomalySummaryTableTop10 } from "@/features/anomaly/list/AnomalySummaryTableTop10";
import { HomeContainerStyled, TableTitleContainer } from "@/features/home/Home.styled";
import { getCurrentCustomerId } from "@/utils/customers";

export default function Home() {
  const query = useHomeQuery(
    {
      recentDetailVehiclesPagination: { pageSize: 10, currentPage: 1 },
    },
    {
      staleTime: QUERY_SETTINGS.SHORT_STALE_TIME,
    }
  );
  const customerQuery = useHomeCustomerQuery({}, { staleTime: QUERY_SETTINGS.SHORT_STALE_TIME });
  const customerId = getCurrentCustomerId();

  const recentVehiclesData = useMemo(() => {
    const customer = query.data?.userSettings?.customerSettings?.find((customer) => customer.customerId === customerId);
    return customer?.recentDetailVehicles?.items || undefined;
  }, [query, customerId]);

  return (
    <HomeContainerStyled>
      <FleetIntel />
      <div className="grid-widget-container">
        <BasicWidget
          className="table"
          title={
            <TableTitleContainer>
              <div>Recently Viewed Vehicles</div>
            </TableTitleContainer>
          }
          extra={<Link to="/vehicle">View All</Link>}
        >
          <VehicleTable data={recentVehiclesData} isLoading={query.isLoading} />
        </BasicWidget>
        <AnomalySummaryTableTop10 />
        <BasicWidget className="table" title="Data Source Status" subtitle="Most recent update">
          <DatabaseStatus isLoading={customerQuery.isLoading} data={customerQuery.data?.dataSourceStatus} />
        </BasicWidget>
      </div>
    </HomeContainerStyled>
  );
}
