let abortController = new AbortController();

export const ROUTE_CHANGE_ABORT = "aborting because of route change";

export const getSignal = () => abortController.signal;

export const abortRequests = (reason: string) => {
  abortController.abort(reason);
  abortController = new AbortController();
};
