import { AppState, Auth0Provider } from "@auth0/auth0-react";
import { PropsWithChildren } from "react";

const sessionStorageCache = {
  get: (key: string) => {
    const result = sessionStorage.getItem(key);
    return result ? JSON.parse(result) : undefined;
  },
  set: (key: string, value: unknown) => {
    sessionStorage.setItem(key, JSON.stringify(value));
  },
  remove: (key: string) => {
    sessionStorage.removeItem(key);
  },
};

function AuthenticationProvider({ children }: PropsWithChildren<{}>) {
  const redirectUri = `${window.location.origin}/auth`;

  return (
    <Auth0Provider
      domain={import.meta.env.VITE_AUTH0_DOMAIN}
      clientId={import.meta.env.VITE_AUTH0_CLIENT_ID}
      authorizationParams={{
        redirect_uri: redirectUri,
        audience: import.meta.env.VITE_AUTH0_AUDIENCE || undefined,
      }}
      useRefreshTokens
      cache={sessionStorageCache}
      onRedirectCallback={(appState?: AppState) => {
        if (appState?.returnTo) {
          window.location.assign(appState.returnTo);
        }
      }}
      legacySameSiteCookie={false}
    >
      {children}
    </Auth0Provider>
  );
}

export { AuthenticationProvider, sessionStorageCache };
