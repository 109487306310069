import { Link } from "@tanstack/react-router";

import { BasicPage } from "./BasicPage.styled";

interface Page404Props {
  data?: unknown;
  message?: string;
}

const Page404 = ({ message = "Not found" }: Page404Props) => {
  return (
    <BasicPage>
      <h1>
        <strong>{message}</strong>
      </h1>
      <Link to="/">Back Home</Link>
    </BasicPage>
  );
};

export default Page404;
