import { DEFAULT_PAGE_SIZE } from "@lib/src/DEFAULT_PAGE_SIZE";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { Tooltip, Typography } from "antd";
import { ColumnType } from "antd/es/table";
import { TablePaginationConfig } from "antd/lib";
import { FilterValue, SorterResult } from "antd/lib/table/interface";
import { useState } from "react";

import {
  SavedExpression,
  SavedExpressionsSortField,
  SavedExpressionsSortInput,
  useGetSavedExpressionByIdQuery,
  useGetSavedExpressionsQuery,
} from "@/api";
import { QUERY_SETTINGS } from "@/constants";
import { requireCurrentCustomerId } from "@/utils/customers";
import { humanFriendlyStatuses } from "@/utils/patterns";

import { BasicTable } from "../tables/BasicTable";
import { useRemoteSorting } from "../tables/useRemoteSorting";
import EntityCreatorElement from "../ui/EntityCreatorElement";
import { SavedExpressionListContainer } from "./SavedExpressionList.styled";

export const SavedExpressionList = () => {
  const queryClient = useQueryClient();
  const [currentPage, setCurrentPage] = useState(1);
  const { sorting, handleTableChangeForSorting, toMaybeColumnWithInitialSorting } = useRemoteSorting({
    fieldsEnumOptions: Object.values(SavedExpressionsSortField),
    initialValue: { field: SavedExpressionsSortField.Created, order: "descend" },
  });

  const navigate = useNavigate();

  const { data, isFetching } = useGetSavedExpressionsQuery(
    {
      customerId: requireCurrentCustomerId(),
      pagination: { pageSize: DEFAULT_PAGE_SIZE, currentPage },
      sorting: sorting as SavedExpressionsSortInput,
    },
    { staleTime: QUERY_SETTINGS.DEFAULT_STALE_TIME }
  );

  const total = data?.savedExpressions?.savedExpressions?.pagination?.totalCount;
  const expressions = data?.savedExpressions?.savedExpressions.items as SavedExpression[];

  const invalidatedSavedExpression = (expression: string) =>
    queryClient.invalidateQueries({
      queryKey: useGetSavedExpressionByIdQuery.getKey({
        customerId: requireCurrentCustomerId(),
        expressionId: expression,
      }),
    });

  const handleTableChange = (
    _pagination: TablePaginationConfig,
    _filters: Record<string, FilterValue | null>,
    sorterResult: SorterResult<SavedExpression> | SorterResult<SavedExpression>[]
  ) => {
    handleTableChangeForSorting(_pagination, _filters, sorterResult as SorterResult<unknown>);
  };

  const onPatternIdClick = (id: string) => {
    invalidatedSavedExpression(id).then(() => navigate({ to: "/pattern/$patternId", params: { patternId: id } }));
  };

  const columns: ColumnType<SavedExpression>[] = [
    {
      key: "name",
      title: "Name",
      dataIndex: "name",
      width: "30%",
      sorter: true,
      render: (name: SavedExpression["name"], row: SavedExpression) => {
        if (typeof row === "object" && row !== null && "id" in row) {
          return <Typography.Link onClick={() => onPatternIdClick(row.id)}>{name}</Typography.Link>;
        } else {
          return (
            <Tooltip title="This pattern can't be edited until is Calculating" placement="top">
              <Typography.Text
                style={{
                  cursor: "not-allowed",
                }}
              >
                {name}
              </Typography.Text>
            </Tooltip>
          );
        }
      },
    },
    {
      key: "description",
      title: "Description",
      dataIndex: "description",
      sorter: true,
      render(description: SavedExpression["description"]) {
        if (description) return <span>{description}</span>;
        return <span>-</span>;
      },
    },
    {
      key: "creatorName",
      title: "Author",
      dataIndex: "creator.name",
      width: 220,
      sorter: true,
      render: (_: unknown, row: SavedExpression) => <EntityCreatorElement creator={row.creator} />,
    },
    {
      key: "status",
      title: "Status",
      dataIndex: "status",
      width: 120,
      sorter: true,
      render(status: SavedExpression["status"]) {
        if (status) return <span>{humanFriendlyStatuses(status)} </span>;
        return <span>Unknown</span>;
      },
    },
    {
      key: "created",
      title: "Created",
      dataIndex: "created",
      width: 220,
      sorter: true,
      render: (created: SavedExpression["created"]) => <span>{new Date(created).toLocaleString()}</span>,
    },
  ].map(toMaybeColumnWithInitialSorting);

  return (
    <SavedExpressionListContainer className="saved-expression-list">
      <BasicTable
        dataSource={expressions}
        columns={columns}
        sortDirections={["ascend", "descend", "ascend"]}
        rowKey={(expression) => expression.id}
        onChange={handleTableChange}
        pagination={{
          defaultPageSize: DEFAULT_PAGE_SIZE,
          total,
          current: currentPage,
          onChange: setCurrentPage,
          showSizeChanger: false,
        }}
        loading={isFetching}
      />
    </SavedExpressionListContainer>
  );
};
