import { SingleVehicleAnomalyDetector } from "@/api/customerApi";

import { getLast90Days, getLast90DaysFromSpecificDate } from "./getLast90Days";

// The linter claims the find last index doesnt exist but it does.
export const getLast90DaysIndex = (anomaly: SingleVehicleAnomalyDetector) =>
  Math.max(
    // @ts-ignore
    anomaly.vehicleData.history.x.findLastIndex((date: number) => date < getLast90Days()),
    0
  );

export const getLast90DaysIndexFromMostRecentDate = (anomaly: SingleVehicleAnomalyDetector) => {
  const xValues = anomaly.vehicleData.history.x;
  const mostRecentDate = xValues[xValues.length - 1];
  const ninetyDaysAgoFromMostRecentDate = getLast90DaysFromSpecificDate(mostRecentDate);
  return Math.max(
    // @ts-ignore
    anomaly.vehicleData.history.x.findLastIndex((date: number) => date < ninetyDaysAgoFromMostRecentDate),
    0
  );
};
