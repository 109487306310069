import { useEffect } from "react";

/**
 * Custom hook to execute callback whenever the keyup event triggers with the esc key
 * @param callback
 */
const useEscKeyCallback = (callback: () => void) => {
  useEffect(() => {
    const handleEscKeyPress = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        callback();
      }
    };

    document.addEventListener("keyup", handleEscKeyPress);

    return () => {
      document.removeEventListener("keyup", handleEscKeyPress);
    };
  }, [callback]);
};

export default useEscKeyCallback;
