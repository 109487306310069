import * as Sentry from "@sentry/react";

import { LogEntry, LogLevels } from "./Logger";
import { getItemFromSessionStorage } from "./sessionStorage";

/** Forward logs to Sentry.io. By default only log levels WARNING and above will be forwarded. */
export default function createSentryLogger(minLevel: LogLevels = LogLevels.WARN, enableBreadcrumbs: boolean = true) {
  const mapLevelToSeverity: { [key in LogLevels]: Sentry.SeverityLevel | undefined } = {
    [LogLevels.SILLY]: "debug",
    [LogLevels.DEBUG]: "debug",
    [LogLevels.INFO]: "info",
    [LogLevels.WARN]: "warning",
    [LogLevels.ERROR]: "error",
    [LogLevels.CRITICAL]: "fatal",
    [LogLevels.NONE]: undefined,
  };

  return function log(logEntry: LogEntry) {
    const severity = mapLevelToSeverity[logEntry.level];
    if (typeof severity !== "undefined" && logEntry.level >= minLevel) {
      const message = logEntry.message;
      const extras = logEntry.payload ? { ...logEntry.payload } : {};
      const user = extras?.user ?? {};
      const exception = extras.error instanceof Error ? extras.error : undefined;
      if (exception) {
        delete extras.error;
      }
      const sessionId = getItemFromSessionStorage("sessionId");
      if (sessionId) {
        extras.sessionId = sessionId;
        Sentry.setTag("Session Id", sessionId);
      }
      const captureContext = { extra: extras, level: severity, tags: { log_tag: logEntry.meta?.tag } };
      Sentry.setUser({ email: user?.email ?? "" });
      if (message && message !== exception?.message) {
        Sentry.captureMessage(message, captureContext);
      }
      if (exception) {
        Sentry.captureException(exception, captureContext);
      }
    } else if (enableBreadcrumbs) {
      Sentry.addBreadcrumb({
        category: logEntry.meta?.tag,
        message: logEntry.message,
        data: logEntry.payload,
        level: severity,
        type: "default",
      });
    }
  };
}
