import { RepairConcept, RepairEventType } from "@/api";
import { RepairConceptFields } from "@/contexts/EditRepairConceptProvider";

export const convertToRepairConceptFields = (repair?: RepairConcept): RepairConceptFields | undefined => {
  if (!repair) return;
  return {
    id: repair.id,
    name: repair.name,
    cost: repair.cost,
    repairEventType: repair.repairEventType as RepairEventType,
    triggerExpressionEventId: repair.triggerExpressionEvent?.id,
    version: repair.version,
  };
};
