import { Button, Flex, Popconfirm, Tooltip } from "antd";

import { TopHeadingWithButtons } from "./TopHeadingWithButtons";

export type TopHeadingWithButtonsForAnomalyAndPatternProps = {
  isNew: boolean;
  itemType: "Pattern" | "Anomaly Detector";
  hideButtons: boolean;
  isReadOnly: boolean;
  isLoading: boolean;
  isDisabled: boolean;
  isOwnedByMe: boolean;
  isCalculating?: boolean;
  onDelete: () => any;
  onCreate: () => any;
  onUpdate: () => any;
  onSaveAs: () => any;
  onCancel: () => any;
};
export const TopHeadingWithButtonsForAnomalyAndPattern = ({
  isNew,
  itemType,
  hideButtons,
  isReadOnly,
  isLoading,
  isDisabled,
  isOwnedByMe,
  isCalculating,
  onDelete,
  onCreate,
  onUpdate,
  onSaveAs,
  onCancel,
}: TopHeadingWithButtonsForAnomalyAndPatternProps) => {
  const isDeletable = !isReadOnly && !isNew && isOwnedByMe;
  const canSave = isOwnedByMe && !isReadOnly;

  const updateTooltipMessage = canSave
    ? isCalculating
      ? "Cannot update patterns while they are being calculated. Please wait until the status changes, or use 'Save As' to create a new pattern with your changes."
      : "Save changes"
    : "Can only update items you've created";

  return (
    <TopHeadingWithButtons
      title={isNew ? `Create New ${itemType}` : `Edit ${itemType}`}
      buttons={
        hideButtons ? null : (
          <Flex gap={4}>
            <Tooltip title="Back to pattern list">
              <Button type="text" onClick={onCancel} loading={isLoading} disabled={isLoading}>
                Cancel
              </Button>
            </Tooltip>
            {!isNew && (
              <Popconfirm
                title="Are you sure to delete this item?"
                okText="Yes"
                cancelText="No"
                onConfirm={onDelete}
                disabled={isLoading}
              >
                <Button
                  danger
                  type="text"
                  loading={isLoading}
                  disabled={!isDeletable || isLoading}
                  style={{ marginRight: "auto" }}
                >
                  Delete
                </Button>
              </Popconfirm>
            )}
            {!isNew && (
              <Tooltip title="Make a copy of this item with a new name">
                <Button onClick={onSaveAs} type="default" loading={isLoading} disabled={isLoading || isDisabled}>
                  Save As
                </Button>
              </Tooltip>
            )}
            {isNew && (
              <Button
                onClick={onCreate}
                loading={isLoading}
                disabled={isLoading || isDisabled}
                type="primary"
                style={{ marginLeft: "auto" }}
              >
                Create
              </Button>
            )}
            {!isNew && (
              <Tooltip title={updateTooltipMessage}>
                <Button
                  onClick={onUpdate}
                  loading={isLoading}
                  disabled={!canSave || isLoading || isDisabled || isCalculating}
                  type="primary"
                >
                  Save
                </Button>
              </Tooltip>
            )}
          </Flex>
        )
      }
    />
  );
};
