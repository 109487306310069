import { configureStore, createSelector, Store } from "@reduxjs/toolkit";

import { DiscountDensityFunction, Forecast } from "@/api";

import ForecastSlice, { ForecastActions } from "./ForecastSlice";

export interface ForecastSelector {
  forecast: ForecastModel;
}

export interface Threshold {
  id: string;
  value: number;
}

export interface ForecastModel {
  forecast: Forecast;
  editingDensityFn?: {
    i: number;
    j: number;
    func: DiscountDensityFunction;
  };
  selectingRepairConcept?: boolean;
}

export const selectEditingDensityFunction = createSelector(
  (state: ForecastSelector) => state.forecast,
  (forecast) => forecast.editingDensityFn
);

export const selectDiscountUnit = createSelector(
  (state: ForecastSelector) => state.forecast,
  (forecast) => forecast.forecast.discountUnit
);

let forecastStore: Store;

const configureForecastStore = (initialState?: ForecastModel) => {
  if (!forecastStore) {
    forecastStore = configureStore({
      reducer: {
        forecast: ForecastSlice.reducer,
      },
      preloadedState: initialState ? { forecast: initialState } : undefined,
    });
  } else {
    forecastStore.dispatch(ForecastActions.updateForecastModel({ forecast: initialState?.forecast }));
  }
  return forecastStore;
};

export default configureForecastStore;
