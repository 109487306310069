import { useParams, useSearch } from "@tanstack/react-router";
import { useState } from "react";

/**
 *
 * Detects a change in the params if the event type is the same. Allows for the page to rerender
 * when clicking on links of the same event type in event analysis
 */

export const useEventAnalysisParams = () => {
  const { faultCodeId, patternEventId } = useParams({ strict: false });
  const { ecu, troubleCode, softwareVersion, hardwareVersion, softwarePartNumber, hardwarePartNumber } = useSearch({
    strict: false,
    select: ({ ecu, troubleCode, softwareVersion, hardwareVersion, softwarePartNumber, hardwarePartNumber }) => ({
      ecu,
      troubleCode,
      softwareVersion,
      hardwareVersion,
      softwarePartNumber,
      hardwarePartNumber,
    }),
  });

  const [params, setParams] = useState<Record<string, string | undefined | null>>({});

  const queryParamsObject = {
    ecu,
    softwareVersion,
    hardwareVersion,
    softwarePartNumber,
    hardwarePartNumber,
    troubleCode,
  };

  const hasQueryParamsChanged = Object.entries(queryParamsObject).some(([key, value]) => params[key] !== value);

  if (patternEventId !== params.patternEventId || faultCodeId !== params.faultCodeId || hasQueryParamsChanged) {
    setParams((prevParams) => ({
      ...prevParams,
      ...queryParamsObject,
      faultCodeId,
      patternEventId,
    }));
  }

  return params;
};
