import { PlusCircleOutlined } from "@ant-design/icons";
import { useNavigate } from "@tanstack/react-router";
import { Button } from "antd";

import { Forecast } from "@/api";
import ForecastPicker from "@/features/forecast/ForecastPicker";

import { ForecastHomeContainer } from "./ForecastHome.styled";

const ForecastHome = () => {
  const navigate = useNavigate();

  const newForecastHandler = () => {
    navigate({ to: "/forecast/new" });
  };

  const forecastSelectedHandler = (forecast: Forecast) => {
    navigate({ to: "/forecast/$forecastId", params: { forecastId: forecast.id } });
  };

  return (
    <ForecastHomeContainer>
      <div className="forecasts-header">
        <h4 className="heading-x-small">Forecasts</h4>
        <Button type="text" className="text-button" onClick={newForecastHandler}>
          <PlusCircleOutlined />
          Create Forecast
        </Button>
      </div>
      <div className="forecasts-body">
        <ForecastPicker onForecastSelected={forecastSelectedHandler} />
      </div>
    </ForecastHomeContainer>
  );
};

export default ForecastHome;
