import { TablePaginationConfig } from "antd/lib";
import { FilterValue, SorterResult, SortOrder } from "antd/lib/table/interface";
import { useState } from "react";

export interface UseRemoteSortingParams<TSortFieldEnum> {
  fieldsEnumOptions: TSortFieldEnum[];
  initialValue?: MaybeSortingValue<TSortFieldEnum>;
}

export interface UseRemoteSortingResult<TSortFieldEnum> {
  sorting: MaybeSortingValue<TSortFieldEnum>;
  handleTableChangeForSorting: (
    _pagination: TablePaginationConfig,
    _filters: Record<string, FilterValue | null>,
    sorter: SorterResult<unknown> | SorterResult<unknown>[]
  ) => void;
  toMaybeColumnWithInitialSorting: <TColumn extends { key?: unknown }>(column: TColumn) => TColumn;
}

export interface SortingValue<T> {
  field: T;
  order: SortOrder;
}

export type MaybeSortingValue<T> = SortingValue<T> | null;

/**
 * Facade stateful hook, combining interfaces of:
 * - Ant Design table sorting.
 * - Amaya standards for sorting via GraphQL generated stuff.
 */
export function useRemoteSorting<TSortFieldEnum>(
  params: UseRemoteSortingParams<TSortFieldEnum>
): UseRemoteSortingResult<TSortFieldEnum> {
  const [sorting, setSorting] = useState<MaybeSortingValue<TSortFieldEnum>>(params.initialValue ?? null);

  const handleTableChangeForSorting = (
    _pagination: TablePaginationConfig,
    _filters: Record<string, FilterValue | null>,
    sorterResult: SorterResult<unknown> | SorterResult<unknown>[]
  ) => {
    if (Array.isArray(sorterResult)) {
      console.warn("Unsupported usage of multiple sorting, ignoring it.");
      return;
    }

    if (!sorterResult.order) {
      // Null is understood as "no explicit sorting".
      setSorting(null);
      return;
    }

    if (!sorterResult.columnKey) {
      console.error("Unexpected empty column key detected by sorting change, ignoring it.");
      return;
    }

    if (!params.fieldsEnumOptions.includes(sorterResult.columnKey as TSortFieldEnum)) {
      console.error("Unexpected field detected by sorting change, ignoring it.");
      return;
    }

    setSorting({ field: sorterResult.columnKey, order: sorterResult.order } as SortingValue<TSortFieldEnum>);
  };

  const toMaybeColumnWithInitialSorting = <TColumn extends { key?: unknown }>(column: TColumn) => {
    if (!params.initialValue || column.key !== params.initialValue.field) {
      return column;
    }

    return { ...column, defaultSortOrder: params.initialValue.order };
  };

  return {
    // to be provided to generated graphql hooks
    sorting,
    // to be provided to AntD table (aka, `BasicTable`) change prop
    handleTableChangeForSorting,
    // after creating a AntD columns definition,
    // map them with this helper to mark which of them is the default sorting
    toMaybeColumnWithInitialSorting,
  };
}
