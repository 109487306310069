import IconDropdown from "@amayaIcons/icon-drop-down.svg";
import { SelectProps } from "antd";
import { DefaultOptionType } from "antd/lib/select";
import { Controller } from "react-hook-form";

import { StyledSelect } from "./BasicSelect.styled";
import FormItem from "./FormItem";
import { BasicInputProps } from "./types";

export type BasicSelectProps = SelectProps & BasicInputProps;

export default function BasicSelect(props: BasicSelectProps) {
  const {
    name,
    defaultValue,
    label,
    hideLabel,
    formItemClassName,
    className,
    tooltip,
    placeholder,
    disabled,
    options,
    loading,
    getPopupContainer,
    required,
  } = props;

  if (!name) {
    return (
      <FormItem label={label} hideLabel={hideLabel} formItemClassName={formItemClassName} tooltip={tooltip}>
        <StyledSelect
          className={className}
          defaultValue={defaultValue}
          value={props.value}
          onSelect={props.onSelect as any}
          onChange={props.onChange as any}
          options={options}
          disabled={disabled}
          getPopupContainer={getPopupContainer}
          placeholder={placeholder}
          loading={loading}
          mode={props.mode}
          allowClear={props.allowClear}
          suffixIcon={<IconDropdown />}
        />
      </FormItem>
    );
  }

  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      rules={{ required }}
      render={({ field, fieldState }) => {
        return (
          <FormItem
            label={label}
            hideLabel={hideLabel}
            formItemClassName={formItemClassName}
            tooltip={tooltip}
            error={fieldState.error}
          >
            <StyledSelect
              className={className}
              value={field.value}
              onSelect={(value, option) => {
                if (props.onSelect) {
                  props.onSelect(value, option as DefaultOptionType);
                } else {
                  field.onChange(value);
                }
              }}
              options={options}
              disabled={disabled}
              getPopupContainer={getPopupContainer}
              placeholder={placeholder}
              loading={loading}
              suffixIcon={<IconDropdown />}
            />
          </FormItem>
        );
      }}
    />
  );
}
