import styled from "styled-components";

export const LoadingContainer = styled.div`
  min-height: 100px;
  display: flex;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;
`;
