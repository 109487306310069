import { Result } from "antd";
import { EChartsReactProps } from "echarts-for-react";
import _ from "lodash";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { CalculatedStatus, ExpressionEventOperator, TimeSeriesIndependentVar } from "@/api";
import {
  ExpressionEventOccurrencesPreviewQueryVariables,
  useExpressionEventOccurrencesPreviewQuery,
} from "@/api/customerApi";
import { BaseEChart } from "@/components/ui/BaseEChart";
import { QUERY_SETTINGS } from "@/constants";
import GlobalFilterContext from "@/contexts/GlobalFilterContext";
import { buildExpressionEventChartConfig } from "@/features/chartElementLibrary/ChartLibraryBuildUtils";

import { ExpressionEventPreviewContent, ExpressionEventPreviewContentEmpty } from "./ExpressionEvent.styled";

type ExpressionEventPreviewProps = {
  expressionId?: string;
  expressionStatus?: CalculatedStatus;
  operator?: ExpressionEventOperator;
  threshold?: number;
};

type DebouncedVariables = Partial<
  ExpressionEventOccurrencesPreviewQueryVariables & { expressionStatus: CalculatedStatus }
>;

export const ExpressionEventPreview = (props: ExpressionEventPreviewProps) => {
  const { globalFilter } = useContext(GlobalFilterContext);
  const { expressionId, expressionStatus, operator, threshold } = props;
  const [queryVariables, setQueryVariables] = useState<DebouncedVariables>({});
  const independentVar = TimeSeriesIndependentVar.Mileage;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSetQueryVariables = useCallback(_.debounce(setQueryVariables, 1000), []);

  useEffect(() => {
    if (independentVar !== queryVariables.independentVar) {
      setQueryVariables({
        expressionId: expressionId!,
        expressionStatus,
        operator: operator!,
        threshold: threshold!,
        independentVar: independentVar!,
      });
    } else {
      debouncedSetQueryVariables({
        expressionId: expressionId!,
        expressionStatus,
        operator: operator!,
        threshold: threshold!,
        independentVar: independentVar!,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expressionId, expressionStatus, operator, threshold, independentVar, debouncedSetQueryVariables]);

  const queryEnabled =
    !!queryVariables.expressionId &&
    !!queryVariables.operator &&
    !!queryVariables.independentVar &&
    typeof queryVariables.threshold === "number" &&
    queryVariables.expressionStatus === CalculatedStatus.Available;

  const { data: previewData, isFetching } = useExpressionEventOccurrencesPreviewQuery(
    { ...(queryVariables as ExpressionEventOccurrencesPreviewQueryVariables), filter: globalFilter },
    {
      enabled: queryEnabled,
      staleTime: QUERY_SETTINGS.LONG_STALE_TIME,
    }
  );

  const chartConfig: EChartsReactProps = useMemo(() => {
    if (!previewData || isFetching)
      return {
        option: {},
      };
    return buildExpressionEventChartConfig(
      previewData!.filteredQuery.expressionEvents!.occurrencesPreview.count,
      previewData!.filteredQuery.expressionEvents!.occurrencesPreview.x,
      previewData!.filteredQuery.expressionEvents!.occurrencesPreview.y,
      queryVariables.independentVar
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [previewData]);

  return (
    <ExpressionEventPreviewContent>
      {queryEnabled ? (
        <>
          <p>The Preview shows the percentage of intervals for which the pattern event criteria are met.</p>
          <BaseEChart className="echarts" {...chartConfig} showLoading={isFetching} />
        </>
      ) : (
        <ExpressionEventPreviewContentEmpty>
          {!queryVariables.expressionStatus || queryVariables.expressionStatus === CalculatedStatus.Available ? (
            <Result title={`Pattern Is Incomplete`} status="warning" className="pullsystems-result" />
          ) : (
            <Result
              title={`Pattern status is ${queryVariables.expressionStatus?.toLowerCase()}`}
              className="pullsystems-result"
            />
          )}
        </ExpressionEventPreviewContentEmpty>
      )}
    </ExpressionEventPreviewContent>
  );
};
