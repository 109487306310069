import { Select } from "antd/lib";
import { SelectProps } from "rc-select";
import { Dispatch, SetStateAction, useState } from "react";

import { Cluster } from "@/api";
import { TopFaultParamsInput } from "@/api/customerApi";
import { FaultFingerprintsTable } from "@/components/tables/Cluster/FaultFingerprintsTable";
import { BasicWidget } from "@/components/widget/BasicWidget";
import { toTitleCase } from "@/utils/toTitleCase";

import { ClusterViewFaultTableHeader } from "./ClusterView.styled";

export interface FaultFingerprintsWidgetProps {
  setAffectedPvins: Dispatch<SetStateAction<string[] | undefined>>;
  cluster: Cluster;
  setModalView: Dispatch<
    SetStateAction<"vehiclesAffected" | "addVehiclesToCluster" | "viewMissingPvins" | "copyCluster" | undefined>
  >;
  setLoading: Dispatch<SetStateAction<boolean>>;
}

export function FaultFingerprintsWidget(props: FaultFingerprintsWidgetProps) {
  const [topFaultParams, setTopFaultParams] = useState<TopFaultParamsInput>({ includeTroubleCode: true });

  return (
    <BasicWidget
      title={
        <ClusterViewFaultTableHeader>
          <h3 className="frame-input-label heading-x-small">Fault Fingerprints</h3>
          <FaultFingerprintsEnabledColumnsSelection setTopFaultParams={setTopFaultParams} />
        </ClusterViewFaultTableHeader>
      }
      noHeaderStyling
    >
      <FaultFingerprintsTable
        topFaultParams={topFaultParams}
        cluster={props.cluster}
        setAffectedPvins={props.setAffectedPvins}
        setModalView={props.setModalView}
        setLoading={props.setLoading}
      />
    </BasicWidget>
  );
}

function FaultFingerprintsEnabledColumnsSelection(props: {
  setTopFaultParams: Dispatch<SetStateAction<TopFaultParamsInput>>;
}) {
  const options: SelectProps["options"] = [
    "trouble code",
    "software version",
    "hardware version",
    "software part number",
    "hardware part number",
  ].map((option) => ({
    value: option,
    label: toTitleCase(option),
  }));

  const handleChange: SelectProps["onChange"] = (_, changingOptions) => {
    if (Array.isArray(changingOptions)) {
      props.setTopFaultParams({
        includeTroubleCode: changingOptions.some((o) => o.value === "trouble code"),
        includeSoftwareVersion: changingOptions.some((o) => o.value === "software version"),
        includeHardwareVersion: changingOptions.some((o) => o.value === "hardware version"),
        includeSoftwarePartNumber: changingOptions.some((o) => o.value === "software part number"),
        includeHardwarePartNumber: changingOptions.some((o) => o.value === "hardware part number"),
      });
    }
  };

  return (
    <Select
      options={options}
      dropdownStyle={{ textTransform: "capitalize" }}
      maxTagCount={"responsive"}
      mode={"multiple"}
      defaultValue={["trouble code"]}
      onChange={handleChange}
    />
  );
}
