import { DEFAULT_PAGE_SIZE } from "@lib/src/DEFAULT_PAGE_SIZE";
import { useQueryClient } from "@tanstack/react-query";
import { Link } from "@tanstack/react-router";
import { Button, notification } from "antd";
import { FilterValue, SorterResult, TablePaginationConfig } from "antd/lib/table/interface";
import { useState } from "react";

import { Cluster, ClusterSortField, ClustersSortInput, Pagination, useListClustersQuery } from "@/api";
import { TopHeadingWithButtons } from "@/components/pageContent/TopHeadingWithButtons";
import { BasicTable } from "@/components/tables/BasicTable";
import { useRemoteSorting } from "@/components/tables/useRemoteSorting";
import { BasicWidget } from "@/components/widget/BasicWidget";
import { Route } from "@/routes/cluster.index";
import { getCurrentCustomerId } from "@/utils/customers";
import { parseDateToMdStr } from "@/utils/dateUtils";

import { ClusterModal } from "../clusterModal/ClusterModal";
import { DeleteClusterModal } from "./DeleteClusterModal";

export const ClusterHome = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [api, context] = notification.useNotification();

  const currentPage = Route.useSearch({ select: (search) => search.clustersPage });
  const clusterNavigate = Route.useNavigate();

  const setCurrentPage = (page: number) => {
    return clusterNavigate({ search: (prev) => ({ ...prev, clustersPage: page }), resetScroll: true });
  };

  const pagination: Pagination = { currentPage: currentPage || 1, pageSize: DEFAULT_PAGE_SIZE };

  const { sorting, handleTableChangeForSorting, toMaybeColumnWithInitialSorting } = useRemoteSorting({
    fieldsEnumOptions: Object.values(ClusterSortField),
    initialValue: { field: ClusterSortField.Created, order: "descend" },
  });

  const queryClient = useQueryClient();
  const { data, isLoading, isFetching } = useListClustersQuery({
    customerId: getCurrentCustomerId() ?? "",
    pagination,
    sorting: sorting as ClustersSortInput,
  });

  type columnRow = Omit<Cluster, "faults" | "pvins" | "missingPvins">;

  const columns = [
    {
      key: "name",
      title: "Name",
      dataIndex: "name",
      sorter: true,
      render: (_: string, row: columnRow) => {
        return (
          <Link to="/cluster/$clusterId" params={{ clusterId: row.id }}>
            {row.name}
          </Link>
        );
      },
    },
    {
      key: "description",
      title: "Description",
      dataIndex: "description",
      sorter: true,
      render: (_: string, row: columnRow) => row.description || "-",
    },
    {
      key: "creatorName",
      title: "Author",
      dataIndex: "creator.name",
      sorter: true,
      render: (_: string, row: columnRow) => {
        return row.creator?.name ?? "-";
      },
    },
    {
      key: "created",
      title: "Date Created",
      dataIndex: "created",
      width: 256,
      sorter: true,
      render: (_: string, row: columnRow) => {
        return parseDateToMdStr(new Date(row.created));
      },
    },
    { key: "vehicleCount", title: "Vehicle Count", dataIndex: "vehicleCount", width: 112 },
    {
      key: "actions",
      title: "Actions",
      dataIndex: "",
      width: 96,
      render: (_: string, row: columnRow) =>
        row.creator?.isMe && <DeleteClusterModal onSuccess={onSuccessClusterAction} api={api} clusterId={row.id} />,
    },
  ].map(toMaybeColumnWithInitialSorting);

  const onSuccessClusterAction = async () => {
    setIsModalOpen(false);
    await setCurrentPage(1);
    queryClient.refetchQueries({ queryKey: useListClustersQuery.getKey({ customerId: getCurrentCustomerId() ?? "" }) });
  };

  const handleTableChange = (
    _pagination: TablePaginationConfig,
    _filters: Record<string, FilterValue | null>,
    sorterResult:
      | SorterResult<Omit<Cluster, "pvins" | "missingPvins">>
      | SorterResult<Omit<Cluster, "pvins" | "missingPvins">>[]
  ) => {
    handleTableChangeForSorting(_pagination, _filters, sorterResult as SorterResult<unknown>);
  };

  return (
    <>
      <TopHeadingWithButtons
        title="Clusters"
        buttons={
          <Button type="primary" onClick={() => setIsModalOpen(true)}>
            Create New Cluster
          </Button>
        }
      />
      <BasicWidget>
        {context}
        <BasicTable
          pagination={{
            defaultPageSize: DEFAULT_PAGE_SIZE,
            total: data?.clusters?.clusters.pagination.totalCount,
            current: currentPage,
            onChange: setCurrentPage,
            showSizeChanger: false,
          }}
          onChange={handleTableChange}
          columns={columns}
          dataSource={data?.clusters?.clusters.data}
          rowKey={"id"}
          loading={isLoading || isFetching}
        />
        <ClusterModal
          handleSuccess={onSuccessClusterAction}
          isOpen={isModalOpen}
          onCancel={() => setIsModalOpen(false)}
          api={api}
          initialView={"newCluster"}
        />
      </BasicWidget>
    </>
  );
};
