import { Collapse } from "antd";
import styled from "styled-components";

export const StyledExpressionCollapse = styled(Collapse)`
  border: none;
  padding: 0 !important;
  background-color: transparent;
  position: relative;

  .ant-form-item-control-input {
    border: none;
  }

  .ant-collapse-header-text {
    font-size: 18px;
  }

  .ant-collapse-expand-icon {
    margin: auto;
  }

  .ant-collapse-content,
  .ant-collapse-item {
    border: none;
    background-color: transparent;
  }
`;

export const NestedItems = styled.div`
  padding-left: 32px;
`;
