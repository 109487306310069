import styled from "styled-components";

export const ErrorMessageContainer = styled.div`
  max-width: 500px;
  padding: 0.2rem;
  .error-message-copy {
    color: #0094ff;
    cursor: pointer;
  }
`;
