import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";

import {
  DensityFunction,
  DensityFunctionIndependentVariable,
  DiscountDensityFunction,
  Forecast,
  ProductionForecast,
  ReadOnlyUser,
  RepairConcept,
} from "@/api";
import { SimpleDensityCurve } from "@/features/forecast/ForecastHelper";

import { ForecastModel, Threshold } from "./ForecastStore";

interface AddRepairConceptProps {
  repair: RepairConcept;
  threshold: Threshold;
  discount: DensityFunction;
}

interface RemoveRepairConceptProps {
  i: number;
}

interface StartEditingDensityFnProps {
  i: number;
  j: number;
  fn: DiscountDensityFunction;
}

interface SaveDiscountDensityFnProps {
  newDistribution: DensityFunction;
  discountCoord: [number, number];
}

interface UpdateRepairConceptThresholdProps {
  newThreshold: number;
  repairIndex: number;
}

interface UpdateProductionForecastProps {
  productionForecastId: string;
}

interface UpdateForecastDiscountUnitProps {
  variable: DensityFunctionIndependentVariable;
}

interface UpdateForecastModelProps {
  forecast?: Forecast;
}

const initialState = {
  forecast: {
    id: "",
    name: "New Forecast",
    repairConcepts: [],
    discountUnit: DensityFunctionIndependentVariable.OdometerKm,
    discounts: [[]],
    created: "",
    lastModified: "",
    creator: {} as ReadOnlyUser,
    productionForecast: {} as ProductionForecast,
    version: 1,
  },
} as ForecastModel;

const ForecastSlice = createSlice({
  name: "forecast",
  initialState,
  reducers: {
    addRepairConcept(state, action: PayloadAction<AddRepairConceptProps>) {
      state.forecast.repairConcepts = [
        ...state.forecast.repairConcepts,
        {
          repairConcept: action.payload.repair,
          threshold: action.payload.threshold.value,
        },
      ];
      // Add a new discount on each of the existing lines
      const newDiscount = action.payload.discount;
      state.forecast.discounts.forEach((discountLine) => {
        discountLine.push({
          totalDiscount: 0,
          distribution: {
            points: newDiscount.points,
            y0: newDiscount.y0,
          },
        });
      });
      // And add a new line of discounts
      const newDiscountsLine = state.forecast.repairConcepts.map((_repairConcept) => {
        return {
          totalDiscount: 0,
          distribution: {
            points: _.cloneDeep(SimpleDensityCurve),
            y0: newDiscount.y0,
          },
        };
      });
      // Set discount matrix repair concept intersection with itself
      newDiscountsLine.push({
        totalDiscount: 0,
        distribution: {
          points: _.cloneDeep(SimpleDensityCurve),
          y0: newDiscount.y0,
        },
      });
      state.forecast.discounts = [...state.forecast.discounts, newDiscountsLine];
    },
    removeRepairConcept(state, { payload: { i } }: PayloadAction<RemoveRepairConceptProps>) {
      state.forecast.repairConcepts = state.forecast.repairConcepts.filter((_, j) => j !== i);
      state.forecast.discounts = state.forecast.discounts.filter((_, j) => j !== i);
      state.forecast.discounts = state.forecast.discounts.map((disc) => {
        return disc.filter((_, j) => j !== i);
      });
    },
    startEditingDensityFn(state, { payload: { i, j, fn } }: PayloadAction<StartEditingDensityFnProps>) {
      state.editingDensityFn = {
        i,
        j,
        func: fn,
      };
    },
    saveDiscountDensityFn(state, action: PayloadAction<SaveDiscountDensityFnProps>) {
      const [x, y] = action.payload.discountCoord;
      state.forecast.discounts[x][y].distribution = action.payload.newDistribution;
      state.editingDensityFn = undefined;
    },
    endEditingDensityFn(state) {
      state.editingDensityFn = undefined;
    },
    toggleRepairConceptPicker(state) {
      state.selectingRepairConcept = !state.selectingRepairConcept;
    },
    updateRepairConceptThreshold(state, action: PayloadAction<UpdateRepairConceptThresholdProps>) {
      const { newThreshold } = action.payload;
      const { repairIndex } = action.payload;
      state.forecast.repairConcepts[repairIndex].threshold = newThreshold;
    },
    updateName(state, { payload: { name } }: PayloadAction<{ name: string }>) {
      state.forecast.name = name;
    },
    updateProductionForecast(state, action: PayloadAction<UpdateProductionForecastProps>) {
      state.forecast.productionForecast = { id: action.payload.productionForecastId } as ProductionForecast;
    },
    updateForecastDiscountUnit(state, action: PayloadAction<UpdateForecastDiscountUnitProps>) {
      state.forecast.discountUnit = action.payload.variable;
    },
    updateForecastModel(state, action: PayloadAction<UpdateForecastModelProps>) {
      if (action.payload.forecast) {
        state.forecast = action.payload.forecast;
      } else {
        state.forecast = initialState.forecast;
      }
    },
  },
});

export const ForecastActions = ForecastSlice.actions;

export default ForecastSlice;
