import { useAuth0 } from "@auth0/auth0-react";
import _ from "lodash";
import { createContext, Dispatch, ReactNode, SetStateAction, useEffect, useState } from "react";

import { getFromLocalStorage } from "@/utils/localStorageUtils";
export type GlobalFilterTypes = {
  vehicle: { deliveryCountry: string[]; model: string[]; modelYear: number[]; modelStyle: string[] };
};

const defaultGlobalFilter = {
  vehicle: { deliveryCountry: [], model: [], modelYear: [], modelStyle: [] },
};

export const defaultState: {
  globalFilter: GlobalFilterTypes;
  setGlobalFilter: Dispatch<SetStateAction<GlobalFilterTypes>>;
  emptyFilter: GlobalFilterTypes;
} = {
  globalFilter: defaultGlobalFilter,
  setGlobalFilter: () => {},
  emptyFilter: defaultGlobalFilter,
};

export const GlobalFilterContext = createContext(defaultState);

interface GlobalFilterProps {
  children: ReactNode;
}

const localStorageKey = "GLOBAL_FILTER";

export const GlobalFilterProvider = ({ children }: GlobalFilterProps) => {
  const { user } = useAuth0();
  const [globalFilter, setGlobalFilter] = useState<GlobalFilterTypes>(defaultGlobalFilter);
  const [emptyFilter] = useState<GlobalFilterTypes>(defaultGlobalFilter);

  const ensureGlobalFilterHasCorrectKeys = (filter: string) => {
    // gets any new entitys that may have been added
    const clonedDefaultFilter = _.cloneDeep(defaultGlobalFilter);
    const newFilters = Object.assign({}, clonedDefaultFilter, JSON.parse(filter));
    Object.keys(newFilters).forEach((entity) => {
      if (!defaultGlobalFilter[entity as keyof typeof defaultGlobalFilter]) {
        // removes any old entity
        delete newFilters[entity];
      } else {
        // gets any new entity types
        newFilters[entity] = Object.assign(
          {},
          clonedDefaultFilter[entity as keyof typeof defaultGlobalFilter],
          newFilters[entity]
        );
        // removes any old entity types
        const entityTypes = Object.keys(newFilters[entity]);
        entityTypes.forEach((entityType) => {
          if (!defaultGlobalFilter[entity as "vehicle"][entityType as "model"]) {
            delete newFilters[entity][entityType];
          }
        });
      }
    });
    return newFilters;
  };

  useEffect(() => {
    if (user) {
      const filters = getFromLocalStorage(`${localStorageKey}${user.email}`);
      if (filters) {
        setGlobalFilter(ensureGlobalFilterHasCorrectKeys(filters));
      }
    }
  }, [user]);

  return (
    <GlobalFilterContext.Provider value={{ globalFilter, setGlobalFilter, emptyFilter }}>
      {children}
    </GlobalFilterContext.Provider>
  );
};

export default GlobalFilterContext;
