import { QuestionCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import { EChartsReactProps } from "echarts-for-react";
import { useMemo } from "react";
import { useTheme } from "styled-components";

import { ExpressionHistogram } from "@/api/customerApi";
import { BaseEChart } from "@/components/ui/BaseEChart";
import { buildHistogramChartConfig } from "@/features/chartElementLibrary/ChartLibraryBuildUtils";

type ExpressionHistogramPreviewProps = {
  loading: boolean;
  data?: ExpressionHistogram;
};

const INTERVAL_NUMBER = 5;

export const ExpressionHistogramPreview = (props: ExpressionHistogramPreviewProps) => {
  const theme = useTheme();
  const { data: { min = [], max = [], pct = [], outliers } = {}, loading } = props;

  const minIndex = useMemo(() => min.findIndex((v) => v === null), [min]);

  const chartConfig: EChartsReactProps = buildHistogramChartConfig(INTERVAL_NUMBER, min, max, pct);

  return (
    <div>
      <h3>
        <b>Fleet Histogram </b>
        {outliers && (
          <Tooltip title="For display purposes, histogram has upper and lower 5% of results hidden" placement="topLeft">
            <QuestionCircleOutlined style={{ color: theme.colors.blueGray, fontSize: 16 }} />
          </Tooltip>
        )}
      </h3>
      <BaseEChart {...chartConfig} showLoading={loading} style={{ height: 500 }} />
      {minIndex > 0 && <h4>% of null values: {(pct[minIndex] * 100).toFixed(2)} %</h4>}
    </div>
  );
};
