import { TimeSeriesExpressionType } from "./types";

type Definition = {
  type: TimeSeriesExpressionType;
  value: string;
};

export const formatExpressionId = (expressionDefinition: Definition): Definition => {
  if (expressionDefinition.type === TimeSeriesExpressionType.Signal && expressionDefinition.value) {
    const parts = expressionDefinition.value.split(".");
    return {
      ...expressionDefinition,
      value: parts[parts.length - 1],
    };
  }
  return expressionDefinition;
};

export const formatToPermanentId = (str: string): string => {
  const sep = str.split(":");
  return sep[sep.length - 1];
};
