import { Form } from "antd";
import { useState } from "react";
import { useSelector } from "react-redux";

import { FaultTimeLineChart } from "@/components/charts/FaultTimeLineChart";
import BasicSelect from "@/components/form/input/BasicSelect";
import BlockingLoading from "@/components/ui/BlockLoading";
import { BasicWidget } from "@/components/widget/BasicWidget";
import { selectEventFilter } from "@/contexts/EventAnalysisChartStore";
import { toTitleCase } from "@/utils/toTitleCase";

import { EventTimeLineHeader } from "./EventTimeLine.styled";

export const EventTimeLine = () => {
  const { selectedEventFilter } = useSelector(selectEventFilter);

  const [rangeUnits, setRangeUnits] = useState<"kms" | "days">("kms");
  const [range, setRange] = useState<30 | 60 | 90 | 500 | 1000 | 5000 | 10000>(500);

  const handleRangeUnitchange = (value: "kms" | "days") => {
    if (value === "days") {
      setRangeUnits(value);
      setRange(30);
    } else {
      setRangeUnits(value);
      setRange(500);
    }
  };

  if (!selectedEventFilter) return <BlockingLoading />;

  return (
    <BasicWidget title={toTitleCase("Aggregate fault history")}>
      <Form layout="vertical">
        <EventTimeLineHeader>
          <BasicSelect
            options={(rangeUnits === "days" ? [30, 60, 90] : [500, 1000, 5000, 10000]).map((o) => ({
              value: o,
              label: o,
            }))}
            value={range}
            onChange={(value) => setRange(value)}
            label={"Range"}
          />
          <BasicSelect
            label={"Range Units"}
            options={["kms", "days"].map((o) => ({ value: o, label: o }))}
            value={rangeUnits}
            onChange={handleRangeUnitchange}
          />
        </EventTimeLineHeader>
      </Form>
      <hr />
      <FaultTimeLineChart
        faultCodeId={selectedEventFilter?.fault?.faultCode ?? undefined}
        clusterId={selectedEventFilter?.clusterId ?? undefined}
        range={range}
        rangeUnits={rangeUnits}
      />
    </BasicWidget>
  );
};
