import * as Sentry from "@sentry/browser";
import { createFileRoute } from "@tanstack/react-router";
import { Button, Flex } from "antd";

export const Route = createFileRoute("/error")({
  component: () => (
    <Flex justify="center" style={{ padding: "2rem" }}>
      <Button
        type="primary"
        onClick={() => {
          Sentry.captureException(new Error(`Sentry Test Error at ${new Date().toISOString()}`));
        }}
      >
        Break the world
      </Button>
    </Flex>
  ),
});
