import { List, Tooltip } from "antd";

import { SingleVehicleAnomalyDetector } from "@/api/customerApi";
import { useTimeSeriesFields } from "@/hooks/useTimeSeriesFields";

import { displayableInputFields } from "../displayableInputFields";
import { StyledAnomalyPlotButton } from "../StyledAnomalyPlotButton";

export type InputSignalsWithoutWeightsProps = {
  handleSignalClick: (signalId: string) => void;
  handlePlotAnomalyFields: (anomaly: SingleVehicleAnomalyDetector) => void;
  anomaly: SingleVehicleAnomalyDetector;
};

export const InputSignalsWithoutWeights = ({
  handleSignalClick,
  handlePlotAnomalyFields,
  anomaly,
}: InputSignalsWithoutWeightsProps) => {
  const { byId } = useTimeSeriesFields();
  const inputFields = displayableInputFields(anomaly, byId);
  return (
    <List
      size="small"
      bordered={false}
      dataSource={inputFields}
      renderItem={(signal) => {
        return (
          <List.Item style={{ paddingLeft: 0 }} key={signal.id}>
            <Tooltip title={"Plot Below"} placement="top" arrow={true}>
              <StyledAnomalyPlotButton onClick={() => handleSignalClick(signal.id)}>
                {signal.friendlyName}
              </StyledAnomalyPlotButton>
            </Tooltip>
          </List.Item>
        );
      }}
    >
      <List.Item style={{ paddingLeft: 0 }}>
        <StyledAnomalyPlotButton onClick={() => handlePlotAnomalyFields(anomaly)}>
          Plot All Below
        </StyledAnomalyPlotButton>
      </List.Item>
    </List>
  );
};
