import { Auth0ContextInterface, useAuth0 } from "@auth0/auth0-react";
import { IdToken } from "@auth0/auth0-spa-js";
import { notification } from "antd";
import { GraphQLError } from "graphql";
import { useContext } from "react";

import { ErrorKey } from "@/api";
import { HTTP_SETTINGS } from "@/constants";
import { CustomerContext } from "@/contexts/CustomerProvider";
import { getSignal, ROUTE_CHANGE_ABORT } from "@/features/AbortController";
import ApiError from "@/utils/errors/ApiError";
import Logger, { Payload } from "@/utils/Logger";

import i18next from "../i18n";

const logger = Logger.create(import.meta.url);
const errorNotificationDuration = 5;
const errorPrefix = "error.";

const extractQueryName = (query: string) => {
  const trimmedQuery = query.trim();

  const lines = trimmedQuery.split("\n");

  const queryLine = lines.find((line) => line.trim().startsWith("query"));

  const match = queryLine?.match(/query\s+(\w+)/);

  return match ? match[1] : null;
};

type GraphQLErrorException = {
  errorCode?: string;
  stack?: ReadonlyArray<string>;
  maskMessage?: boolean;
  name?: string;
  notify?: boolean;
  params?: ReadonlyMap<string, string>;
};

type Params = Record<string, unknown>;
function displayBackendError(error: ApiError) {
  console.log(error);
  const extensions = error.cause?.extensions;
  const exception = extensions?.exception as GraphQLErrorException | undefined;
  // determine if this error message should be shown to the user via notification (defaults to true)
  const shouldNotify = exception && exception?.notify ? exception.notify : true;
  if (shouldNotify) {
    // determine if this is a backend error we know of (ApiError), or some other error thrown by the api (Error, etc)
    const errorType: string = exception && exception.name ? exception.name : "Error";
    // extract the error-id - this will be shown as the notification header
    const errorId: string = extensions && extensions["error-id"] ? (extensions["error-id"] as string) : "";
    // extract errorCode - this will be used by i18n for localization if it exists in [en.json or de.json]
    // if this is some other type of error, notify user as a generic error (actual error will still be logged)
    const errorCode: string =
      errorType === "APIError" && exception?.errorCode
        ? errorPrefix + exception.errorCode
        : errorPrefix + ErrorKey.GenericError;
    // extract the log error message (this will be displayed if no i18n translation)
    var logErrorMsg: string = error.message;
    // try to get i18n translation using the errorCode as key
    if (errorCode) {
      // extract params - this will be passed to i18n for interpolation
      const errorMeta: Params = exception?.params ? { ...exception.params, interpolation: { escapeValue: false } } : {};
      // if the localization text exists, that will be used instead of the log message
      const localizedMsg: string = i18next.t(errorCode, errorMeta);
      if (localizedMsg !== errorCode) {
        // if i18n can't resolve the localization text, use the original log message instead of the errorCode
        logErrorMsg = localizedMsg ? localizedMsg : logErrorMsg;
      }
    }
    // send the notification
    notification.error({
      message: errorId,
      description: logErrorMsg,
      duration: errorNotificationDuration,
    });
  }
}
// 30s * 10 retries === 300, our original api timeout
const maxRetries = 10;
export function fetcher<TData, TVariables>(
  query: string,
  { getAccessTokenSilently, getIdTokenClaims, user }: Auth0ContextInterface,
  url: string,
  options?: Record<string, string>
): (variables?: TVariables) => Promise<TData> {
  return async (variables?: TVariables) => {
    const signal = getSignal();
    // We keep track of where the request was sent from in the app to determine if errors should be displayed later.
    const requestPath = window?.location?.pathname;
    // Even though we set out timeout to 300 in our serverless yml Redshift will timeout our apis after 29 seconds.
    // This is a hacky work around to check if the error is due to a gateway time out and if it is retry the query.
    // Redshift aggresively cahces the queries so eventually we will get data back.
    let apiTimeout = true;
    let numRetries = 0;
    let idTokenClaims: IdToken | undefined;
    while (apiTimeout === true && numRetries < maxRetries) {
      apiTimeout = false;
      try {
        let token: string | undefined;
        try {
          [token, idTokenClaims] = await Promise.all([getAccessTokenSilently(), getIdTokenClaims()]);
        } catch (error) {
          token = undefined;
        }
        const auth: Record<string, string> = token ? { Authorization: `Bearer ${token}` } : {};
        const session: Record<string, string> = {};
        if (idTokenClaims?.sid) {
          session[HTTP_SETTINGS.SESSION_ID_HEADER] = idTokenClaims.sid;
        }
        const response = await fetch(url, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            ...session,
            ...auth,
            ...options,
          },
          body: JSON.stringify({
            query,
            variables,
          }),
          signal,
        });
        if (response.status === 504) {
          apiTimeout = true;
          throw new ApiError("Response not successful: Received status code 504");
        }
        const json = await response.json();
        if (json?.errors) {
          const error = json.errors[0] || {};
          throw new ApiError(error.message || `API Fetch Error: ${error}`, error);
        }
        return json.data as TData;
      } catch (error) {
        // The path the user is in wher the error is recieved
        const currPath = window?.location?.pathname;
        numRetries += 1;
        // We cancle requests when the rout changes which causes an error. Dont need to display or log this error.
        if (error === ROUTE_CHANGE_ABORT) throw error;
        if (error instanceof ApiError) {
          if (
            apiTimeout &&
            error.message === "Response not successful: Received status code 504" &&
            numRetries <= maxRetries &&
            currPath === requestPath &&
            !query.includes("mutation")
          ) {
            apiTimeout = true;
            logger.error(`Api Timeout on ${extractQueryName(query)} ${error}`, { error, user } as Payload);
          } else {
            // Log the error but will only display if the user is on the same page
            if (currPath === requestPath) displayBackendError(error);
            logger.error(`Error fetching API: ${error}`, { error, user, sessionId: idTokenClaims?.sid } as Payload);
            throw error; // continue throwing
          }
        } else if (!apiTimeout || numRetries === maxRetries || query.includes("mutation")) {
          // Do not retry if the error is not API Timeout, max retries are reached or the it was a mutation
          // Log the error but will only display if the user is on the same page
          if (currPath === requestPath) displayBackendError(error as ApiError);
          logger.error(`Error fetching API: ${error}`, { error, user, sessionId: idTokenClaims?.sid } as Payload);
          throw new ApiError((error as Error).message || `API Fetch Error: ${error}`, error as GraphQLError);
        } else {
          logger.info(`Error fetching API. Retrying now. Attempt ${numRetries} out of ${maxRetries}`);
        }
      }
    }
    // we will never hit this but it makes typescript happy =)
    throw new ApiError("Error fetching API");
  };
}

/**
 * Low-level API fetcher hook. Do not use directly!
 *
 * @param query The GraphQL query to execute.
 * @param options Custom HTTP headers to pass to the GraphQL query.
 * @returns The result of the GraphQL query.
 */
export function useApiFetcher<TData, TVariables>(
  query: string,
  options?: Record<string, string>
): (variables?: TVariables) => Promise<TData> {
  const auth0 = useAuth0();
  return fetcher(query, auth0, import.meta.env.VITE_API_URL, options);
}

/**
 * Low-level API fetcher hook for customer APIs. Do not use directly!
 *
 * @param query The GraphQL query to execute.
 * @param options Custom HTTP headers to pass to the GraphQL query.
 * @returns The result of the GraphQL query.
 */
export function useCustomerApiFetcher<TData, TVariables>(
  query: string,
  options?: Record<string, string>
): (variables?: TVariables) => Promise<TData> {
  const auth0 = useAuth0();
  const customerContext = useContext(CustomerContext);
  let url = customerContext?.currentCustomer?.customApiUrl ?? import.meta.env.VITE_CUSTOMER_API_URL;
  return fetcher(query, auth0, url, options);
}

export default useApiFetcher;
