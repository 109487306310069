import { InfoCircleOutlined } from "@ant-design/icons";
import { DEFAULT_PAGE_SIZE } from "@lib/src/DEFAULT_PAGE_SIZE";
import { Link, useNavigate, useSearch } from "@tanstack/react-router";
import { ColumnType } from "antd/lib/table";
import { useContext } from "react";

import { Pagination } from "@/api";
import { EventFilter, EventOccurrence, useEventOccurrencesQuery } from "@/api/customerApi";
import FriendlyCountryLabel from "@/components/ui/FriendlyCountryLabel";
import { QUERY_SETTINGS } from "@/constants";
import GlobalFilterContext from "@/contexts/GlobalFilterContext";
import { INFERRED_DATE_JSX_MSG } from "@/features/vehicleDetails/FaultHistoryByVehicle";
import { useCustomerIdentifier } from "@/hooks/useCustomerIdentifier";
import { useTablePagination } from "@/hooks/useTablePagination";
import { parseDateToMdStr } from "@/utils/dateUtils";
import { formatNumber } from "@/utils/numberUtils";
import { toModelDescription } from "@/utils/vehicleModel";

import { BasicTable } from "../tables/BasicTable";
import StyledTooltip from "../ui/StyledTooltip";

type EventOccurrencesTableProps = {
  eventFilter: EventFilter;
};

export default function EventOccurrencesTable({ eventFilter }: EventOccurrencesTableProps) {
  const { globalFilter } = useContext(GlobalFilterContext);
  const { sortersInput, onChange } = useTablePagination<EventOccurrence>();

  const currentPage = useSearch({ strict: false, select: (search) => search.eventOccurrencesPage });
  const navigate = useNavigate();

  const pagination: Pagination = {
    currentPage: currentPage || 1,
    pageSize: DEFAULT_PAGE_SIZE,
  };

  const handlePaginationChange = (page: number) => {
    navigate({ search: (prev) => ({ ...prev, eventOccurrencesPage: page }) });
  };

  const { data, isFetching } = useEventOccurrencesQuery(
    {
      eventFilter,
      sorters: sortersInput,
      filter: globalFilter,
      pagination,
    },
    { staleTime: QUERY_SETTINGS.DEFAULT_STALE_TIME }
  );
  const { customerIdentifier } = useCustomerIdentifier();

  const columns: ColumnType<EventOccurrence>[] = [
    {
      key: "date",
      dataIndex: "date",
      title: "Last Occurrence",
      sorter: true,
      defaultSortOrder: "descend",
      width: 156,
      showSorterTooltip: true,
      tooltipMessage: (date, eventOccurrence) => {
        const { isDateInferred } = eventOccurrence;
        const dateString = date ? parseDateToMdStr(new Date(date)) : "-";
        return isDateInferred ? dateString : "";
      },
      render: (date, eventOccurrence) => {
        const { isDateInferred } = eventOccurrence;
        const dateString = date ? parseDateToMdStr(new Date(date)) : "-";
        if (isDateInferred) {
          return (
            <StyledTooltip title={INFERRED_DATE_JSX_MSG} showArrow={false}>
              <span className="event-occurence-date error">
                <InfoCircleOutlined /> {dateString}
              </span>
            </StyledTooltip>
          );
        }
        return <span className="event-occurence-date">{dateString}</span>;
      },
    },
    {
      key: "eventCount",
      dataIndex: "eventCount",
      title: "Occurrences",
      sorter: true,
    },
    {
      key: "vehicle.model",
      title: "Model",
      sorter: true,
      render: (_, occurrence) => toModelDescription(occurrence.vehicle, customerIdentifier.models) ?? "-",
    },
    {
      key: "vehicle.year",
      title: "Year",
      sorter: true,
      render: (_, occurrence) => occurrence.vehicle?.year ?? "-",
    },
    {
      key: "country",
      title: "Country",
      sorter: true,
      render: (_, occurrence) =>
        occurrence.vehicle?.country ? <FriendlyCountryLabel countryCode={occurrence.vehicle?.country} /> : "-",
    },
    {
      key: "odometer",
      dataIndex: "odometer",
      title: "Odometer (km)",
      sorter: true,
      render: (_, occurrence) => (occurrence.odometer ? formatNumber(occurrence.odometer) : "-"),
    },
    {
      key: "vehicle",
      title: "PVIN",
      dataIndex: "vehicle.id",
      tooltipMessage: (_, occurrence) => occurrence.vehicle?.id,
      render: (_, occurrence) =>
        occurrence.vehicle?.id ? (
          <Link to="/vehicle/$pvin" params={{ pvin: occurrence.vehicle.id }}>
            {occurrence.vehicle.id}
          </Link>
        ) : null,
    },
  ];

  return (
    <BasicTable
      loading={isFetching}
      dataSource={data?.filteredQuery.eventOccurrences.occurrences.map((d, i) => ({ key: d.vehicle?.id ?? i, ...d }))}
      columns={columns}
      rowKey={(row) => row.id}
      onChange={(pagination, _filter, sorter) => {
        onChange(pagination, {}, sorter);
        handlePaginationChange(pagination.current || 1);
      }}
      pagination={{
        current: pagination.currentPage,
        total: data?.filteredQuery.eventOccurrences.pagination.totalCount,
        showSizeChanger: false,
      }}
    />
  );
}
