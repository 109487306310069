import { BlockLoadingContainer } from "./BlockLoading.styled";

interface BlockLoadingProps {
  className?: string;
}

const BlockingLoading = ({ className }: BlockLoadingProps) => {
  return <BlockLoadingContainer className={className} />;
};

export default BlockingLoading;
