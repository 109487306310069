import { useContext } from "react";

import { AnomalyDetectorWithVehicleData } from "@/api/customerApi";
import { paginationInputFromTablePaginationConfig } from "@/components/tables/paginationInputFromTablePaginationConfig";
import GlobalFilterContext from "@/contexts/GlobalFilterContext";
import { useTablePagination } from "@/hooks/useTablePagination";

export const useAnomalySummaryQueryParams = () => {
  const { pagination, onChange } = useTablePagination<AnomalyDetectorWithVehicleData>();
  const { globalFilter } = useContext(GlobalFilterContext);

  return {
    filter: globalFilter,
    pagination: paginationInputFromTablePaginationConfig(pagination),
    onChange: onChange,
  };
};
