import { Gaussian } from "ts-gaussian";

import { EventRate } from "@/api/customerApi";
import { addDiagonal, calculateCubicSplineInterceptArray } from "@/utils/mathUtils";

export interface ForecastInterval {
  start: Date;
  end: Date;
  totalVehicles: number;
  // Count of vehicles by number of months since delivery. Contiguous.
  vehicleCountByAge: number[];
  // Count of vehicles by odometer reading in km quantized by OdometerSampleInterval.
  vehicleCountByOdometer: number[];
  // The number of expected repair events of each type
  repairConceptEventCount: number[];
  // The total cost of the repair events of each type
  repairConceptCost: number[];
}

export function applyDiscounts(rates: number[][], discountRates: number[][][]): number[][] {
  return rates.map((ratesOfA: number[], a) =>
    // rateAi: The rate of events[a] we're calculating in interval i
    ratesOfA.map((rateAi: number, i) => {
      const netDiscount = rates
        .map((ratesOfB: number[], b) =>
          // rateBj: The rate of events[b] in interval j (where j < i)
          ratesOfB
            .slice(0, i)
            // Multiply rateBj by the appropriate discount rate of b on a for the relative interval j - i.
            .map((rateBj, j) => rateBj * (discountRates[a][b][i - j] || 0))
            .reduce((rate, discount) => rate * (1 - discount), 1)
        )
        .reduce((rate, net) => rate * net, 1);
      // Take the product of all discounts and apply to rateAi
      return rateAi * netDiscount;
    })
  );
}

/**
 * Calculate the predicted rate of every repair event over intervals of a specified size, using observed
 * rates and a set of thresholds for sampling them, and discount factors applied between the events.
 * @param eventRates Observed number of vehicles for each event. The interval sizes in each must match
 * independentVariableInterval.
 * @param thresholds The threshold of probability to be applied to each event
 * @param discountPoints For every element [i][j], defines a set of 2-D points that can be interpolated with
 * Bezier curves to calculate the degree by which an occurence of event i reduces the probability of event j
 * for any independent variable value.
 * @param discountSmoothness The smoothness quotient with which to interpolate the curves described by
 * discountPoints.
 * @param independentVariableInterval The size of the independent variable used in eventRates and for which
 * the returned value should be calculated.
 */
export function calculateRepairConceptRates(
  eventRates: EventRate[],
  thresholds: number[],
  discountPoints: ([number, number][] | undefined)[][],
  discountSmoothness: number,
  independentVariableInterval: number
): number[][] {
  // discountRates[a][b] is the amount by which an occurrence of a reduces (discounts) the probability of b
  const discountRates: number[][][] = discountPoints.map((d) =>
    d.map(
      (points) =>
        (points && calculateCubicSplineInterceptArray(points, discountSmoothness, independentVariableInterval)) || []
    )
  );

  // TODO: Fill in missing intervals in eventRates
  // rates[i][j] is the natural rate of repair, before discount, of event i in interval j
  const rates: number[][] = eventRates
    .map((eventRate) => [eventRate.countWithEvent.map((c, i) => c / eventRate.countTotal[i]), eventRate.countTotal])
    .map(([rates, counts]) => [rates, rates.map((r, i) => Math.sqrt(r * (1 - r)) / counts[i])])
    .map(([rates, standardErrors], i) => {
      const threshold = thresholds[i];
      return rates.map((r, j) => {
        // Admittedly sketchy statistics math here: we sample the CDF of the standard error of the event
        // to calculate the rate above the provided threshold
        const standardError = standardErrors[j];
        if (r && standardError) {
          return 1 - new Gaussian(r, standardError * standardError).cdf(threshold);
        } else {
          // If there are no events, the SE calculation will be 0. Best we can do here.
          return 0;
        }
      });
    });

  const ratesWithDiscount = applyDiscounts(rates, discountRates);
  return ratesWithDiscount;
}

/**
 * Calculates the repair concept cost per vehicle per independent variable interval
 * @param cost The total lifetime cost of the repair event
 * @param bezierPoints The density distribution of the cost expressed as a series of points [x,y] over which the cost
 * is interpolated.
 * @param smooth The smoothing factor for interpreting the bezierPoints, in the range [0, 1].
 * @param independentVariableInterval The interval of the independent variable.
 * @param rate The rate of ocurrence of the repair event per independent variable interval, in the range [0, 1].
 */
export function calculateRepairEventCost(
  cost: number,
  bezierPoints: [number, number][],
  smooth: number,
  independentVariableInterval: number,
  rate: number[]
): number[] {
  // Calculate the cost distribution over the intervals following the event
  let costDistribution: number[] = allocateCost(cost, bezierPoints, smooth, independentVariableInterval);
  return addDiagonal(rate.map((r) => costDistribution.map((c) => c * r)));
}

export function allocateCost(
  cost: number,
  bezierPoints: [number, number][],
  smooth: number,
  independentVariableInterval: number
): number[] {
  let y = calculateCubicSplineInterceptArray(bezierPoints, smooth, independentVariableInterval);
  // We add up the y values to get the total over which the cost is allocated
  let total = y.reduce((tot, y) => tot + y, 0);
  // Allocate cost for each interval as the fraction of the total multiplied by the total cost
  return y.map((y) => (y / total) * cost);
}

/**
 * Calculate a monthly forecast model for a given set of assumptions.
 * @param start The start date of the first monthly interval.
 * @param monthsCount The number of months to forecast. The returned array will have this many elements.
 * @param startingVehicleCountByAge The current number of vehicles for each number of months since delivery.
 * @param startingVehicleCountByOdometer The current number of vehicles for each odometer bucket. The bucket size
 * should match the one used to calculate mileagePerMonth, repairConceptRates, and repairConceptCosts.
 * @param productionForecast The number of new vehicles to be delivered per month starting now.
 * @param mileagePerMonth For each element [i, j], this is the fraction of vehicles with odometer in the ith bucket
 * that drive j * bucket kilometers in the month.
 * @param repairConceptRates For each element i, the rate of the ith repair concept per independent variable bucket.
 * @param repairConceptCosts For each element i, the cost of the ith repair concept per vehicle per independent
 * variable bucket.
 */
export default function calculateForecast(
  start: Date,
  monthsCount: number,
  startingVehicleCountByAge: number[],
  startingVehicleCountByOdometer: number[],
  productionForecast: number[],
  mileagePerMonth: number[][],
  repairConceptRates: number[][],
  repairConceptCosts: number[][]
): ForecastInterval[] {
  start = new Date(start.toDateString());
  start.setDate(0);
  const forecastMonths: [Date, Date][] = [...Array(monthsCount).keys()].map((i) => {
    const d1 = new Date(start);
    d1.setMonth(start.getMonth() + i);
    const d2 = new Date(start);
    d2.setMonth(start.getMonth() + i + 1);
    return [d1, d2];
  });

  return forecastMonths.reduce((accum, [start, end], i) => {
    let interval: ForecastInterval;
    if (accum.length == 0) {
      interval = {
        start,
        end,
        totalVehicles: startingVehicleCountByOdometer.reduce((sum, n) => sum + n, 0),
        vehicleCountByAge: [...startingVehicleCountByAge],
        vehicleCountByOdometer: [...startingVehicleCountByOdometer],
        repairConceptEventCount: [],
        repairConceptCost: [],
      };
    } else {
      const lastInterval = accum.slice(-1)[0];
      interval = {
        start,
        end,
        totalVehicles: lastInterval.totalVehicles,
        vehicleCountByAge: [...lastInterval.vehicleCountByAge],
        vehicleCountByOdometer: [...lastInterval.vehicleCountByOdometer],
        repairConceptEventCount: [],
        repairConceptCost: [],
      };
    }

    // Apply driving distances to the existing vehicles
    // Each element is an array of vehicle counts to add to each subsequent interval
    const drivingDistribution = interval.vehicleCountByOdometer.map((vehicles, mileage) => {
      if (mileagePerMonth.length > mileage) {
        return mileagePerMonth[mileage].map((f) => vehicles * f);
      } else {
        return [];
      }
    });
    interval.vehicleCountByOdometer = addDiagonal(drivingDistribution);

    // Add the newly-produced vehicles
    interval.totalVehicles += productionForecast[i];
    interval.vehicleCountByAge.unshift(productionForecast[i]);
    interval.vehicleCountByOdometer[0] += productionForecast[i];

    // Calculate the total number of repair concepts triggered by multiplying the rate (by odometer) by the
    // vehicle count for the same odometer reading
    interval.repairConceptEventCount = repairConceptRates.map((repairConceptRate) =>
      repairConceptRate.map((r, i) => r * interval.vehicleCountByOdometer[i]).reduce((tot, r) => tot + r, 0)
    );

    // FIXME: This is probably buggy. We are calculating only the cost for the current e.g. 100-km interval, not
    // all of the intervals actually driven in the month. Should write some test cases with simple 1-vehicle
    // examples.
    interval.repairConceptCost = repairConceptCosts.map((repairConceptCost) =>
      repairConceptCost
        .filter((_, i) => interval.vehicleCountByOdometer.length > i)
        .map((c, i) => c * interval.vehicleCountByOdometer[i])
        .reduce((tot, c) => tot + c, 0)
    );

    accum.push(interval);
    return accum;
  }, [] as ForecastInterval[]);
}
