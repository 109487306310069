import { ColumnType } from "antd/es/table";
import { useEffect, useMemo } from "react";

import { SortFilter, SortOrder } from "@/api";
import { VehicleCountByCountry } from "@/api/customerApi";
import { useTablePagination } from "@/hooks/useTablePagination";
import { getCountryName } from "@/utils/countries";
import { toPercentage } from "@/utils/mathUtils";

import { BasicTable } from "./BasicTable";

interface FleetCountryTableParams {
  data?: VehicleCountByCountry[];
  isLoading: boolean;
  total?: number;
  selectedCountry?: string;
  onCountrySelected?: (country: string) => void;
}

const compareVehicleCountItems = (
  itemA: VehicleCountByCountry,
  itemB: VehicleCountByCountry,
  sorters: SortFilter[]
) => {
  const differentiationFilter = sorters.find(
    (sorter) =>
      itemA[sorter.field as keyof VehicleCountByCountry] !== itemB[sorter.field as keyof VehicleCountByCountry]
  );
  if (!differentiationFilter) {
    return 0;
  }

  let rawOrder = 0;
  const fieldKey = differentiationFilter.field as keyof VehicleCountByCountry;
  if (itemA[fieldKey] === null || itemA[fieldKey] === undefined) {
    rawOrder = -1;
  } else if (itemB[fieldKey] === null || itemB[fieldKey] === undefined) {
    rawOrder = 1;
  } else {
    rawOrder = itemA[fieldKey]! > itemB[fieldKey]! ? 1 : -1;
  }

  return differentiationFilter.order === SortOrder.Ascend ? rawOrder : -rawOrder;
};

const numberFormat = new Intl.NumberFormat();

const HIGHLIGHTED_ROW_CLASS = "highlighted";

export default function FleetCountryTable({
  data,
  total,
  isLoading,
  selectedCountry,
  onCountrySelected,
}: FleetCountryTableParams) {
  const { sortersInput, onChange } = useTablePagination<VehicleCountByCountry>();

  const sortedData = useMemo(() => {
    return data
      ?.map((item) => {
        const withRepairEventsFraction =
          item?.vehicles && item.vehicles > 0 ? (item.vehiclesWithMaintenanceEvents ?? 0) / item.vehicles : 0;

        return { ...item, withRepairEventsFraction };
      })
      .sort((itemA, itemB) => compareVehicleCountItems(itemA, itemB, sortersInput));
  }, [data, sortersInput]);

  useEffect(() => {
    if (selectedCountry) {
      document.querySelector(`.${HIGHLIGHTED_ROW_CLASS}`)?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
        inline: "start",
      });
    }
  }, [selectedCountry]);

  const handleRowSelected = (vehicle: VehicleCountByCountry) => {
    if (vehicle.country && onCountrySelected) {
      return onCountrySelected(selectedCountry !== vehicle.country ? vehicle.country : "");
    }
  };

  const columns: ColumnType<VehicleCountByCountry>[] = [
    {
      title: "Country",
      key: "country",
      sorter: true,
      render: (_, data) => {
        if (data.country == "Total") return "Total";
        return getCountryName(data.country ?? "");
      },
    },
    {
      title: "Total Vehicles",
      key: "vehicles",
      width: 120,
      align: "end",
      sorter: true,
      render: (_, data) => data.vehicles && numberFormat.format(data.vehicles),
      defaultSortOrder: "descend",
    },
    // TODO update data of columns below
    {
      title: "With Repairs",
      key: "repairEvents",
      width: 110,
      align: "end",
      sorter: true,
      render: (_, { vehiclesWithMaintenanceEvents }) =>
        vehiclesWithMaintenanceEvents === undefined ? "-" : numberFormat.format(vehiclesWithMaintenanceEvents ?? 0),
    },
    {
      title: "% With Repairs",
      key: "repairEventsPercentage",
      width: 150,
      align: "end",
      dataIndex: "percentageRepairEvents",
      sorter: true,
      render: (_, { vehicles, vehiclesWithMaintenanceEvents }) => {
        return toPercentage({
          value: vehiclesWithMaintenanceEvents as number | undefined,
          min: 0,
          max: vehicles ?? 0,
          significantDigits: 1,
        });
      },
    },
  ];

  const totalWithRepairEvents = data?.reduce((acc, curr) => acc + (curr.vehiclesWithMaintenanceEvents || 0), 0);

  const countryData = [
    {
      country: "Total",
      vehicles: total,
      vehiclesWithMaintenanceEvents: totalWithRepairEvents,
      withRepairEventsFraction: total && totalWithRepairEvents ? total / totalWithRepairEvents : 0,
    },
    ...(sortedData ?? []),
  ];

  return (
    <div className="fleet-intel-table">
      <BasicTable
        id="fleetCountryTable"
        onChange={onChange}
        dataSource={countryData}
        columns={columns}
        rowKey={"country"}
        rowClassName={(vehicle, i) => {
          if (i && i > 0) {
            return vehicle.country === selectedCountry ? `row-cta ${HIGHLIGHTED_ROW_CLASS}` : `row-cta`;
          } else {
            return "bold-row";
          }
        }}
        onRow={(vehicle, i) => {
          return {
            onClick: () => (i && i > 0 ? handleRowSelected(vehicle) : null),
          };
        }}
        pagination={false}
        loading={isLoading}
      />
    </div>
  );
}
