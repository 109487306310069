import { FilePdfOutlined, SaveOutlined, ShareAltOutlined } from "@ant-design/icons";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { Button, Input } from "antd";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";

import { useCreateForecastMutation, useForecastsQuery, useUpdateForecastMutation } from "@/api";
import { ForecastFleetInputDataQueryVariables, useForecastFleetInputDataQuery } from "@/api/customerApi";
import { QUERY_SETTINGS, UI_SETTINGS } from "@/constants";
import { ForecastActions } from "@/contexts/ForecastSlice";
import { ForecastSelector } from "@/contexts/ForecastStore";
import { ForecastContainer } from "@/features/forecast/Forecast.styled";
import { toEventFilter, toForecastInput } from "@/features/forecast/ForecastHelper";
import ForecastOutput from "@/features/forecast/ForecastOutput";
import { requireCurrentCustomerId } from "@/utils/customers";

import ForecastDensityFunctionEditor from "./DensityFunctionEditor";
import ForecastMatrix from "./ForecastMatrix";
import ProductionForecastSelector from "./ProductionForecastSelector";
import RepairConceptPickerDialog from "./RepairConceptPickerDialog";

export default function Forecast() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  // TODO proper selectors for forecast data
  const forecastModel = useSelector((state: ForecastSelector) => state.forecast);

  const updateForecastMutation = useUpdateForecastMutation({
    onSettled: () => forecastMutationSettledHandler(),
  });
  const createForecastMutation = useCreateForecastMutation({
    onSettled: () => forecastMutationSettledHandler(),
  });

  const forecastMutationSettledHandler = () => {
    queryClient.invalidateQueries({ queryKey: useForecastsQuery.getKey({ customerId: requireCurrentCustomerId() }) });
    navigate({ to: "/forecast" });
  };

  const saveForecastHandler = () => {
    if (forecastModel.forecast.id) {
      updateForecastMutation.mutate({
        customerId: requireCurrentCustomerId(),
        id: forecastModel.forecast.id,
        forecast: toForecastInput(forecastModel.forecast),
        version: forecastModel.forecast.version,
      });
    } else {
      createForecastMutation.mutate({
        customerId: requireCurrentCustomerId(),
        forecast: toForecastInput(forecastModel.forecast),
      });
    }
  };

  const onForecastNameChange = _.debounce((newName: string) => {
    dispatch(ForecastActions.updateName({ name: newName }));
  }, UI_SETTINGS.STANDARD_INPUT_DEBOUNCE);

  const fleetInputQueryVariables: ForecastFleetInputDataQueryVariables = {
    eventFilters: forecastModel.forecast.repairConcepts.map(toEventFilter),
    odometerSampleSize: 100,
  };

  const productionForecastSelectedHandler = (forecastId: string) => {
    dispatch(ForecastActions.updateProductionForecast({ productionForecastId: forecastId }));
    queryClient.invalidateQueries({ queryKey: useForecastFleetInputDataQuery.getKey(fleetInputQueryVariables) });
  };

  const {
    data: fleetInputData,
    isLoading: fleetInputDataIsLoading,
    isRefetching: fleetInputDataIsRefetching,
  } = useForecastFleetInputDataQuery(fleetInputQueryVariables, {
    staleTime: QUERY_SETTINGS.DEFAULT_STALE_TIME,
  });

  function onAddOrRemoveRepairConcept() {
    queryClient.invalidateQueries({ queryKey: useForecastFleetInputDataQuery.getKey(fleetInputQueryVariables) });
  }

  return (
    <ForecastContainer className="card">
      <div className="forecast-header-controls">
        <div className="forecast-data">
          <Input
            className="forecast-name"
            defaultValue={forecastModel.forecast?.name}
            onChange={(e) => onForecastNameChange(e.target.value)}
          />
          <ProductionForecastSelector
            onForecastSelected={productionForecastSelectedHandler}
            defaultValue={forecastModel.forecast?.productionForecast?.id}
          />
        </div>
        <Button
          type="text"
          className="text-button"
          onClick={saveForecastHandler}
          loading={updateForecastMutation.isLoading || createForecastMutation.isLoading}
        >
          Save
          <SaveOutlined />
        </Button>
      </div>
      <ForecastMatrix onAddOrRemoveRepairConcept={onAddOrRemoveRepairConcept} />
      <ForecastOutput data={fleetInputData} isLoading={fleetInputDataIsLoading || fleetInputDataIsRefetching} />
      <div className="forecast-share-controls">
        <Button type="text" className="text-button">
          Share
          <ShareAltOutlined />
        </Button>
        <Button type="text" className="text-button">
          Export to PDF
          <FilePdfOutlined />
        </Button>
      </div>
      <ForecastDensityFunctionEditor />
      <RepairConceptPickerDialog onAddOrRemoveRepairConcept={onAddOrRemoveRepairConcept} />
    </ForecastContainer>
  );
}
