import styled from "styled-components";

export const EventTimeLineHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  .ant-form-item {
    margin: 5px 24px;
  }
`;
