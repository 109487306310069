import EventsIcon from "@amayaIcons/Events-dark.svg";
import { useNavigate } from "@tanstack/react-router";
import { useEffect, useState } from "react";
import { useId } from "react";

import { Fault } from "@/api/customerApi";
import DropdownWidget from "@/components/ui/DropdownWidget";

type AnalysisOptionsKey = "code" | "code_ecu" | "code_ecu_troublecode";

type FaultHistoryAnalysisLinkProps = {
  fault: Fault;
  rootElementId: string;
};

const FaultEventAnalysisLink = ({ fault, rootElementId }: FaultHistoryAnalysisLinkProps) => {
  const optionsGroupId = useId();
  const [tableElement, setTableElement] = useState<HTMLElement | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const foundElement = document.getElementById(rootElementId);
    if (!foundElement) {
      console.error(`For "FaultEventAnalysisLink" Element with id ${rootElementId} was not found.`);
    }

    setTableElement(foundElement);
  }, [rootElementId]);

  if (!tableElement) {
    return null;
  }

  const handleFaultEventAnalyis = (key: AnalysisOptionsKey, fault: Fault) => {
    switch (key) {
      case "code":
        navigate({ to: "/fault/$faultCodeId", params: { faultCodeId: fault.code } });
        break;
      case "code_ecu":
        navigate({ to: "/fault/$faultCodeId", params: { faultCodeId: fault.code }, search: { ecu: fault.source } });
        break;
      case "code_ecu_troublecode":
        navigate({
          to: "/fault/$faultCodeId",
          params: { faultCodeId: fault.code },
          search: { ecu: fault.source, troubleCode: fault.troubleCode ?? undefined },
        });
        break;
    }
  };

  return (
    <DropdownWidget
      elements={[
        {
          key: optionsGroupId,
          type: "group",
          label: "View in Event Analysis",
          children: [
            { key: "code", label: "Fault Code" },
            { key: "code_ecu", label: "ECU and Fault Code" },
            { key: "code_ecu_troublecode", label: "ECU, Fault Code, and Trouble Code" },
          ],
        },
      ]}
      onElementSelected={(key) => handleFaultEventAnalyis(key as AnalysisOptionsKey, fault)}
      buttonSize="small"
      icon={<EventsIcon />}
      placement={"bottomLeft"}
      renderElement={tableElement}
      destroyOnHide={true}
    />
  );
};

export default FaultEventAnalysisLink;
