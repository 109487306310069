import { RetweetOutlined } from "@ant-design/icons";
import { useContext } from "react";

import { CustomerContext } from "@/contexts/CustomerProvider";
import { constructBaseAppUrl } from "@/utils/customers";

import { CustomerPickerStyled } from "./CustomerPicker.styled";

export default function CustomerPicker() {
  // TODO: Consolidate this with other bootstrap queries
  const customers = useContext(CustomerContext);

  const options =
    customers?.customers.map((c) => ({
      value: c.id,
      label: c.name,
    })) || [];

  function onSelect(customerId: unknown) {
    window.location.href = constructBaseAppUrl(customerId as string);
  }

  // Do not display the picker if there is only one customer
  return options.length > 1 ? (
    // TODO: Include logos
    <CustomerPickerStyled
      suffixIcon={<RetweetOutlined />}
      options={options}
      onSelect={onSelect}
      defaultValue={customers.currentCustomer?.id}
    />
  ) : (
    <></>
  );
}
