import { SelectProps } from "antd";
import { Color } from "echarts";
import { EChartsReactProps } from "echarts-for-react";
import { DeepPartial } from "react-hook-form";

import {
  DensityFunctionIndependentVariable,
  Forecast,
  ForecastInput,
  ForecastRepair,
  Point,
  ProductionForecast,
} from "@/api";
import { EventFilter } from "@/api/generated/graphql.customer";
import { ForecastModel } from "@/contexts/ForecastStore";
import { ForecastInterval } from "@/utils/forecast/calculateForecast";

const BarColors: Color[] = ["#6666ff", "#8888ff", "#aaaaff", "#ccccff"];

export const getProductionForecastOptions = (forecasts: DeepPartial<ProductionForecast>[]): SelectProps[] => {
  return forecasts.map((f) => {
    return {
      value: f.id,
      label: f.name,
    };
  });
};

export const getDensityFunctionIndependentVariableOptions = (): SelectProps[] => {
  return Object.values(DensityFunctionIndependentVariable).map((v) => {
    return {
      value: v,
      label: v,
    };
  });
};

export const buildForecastChartConfig = (
  forecastModel: ForecastModel,
  forecastIntervals: ForecastInterval[]
): EChartsReactProps => {
  return {
    option: {
      backgroundColor: "#f9fafe",
      grid: {
        containLabel: true,
        left: "2%",
        right: "2%",
      },
      legend: {},
      xAxis: {
        type: "time",
        data: forecastIntervals.map((f) => f.start),
      },
      yAxis: [
        {
          type: "value",
          name: "USD",
          alignTicks: true,
        },
        {
          type: "value",
          name: "Vehicles",
          alignTicks: true,
        },
      ],
      series: [
        ...forecastModel.forecast.repairConcepts.map((r, i) => ({
          type: "bar",
          stack: "total",
          itemStyle: { color: BarColors[i] },
          name: r.repairConcept.name,
          yAxisIndex: 0,
          data: forecastIntervals.map(({ start, repairConceptCost }) => [start, repairConceptCost[i]]),
        })),
        {
          type: "line",
          name: "Vehicles",
          yAxisIndex: 1,
          symbolSize: 2,
          data: forecastIntervals.map(({ start, totalVehicles }) => [start, totalVehicles]),
        },
      ],
    },
  };
};

export function toForecastInput(forecast: Forecast): ForecastInput {
  return {
    name: forecast.name,
    productionForecastId: forecast.productionForecast.id,
    repairConcepts: forecast.repairConcepts.map((r) => ({
      repairConceptId: r.repairConcept.id,
      threshold: r.threshold,
    })),
    discounts: forecast.discounts.map((discountRow) =>
      discountRow.map((d) => ({
        totalDiscount: d.totalDiscount,
        distribution: {
          y0: d.distribution!.y0,
          points: d.distribution!.points,
        },
      }))
    ),
    discountUnit: forecast.discountUnit,
  };
}

export function toEventFilter(forecastRepair: ForecastRepair): EventFilter {
  return {
    expressionEventId: forecastRepair.repairConcept.triggerExpressionEvent?.id,
    repairEventType: forecastRepair.repairConcept.repairEventType,
  };
}

export const SimpleDensityCurve: Point[] = [
  { x: 20000, y: 0.95 },
  { x: 60000, y: 0.05 },
  { x: 80000, y: 0 },
];
