import { useSelector } from "react-redux";

import { AnalysisSignal, selectDrawerIsOpen } from "@/contexts/EventAnalysisChartStore";

import StyledInlineDrawer from "../ui/StyledInlineDrawer";
import EventTimeSeriesTabs from "./EventTimeSeriesTabs";

interface EventTimeSeriesSettingsProps {
  selectedSignals: AnalysisSignal[];
}

const EventTimeSeriesSettings = ({ selectedSignals }: EventTimeSeriesSettingsProps) => {
  const { drawerIsOpen } = useSelector(selectDrawerIsOpen);

  return (
    <StyledInlineDrawer open={drawerIsOpen}>
      <EventTimeSeriesTabs selectedSignals={selectedSignals} />
    </StyledInlineDrawer>
  );
};

export default EventTimeSeriesSettings;
