import { Layout } from "antd";
import styled from "styled-components";

export const Container = styled(Layout.Sider)`
  height: 100vh;
  // Override antd sidebar position
  position: fixed !important;
  z-index: 100;
  // Override antd colapsed sidebar styling
  &.ant-layout-sider-collapsed {
    flex: 0 0 ${({ theme }) => theme.sizes.sidebar} !important;
    min-width: inherit !important;
    width: ${({ theme }) => theme.sizes.sidebar} !important;
  }
  .ant-btn-primary {
    background-color: ${(props) => props.theme.colors.background};
    border: 1px solid ${(props) => props.theme.colors.background};
  }
  .ant-layout-sider-children {
    display: flex;
    flex-direction: column;
    align-items: center;
    .version-container {
      cursor: pointer;
      color: ${({ theme }) => theme.colors.defaultWhite};
      position: absolute;
      bottom: 32px;
      left: 0;
      right: 0;
      text-align: center;
    }
  }
  .nav-btn-label {
    font-family: "Hubot Sans";
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 150px;
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 2px;
  }
  .nav-btn-label {
    font-family: "Hubot Sans";
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 150px;
    text-align: left;
    display: flex;
    flex-direction: column;
    gap: 2px;
  }
`;

export const SideIcon = styled.div`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  &:hover {
    .link-tooltip {
      visibility: visible;
      animation: fadeIn 0.14s ease-in forwards;
    }
  }
`;

export const SideLinkTooltip = styled.div`
  align-items: center;
  background-color: ${(props) => props.theme.colors.backgroundHover};
  border-radius: 8px;
  display: flex;
  font-family: "Hubot Sans";
  height: 42px;
  left: 0;
  min-width: 212px;
  padding-left: 48px;
  padding-right: 16px;
  position: absolute;
  visibility: hidden;
  z-index: -1;
  opacity: 0;
  @keyframes fadeIn {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const NavSectionMenu = styled.div`
  display: flex;
  width: 244px;
  height: 100vh;
  position: absolute;
  left: 0;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
  &.collapsed {
    width: 72px;
  }

  .link {
    border-radius: 6px;
    color: white;
    align-items: center;
    display: flex;
    width: 100%;
    gap: 8px;
    user-select: none;
    z-index: 999;
    &:hover {
      background-color: ${(props) => props.theme.colors.backgroundHover};
    }
    &.active {
      background-color: ${(props) => props.theme.colors.backgroundActive};
    }
    &.profile {
      align-items: start;
      padding: 8px 0;
    }
  }
  &.collapsed {
    .link {
      &:hover {
        background-color: transparent !important;
      }
    }
  }
`;

export const NavSectionHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
`;

export const NavSectionBottom = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;
  .links {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 12px;
    height: 56px;
  }
  .nav-btn-close {
    cursor: pointer;
    margin-bottom: 44px;
  }
`;

export const ClientInfoContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  height: 42px;
  margin-bottom: 3.5rem;
  width: 100%;
`;

export const LogoutButton = styled.a`
  color: ${(props) => props.theme.colors.secondary};
  font-family: "Inter";
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  align-self: flex-start;
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
`;
