import { Form, Input } from "antd";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";

import { AnomalyBarValues, AnomalyHistogramTextInputInfo, barName } from "./AnomalyHistogramPreview";
import { barNameMap } from "./AnomalyHistogramPreviewLogic";
import { AnomalyHistogramTextInputStyled } from "./AnomalyHistogramTextInput.styled";

type AnomalyHistogramTextInputProps = {
  x: number;
  y: number;
  name: barName;
  value: number;
  barValues: AnomalyBarValues;
  min: number;
  max: number;
  setBarValues: Dispatch<SetStateAction<AnomalyBarValues | undefined>>;
  setInputInfo: Dispatch<SetStateAction<AnomalyHistogramTextInputInfo | undefined>>;
};

export const AnomalyHistogramTextInput = ({
  x,
  y,
  name,
  value: initialValue,
  barValues,
  min,
  max,
  setBarValues,
  setInputInfo,
}: AnomalyHistogramTextInputProps) => {
  const [value, setValue] = useState<number | string>();
  const [isFormValid, setIsFormValid] = useState(true);
  const [form] = Form.useForm();

  useEffect(() => {
    setValue(initialValue);
  }, [initialValue]);

  useEffect(() => {
    // this has to be in a use effect or else the form is out of date
    form
      .validateFields([name])
      .then(() => setIsFormValid(true))
      .catch(() => setIsFormValid(false));
  }, [form, name, value]);

  const handleOnChange = (input: number | string) => {
    setValue(input);
  };

  const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && isFormValid) {
      setBarValues({ ...barValues, [name]: Number(value) });
      setInputInfo(undefined);
    }

    if (e.key === "Escape") {
      setInputInfo(undefined);
    }
  };

  const handleBlur = () => {
    setInputInfo(undefined);
  };

  const validators = {
    lowerNormal: () => {
      if (isNaN(Number(value))) {
        return Promise.reject("Value must be a valid number");
      }
      if (barValues.cautionBar < Number(value)) {
        setIsFormValid(false);
        return Promise.reject("Lower Normal Threshold should be less than Caution Threshold");
      }

      if (Number(value) < min) {
        setIsFormValid(false);
        return Promise.reject(`Lower Normal Threshold limit of ${min.toFixed(2)} reached`);
      }
      setIsFormValid(true);
      return Promise.resolve();
    },
    cautionBar: () => {
      if (isNaN(Number(value))) {
        return Promise.reject("Value must be a valid number");
      }
      if (Number(value) < barValues.lowerNormal) {
        return Promise.reject("Caution Threshold should be greater than Lower Normal Threshold");
      } else if (Number(value) > barValues.warningBar) {
        return Promise.reject("Caution Threshold should be less than Warning Threshold");
      }
      setIsFormValid(true);
      return Promise.resolve(true);
    },
    warningBar: () => {
      if (isNaN(Number(value))) {
        return Promise.reject("Value must be a valid number");
      }
      if (Number(value) < barValues.cautionBar) {
        return Promise.reject("Warning Threshold should be greater than Caution Threshold");
      } else if (Number(value) > barValues.upperNormal) {
        return Promise.reject("Warning Threshold should be less than Upper Normal Threshold");
      }
      return Promise.resolve();
    },
    upperNormal: () => {
      if (isNaN(Number(value))) {
        return Promise.reject("Value must be a valid number");
      }
      if (Number(value) < barValues.warningBar) {
        return Promise.reject("Upper Normal Threshold should be greater than Warning Threshold");
      }
      if (Number(value) > max) {
        return Promise.reject(`Upper Normal Threshold limit of ${max.toFixed(2)} reached`);
      }
      return Promise.resolve();
    },
  };

  return (
    <AnomalyHistogramTextInputStyled style={{ left: x, top: y }} onBlur={handleBlur}>
      <b>{barNameMap[name]}</b>
      <hr />
      <Form form={form}>
        <Form.Item key={name} name={name} rules={[{ validator: validators[name] }]}>
          {/* Need to use type text with a pattern to allow for decimal places. Validation checks for a valid number.
          Wrapping the input in the div makes the inital value work for some reason. */}
          <div>
            <Input
              id={"AnomalyHistogramTextInput"}
              value={value}
              type="text"
              pattern="[0-9]*\.?[0-9]*"
              onChange={(e) => handleOnChange(e.target.value)}
              autoFocus
              onKeyDown={handleKeyPress}
            />
          </div>
        </Form.Item>
      </Form>
    </AnomalyHistogramTextInputStyled>
  );
};
