export const DEFAULT_LOCALE = "en-US";

/**
 * Tanstack Query settings
 * @constant
 */
export const QUERY_SETTINGS = {
  DEFAULT_STALE_TIME: 5 * 60000, // 5 minutes
  SHORT_STALE_TIME: 60000, // 1 minute
  LONG_STALE_TIME: 24 * 60 * 60000, // 24 hours
  RETRY_AMOUNT: 3, // Tanstack Query default
  RETRY_DELAY: 1000, // Tanstack Query default
} as const;

/**
 * UI settings
 * @constant
 */
export const UI_SETTINGS = {
  STANDARD_INPUT_DEBOUNCE: 250,
  SEARCH_INPUT_DEBOUNCE: 500, // 500 ms
  VALIDATION_INPUT_DEBOUNCE: 1000, // 1s
  MIN_SEARCH_LENGTH: 3,
} as const;

/**
 * Http settings
 * @constant
 */
export const HTTP_SETTINGS = {
  SESSION_ID_HEADER: "A0-Session-Id",
} as const;

export const COOKIE_APP = {
  VERSION: "x-app-version",
} as const;

/**
 * SCV chart key
 */
export const SCV_CHART = "chartLibrary";

/**
 * Event Analysis chart key
 */
export const EVENT_ANALYSIS_CHART = "xEventAnalysisChart";
