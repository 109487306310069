import styled from "styled-components";

export const RepairConceptHomeContainer = styled.div.attrs({ className: "card" })`
  margin: 1rem;
  padding: 1rem;
  .repair-concepts-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .repair-concepts-body {
    margin: 1rem 0;
  }
`;
