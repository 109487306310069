import { PlusCircleOutlined } from "@ant-design/icons";
import { useNavigate } from "@tanstack/react-router";
import { Button } from "antd";

import { RepairConcept } from "@/api";

import RepairConceptPicker from "../forecast/RepairConceptPicker";
import { RepairConceptHomeContainer } from "./RepairConceptHome.styled";

const RepairConceptHome = () => {
  const navigate = useNavigate();

  const newRepairConceptHandler = () => {
    navigate({ to: "/repair-concept/new", resetScroll: true });
  };

  const repairConceptSelectedHandler = (repair: RepairConcept) => {
    navigate({ to: "/repair-concept/$repairConceptId", params: { repairConceptId: repair.id } });
  };

  return (
    <RepairConceptHomeContainer>
      <div className="repair-concepts-header">
        <h4 className="heading-x-small">Repair Concepts</h4>
        <Button type="text" className="text-button" onClick={newRepairConceptHandler}>
          <PlusCircleOutlined />
          Create Repair Concept
        </Button>
      </div>
      <div className="repair-concepts-body">
        {/* TODO RepairConceptPicker styling for this page */}
        <RepairConceptPicker onRepairConceptSelected={repairConceptSelectedHandler} />
      </div>
    </RepairConceptHomeContainer>
  );
};

export default RepairConceptHome;
