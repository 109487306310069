import _ from "lodash";
import { useMemo } from "react";

import { useSavedExpressionsQuery } from "@/api";
import { QUERY_SETTINGS } from "@/constants";
import { requireCurrentCustomerId } from "@/utils/customers";

export const useExpressionSelectorOptions = () => {
  const { data, isLoading } = useSavedExpressionsQuery(
    { customerId: requireCurrentCustomerId() },
    { staleTime: QUERY_SETTINGS.SHORT_STALE_TIME }
  );

  const savedExpressions = data?.savedExpressions?.savedExpressions;

  const expressionSelectOptions = useMemo(() => {
    if (savedExpressions) {
      const unsorted = savedExpressions.items.map((expression) => ({
        label: expression.name,
        value: expression.id,
        creator: expression.creator?.name ?? "Pull Systems",
      }));
      return _.sortBy(unsorted, (s) => s.label);
    }
    return [];
  }, [savedExpressions]);

  return {
    isLoading,
    savedExpressions,
    expressionSelectOptions,
  };
};
