import { CheckCircleFilled } from "@ant-design/icons";
import { Button } from "antd";
import React, { useState } from "react";

import ProgressBar from "./ProgressBar";
import { WizardContainer, WizardGeneralContainer, WizardStepsContainer } from "./UIzard.styled";

const calculateProgressPercent = (totalSteps: number, currentStep: number): number => {
  return (100 / totalSteps) * currentStep;
};

interface UIZardElement {
  id: string;
  title: string;
  label: string;
  jsx: React.JSX.Element;
}

interface UIZardProps {
  stepList: UIZardElement[];
  initialStep?: number;
  finishButtonLabel?: string;
  onStepChange?: (stepIndex: number) => void;
  onCancel?: () => void;
  finishIsLoading?: boolean;
  onFinish?: () => void;
}

// TODO validation to block/unblock next step
const UIZard = ({
  stepList,
  initialStep = 0,
  finishButtonLabel = "Finish",
  onStepChange,
  onCancel,
  finishIsLoading,
  onFinish,
}: UIZardProps) => {
  const [stepIndex, setStepIndex] = useState(initialStep);

  const handleBack = () => {
    if (stepIndex === 0) return;
    setStepIndex((prev) => (prev -= 1));
  };

  const handleForward = () => {
    if (stepIndex === lastIndex) return;
    setStepIndex((prev) => (prev += 1));
  };

  if (onStepChange && stepIndex !== initialStep) {
    onStepChange(stepIndex);
  }

  const currentIndex = stepIndex + 1;
  const lastIndex = stepList.length - 1;
  const contentItem = stepList[stepIndex];

  return (
    <WizardGeneralContainer>
      <WizardStepsContainer>
        {stepList.map((step, i) => {
          const stepIsPast = stepIndex > i;
          const stepIsActive = stepIndex === i;
          const stepIsFuture = stepIndex < i;
          return (
            <div key={step.id} className="step-item">
              {stepIsPast && <CheckCircleFilled />}
              {stepIsActive && <div className="active-bullet" />}
              {stepIsFuture && <div className="future-bullet" />}
              {i < lastIndex && <div className="step-line" />}
              <p className="step-label body-medium dark">{step.label}</p>
            </div>
          );
        })}
      </WizardStepsContainer>
      <WizardContainer>
        <div className="content-wrapper">
          <div className="content-header">
            <p className="body-small">
              Step {currentIndex} of {stepList.length}
            </p>
            <h4 className="heading-x-small">{contentItem.title}</h4>
          </div>
          <div className="content-body">{contentItem.jsx}</div>
        </div>
        <div className="progress-container">
          <ProgressBar percent={calculateProgressPercent(stepList.length, currentIndex)} />
        </div>
        <div className="wizard-controls-container">
          <div className="controls-section left-controls">
            <Button className="secondary-button" onClick={onCancel}>
              Cancel
            </Button>
          </div>
          <div className="controls-section right-controls">
            <Button className="secondary-button" onClick={handleBack} disabled={stepIndex === 0}>
              Back
            </Button>
            {stepIndex !== lastIndex && (
              <Button className="primary-button" onClick={handleForward}>
                Next
              </Button>
            )}
            {stepIndex === lastIndex && (
              <Button className="primary-button" onClick={onFinish} loading={finishIsLoading}>
                {finishButtonLabel}
              </Button>
            )}
          </div>
        </div>
      </WizardContainer>
    </WizardGeneralContainer>
  );
};

export default UIZard;
