import { ColumnType } from "antd/es/table";

import { AnomalyDetectorWithVehicleData } from "@/api/customerApi";
import EntityCreatorElement from "@/components/ui/EntityCreatorElement";
import { parseDateToMdStr } from "@/utils/dateUtils";

export const anomalySummaryExtraColumns: ColumnType<AnomalyDetectorWithVehicleData>[] = [
  {
    key: "creatorName",
    title: "Author",
    dataIndex: "creatorName",
    sorter: true,
    render: (_text: string, row: AnomalyDetectorWithVehicleData) => (
      <EntityCreatorElement creator={row.detector.creator} />
    ),
    width: 150,
  },
  {
    key: "created",
    title: "Date Created",
    dataIndex: "created",
    sorter: true,
    sortDirections: ["ascend", "descend"],
    render: (_, { detector: { created } }: AnomalyDetectorWithVehicleData) =>
      created ? <>{parseDateToMdStr(new Date(created))}</> : "-",
    width: 150,
  },
  {
    key: "detectorType",
    title: "Type",
    dataIndex: "detectorType",
    sorter: true,
    render: (_text: string, row: AnomalyDetectorWithVehicleData) => {
      return row.detector.type ?? "Pattern";
    },
    width: 120,
  },
];
