import styled from "styled-components";

export const SytledInlineDrawerWrapper = styled.div`
  min-width: 520px;
  height: 780px;
  overflow-y: auto;
  box-shadow: 0px 1px 6px 0px #00000026;
  &.open {
    animation: drawerIn 100ms ease-in;
  }
  &.closed {
    display: none;
  }
  @keyframes drawerIn {
    0% {
      opacity: 0;
      transform: translateX(520px);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
`;
