import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "@tanstack/react-router";
import { Button, Col, Row } from "antd";
import { useState } from "react";

import { useUserEventsQuery } from "@/api";
import { TopHeadingWithButtons } from "@/components/pageContent/TopHeadingWithButtons";
import { BasicWidget } from "@/components/widget/BasicWidget";
import { getCurrentCustomerId } from "@/utils/customers";

import { ExpressionEventModal } from "../event/ExpressionEvent/ExpressionEventModal";
import { RecentlyViewedEventsTable } from "./Tables/RecentlyViewedEventsTable";
import { UserEventsTable } from "./Tables/UserEventsTable";

export const EventAnalysis = () => {
  const queryClient = useQueryClient();
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  const handleMutation = async () => {
    await navigate({ search: (prev) => ({ ...prev, eventsPage: 1 }) });
    queryClient.invalidateQueries(useUserEventsQuery.getKey({ customerId: getCurrentCustomerId() ?? "" }));
    setShowModal(false);
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  const CreateButton = () => {
    return (
      <Button type="primary" onClick={() => setShowModal(true)}>
        Create New Event
      </Button>
    );
  };

  return (
    <>
      <TopHeadingWithButtons title="Event Analysis" buttons={<CreateButton />} />
      <Row gutter={16}>
        <Col span={12}>
          <BasicWidget title="Recently Viewed Events">
            <RecentlyViewedEventsTable />
          </BasicWidget>
        </Col>
        <Col span={12}>
          <BasicWidget title="Pattern Events">
            <UserEventsTable />
          </BasicWidget>
        </Col>
      </Row>
      <ExpressionEventModal open={showModal} onCancel={handleCancel} onMutate={handleMutation} />
    </>
  );
};
