import styled from "styled-components";

export const FieldPlotterContainer = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  .field-control {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .plotter-switch {
    width: 2rem;
  }
`;
