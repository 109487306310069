import { ExpressionType, ExpressionValueType, TimeSeriesExpressionType } from "@lib/src/expression/types";
import { Select, SelectProps } from "antd";
import { useMemo } from "react";
import styled from "styled-components";

import { caseInsensitiveContains } from "@/utils/caseInsensitiveContains";

import { enumObjectEntries } from "../../../../lib/src/enumObjectEntries";

const operatorOptions: { [key in ExpressionValueType]: { value: keyof typeof ExpressionType; label: string }[] } = {
  [ExpressionValueType.Comparison]: [
    { value: ExpressionType.Gt, label: "Greater than" },
    { value: ExpressionType.Gte, label: "Greater or equal" },
    { value: ExpressionType.Lt, label: "Less than" },
    { value: ExpressionType.Lte, label: "Less or equal" },
    { value: ExpressionType.Eq, label: "Equal" },
    { value: ExpressionType.Neq, label: "Not equal" },
    { value: ExpressionType.And, label: "And" },
    { value: ExpressionType.Or, label: "Or" },
    { value: ExpressionType.IsNull, label: "Is null" },
    { value: ExpressionType.IsNotNull, label: "Is not null" },
    { value: ExpressionType.Not, label: "Not" },
  ],
  [ExpressionValueType.Mathematical]: [
    { value: ExpressionType.Add, label: "Add" },
    { value: ExpressionType.Subtract, label: "Subtract" },
    { value: ExpressionType.Multiply, label: "Multiply" },
    { value: ExpressionType.Divide, label: "Divide" },
    { value: ExpressionType.Abs, label: "Absolute value" },
  ],
  [ExpressionValueType.Conditional]: [
    { value: ExpressionType.If, label: "If" },
    { value: ExpressionType.Case, label: "Case" },
  ],
  [ExpressionValueType.Aggregation]: [{ value: ExpressionType.Aggregation, label: "Aggregation" }],
  [ExpressionValueType.Literal]: [
    { value: ExpressionType.Null, label: "Null" },
    { value: ExpressionType.LiteralNumeric, label: "Constant number" },
  ],
  [ExpressionValueType.TimeSeries]: [
    { value: TimeSeriesExpressionType.Signal, label: "Signal" },
    { value: TimeSeriesExpressionType.AnomalyScore, label: "Anomaly Score" },
    { value: TimeSeriesExpressionType.Pattern, label: "Pattern" },
  ],
};

const StyleSelect = styled(Select<keyof typeof ExpressionType>)`
  font-weight: 400;
`;

export const ExpressionTypeSelect = (props: SelectProps<keyof typeof ExpressionType>) => {
  const { ...selectProps } = props;
  const expressionTypeOptions = useMemo(() => {
    return enumObjectEntries(ExpressionValueType).map(([key, value]) => ({
      label: value,
      options: operatorOptions[key],
    }));
  }, []);

  const filterOptionByLabel = (label: string = "", input: string = "") => {
    return caseInsensitiveContains(input, label);
  };

  return (
    <StyleSelect
      showSearch
      placeholder="Pick operator"
      filterOption={(input, option) => filterOptionByLabel(option?.label?.toString() ?? "", input)}
      optionLabelProp="label"
      options={expressionTypeOptions}
      disabled={!selectProps.onSelect}
      {...selectProps}
    />
  );
};
