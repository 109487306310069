/* eslint-disable react-hooks/exhaustive-deps */
import { InfoCircleFilled, QuestionCircleOutlined } from "@ant-design/icons";
import { Expression } from "@lib/src/expression/types";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate, useRouter } from "@tanstack/react-router";
import { Alert, Button, Card, Col, Flex, Form, Input, Modal, notification, Popover, Row, Spin } from "antd";
import { useCallback, useEffect, useState } from "react";

import {
  CalculatedStatus,
  SavedExpression,
  useCreateExpressionAnomalyMutation,
  useCreateSavedExpressionMutation,
  useDeleteExpressionAnomalyMutation,
  useEditExpressionAnomalyMutation,
  useExpressionAnomalyByIdQuery,
  useGetSavedExpressionsForEventFormQuery,
  useSavedExpressionsQuery,
  useTimeSeriesFieldsQuery,
} from "@/api";
import { useAnomalyAffectedVehiclesQuery, useAnomalySummaryQuery } from "@/api/customerApi";
import { StyledSelect } from "@/components/form/input/BasicSelect.styled";
import { TopHeadingWithButtonsForAnomalyAndPattern } from "@/components/pageContent/TopHeadingWithButtonsForAnomalyAndPattern";
import { ExpressionInput } from "@/components/savedExpression/ExpressionInput";
import { useFindUnavailableDefinitions } from "@/components/savedExpression/hooks/useFindUnavailableDefinitions";
import { SaveAsModal } from "@/components/savedExpression/SaveAsModal";
import { useExpressionTreeBuilder } from "@/components/savedExpression/useExpressionTreeBuilder";
import { ButtonsContainer } from "@/components/ui/Editor.styled";
import { requireCurrentCustomerId } from "@/utils/customers";

import { handleDeleteAnomaly } from "../details/handleDeleteAnomaly";
import { useAnomalySummaryQueryParams } from "../hooks/useAnomalySummaryQueryparams";
import { AfterCreate } from "./AfterCreate";
import { ThresholdTitle } from "./AnomalyDetectorEditor.styled";
import { createDetector } from "./createDetector";
import { ExistingPatternSelector } from "./ExistingPatternSelector";
import { expressionIdToSave } from "./expressionIdToSave";
import { PatternAnomalyDetectorPreview } from "./PatternAnomalyDetectorPreviewNew";

export type AnomalyDetectorEditorProps = {
  detectorId?: string;
  afterCreate?: AfterCreate;
};

export type FormValues = {
  name: string;
  description: string;
  low: number;
  high: number;
  caution: number;
  warning: number;
  source: string;
  formula: any;
};

export type PatternSource = "newPattern" | "existingPattern";

export const AnomalyDetectorEditor = (props: AnomalyDetectorEditorProps) => {
  const [modal, contextHolder] = Modal.useModal();

  const { detectorId, afterCreate } = props;
  const [, setCreatingAnomaly] = useState(false);
  const [selectedExpression, setSelectedExpression] = useState<SavedExpression | undefined>();
  const [isSaveAsModalVisible, setIsSaveAsModalVisible] = useState(false);
  const [patternSource, setPatternSource] = useState<PatternSource | undefined>(
    detectorId ? "existingPattern" : undefined
  );
  const [submittable, setSubmittable] = useState(false);
  const [formulaValid, setFormulaValid] = useState(false);
  const [barValues, setBarValues] = useState<{
    lowerNormal: number;
    upperNormal: number;
    cautionBar: number;
    warningBar: number;
  }>();
  const [outliersRemoved, setOutliersRemoved] = useState(false);
  const [notificationApi, notificationContextHolder] = notification.useNotification();
  const { rootExpression, editExpression } = useExpressionTreeBuilder();

  const [form] = Form.useForm<FormValues>();
  const { history } = useRouter();
  const navigate = useNavigate();

  const { filter, pagination } = useAnomalySummaryQueryParams();

  const createExpressionAnomaly = useCreateExpressionAnomalyMutation();
  const editExpressionAnomaly = useEditExpressionAnomalyMutation();
  const deleteAnomaly = useDeleteExpressionAnomalyMutation();

  const queryClient = useQueryClient();

  const { data: detectorData, isLoading: isAnomalyLoading } = useExpressionAnomalyByIdQuery(
    {
      customerId: requireCurrentCustomerId() ?? "",
      id: detectorId ?? "",
    },
    { enabled: !!detectorId }
  );

  const isNew = !detectorId;
  const isLoading =
    (isAnomalyLoading && !isNew) || createExpressionAnomaly.isLoading || editExpressionAnomaly.isLoading;

  const isDeleting = deleteAnomaly.isLoading;

  const anomaly = detectorData?.expressionAnomalies?.expressionAnomaly;
  const isReadOnly = !isNew && !anomaly?.creator?.isMe;

  const values = Form.useWatch([], form);

  useEffect(() => {
    form
      .validateFields({
        validateOnly: true,
      })
      .then(() => setSubmittable(true && formulaValid))
      .catch(() => setSubmittable(false));
  }, [form, values, formulaValid]);

  useEffect(() => {
    if (anomaly) {
      setSelectedExpression(anomaly?.expression as SavedExpression);
      setSubmittable(true);
      setFormulaValid(true);
      clearForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [anomaly]);

  useEffect(() => {
    const currentPattern = ((patternSource == "existingPattern"
      ? selectedExpression?.definition
      : anomaly?.expression?.definition) || {}) as Expression;
    editExpression(currentPattern, []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patternSource, selectedExpression, anomaly]);

  const clearForm = () => {
    form.setFieldsValue({
      name: anomaly?.name ?? "",
      description: anomaly?.description ?? "",
      high: anomaly?.threshold_upper ?? 0,
      low: anomaly?.threshold_lower ?? 0,
      caution: anomaly?.threshold_caution ?? 0,
      warning: anomaly?.threshold_warning ?? 0,
      source: detectorId ? "existingPattern" : undefined,
      formula: anomaly?.expression ?? "",
    });
  };

  const resetToBlankOrLastSaved = async () => {
    editExpression(anomaly?.expression.definition ?? {}, []);
    setSelectedExpression(anomaly?.expression as SavedExpression);
    setPatternSource(detectorId ? "existingPattern" : undefined);
    clearForm();
  };

  const { patternDefinition, patternsAreNotAvailable } = useFindUnavailableDefinitions({ rootExpression });

  const disabled = (!isNew && isReadOnly) ?? false;

  //TODO: Perhaps support inline updating a referenced pattern
  const isPatternEditable = !disabled && patternSource == "newPattern";

  const shouldShowPatternDef =
    patternSource == "newPattern" || (patternSource == "existingPattern" && !!selectedExpression);

  const createSavedExpressionMutation = useCreateSavedExpressionMutation();

  const invalidateQueries = () => {
    invalidateAnomalySummaryQuery();
    invalidateAnomalyVehiclesQuery();
    invalidateAnomalyByIdQuery();
    invalidateTimeSeriesFieldsQuery();
    invalidateSavedExpressionOptions();
    invalidateExpressionFormEventsQuery();
  };

  const invalidateExpressionFormEventsQuery = () => {
    queryClient.invalidateQueries({
      queryKey: useGetSavedExpressionsForEventFormQuery.getKey({
        customerId: requireCurrentCustomerId(),
        status: CalculatedStatus.Available,
      }),
    });
  };

  const invalidateTimeSeriesFieldsQuery = () => {
    queryClient.invalidateQueries({
      queryKey: useTimeSeriesFieldsQuery.getKey({
        customerId: requireCurrentCustomerId(),
      }),
    });
  };

  const invalidateAnomalySummaryQuery = () => {
    queryClient.invalidateQueries({
      queryKey: useAnomalySummaryQuery.getKey({
        filter: filter,
        pagination: pagination,
        id: detectorId,
      }),
    });
  };

  const invalidateAnomalyByIdQuery = () => {
    if (!detectorId) return;
    queryClient.invalidateQueries({
      queryKey: useExpressionAnomalyByIdQuery.getKey({
        customerId: requireCurrentCustomerId() ?? "",
        id: detectorId,
      }),
    });
  };

  const invalidateAnomalyVehiclesQuery = () => {
    if (!detectorId) return;
    queryClient.invalidateQueries({
      queryKey: useAnomalyAffectedVehiclesQuery.getKey({
        anomalyId: detectorId,
        filter: filter,
        pagination: pagination,
      }),
    });
  };

  const invalidateSavedExpressionOptions = () => {
    queryClient.invalidateQueries({
      queryKey: useSavedExpressionsQuery.getKey({
        customerId: requireCurrentCustomerId(),
      }),
    });
  };

  const onCreate = useCallback(async () => {
    if (!barValues) throw new Error(`Cannot save without threshold values`);
    setCreatingAnomaly(true);
    const { name, description } = form.getFieldsValue();
    notificationApi.info({ message: `Creating ${name}...` });
    const expressionId = await expressionIdToSave(
      name,
      1,
      selectedExpression,
      rootExpression as Expression,
      createSavedExpressionMutation,
      notificationApi,
      setIsSaveAsModalVisible
    );
    await createDetector(
      barValues,
      expressionId,
      name,
      description,
      createExpressionAnomaly,
      setCreatingAnomaly,
      afterCreate
    ).then(invalidateQueries); // Invalidate all queries
    notificationApi.success({ message: `Success! ${name} created` });
  }, [
    afterCreate,
    barValues,
    createExpressionAnomaly,
    createSavedExpressionMutation,
    form,
    notificationApi,
    rootExpression,
    selectedExpression,
  ]);

  const onDelete = () => {
    try {
      if (!anomaly) return;
      const { id, version } = anomaly;
      handleDeleteAnomaly(modal, notificationApi, id, version, deleteAnomaly, navigate, queryClient);
    } catch (error) {
      notificationApi.error({ message: "Invalid Anomaly Detector" });
    }
  };

  const onSaveCopy = async (newName: string, description: string) => {
    if (!barValues) throw new Error(`Cannot save without threshold values`);
    setIsSaveAsModalVisible(false);
    notificationApi.info({ message: `Creating Copy ${newName} in the background...` });
    const expressionId = await expressionIdToSave(
      newName,
      1,
      selectedExpression,
      rootExpression as Expression,
      createSavedExpressionMutation,
      notificationApi,
      setIsSaveAsModalVisible
    );
    await createDetector(
      barValues,
      expressionId,
      newName,
      description,
      createExpressionAnomaly,
      setCreatingAnomaly
    ).then(invalidateQueries);
    notificationApi.success({ message: `Success! ${newName} created!` });
  };

  const onUpdate = async () => {
    setCreatingAnomaly(true);
    const { name, description } = form.getFieldsValue();
    if (!anomaly) throw new Error(`Cannot save missing anomaly`);
    if (!barValues) throw new Error(`Cannot save without threshold values`);
    notificationApi.info({ message: `Saving ${name}...` });
    const expressionId = await expressionIdToSave(
      name,
      anomaly.version + 1,
      selectedExpression,
      rootExpression as Expression,
      createSavedExpressionMutation,
      notificationApi,
      setIsSaveAsModalVisible
    );
    const variables = {
      customerId: requireCurrentCustomerId(),
      expressionAnomaly: {
        threshold_lower: barValues.lowerNormal,
        threshold_upper: barValues.upperNormal,
        threshold_caution: barValues.cautionBar,
        threshold_warning: barValues.warningBar,
        expressionId: expressionId,
        name: name,
        description: description,
      },
      version: anomaly.version,
      id: anomaly.id,
    };
    editExpressionAnomaly.mutate(variables, {
      onSettled: () => {
        setCreatingAnomaly(false);
      },
      onSuccess: () => {
        notificationApi.success({ message: `Success! ${name} saved!` });
        if (anomaly.id && name) afterCreate?.({ id: anomaly.id, name });
        invalidateQueries();
      },
    });
  };

  const handleExpressionChange = (valid: boolean) => setFormulaValid(valid);

  const onExpressionChanged = (expression: SavedExpression) => {
    setSelectedExpression(expression);
    setBarValues(undefined);
    form.setFieldValue("formula", expression);
  };

  const Title = () => {
    return (
      <ThresholdTitle>
        <div className="ThresholdTitle-Title">Thresholds</div>
        <Popover
          className="ThresholdTitle-Info"
          title={<h3>How to set the thresholds</h3>}
          content={
            <div style={{ width: 300 }}>
              <p>
                To adjust the lower normal, caution, warning, and upper normal thresholds for your detector, drag the
                bars below or click on a bar to manually input a value. When manually entering a value, press the Enter
                key to apply your changes. These markers determine the safe, caution, and warning ranges for your
                detector, as well as normalize values above or below the normal thresholds.
              </p>
              <p>
                If the thresholds are stacked on top of each other, double-clicking on a threshold will change its
                stacking order.
              </p>
            </div>
          }
        >
          <QuestionCircleOutlined />
        </Popover>
        {outliersRemoved && (
          <Popover content={"For display purposes, histogram has upper and lower 5% of results hidden"}>
            <InfoCircleFilled />
          </Popover>
        )}
      </ThresholdTitle>
    );
  };

  return (
    <Flex vertical>
      {contextHolder}
      {notificationContextHolder}
      {isDeleting && <Spin fullscreen />}
      <TopHeadingWithButtonsForAnomalyAndPattern
        isNew={isNew}
        isOwnedByMe={anomaly?.creator?.isMe || false}
        itemType="Anomaly Detector"
        hideButtons={false}
        onCreate={onCreate}
        onDelete={onDelete}
        onUpdate={onUpdate}
        onCancel={() => history.go(-1)}
        onSaveAs={() => setIsSaveAsModalVisible(true)}
        {...{
          isLoading,
          isReadOnly,
          isDisabled:
            isDeleting ||
            isLoading ||
            !submittable ||
            (patternSource == "existingPattern" && !selectedExpression) ||
            !barValues,
        }}
      />
      <Row gutter={[24, 2]}>
        <Col md={12} xxl={10}>
          <Card title="Detector Definition" bordered={false}>
            <Form layout="vertical" form={form}>
              <Flex gap={8} vertical>
                <Form.Item
                  label="Name"
                  name="name"
                  required
                  rules={[
                    {
                      required: true,
                      message: "Please provide a unique anomaly detector name",
                    },
                  ]}
                >
                  <Input placeholder="Provide a unique anomaly detector name" disabled={disabled || isLoading} />
                </Form.Item>
                <Form.Item label="Description" name={"description"}>
                  <Input
                    placeholder="Provide a short description of the anomaly detector"
                    disabled={disabled || isLoading}
                  />
                </Form.Item>
                <Form.Item
                  label="Pattern Source"
                  name="source"
                  required
                  rules={[
                    {
                      required: true,
                      message: "Please select a source",
                    },
                  ]}
                >
                  <StyledSelect
                    disabled={disabled || isLoading}
                    options={[
                      { value: "newPattern", label: "New Pattern" },
                      { value: "existingPattern", label: "Existing Pattern" },
                    ]}
                    onSelect={(newPatternSource) => {
                      if (newPatternSource == "newPattern") setSelectedExpression(undefined);
                      setPatternSource(newPatternSource as PatternSource);
                    }}
                    placeholder="Select a Pattern Source"
                  />
                </Form.Item>
                {patternSource == "existingPattern" && (
                  <Form.Item
                    label="Existing Pattern"
                    name="formula"
                    rules={[
                      {
                        required: true,
                        message: "Please select a pattern",
                      },
                    ]}
                  >
                    <ExistingPatternSelector
                      expression={selectedExpression}
                      disabled={disabled || isLoading}
                      hasSearch
                      onExpressionChanged={onExpressionChanged}
                    />
                    {patternsAreNotAvailable && (
                      <Alert
                        style={{
                          marginTop: "0.5rem",
                        }}
                        showIcon
                        message={
                          patternDefinition
                            ? `${patternDefinition.name} or one of its dependencies was changed and is currently calculating. Please wait until it is
                      finished or select another pattern. If the problem persists please contact support.`
                            : `Looks like the associated definition is not more available or was deleted. Please select create a new formula, or contact support.`
                        }
                        type="info"
                      ></Alert>
                    )}
                  </Form.Item>
                )}
                {shouldShowPatternDef && (
                  <Form.Item
                    label={isPatternEditable ? "Formula" : "Existing Formula (read only)"}
                    required
                    name="formula"
                    rules={[
                      {
                        required: true,
                        message: "Please enter the pattern formula",
                      },
                    ]}
                  >
                    <ExpressionInput
                      expression={rootExpression}
                      onChange={(newValue, path) => {
                        if (isPatternEditable) {
                          setBarValues(undefined);
                          setOutliersRemoved(false);
                          editExpression(newValue, path);
                        }
                      }}
                      disabled={!isPatternEditable || disabled || isLoading}
                      path={[]}
                    />
                  </Form.Item>
                )}
              </Flex>
            </Form>
            {!isReadOnly && (
              <ButtonsContainer>
                <Button onClick={resetToBlankOrLastSaved} disabled={isLoading} type="default">
                  Reset
                </Button>
              </ButtonsContainer>
            )}
            <SaveAsModal
              title="Save Anomaly Detector As..."
              originalData={{
                name: anomaly?.name ?? "",
                description: anomaly?.description ?? "",
              }}
              isVisible={isSaveAsModalVisible}
              onOk={(copyData) => onSaveCopy(copyData.name, copyData.description ?? "")}
              onCancel={() => setIsSaveAsModalVisible(false)}
              isLoading={isLoading}
            />
          </Card>
        </Col>
        <Col md={12} xxl={14}>
          <Card title={<Title />} bordered={false}>
            <PatternAnomalyDetectorPreview
              definition={rootExpression as Expression}
              {...{ barValues, setBarValues, setOutliersRemoved }}
              onExpressionChange={handleExpressionChange}
              anomaly={anomaly}
            />
          </Card>
        </Col>
      </Row>
    </Flex>
  );
};
