import styled from "styled-components";

export const FleetMapContainer = styled.div`
  width: 100%;
  position: relative;
  .fleet-intel-map-loading {
    position: absolute;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    left: 50%;
    top: 50%;
    transform: translateY(-50%) translateX(-50%);
    z-index: 9999;
  }
`;
