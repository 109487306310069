import { Select } from "antd";

import { useProductionForecastsQuery } from "@/api";
import { QUERY_SETTINGS } from "@/constants";
import { requireCurrentCustomerId } from "@/utils/customers";

import { getProductionForecastOptions } from "./ForecastHelper";

interface ProductionForecastSelectorProps {
  defaultValue?: string;
  onForecastSelected: (forecastId: string) => void;
}

const ProductionForecastSelector = ({ defaultValue, onForecastSelected }: ProductionForecastSelectorProps) => {
  const { data, isLoading } = useProductionForecastsQuery(
    { customerId: requireCurrentCustomerId() },
    { staleTime: QUERY_SETTINGS.DEFAULT_STALE_TIME }
  );

  const productionForecastOptions = getProductionForecastOptions(data?.productionForecasts?.productionForecasts || []);

  const forecastSelectedHandler = (forecastId: string) => {
    onForecastSelected(forecastId);
  };

  return (
    <Select
      className="production-forecast-select"
      defaultValue={defaultValue}
      loading={isLoading}
      options={productionForecastOptions}
      onSelect={forecastSelectedHandler}
      placeholder="Select a Production Forecast"
    ></Select>
  );
};

export default ProductionForecastSelector;
