import { Expression } from "@lib/src/expression/types";
import { NotificationInstance } from "antd/lib/notification/interface";
import { Dispatch, SetStateAction } from "react";

import { SavedExpression } from "@/api";
import { createExpression } from "@/components/savedExpression/createExpression";
import { CreateSavedExpressionMutation } from "@/components/savedExpression/CreateSavedExpressionMutation";

export const expressionIdToSave = async (
  detectorName: string,
  detectorVersion: number,
  selectedExpression: SavedExpression | undefined,
  rootExpression: Expression,
  createSavedExpressionMutation: CreateSavedExpressionMutation,
  notificationApi: NotificationInstance,
  setIsSaveAsModalVisible: Dispatch<SetStateAction<boolean>>
) => {
  let expressionId: string | undefined;
  if (!selectedExpression) {
    const parentDetectorName = `${detectorName} v.${detectorVersion}`;
    const automaticPatternName = `${parentDetectorName} Source Pattern`;
    const automaticPatternDescription = `automatically generated pattern for ${parentDetectorName}`;
    const createdPattern = await createExpression(
      automaticPatternName,
      automaticPatternDescription,
      rootExpression,
      createSavedExpressionMutation,
      notificationApi,
      setIsSaveAsModalVisible
    );
    expressionId = createdPattern?.id;
    //TODO: cache invalidation - the earlier query to obtain savedexpressions (in the ui) may not re-run and find the new one
  } else {
    expressionId = selectedExpression.id;
  }
  if (!expressionId) throw new Error(`Could not save anomaly due to missing expressionId`);
  return expressionId;
};
