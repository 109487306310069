import styled from "styled-components";

export const EventAnalyzerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  /* TODO move styling below to their own components styling */
  .time-series-chart {
    .fault-code-analysis-graph-tooltip {
      .interval {
        font-weight: bold;
        border-bottom: 1px solid gray;
      }
      table {
        width: 100%;
        tr.header-row {
          &:not(:first-child) {
            border-top: solid 1px gray;
          }
          td {
            font-weight: bold;
          }
        }

        td {
          text-align: left;
          &:not(:first-child) {
            padding-left: 14px;
          }
        }
      }
      .plotted-series-icon {
        width: 10px;
        height: 10px;
        border-radius: 50%;
      }
    }
  }
  .summary-chart-container {
    // No way around this, echarts styling override
    .events-summary-chart {
      height: 250px !important;

      .tooltip {
        .interval {
          font-weight: bold;
        }
        hr {
          border-top: 1px solid #dedede;
        }
      }
    }
  }

  .event-occurrences-title-card {
    .ant-card-head-wrapper {
      width: 100%;
    }
  }
`;
