import { formatExpressionId } from "@lib/src/expression/formatExpressionIds";
import { Expression, TimeSeriesExpressionType } from "@lib/src/expression/types";
import { PartialDeep } from "type-fest";

import { CalculatedStatus, DependencyType, TimeSeriesField } from "@/api";

export const getDependencyTypeName = (type: DependencyType) => {
  switch (type) {
    case DependencyType.Expression:
      return "Pattern";
    case DependencyType.Anomaly:
      return "Anomaly Detector";
    default:
      return "Pattern Event";
  }
};

export const buildDependencyLink = (type: DependencyType, id: string) => {
  switch (type) {
    case DependencyType.Expression:
      return `/pattern/${id}`;
    case DependencyType.Anomaly:
      return `/anomaly-detector/${id}`;
    default:
      return `/pattern-event/${id}`;
  }
};

export const humanFriendlyStatuses = (status?: CalculatedStatus) => {
  switch (status) {
    case CalculatedStatus.Draft:
    case CalculatedStatus.Calculating:
      return "Calculating";
    case CalculatedStatus.Available:
      return "Available";
    case CalculatedStatus.Error:
      return "Error";
    default:
      return "Unavailable";
  }
};

export const isPatternCalculating = (status: CalculatedStatus) => {
  switch (status) {
    case CalculatedStatus.Draft:
    case CalculatedStatus.Calculating:
      return true;
    default:
      return false;
  }
};

export const formatExpressionForCharts = (
  expressionDefinition: PartialDeep<Expression>,
  timeSeriesFields: TimeSeriesField[]
) => {
  if (
    expressionDefinition.type === TimeSeriesExpressionType.Signal ||
    expressionDefinition.type == TimeSeriesExpressionType.Pattern ||
    expressionDefinition.type == TimeSeriesExpressionType.AnomalyScore
  ) {
    const timeSeriesField = timeSeriesFields.find((field) => field.id.includes(expressionDefinition.value!));
    return formatExpressionId({
      type: expressionDefinition.type,
      value: timeSeriesField ? timeSeriesField?.entityId ?? timeSeriesField?.id : "",
    }) as PartialDeep<Expression>;
  }
  return expressionDefinition;
};
