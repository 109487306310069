import { GlobalFilter } from "../globalFilter/GlobalFilter";
import { NavigationSearchBar } from "../searchBar/NavigationSearchBar";
import { GlobalHeaderStyled } from "./GlobalHeader.styled";

export const GlobalHeader = () => {
  return (
    <GlobalHeaderStyled>
      <NavigationSearchBar />
      <GlobalFilter />
    </GlobalHeaderStyled>
  );
};
