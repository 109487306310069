import { SortOrder } from "antd/lib/table/interface";

/**
 * Comparator function for two strings.
 *
 * Very suitable for `Array.prototype.sort`.
 *
 * For this function comparison (as it is case-insensitive), consider:
 * "A" == "a"
 * "A" < "b"
 * Differently than the native `localeCompare` function.
 */
export function compareText(a: string, b: string): number {
  return a.toLocaleLowerCase().localeCompare(b.toLocaleLowerCase());
}

/**
 * Comparator function for two numbers.
 *
 * Very suitable for `Array.prototype.sort`.
 */
export function compareNumber(a: number, b: number): number {
  return a - b;
}

/**
 * Comparator function for two timestamps.
 *
 * Very suitable for `Array.prototype.sort`.
 */
export function compareStrTimestamps(a: string, b: string): number {
  return new Date(a).getTime() - new Date(b).getTime();
}

/**
 * Default sort options for Ant Design tables.
 */
export const DEFAULT_SORT_OPTIONS: SortOrder[] = ["descend", "ascend", null];
