import { isUndefined } from "lodash";

import userLocale from "./locale";

const intlHumanNumberFormatter = new Intl.NumberFormat(userLocale, {
  minimumFractionDigits: 1,
  maximumFractionDigits: 1,
});
const intlNumberFormatter = new Intl.NumberFormat(userLocale, { maximumFractionDigits: 3 });
const intlCompactNumberFormatter = new Intl.NumberFormat(userLocale, {
  notation: "compact",
  maximumFractionDigits: 3,
});
const intlNumberFormatterSimple = new Intl.NumberFormat(userLocale);
const minCompactNumber = 100000;

/**
 *
 * @param number
 * @param autoCompact
 * @returns localized number string, compact format if autoCompact is true
 */
export const formatNumber = (number?: number, autoCompact?: boolean): string => {
  if (isUndefined(number)) return "";
  if (autoCompact && Math.abs(number) >= minCompactNumber) {
    return intlCompactNumberFormatter.format(number);
  }
  return intlNumberFormatter.format(number);
};

/**
 *
 * @param number
 * @returns localized number times 100 as string
 */
export const humanFormatNumber = (number?: number): string => {
  if (isUndefined(number)) return "";
  return intlHumanNumberFormatter.format(number);
};

/**
 *
 * @param number
 * @returns localized compact number string
 */
export const compactFormatNumber = (number?: number): string => {
  if (isUndefined(number)) return "";
  return intlCompactNumberFormatter.format(number);
};

/**
 *
 * @param number
 * @returns localized compact number string
 */
export const simpleFormatNumber = (number?: number): string => {
  if (isUndefined(number)) return "";
  return intlNumberFormatterSimple.format(number);
};
