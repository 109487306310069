import { createSlice, Draft, PayloadAction } from "@reduxjs/toolkit";
import uuid from "react-uuid";

import { TimeSeriesFieldFamily } from "@/api";
import {
  IndependentVarRangeInput,
  IntervalInput,
  Maybe,
  TimeIntervalUnit,
  TimeSeriesIndependentVar,
} from "@/api/customerApi";
import { TimeSeriesFieldInputWithFamily } from "@/features/chartElementLibrary/types";
import { toArray } from "@/utils/arrayUtils";
import { getCurrentCustomerId } from "@/utils/customers";

import { ChartLibraryState, CohortComparisonPlotOption, LibraryChart } from "./ChartLibraryStore";
import { defaultTimeSeriesFieldsByCustomerId } from "./CustomerProvider";

export const defaultGroupLabel = "defaultGroup";

interface SetActiveChartValues {
  chartId: string;
  openSettings?: boolean;
}

interface UpdateChartSpanValues {
  intervalInput?: IntervalInput;
  rangeInput?: IndependentVarRangeInput;
}

interface AddFaultsProps {
  faultCodes: string[];
}

interface RemoveFaultsProps {
  faultCodes: string[];
}

interface SetIndependentVariableProps {
  variable: TimeSeriesIndependentVar;
}

interface CreateEmptyChartProps {
  autoselect?: boolean;
}

interface RemoveChartProps {
  chartId: string;
}

interface ToggleSignalValue {
  fieldId: string;
  chart: Maybe<string>;
  yAxisGroup: Maybe<string>;
  fieldInput: TimeSeriesFieldInputWithFamily;
  comparison: CohortComparisonPlotOption | CohortComparisonPlotOption[];
  selected: boolean;
  anomalyThreshold?: {
    caution: number;
    warning: number;
  };
  unit?: string;
}

interface UpdatePlottedSignalValue {
  seriesId: string;
  newChart?: string;
  createChart?: boolean;
  newFieldInput?: TimeSeriesFieldInputWithFamily;
  newComparison?: CohortComparisonPlotOption | CohortComparisonPlotOption[];
  removeComparison?: boolean;
}

type ResetLibraryValue =
  | {
      createInitialChart?: boolean;
    }
  | undefined;

export const defaultIntervalInputs = {
  [TimeSeriesIndependentVar.Mileage]: { distance: 100 },
  [TimeSeriesIndependentVar.Time]: { distance: 7, timeUnit: TimeIntervalUnit.Day },
};

const removeSeriesFromChart = (state: Draft<ChartLibraryState>, seriesId: string) => {
  state.charts.forEach((chart) => {
    // Starts with chart related series like comparison
    chart.series = chart.series.filter((s) => !s.id.includes(seriesId));
    // Cleanup axis groups
    chart.axisGroups = chart.axisGroups.filter((group) => !!chart.series.filter((s) => s.yAxisId === group.id).length);
    // Always leave a default axisGroup
    if (!chart.axisGroups.length) {
      chart.axisGroups = getDefaultChartLibraryInitalState().charts[0].axisGroups;
    }
  });
};

const createNewChart = (props: { independentVariable: TimeSeriesIndependentVar; chartId?: string }): LibraryChart => {
  return {
    chartId: props.chartId ?? uuid(),
    axisData: {
      data: [],
      id: props.independentVariable.toString(),
      // TODO set x-axis unit (km, s)...
      description: "",
    },
    // plot comparison series if available, in the same chart and in the same group
    series: [],
    axisGroups: [{ id: uuid(), name: defaultGroupLabel }],
    isLoading: false,
  };
};

const defaultAxisId = uuid();

// Will always return a valid library state and will have default series if CustomerProvider has resolved them
export const getDefaultChartLibraryInitalState = (): ChartLibraryState => {
  const currentCustomerId = getCurrentCustomerId();
  const defaultTimeSeriesFields = currentCustomerId
    ? defaultTimeSeriesFieldsByCustomerId.get(currentCustomerId) ?? []
    : [];
  return {
    charts: [
      {
        chartId: uuid(),
        series: defaultTimeSeriesFields.map((f) => ({
          id: f.id,
          name: "",
          TimeSeriesFieldInput: {
            id: f.id,
            aggregateType: f.aggregateType,
            family: TimeSeriesFieldFamily.Signal,
          },
          unit: f.unit,
          comparisonType: ["mean", "stddev"],
          yAxisId: defaultAxisId,
          data: [],
          xValues: [],
          baseName: "",
        })),
        axisData: {
          id: TimeSeriesIndependentVar.Mileage.toString(),
          description: "",
          data: [],
        },
        axisGroups: [{ id: defaultAxisId, name: defaultGroupLabel }],
        isLoading: false,
      },
    ],
    independentVariable: TimeSeriesIndependentVar.Mileage,
    faultCodes: [],
    coloringPoolRecord: {},
  };
};

//TODO: Remove mutation of state
const ChartLibrarySlice = createSlice({
  name: "chartLibrary",
  initialState: getDefaultChartLibraryInitalState(),
  reducers: {
    setActiveChart(state, action: PayloadAction<SetActiveChartValues>) {
      const { chartId, openSettings } = action.payload;
      state.activeChartId = chartId;
      state.librarySettingsOpen = openSettings;
      // Removes any empty chart on close
      if (!openSettings) {
        state.charts = state.charts.filter((chart) => chart.series.length);
      }
    },
    toggleDrawer(state) {
      state.librarySettingsOpen = !state.librarySettingsOpen;
    },
    updateLibrarySpan(state, action: PayloadAction<UpdateChartSpanValues>) {
      state.generalIntervalInput = action.payload.intervalInput;
      state.generalRangeInput = action.payload.rangeInput;
    },
    removeChart(state, action: PayloadAction<RemoveChartProps>) {
      const chartId = action.payload.chartId;
      state.charts = state.charts.filter((c) => c.chartId !== chartId);
    },
    createEmptyChart(state, action: PayloadAction<CreateEmptyChartProps>) {
      const { autoselect } = action.payload;
      const newChartId = uuid();
      const newChart = createNewChart({ independentVariable: state.independentVariable, chartId: newChartId });
      state.charts.push(newChart);
      if (autoselect) {
        state.activeChartId = newChartId;
        state.librarySettingsOpen = true;
      }
    },
    toggleSignal(state, action: PayloadAction<ToggleSignalValue>) {
      // Remove series
      if (!action.payload.selected) {
        removeSeriesFromChart(state, action.payload.fieldId);
      } else {
        const chart = state.charts.find((c) => c.chartId === action.payload.chart);
        //Create new Chart
        if (!chart) {
          const newChart = createNewChart({ independentVariable: state.independentVariable });
          newChart.series.push({
            data: [],
            xValues: [],
            id: action.payload.fieldId,
            name: action.payload.fieldId,
            TimeSeriesFieldInput: action.payload.fieldInput,
            unit: action.payload.unit,
            yAxisId: newChart.axisGroups[0].id,
            anomalyThreshold: action.payload.anomalyThreshold,
            comparisonType: action.payload.comparison ? toArray(action.payload.comparison) : undefined,
            baseName: "",
          });
          state.charts.push(newChart);
        } else if (!action.payload.yAxisGroup) {
          // Create new axis groups
          const currentGroupsLength = chart.axisGroups.length;
          const newGroupId = uuid();
          chart.axisGroups.push({ id: newGroupId, name: `AxGr${currentGroupsLength}` });
          chart.series.push({
            data: [],
            xValues: [],
            id: action.payload.fieldId,
            name: action.payload.fieldId,
            TimeSeriesFieldInput: action.payload.fieldInput,
            unit: action.payload.unit,
            yAxisId: newGroupId,
            comparisonType: action.payload.comparison ? toArray(action.payload.comparison) : undefined,
            anomalyThreshold: action.payload.anomalyThreshold,
            baseName: "",
          });
        }
        // sets data to existing chart series
        else {
          chart.series.push({
            data: [],
            xValues: [],
            id: action.payload.fieldId,
            name: action.payload.fieldId,
            TimeSeriesFieldInput: action.payload.fieldInput,
            unit: action.payload.unit,
            yAxisId: action.payload.yAxisGroup,
            comparisonType: action.payload.comparison ? toArray(action.payload.comparison) : undefined,
            anomalyThreshold: action.payload.anomalyThreshold,
            baseName: "",
          });
        }
      }
    },
    updatePlottedSignal(state, action: PayloadAction<UpdatePlottedSignalValue>) {
      const { seriesId, newFieldInput, newComparison, removeComparison, newChart, createChart } = action.payload;
      state.charts.forEach((chart) => {
        // Find series
        let seriesToUpdate = chart.series.find((s) => s.id === seriesId);
        const targetChart = state.charts.find((chart) => chart.chartId === newChart);
        // Plot signal in existing chart
        if (targetChart && seriesToUpdate) {
          removeSeriesFromChart(state, seriesId);
          const currentGroupsLength = targetChart.axisGroups.length;
          const newGroupId = uuid();
          targetChart.axisGroups.push({ id: newGroupId, name: `AxGr${currentGroupsLength}` });
          seriesToUpdate.yAxisId = newGroupId;
          targetChart.series.push(seriesToUpdate);
        } else {
          // Plot signal in new chart
          if (createChart && seriesToUpdate) {
            removeSeriesFromChart(state, seriesId);
            const newChart = createNewChart({ independentVariable: state.independentVariable });
            seriesToUpdate.yAxisId = newChart.axisGroups[0].id;
            newChart.series.push(seriesToUpdate);
            state.charts.push(newChart);
          }
        }
        // Update values of signal
        if (seriesToUpdate) {
          if (newFieldInput) {
            seriesToUpdate.TimeSeriesFieldInput = newFieldInput;
          }
          if (newComparison) {
            seriesToUpdate.comparisonType = newComparison;
          }
          if (removeComparison) {
            seriesToUpdate.comparisonType = undefined;
          }
        }
      });
    },
    addFaults(state, action: PayloadAction<AddFaultsProps>) {
      const { faultCodes } = action.payload;
      faultCodes.forEach((f) => {
        if (!state.faultCodes.includes(f)) {
          state.faultCodes.push(f);
        }
      });
    },
    removeFaults(state, action: PayloadAction<RemoveFaultsProps>) {
      const { faultCodes } = action.payload;
      state.faultCodes = state.faultCodes.filter((f) => !faultCodes.includes(f));
    },
    setIndependentVariable(state, action: PayloadAction<SetIndependentVariableProps>) {
      const independentVariable = action.payload.variable;
      state.generalIntervalInput = undefined;
      state.generalRangeInput = undefined;
      state.independentVariable = independentVariable;
    },
    // Caution, this will wipe-out the chart library
    // createNewChart assures the new state has an initial standard chart.
    resetLibrary(state, action: PayloadAction<ResetLibraryValue>) {
      state.independentVariable = getDefaultChartLibraryInitalState().independentVariable;
      state.charts = action?.payload?.createInitialChart
        ? [createNewChart({ independentVariable: TimeSeriesIndependentVar.Mileage })]
        : [];
    },
    updateColoringPool(state, action: PayloadAction<{ record: Record<string, string> }>) {
      state.coloringPoolRecord = action.payload.record;
    },
  },
});

export const ChartLibraryActions = ChartLibrarySlice.actions;

export default ChartLibrarySlice;
