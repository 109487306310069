import { Tabs } from "antd";
import styled from "styled-components";

export const StyledTabs = styled(Tabs).attrs({ className: "library-tabs" })`
  .ant-tabs-nav {
    padding: 0 1.5rem;
    margin: 0;
    box-shadow: 0 1px 6px 0 #00000026;
    .ant-tabs-nav-list {
      .ant-tabs-tab-active {
        .ant-tabs-tab-btn {
          color: #000;
        }
        .library-tab-label {
          font-weight: 600;
        }
      }
      .library-tab-label {
        display: flex;
        gap: 0.4rem;
        .signal-counter {
          background-color: #eff8ff;
          color: #175cd3;
          font-weight: 600;
          width: 1.5rem;
          height: 1.5rem;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
`;
