import styled from "styled-components";

export const SectionModalContainer = styled.div`
  position: absolute;
  width: 100%;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 3;
  height: max-content;
`;
