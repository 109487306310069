import { createFileRoute } from "@tanstack/react-router";

import { SavedExpressionHome } from "@/features/savedExpression/SavedExpressionHome";
import AppMainLayout from "@/layouts/AppMainLayout";

export const Route = createFileRoute("/pattern/")({
  component: () => (
    <AppMainLayout>
      <SavedExpressionHome />
    </AppMainLayout>
  ),
});
