import { useQueryClient } from "@tanstack/react-query";
import { Button, Flex, Form, Input } from "antd";
import { NotificationInstance } from "antd/es/notification/interface";
import { useState } from "react";

import { ClusterRedshift, useCreateClusterMutation, useListClustersQuery, useUpdateClusterMutation } from "@/api";
import DateFormatDropdownPicker from "@/components/event/DateFormatDropdownPicker";
import { getCurrentCustomerId } from "@/utils/customers";
import ApiError from "@/utils/errors/ApiError";
import { isValidClusterEntry } from "@/utils/isValidClusterEntry";
import { toPercent } from "@/utils/toPercent";

import {
  ADD_VEHICLES_TO_CLUSTER_BATCH_SIZE,
  addVehiclesToClusterInBatch,
  BatchAddVehiclesToClusterState,
  makeInitialBatchAddVehiclesToClusterState,
} from "../addVehiclesToClusterInBatch";
import { inputToPvinsAndDateRanges } from "../ClusterHelpers";
import { MissingPvins } from "./MissingPvins";

export type NewClusterProps = {
  api: NotificationInstance;
  onSuccess: () => void;
  onCancel: () => void;
  pvins?: string[];
  initialMissingPvins?: string[];
  initialRepeatedCombinations: ClusterRedshift[];
};

type FormField = {
  name: string;
  description?: string;
  input: string;
};

export const newClusterFooter = null;
export const newClusterButtonStyle = {};

export const missingPvinsFooter = null;
export const missingPvinsButtonStyle = { display: "none" };

export const NewCluster = ({
  api,
  onSuccess,
  onCancel,
  pvins,
  initialMissingPvins,
  initialRepeatedCombinations,
}: NewClusterProps) => {
  const queryClient = useQueryClient();
  const [missingPvins, setMissingPvins] = useState<string[] | undefined>(initialMissingPvins);
  const [repeatedCombinations, setRepeatedCombinations] = useState<ClusterRedshift[]>(initialRepeatedCombinations);
  const [dateFormat, setDateFormat] = useState<string>("");
  const [isCreatingCluster, setIsCreatingCluster] = useState(false);
  const [form] = Form.useForm();

  const [batchUpdateState, setBatchUpdateState] = useState<BatchAddVehiclesToClusterState>(
    makeInitialBatchAddVehiclesToClusterState()
  );

  const createCluster = useCreateClusterMutation({
    retry: (failureCount, error: ApiError) => {
      const uniqueNameErrors = [
        "Name is already in use, try with a different one",
        "Error name must be unique",
        "Each row must include a start date.",
      ];
      if (uniqueNameErrors.includes(error.message)) {
        return false;
      }
      return failureCount < 3; // Retry up to 3 times for other errors
    },
  });
  const updateCluster = useUpdateClusterMutation();

  const handleClose = () => {
    form.resetFields();
    setMissingPvins(undefined);
    onCancel();
  };

  const handleConfirm = (args: FormField) => {
    setIsCreatingCluster(true);

    const { name, input, description } = args;

    const { input: inputFormatted, errors } = inputToPvinsAndDateRanges(input, dateFormat);

    if (errors.length) {
      api.error({ message: errors.join(", ") });
      setIsCreatingCluster(false);
      return;
    }

    const mustChunkContent = inputFormatted.length >= ADD_VEHICLES_TO_CLUSTER_BATCH_SIZE;
    const firstContentBatch = inputFormatted.slice(0, ADD_VEHICLES_TO_CLUSTER_BATCH_SIZE);
    const restOfContentToBeBatched = inputFormatted.slice(ADD_VEHICLES_TO_CLUSTER_BATCH_SIZE);

    createCluster.mutate(
      {
        customerId: getCurrentCustomerId() ?? "",
        input: { name, description, content: firstContentBatch },
      },
      {
        onSuccess: (data) => {
          const createdCluster = data.clusters?.createCluster;

          if (!createdCluster) {
            onSuccess();
            return;
          }

          if (!mustChunkContent) {
            queryClient.invalidateQueries({
              queryKey: useListClustersQuery.getKey({ customerId: getCurrentCustomerId() ?? "" }),
            });
            setMissingPvins(createdCluster.missingPvins);
            setRepeatedCombinations(createdCluster.repeatedCombinations);
            onSuccess();
            return;
          }

          api.warning({
            message: "Cluster with initial PVINs created, but the rest of them might take a while, please wait.",
          });

          addVehiclesToClusterInBatch({
            customerId: getCurrentCustomerId() ?? "",
            clusterId: createdCluster.cluster.id,
            clusterVersion: 1,
            updateCluster,
            input: restOfContentToBeBatched,
            onStateChange: setBatchUpdateState,
            onResult: (result) => {
              if (result.isAllSuccessfullyDone) {
                api.success({ message: "Cluster created." });
              } else {
                api.error({ message: "One or more failures, cluster only partially created." });
              }

              if (result.accumulatedMissingPvins.length || result.accumulatedRepeatedCombinations.length) {
                setMissingPvins(result.accumulatedMissingPvins);
                setRepeatedCombinations(result.accumulatedRepeatedCombinations);
              } else {
                handleClose();
              }

              queryClient.invalidateQueries({
                queryKey: useListClustersQuery.getKey({ customerId: getCurrentCustomerId() ?? "" }),
              });
              onSuccess();
            },
          });
        },
        onSettled: () => {
          setIsCreatingCluster(false);
        },
        onError: (error: any) => {
          api.error({
            message: error.message,
          });
        },
      }
    );
  };

  const isSubmitting = isCreatingCluster || batchUpdateState.isUpdating;

  const getSubmitButtonLabel = (): string => {
    if (batchUpdateState.isUpdating) {
      if (batchUpdateState.totalBatchesQtt === 1) {
        return "Adding PVINs...";
      }

      return `Adding PVINs (${toPercent(batchUpdateState.currentBatchIndex / batchUpdateState.totalBatchesQtt, 0)})...`;
    }

    if (isCreatingCluster) {
      return "Creating Cluster...";
    }

    return "Create Cluster";
  };

  return (
    <>
      {!missingPvins && !repeatedCombinations.length && (
        <Form
          form={form}
          layout="vertical"
          name="basic"
          onFinish={(v: FormField) => {
            handleConfirm(v);
          }}
          autoComplete="off"
        >
          <Form.Item<FormField>
            label={"Name"}
            name={"name"}
            rules={[{ required: true, message: "Please input a name for the cluster!" }]}
          >
            <Input placeholder="Enter a name for the cluster" />
          </Form.Item>
          <Form.Item<FormField> label={"Description"} name="description">
            <Input placeholder="Enter a description for the cluser (optional)" />
          </Form.Item>
          <Form.Item<FormField>
            label="PVINs + Date Range"
            name="input"
            rules={[
              { required: true, message: "A cluster needs at least 1 PVIN!" },
              {
                validator: (_, value) => isValidClusterEntry(value),
              },
            ]}
            initialValue={pvins?.join(`\n`)}
          >
            <Input.TextArea placeholder="Enter the pvins and date ranges in the cluster seperated by commas or spaces. Each line is a new pvin + date range value" />
          </Form.Item>
          <Flex justify="end" align="center">
            <DateFormatDropdownPicker onSelected={setDateFormat} />
          </Flex>
          <br />
          <Flex justify="end" align="center">
            <Button type="primary" htmlType="submit" loading={isSubmitting}>
              {getSubmitButtonLabel()}
            </Button>
          </Flex>
        </Form>
      )}
      {(missingPvins?.length || repeatedCombinations.length > 0) && (
        <MissingPvins missingPvins={missingPvins} repeatedCombinations={repeatedCombinations} />
      )}
    </>
  );
};
