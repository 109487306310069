import styled from "styled-components";

export const TimeFrameInputContainer = styled.div`
  height: 43px;
  .frame-input-label {
    margin-right: 1rem;
  }
  .ant-select {
    width: 180px;
    height: 100%;
    font-family: "Inter";
    font-size: 14px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.darkGray};
    .ant-select-selector {
      border-radius: 0.75rem;
      padding: 0.75rem 1rem;
      height: 100%;
    }
  }
`;
