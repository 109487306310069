import styled from "styled-components";

export const AnomalyHistoryKeyContainer = styled.div`
  margin: 2px 0px 8px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  div {
    display: flex;
    align-items: center;
  }

  span {
    width: 16px;
    height: 5px;
    display: block;
    margin-right: 3px;
  }
`;
