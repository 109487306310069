import dayjs from "dayjs";

import { dateFromTimeframe, TimeFrame } from "@/components/form/input/TimeFrameInput";

export const getLast90Days = () => dateFromTimeframe(TimeFrame.LAST_90_DAYS).toDate().getTime();

export const getLast90DaysFromSpecificDate = (date: number) => {
  const ninetyDaysInMs = 7776000000; //90 * 24 * 60 * 60 * 1000
  const startOfMostRecentDate = dayjs(new Date(date)).startOf("day").toDate().getTime();
  return new Date(startOfMostRecentDate - ninetyDaysInMs).getTime();
};
