import { barName } from "./AnomalyHistogramPreview";

type position = [number, number];

const radius = 9;
export const linePositionFn = (pos: position): position => {
  return pos;
};

export const leftCircleFn = ([x, y]: [number, number]) => {
  return [x - radius / 2, y / 2 + radius];
};

export const rectFn = ([x, y]: [number, number]) => {
  return [x - radius / 2, y / 2];
};

export const rightCircleFn = ([x, y]: [number, number]) => {
  return [x + radius / 2, y / 2 + radius];
};

export const imgFn = ([x, y]: [number, number]) => {
  return [x - radius, y / 2];
};

const chartBlue = "#3399ff";

const svgZIndex = 100;

type SvgFunctions = {
  onmousedown: () => void;
  onclick: (e: any) => void;
  ondblclick: (e: any) => void;
};

type TooltipFunctions = {
  position: (
    point: [number, number],
    _params: any,
    _dom: any,
    _rect: any,
    size: { contentSize: [number, number]; viewSize: [number, number] }
  ) => number[];
  formatter: (params: { name: barName }) => string | undefined;
};

export const getLineHandleSvgs = (
  name: string,
  pos: position,
  renderingOrder: string[],
  fns: SvgFunctions,
  tooltipFns: TooltipFunctions
) => {
  const tooltip = {
    trigger: "axis",
    axisPointer: {
      type: "shadow",
    },
    ...tooltipFns,
  };
  const zIndex = svgZIndex + renderingOrder.findIndex((bar) => bar === name);
  const leftCircle = {
    name: name,
    position: leftCircleFn(pos),
    z: zIndex,
    type: "circle",
    shape: {
      cx: 0,
      cy: 0,
      r: radius,
    },
    style: {
      fill: chartBlue,
    },
    tooltip,
    positionFn: leftCircleFn,
    ...fns,
  };

  const rightCircle = {
    name: name,
    position: rightCircleFn(pos),
    z: zIndex,
    type: "circle",
    shape: {
      cx: 0,
      cy: 0,
      r: radius,
    },
    style: {
      fill: chartBlue,
    },
    tooltip,
    positionFn: rightCircleFn,
    ...fns,
  };

  const rect = {
    name: name,
    position: rectFn(pos),
    z: zIndex,
    type: "rect",
    shape: {
      width: radius,
      height: radius * 2,
    },
    style: {
      fill: chartBlue,
    },
    tooltip,
    positionFn: rectFn,
    ...fns,
  };

  const img = {
    name: name,
    position: imgFn(pos),
    z: zIndex,
    type: "image",
    style: {
      image:
        "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAACXBIWXMAAAsTAAALEwEAmpwYAAABAUlEQVR4nO2YSQ7CMAxFcxWmPa3gZMBVgZbhBFQc4aEIEF10CoqTNvJbdfPt/9WqdmKMoiiKoigTBlgBO2DuoFkAe2Ap667fSA5UvDk56IqPprI1ZF0OM285OmjPNV0VPESDefucOeizBn0u6/rXfA08as2fwDZWnahNCRlCqhkhQkg3QbJ+qNeMRJ+WohtvriVDhDbvNUQs815CtAyZwUPKF/zrA7jGNt8RojR9AJcRByjS/4Si7SmB/kTiIQgwC8RCEHAaT2OVSGKZS2KdTuJAk8SRMolDfUeI0kFbRjXfEuJmBgLco5v/Yq8UgYO9YjRu15FWMxuqURRFURTFjJAXVNBwsYCu1LIAAAAASUVORK5CYII=",
      width: radius * 2,
      height: radius * 2,
    },
    tooltip,
    positionFn: imgFn,
    ...fns,
  };
  return [leftCircle, rightCircle, rect, img];
};

type lineArguments = {
  name: string;
  pos: position;
  renderingOrder: string[];
  chart: any;
  maxPct: number;
  chartZeroYPixel: number;
  positionFn: TooltipFunctions["position"];
  formatterFn: TooltipFunctions["formatter"];
  onClickFn: (e: any) => void;
  onDoubleClickFn: (e: any) => void;
  handleBarDrag: (x: number, dragBarName: barName) => void;
  handleBarDragEnd: () => void;
};

export const getLine = ({
  name,
  pos,
  renderingOrder,
  chart,
  maxPct,
  chartZeroYPixel,
  positionFn,
  formatterFn,
  onClickFn,
  onDoubleClickFn,
  handleBarDrag,
  handleBarDragEnd,
}: lineArguments) => {
  return {
    z: svgZIndex + renderingOrder.findIndex((bar) => bar === name) - 1,
    id: name,
    className: "draggable-bars",
    name: name,
    type: "line",
    position: pos,
    shape: {
      x1: 0,
      x2: 0,
      y1: 0,
      y2: chart.convertToPixel("grid", [0, maxPct])[1] - chartZeroYPixel,
    },
    cursor: "ew-resize",
    invisible: false,
    style: {
      stroke: chartBlue,
      lineDash: "dashed",
      lineWidth: 1.5,
    },
    draggable: true,
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
      position: positionFn,
      formatter: formatterFn,
    },
    onclick: onClickFn,
    ondblclick: onDoubleClickFn,
    ondrag: function () {
      // @ts-ignore
      handleBarDrag(this.x, name);
    },
    ondragend: function () {
      handleBarDragEnd();
    },
  };
};
