import { ConfigProvider } from "antd";
import { PropsWithChildren } from "react";

import { EmptyComponent } from "@/components/ui/EmptyComponent";
import { defaultTheme } from "@/styles/Global.styled";

// TODO style empty element
export const EmptyElement = () => {
  return <EmptyComponent description="No data" />;
};

export const AntdConfigProvider = ({ children }: PropsWithChildren<{}>) => {
  return (
    <ConfigProvider
      renderEmpty={EmptyElement}
      theme={{
        hashed: false,
        token: {
          colorPrimary: defaultTheme.colors.accentBlue,
          colorLink: defaultTheme.colors.accentBlue,
        },
        components: {
          Layout: {
            siderBg: defaultTheme.colors.background,
            headerBg: defaultTheme.colors.background,
          },
          Table: {
            fontFamily: "Inter",
            fontSize: 12,
            headerBg: defaultTheme.colors.oddRow,
            headerSortHoverBg: defaultTheme.colors.hoverRow,
            headerSortActiveBg: defaultTheme.colors.oddRow,
            headerSplitColor: undefined,
          },
          Pagination: {},
          Modal: {
            padding: 0,
            paddingContentHorizontal: 0,
            paddingContentHorizontalLG: 0,
            paddingContentHorizontalSM: 0,
            paddingContentVertical: 0,
            paddingContentVerticalLG: 0,
            paddingContentVerticalSM: 0,
            paddingLG: 0,
            paddingMD: 0,
            paddingSM: 0,
            paddingXL: 0,
            paddingXS: 0,
            paddingXXS: 0,
            margin: 0,
            headerBg: defaultTheme.colors.defaultWhite,
            contentBg: defaultTheme.colors.smoothGray,
          },
          Button: {
            colorLink: defaultTheme.colors.defaultBlack,
            controlHeight: 42,
            paddingContentHorizontal: 24,
            dangerColor: defaultTheme.colors.error,
          },
          Form: {
            verticalLabelMargin: 0,
            verticalLabelPadding: 0,
          },
          Typography: {
            fontFamily: "Hubot Sans",
            colorError: defaultTheme.colors.error,
            fontSize: 14,
            fontSizeHeading4: 24,
            margin: 0,
            titleMarginBottom: 0,
            titleMarginTop: 0,
          },
          Input: {
            controlHeight: 42,
          },
          Select: {
            controlHeight: 42,
          },
          InputNumber: {
            controlHeight: 42,
          },
          Result: {
            titleFontSize: 18,
            fontWeightStrong: 400,
            fontFamily: "Hubot Sans",
          },
          Card: {
            headerBg: defaultTheme.colors.defaultWhite,
            headerHeight: 64,
            headerFontSize: 18,
            fontFamily: "Hubot Sans",
            colorBgContainer: defaultTheme.colors.smoothGray,
            boxShadow: "0px 1px 6px 0px rgba(0, 0, 0, 0.15)",
            boxShadowSecondary: "0px 1px 6px 0px rgba(0, 0, 0, 0.15)",
          },
          Collapse: {
            headerPadding: "8px 0",
            contentPadding: 0,
          },
          Tooltip: {
            fontFamily: "Inter",
            colorBgSpotlight: defaultTheme.colors.defaultWhite,
            colorTextLightSolid: defaultTheme.colors.backgroundActive,
            borderRadius: 8,
          },
        },
      }}
      modal={{
        className: "modal-pullsystems",
      }}
      form={{
        className: "form-pullsystems",
      }}
      card={{
        className: "card-pullsystems",
        styles: {},
      }}
      pagination={{
        className: "pullsystems-table-pagination",
      }}
    >
      {children}
    </ConfigProvider>
  );
};
