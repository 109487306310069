import { isNotNullOrUndefined } from "@lib/src/isNotNullOrUndefined";
import * as echarts from "echarts";
import EChartsReact from "echarts-for-react";
import _ from "lodash";
import { RefObject, useEffect, useRef, useState } from "react";

import { VehicleCountByCountry } from "@/api/customerApi";
import geoJson from "@/assets/world-map.json";
import { FleetMapContainer } from "@/components/fleetMap/FleetMap.styled";
import { UI_SETTINGS } from "@/constants";
import { getCountryName } from "@/utils/countries";

import { BaseEChart } from "../ui/BaseEChart";

const MIN_VIEWPORT_WIDTH = 1366;
const MAX_VIEWPORT_WIDTH = 2560;
const MIN_ZOOM_VALUE = 1.3;
const MAX_ZOOM_VALUE = 2.9;

const calculateZoomValue = (): number => {
  const viewportWidth = window.innerWidth;
  const clampedViewportWidth = Math.min(Math.max(viewportWidth, MIN_VIEWPORT_WIDTH), MAX_VIEWPORT_WIDTH);
  const normalizedValue = (clampedViewportWidth - MIN_VIEWPORT_WIDTH) / (MAX_VIEWPORT_WIDTH - MIN_VIEWPORT_WIDTH);

  return MIN_ZOOM_VALUE + normalizedValue * (MAX_ZOOM_VALUE - MIN_ZOOM_VALUE);
};

echarts.registerMap("world", geoJson as any);

interface FleetMapParams {
  data?: VehicleCountByCountry[];
  selectedCountry?: string;
  onCountrySelected?: (country: string) => void;
  isLoading: boolean;
}

const buildFleetMapChartOptions = (zoom: number, vehicleCountByCountry?: VehicleCountByCountry[]) => {
  const lightestBlue = "#ccddff";
  const darkestBlue = "#7799ff";
  const defaultColor = "#4a4a4a";
  const borderColor = "#cccccc";
  const selectedBorderColor = "#ffac40";
  const selectedColor = "#ffcc60";
  const mouseoverColor = selectedColor;
  const data =
    geoJson?.features.map(({ properties: { name } }) => {
      const vehicleForCountry = vehicleCountByCountry?.find((v) => v.country == name);
      const disabled = vehicleForCountry == undefined;
      return {
        name,
        value: vehicleForCountry?.vehicles,
        select: {
          disabled,
          itemStyle: {
            borderWidth: 2,
            borderColor: selectedBorderColor,
            areaColor: disabled ? defaultColor : selectedColor,
          },
          label: {
            show: false,
          },
        },
        itemStyle: {
          borderColor,
          areaColor: defaultColor,
          label: {
            show: false,
          },
        },
        emphasis: {
          itemStyle: {
            areaColor: disabled ? defaultColor : mouseoverColor,
          },
          label: {
            show: false,
          },
        },
      };
    }) || [];
  return {
    option: {
      visualMap: {
        show: false,
        min: 0,
        max: Math.max(...data.map((d) => d.value).filter(isNotNullOrUndefined)),
        inRange: {
          color: data?.length ? [lightestBlue, darkestBlue] : defaultColor,
          symbol: "none",
        },
        calculable: true,
      },
      tooltip: {
        formatter: (params: { data: (typeof data)[number] }) => {
          return `${params.data.value || 0} vehicles in ${getCountryName(params.data.name)}`;
        },
      },
      legend: {
        show: false,
      },
      series: [
        {
          type: "map",
          map: "world",
          showLegendSymbol: false,
          zoom,
          selectedMode: "single",
          data,
          projection: {
            // Mercator projection
            project: ([x, y]: [number, number]) => [
              (x / 180) * Math.PI,
              -Math.log(Math.tan((Math.PI / 2 + (y / 180) * Math.PI) / 2)),
            ],
            unproject: ([x, y]: [number, number]) => [
              (x * 180) / Math.PI,
              ((2 * 180) / Math.PI) * Math.atan(Math.exp(y)) - 90,
            ],
          },
        },
      ],
    },
  };
};

export default function FleetMap({ data, selectedCountry, onCountrySelected, isLoading }: FleetMapParams) {
  const [mapZoom, setMapZoom] = useState(calculateZoomValue());
  const mapRef = useRef<EChartsReact>();

  const chartConfig = buildFleetMapChartOptions(mapZoom, data);

  const onElementSelected = (params: any) => {
    const isNonEmptyCountry = data?.find((v) => params.data?.name == v?.country)?.vehicles;
    if (isNonEmptyCountry) {
      if (params.data?.name) {
        return onCountrySelected?.(params.data.name !== selectedCountry ? params.data.name : "");
      }
    } else {
      return onCountrySelected?.("");
    }
  };

  const handleResize = _.debounce(() => {
    setMapZoom(calculateZoomValue());
  }, UI_SETTINGS.STANDARD_INPUT_DEBOUNCE);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleMapReady = () => {
    const mapInstance = mapRef.current?.getEchartsInstance();
    if (mapInstance) {
      if (!selectedCountry) {
        mapInstance.dispatchAction({
          type: "toggleSelect",
        });
      } else {
        mapInstance.dispatchAction({
          type: "select",
          name: selectedCountry,
        });
      }
    }
  };

  return (
    <FleetMapContainer className="fleet-intel-map">
      <BaseEChart
        {...chartConfig}
        className="echarts fleet-map-chart"
        ref={mapRef as RefObject<EChartsReact>}
        onChartReady={handleMapReady}
        onEvents={{ click: onElementSelected }}
        showLoading={isLoading}
        notMerge={true}
      />
    </FleetMapContainer>
  );
}
