import { ReactNode, useCallback, useEffect, useMemo, useRef } from "react";

import { GRAPH_COLORS } from "@/features/chartElementLibrary/ChartLibraryBuildUtils";

import { ChartColoringPoolContext, ChartColoringPoolContextValue } from "./ChartColoringPoolContext";

export interface ChartColoringPoolProviderProps {
  children: ReactNode;
  statefulColoringPoolRecord?: Record<string, string>;
  usedSeriesIds?: string[];
  allColors?: string[];
  statefullyUpdatePoolChange?: (payload: { record: Record<string, string> }) => void;
}

/**
 * To wrap chart widgets so they can share the same pool of colors,
 * following a more deterministic yet automatic color assignment.
 *
 * By default, the colors are locked are consistently using volatile in-memory logic,
 * but you can (and probably should) inject external stateful dependencies
 * like Redux/URL/Storage getters/setters to assist this flow.
 */
export const ChartColoringPoolProvider = ({
  children,
  statefulColoringPoolRecord = {},
  usedSeriesIds = [],
  allColors = Array.from(GRAPH_COLORS),
  statefullyUpdatePoolChange = () => {},
}: ChartColoringPoolProviderProps) => {
  // derivates initial "state" from (probably) what came from the url
  const initials = useMemo(
    () =>
      buildInitialColoringSetup({
        statefulColoringPoolRecord,
        usedSeriesIds,
        allColors,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  // using refs (instead of regular states) because these locks are acquired during
  // render time of most functions, and we don't want renders triggering renders,
  // so we keep the locks in refs and only synchronyze it with redux (and from redux to url)
  // when there is indeed some arbitrary change.
  const colorsMemoRef = useRef<Record<string, string>>(initials.colorsMemo);
  const nextColorRef = useRef<number>(initials.nextColorIndex);

  useEffect(() => {
    const isSameLockedSeries =
      Object.keys(colorsMemoRef.current).length === Object.keys(statefulColoringPoolRecord).length;

    if (isSameLockedSeries) {
      return;
    }

    // more locked stuff, save it on redux/whatever so it will be broadcasted to url
    statefullyUpdatePoolChange({ record: { ...colorsMemoRef.current } });
  }, [allColors, statefulColoringPoolRecord, statefullyUpdatePoolChange, usedSeriesIds]);

  const acquireColor = useCallback(
    (seriesId: string): string => {
      if (!seriesId) {
        // empty strs should never arrive here,
        // but in case it does, the black line will discretly highlight that something is wrong
        return "#000";
      }

      const colorsMemo = colorsMemoRef.current;
      const alreadyAssignedColor = colorsMemoRef.current[seriesId];
      const currentNextColorIndex = nextColorRef.current;

      if (!alreadyAssignedColor) {
        const nextColorCode = allColors[nextColorRef.current];
        colorsMemoRef.current = { ...colorsMemo, [seriesId]: nextColorCode };

        nextColorRef.current = currentNextColorIndex + 1;
      }

      if (currentNextColorIndex >= allColors.length) {
        nextColorRef.current = 0;
      }

      const assignedColor = colorsMemoRef.current[seriesId];

      return assignedColor;
    },
    [allColors]
  );

  const value: ChartColoringPoolContextValue = {
    acquireColor,
  };

  return <ChartColoringPoolContext.Provider value={value}>{children}</ChartColoringPoolContext.Provider>;
};

function buildInitialColoringSetup({
  statefulColoringPoolRecord,
  usedSeriesIds,
  allColors,
}: {
  statefulColoringPoolRecord: Record<string, string>;
  usedSeriesIds: string[];
  allColors: string[];
}): {
  colorsMemo: Record<string, string>;
  nextColorIndex: number;
} {
  const colorsRecord = getRecordWithoutUnuseds({ statefulColoringPoolRecord, usedSeriesIds });
  const nextColorIndex = getHigherInitialColorByIndex({ colorsRecord, allColors }) + 1;
  return {
    colorsMemo: colorsRecord,
    nextColorIndex,
  };
}

// if the user keeps "playing around" selecting and unselecting several colors
// and refreshs the page, after reading all these locks during the first render
// all these unused locks can be forgotten.
// (this also helps the lock object to not keep growing forever with unused/garbage old locks)
function getRecordWithoutUnuseds({
  statefulColoringPoolRecord,
  usedSeriesIds,
}: {
  statefulColoringPoolRecord: Record<string, string>;
  usedSeriesIds: string[];
}): Record<string, string> {
  return usedSeriesIds.reduce(
    (accRecord, usedSeriesId) =>
      statefulColoringPoolRecord[usedSeriesId]
        ? { ...accRecord, [usedSeriesId]: statefulColoringPoolRecord[usedSeriesId] }
        : accRecord,
    {} as Record<string, string>
  );
}

// so the "next index" pointer can begin from where "it seems to be stopped"...
// instead of where it really stopped, because one user might have assigned too many colors
// but not using any of them causing the shared url unecessarily unclude all used locks.
function getHigherInitialColorByIndex({
  colorsRecord,
  allColors,
}: {
  colorsRecord: Record<string, string>;
  allColors: string[];
}): number {
  return Object.values(colorsRecord).reduce((higherIndex, color) => {
    const currentColorIndex = allColors.indexOf(color);
    return currentColorIndex > higherIndex ? currentColorIndex : higherIndex;
  }, -1);
}
