import { Popover, PopoverProps } from "antd";
import { forwardRef } from "react";

// eslint-disable-next-line react/display-name
const PopoverWrapper = forwardRef<HTMLDivElement, any>(({ ...props }: PopoverProps, ref) => {
  return (
    <div
      ref={ref}
      onClick={(e) => {
        e.stopPropagation(); // Stop event from propagating to the document
      }}
    >
      <Popover {...props} rootClassName="amaya-styled-popover" overlayInnerStyle={{ padding: 0, minWidth: 510 }} />
    </div>
  );
});

export default PopoverWrapper;
