import { humanFormatNumber } from "@/utils/numberUtils";

export const buildAnomalyMarkLines = (cautionThreshold: number, warningThreshold: number, preview: boolean) => ({
  symbol: ["none", "none"],
  data: [
    {
      yAxis: cautionThreshold,
      lineStyle: {
        type: "dashed",
        color: "dedede",
        opacity: 0.3,
      },
    },
    {
      yAxis: warningThreshold,
      lineStyle: {
        type: "dashed",
        color: "dedede",
        opacity: 0.3,
      },
    },
  ],
  emphasis: {
    lineStyle: {
      opacity: 0.3,
      color: "dedede",
      width: 1,
    },
    label: {
      show: false,
    },
  },
  label: {
    show: !preview,
    formatter: (params: any) => humanFormatNumber(params?.data?.value),
  },
  silent: true,
});
