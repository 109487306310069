import IconSearch from "@amayaIcons/icon-search.svg";
import { Input } from "antd";
import _ from "lodash";
import { PropsWithChildren } from "react";

import { UI_SETTINGS } from "@/constants";

import { SignalSelectorContainer } from "./LibrarySelector.styled";

type LibraryListWithSearchProps = {
  searchPlaceholder?: string;
  onSearch?: (searchTerm: string) => void;
};

const LibraryListWithSearch = (props: PropsWithChildren<LibraryListWithSearchProps>) => {
  const { searchPlaceholder = "serch", onSearch } = props;

  const handleSearch = _.debounce((searchTerm: string) => {
    onSearch ? onSearch(searchTerm) : undefined;
  }, UI_SETTINGS.SEARCH_INPUT_DEBOUNCE);

  return (
    <SignalSelectorContainer>
      {onSearch && (
        <Input
          className="search-signal-input"
          placeholder={searchPlaceholder}
          onChange={(e) => handleSearch(e.target.value)}
          suffix={<IconSearch />}
        />
      )}
      {props.children}
    </SignalSelectorContainer>
  );
};

export default LibraryListWithSearch;
