import styled from "styled-components";

export const AnomalyCardTitleWrapper = styled.div`
  height: 5rem;
  display: flex;
  align-items: center;
  gap: 1.5rem;
  .anomaly-title-color {
    width: 0.5rem;
    height: 5rem;
  }
  .anomaly-title-title {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;

    h3 {
      color: #5f5f5f;
    }
  }
  .anomaly-title-button {
    flex: 1 1 auto;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 24px;
    svg {
      cursor: pointer;
    }
  }
  .anomaly-detector-icon-container {
    margin-left: auto;
    padding: 0 1rem;
  }
`;
