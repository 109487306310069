import { Link } from "@tanstack/react-router";
import { Button, Flex } from "antd";

export const NewPatternButton = () => {
  return (
    <Flex align="flex-end" justify="flex-end">
      <Link to="/anomaly-detector/new">
        <Button type="primary">Create New Detector</Button>
      </Link>
    </Flex>
  );
};
