import ErrorIcon from "@amayaIcons/icon-unplugged.svg";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { Divider, Popover } from "antd";
import { ThemeProvider } from "styled-components";

import { ErrorMessage } from "@/components/error/ErrorMessage";
import { defaultTheme } from "@/styles/Global.styled";

import { FallbackPageContainer, FallbackPageReloadContainer } from "./FallbackPage.styled";

type FallbackPageProps = {
  error: any;
  resetErrorBoundary: () => void;
};

const FallbackPage = ({ error, resetErrorBoundary }: FallbackPageProps) => {
  return (
    <ThemeProvider theme={defaultTheme}>
      <FallbackPageContainer>
        <div style={{ height: "48xp", width: "78px" }}>
          <ErrorIcon />
        </div>
        <Divider type="vertical" />
        <div>
          <p className="fallback-larger-text">Oops!</p>
          <p>
            An Error Occured.{" "}
            <Popover content={<ErrorMessage message={error?.message} />}>
              <QuestionCircleOutlined />
            </Popover>
          </p>
          <FallbackPageReloadContainer onClick={() => resetErrorBoundary()}>
            <p>Click here to reload</p>
          </FallbackPageReloadContainer>
        </div>
      </FallbackPageContainer>
    </ThemeProvider>
  );
};

export default FallbackPage;
