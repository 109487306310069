import { DefaultOptionType } from "antd/lib/select";

import { AggregateType } from "@/api/customerApi";
import { CohortComparisonPlotOption, cohortComparisonPlotOptions } from "@/contexts/ChartLibraryStore";

import { PlottedSignalData } from "./ChartLibraryTabs";

export const plotterOptionsDraft = new Map<string, PlottedSignalData>();

// This is to have select options with 'no value' and use that option as undefined
export const emptyStringAsUndef = (input: any) => {
  if (input === "") return;
  if (!input) return;
  if (Array.isArray(input)) {
    if (!input.filter((e) => !!e).length) return;
    return input.filter((e) => !!e);
  }
  return input;
};

const avaliableEventAggregations = ["Mean", "Median"]; // as defined by queryEventTimeSeriesHistory.ts
export const getAvailableEventAggregationsOptions = () => {
  return Object.entries(AggregateType)
    .filter(([k, _v]) => avaliableEventAggregations.includes(k))
    .map(([k, v]) => {
      return {
        value: v,
        label: k,
      };
    });
};

export const getAggregateOptionValue = (aggValue?: string): AggregateType | undefined => {
  const typesKeys = Object.values(AggregateType);
  if (aggValue && typesKeys.includes(aggValue as AggregateType)) {
    return aggValue as AggregateType;
  }
  return;
};

export const getAvailableComparisonOptions = (dualComparison?: boolean): DefaultOptionType[] => {
  return dualComparison
    ? cohortComparisonPlotOptions.filter((o) => o.isRangedComparison)
    : [{ value: "", label: "None" }, ...cohortComparisonPlotOptions.filter((o) => !o.isRangedComparison)];
};

export const getComparisonOptionValue = (comparisonValue?: string): CohortComparisonPlotOption | undefined => {
  return cohortComparisonPlotOptions.find((option) => option.value === comparisonValue)?.value;
};
