import { Layout } from "antd";
import styled from "styled-components";

export const Container = styled(Layout.Content)<{ height?: number }>`
  width: 100%;
  flex: 1;
  min-height: 0;
  display: flex;
  align-items: center;
  gap: 1rem;
  justify-content: center;
  ${({ height }) => (height ? `height: ${height}px;` : "")}
  &.full-height {
    height: 100vh;
  }
  .loading-logo {
    animation: pulseAnimation 1s infinite;
  }
  .loading-tip {
    font-size: 0.75rem;
    font-family: "Hubot Sans";
  }
`;
