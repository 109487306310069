export const ensureTooltipIsInChart = (
  point: [number, number],
  size: { contentSize: [number, number]; viewSize: [number, number] }
) => {
  let posX = point[0];
  let posY = point[1];
  const chartWidth = size.viewSize[0];
  const chartHeight = size.viewSize[1];
  const tooltipWidth = size.contentSize[0];
  const tooltipHeight = size.contentSize[1];
  const offsetX = 10;
  const offsetY = 10;

  if (posX + tooltipWidth + offsetX > chartWidth) {
    posX = chartWidth - tooltipWidth - offsetX;
  } else if (posX - offsetX < 0) {
    posX = offsetX;
  }

  if (posY + tooltipHeight + offsetY > chartHeight) {
    posY = chartHeight - tooltipHeight - offsetY;
  } else if (posY - offsetY < 0) {
    posY = offsetY;
  }

  return [posX, posY];
};
