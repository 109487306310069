import { ensureTooltipIsInChart } from "@/utils/eCharts";

export const getClusterChartLayoutConfig = () => {
  return {
    yNameGap: 50,
    xNameGap: 20,
    grid: {
      left: "10%",
      bottom: "10%",
      containLabel: true,
    },
    toolbox: {
      orient: "vertical",
      itemSize: 20,
      top: -275,
      itemGap: 15,
      feature: {
        dataZoom: { iconStyle: { opacity: 0 } },
        restore: {},
      },
    },
    dataZoom: [
      {
        type: "inside",
        zoomOnMouseWheel: "shift",
        preventDefaultMouseMove: false,
        disabled: false,
        zoomLock: true,
      },
    ],
    position(
      point: [number, number],
      _params: any,
      _dom: any,
      _rect: any,
      size: { contentSize: [number, number]; viewSize: [number, number] }
    ) {
      return ensureTooltipIsInChart(point, size);
    },
  };
};
