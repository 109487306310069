import { EChartsInstance } from "echarts-for-react";
import { Dispatch, SetStateAction, useEffect, useMemo, useRef, useState } from "react";

import { BaseEChart } from "@/components/ui/BaseEChart";

import { anomalyHistogramPreviewLogic } from "./AnomalyHistogramPreviewLogic";
import { anomalyHistogramPreviewUtilityFunctions } from "./anomalyHistogramPreviewUtilityFunctions";
import { AnomalyHistogramTextInput } from "./AnomalyHistogramTextInput";

export type AnomalyHistogramTextInputInfo = {
  x: number;
  y: number;
  name: barName;
  value: number;
};

type AnomalyHistogramPreviewProps = {
  loading: boolean;
  data: {
    min: number[];
    max: number[];
    pct: number[];
  };
  anomaly?: {
    thresholds: AnomalyBarValues;
    version: number;
    id: string;
  };
  barValues: AnomalyBarValues;
  setBarValues: Dispatch<SetStateAction<AnomalyBarValues | undefined>>;
};

export type AnomalyBarValues = {
  cautionBar: number;
  warningBar: number;
  lowerNormal: number;
  upperNormal: number;
};

export type barName = keyof AnomalyBarValues;

export const AnomalyHistogramPreview = (props: AnomalyHistogramPreviewProps) => {
  // the order that the bars are in in this array is used for their z index. When a bar is clicked
  // it is placed at the front of the array giving it a lower z index. That way if bars are ever stacked on eachother
  // a user can click the bars to cycle them so they do not soft lock themselves into an unmovable position.
  const [renderingOrder, setRenderingOrder] = useState(["lowerNormal", "cautionBar", "warningBar", "upperNormal"]);
  const [inputInfo, setInputInfo] = useState<{ x: number; y: number; name: barName; value: number }>();

  const {
    barValues,
    setBarValues,
    data: { min, max, pct },
    loading,
  } = props;

  const chartRef = useRef<EChartsInstance>();
  const dragBarRef = useRef<barName>();
  const maxPct = useMemo(() => {
    return Math.ceil(Math.max(...pct) * 100) + 1;
  }, [pct]);

  const chartFunctions = useMemo(
    () => anomalyHistogramPreviewUtilityFunctions({ chartRef, min, max, pct }),
    [chartRef, min, max, pct]
  );

  const barValuesRef = useRef(barValues);

  const handleRenderOrder = (lastClickedBar: string) => {
    const newOrder = [lastClickedBar];
    renderingOrder.forEach((bar) => {
      if (bar !== lastClickedBar) {
        newOrder.push(bar);
      }
    });
    setRenderingOrder(newOrder);
  };

  const { chartConfig, handleChartReady, setChartGraphics } = anomalyHistogramPreviewLogic({
    min,
    max,
    pct,
    setBarValues,
    barValues,
    chartRef,
    dragBarRef,
    maxPct,
    chartFunctions,
    barValuesRef,
    renderingOrder,
    handleRenderOrder,
    inputInfo,
    setInputInfo,
  });

  useEffect(() => {
    setChartGraphics();
    barValuesRef.current = barValues;
  }, [barValues, setChartGraphics]);

  return (
    <div id="AnomalyHistogramPreview" style={{ position: "relative" }}>
      <BaseEChart
        {...chartConfig}
        showLoading={loading}
        style={{ height: 500 }}
        onChartReady={handleChartReady}
        opts={{ renderer: "svg" }}
      />
      {inputInfo && (
        <AnomalyHistogramTextInput
          {...inputInfo}
          barValues={barValues}
          min={min[0]}
          max={max[max.length - 1]}
          setBarValues={setBarValues}
          setInputInfo={setInputInfo}
        />
      )}
    </div>
  );
};
