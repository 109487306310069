import { Col, message, Row, Tooltip } from "antd";
import { Dispatch, SetStateAction, useRef } from "react";

import { TimeSeriesFieldFamily } from "@/api";
import { AnomalyDetectorType, SingleVehicleAnomalyDetector } from "@/api/customerApi";
import { useTimeSeriesFields } from "@/hooks/useTimeSeriesFields";

import IconHelp from "../../../../../components/icons/icon-help.svg";
import { AnomalyDetectorSubHeading } from "./AnomalyDetectorSubHeading";
import { AnomalyHistoryChart } from "./chart/AnomalyHistoryChart";
import { Coord } from "./inputs/Coord";
import { InputSignalsWithoutWeights } from "./inputs/InputSignalsWithoutWeights";
import { SignalWeightChart } from "./inputs/SignalWeightChart";
import AnomalyDetectorSummary from "./summary/AnomalyDetectorSummary";

interface AnomalyDetectorProps {
  anomaly: SingleVehicleAnomalyDetector;
  onPlot?: (anomaly: SingleVehicleAnomalyDetector) => void;
  onSignalSelected?: (fieldId: string) => void;
}

export const AnomalyDetectorExpanded = ({ anomaly, onPlot, onSignalSelected }: AnomalyDetectorProps) => {
  const isExpressionAnomaly = anomaly.detector.type == AnomalyDetectorType.Pattern;
  const childRef = useRef<{ setHoverCoord: Dispatch<SetStateAction<Coord | undefined>> }>();
  const { byFieldIdAndFamily } = useTimeSeriesFields();

  const handlePlotAnomalyFields = (anomaly: SingleVehicleAnomalyDetector) => {
    if (onPlot) {
      onPlot(anomaly);
      message.info(`Plotting below...`);
    }
  };

  const handleSignalClick = (fieldId: string) => {
    const timeSeriesField = byFieldIdAndFamily(fieldId, TimeSeriesFieldFamily.AnomalyScore);

    if (timeSeriesField) {
      onSignalSelected?.(timeSeriesField.id);
      message.info(`Plotting ${timeSeriesField.displayName} below...`);
    }
  };

  const isMLAnomaly = !isExpressionAnomaly;

  const anomalyDetectorExplainer = `
    The following input signals / patterns influenced this anomaly detector.
    ${isMLAnomaly ? "The bars represent how each signal for this vehicle compares to normal vehicles." : ""}
  `;

  return (
    <Row gutter={[24, 24]} style={{ width: 1200 }}>
      <Col span={6}>
        <AnomalyDetectorSummary anomaly={anomaly} />
      </Col>
      <Col span={9}>
        <AnomalyHistoryChart {...{ anomaly, childRef }} onHandlePlotScore={handleSignalClick} />
      </Col>
      <Col span={9}>
        <AnomalyDetectorSubHeading>
          {isMLAnomaly ? "Anomaly Signature" : "Relevant Inputs"}
          <Tooltip title={anomalyDetectorExplainer} arrow={false}>
            <IconHelp />
          </Tooltip>
        </AnomalyDetectorSubHeading>
        {isMLAnomaly && (
          <SignalWeightChart anomaly={anomaly} onPlot={onPlot} onSignalSelected={onSignalSelected} ref={childRef} />
        )}
        {isExpressionAnomaly && (
          <InputSignalsWithoutWeights {...{ handleSignalClick, handlePlotAnomalyFields, anomaly }} />
        )}
      </Col>
    </Row>
  );
};
