import { useContext, useState } from "react";

import { useVehicleCountForFleetIntelQuery } from "@/api/customerApi";
import GlobalFilterContext from "@/contexts/GlobalFilterContext";

import FleetMap from "../fleetMap/FleetMap";
import TimeFrameInput, { dateFromTimeframe, TimeFrame } from "../form/input/TimeFrameInput";
import FleetCountryTable from "../tables/FleetCountryTable";
import { BasicWidget } from "../widget/BasicWidget";
import { FleetIntelContainer, FleetIntelHeaderContainer } from "./FleetIntel.styled";

export const FleetIntel = () => {
  const { globalFilter } = useContext(GlobalFilterContext);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [timeframe, setTimeframe] = useState(TimeFrame.ALL_TIME);

  const startDate = dateFromTimeframe(timeframe);

  const fleetIntelQuery = useVehicleCountForFleetIntelQuery({
    filter: globalFilter,
    startDate: startDate?.toISOString(),
  });

  const intelHeader = (
    <FleetIntelHeaderContainer>
      <h2 className="heading-x-small dark">Fleet Intel</h2>
      <TimeFrameInput label="Filter by repairs" value={timeframe} onChange={setTimeframe}></TimeFrameInput>
    </FleetIntelHeaderContainer>
  );

  return (
    <BasicWidget title={intelHeader} noHeaderStyling>
      <FleetIntelContainer>
        <FleetMap
          data={fleetIntelQuery.data?.filteredQuery.vehicleCountByCountry.vehiclesByCountry}
          isLoading={fleetIntelQuery.isLoading}
          selectedCountry={selectedCountry}
          onCountrySelected={setSelectedCountry}
        />
        {!fleetIntelQuery.isLoading && (
          <FleetCountryTable
            total={fleetIntelQuery.data?.filteredQuery.vehicleCountByCountry.totalVehicles}
            data={fleetIntelQuery.data?.filteredQuery.vehicleCountByCountry.vehiclesByCountry}
            isLoading={fleetIntelQuery.isLoading}
            selectedCountry={selectedCountry}
            onCountrySelected={setSelectedCountry}
          />
        )}
      </FleetIntelContainer>
    </BasicWidget>
  );
};
