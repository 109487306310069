import styled from "styled-components";

export const AnomalyDetectorPreviewCardsContainer = styled.div`
  height: 100%;
  &.selected-anomaly {
    min-height: 550px;
  }
`;

export const AnomalyModalContainer = styled.div`
  width: 100%;
`;

export const AnomalyModalStyle = styled.div`
  padding: 2rem;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(222, 222, 222, 0.6);
  &:hover {
    cursor: pointer;
  }
  .ant-card {
    cursor: auto;
  }
  .ant-card-head {
    padding: 0px;
  }
  .ant-card-head-title {
    padding: 0px;
    border-bottom: 1px solid #dedede;
  }
  .ant-card-body {
    background: #f9fafe;
  }
`;

export const AnomalyDetectorEmptyList = styled.div`
  overflow: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 0.75rem;
  background-color: ${({ theme }) => theme.colors.defaultWhite};
  padding: 1.5rem;
`;
