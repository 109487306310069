// Load stylesheets
import "unfonts.css";
import "./styles/App.less";

import { createRouter, ParseRoute, RouterProvider } from "@tanstack/react-router";

import { ApiClientProvider } from "./contexts/ApiClientProvider";
import { AuthenticationProvider } from "./contexts/AuthenticationProvider";
// Import the generated route tree
import { routeTree } from "./routeTree.gen";

export type ValidAppRoute = ParseRoute<typeof routeTree>["fullPath"];

// Create a new router instance
const router = createRouter({ routeTree });

// Register the router instance for type safety
declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

function App() {
  return (
    <AuthenticationProvider>
      <ApiClientProvider>
        <RouterProvider router={router} />
      </ApiClientProvider>
    </AuthenticationProvider>
  );
}

export default App;
