import { ExpressionEvent, SavedExpression } from "@/api";
import FromNowFormatter from "@/components/ui/FromNowFormatter";

import { ExpressionEventContainer } from "./ExpressionEventListItem.styled";

type ExpressionEventSelection = Pick<ExpressionEvent, "id" | "name" | "created"> & {
  creator?: { name: string } | null;
  expression: Pick<SavedExpression, "id" | "status">;
};
type ExpressionEventListItemProps = {
  event: ExpressionEventSelection;
  onClick?: () => void;
  selected?: boolean;
};

export const ExpressionEventListItem = (props: ExpressionEventListItemProps) => {
  const { event, onClick, selected } = props;
  return (
    <ExpressionEventContainer className={selected ? "selected" : ""} key={event.id} onClick={onClick}>
      <div className="expression-header">
        <p className="label-normal">{event.name}</p>
        <p className="body-medium">{event.creator ? event.creator.name : "Unknown"}</p>
      </div>
      <div className="expression-data">
        <p className="body-small">{event.expression.status}</p>
        <p className="body-small">
          Created <FromNowFormatter value={new Date(Date.parse(event.created!))} />
        </p>
      </div>
    </ExpressionEventContainer>
  );
};
