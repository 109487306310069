import { ExpressionIntervalType } from "../generated/graphql";
import { AggregationType, CaseExpression, Expression, ExpressionType } from "./types";

export enum ValuesLength {
  Unary,
  Binary,
  Ternary,
  Arbitrary,
  NotRequired,
}

export const valuesLength = {
  [ExpressionType.Aggregation]: ValuesLength.Unary,

  // Numeric Expressions
  [ExpressionType.LiteralNumeric]: ValuesLength.NotRequired,
  [ExpressionType.Add]: ValuesLength.Binary,
  [ExpressionType.Subtract]: ValuesLength.Binary,
  [ExpressionType.Multiply]: ValuesLength.Binary,
  [ExpressionType.Divide]: ValuesLength.Binary,
  [ExpressionType.Abs]: ValuesLength.Unary,

  // Boolean expressions
  [ExpressionType.Gt]: ValuesLength.Binary,
  [ExpressionType.Gte]: ValuesLength.Binary,
  [ExpressionType.Lt]: ValuesLength.Binary,
  [ExpressionType.Lte]: ValuesLength.Binary,
  [ExpressionType.Eq]: ValuesLength.Binary,
  [ExpressionType.Neq]: ValuesLength.Binary,
  [ExpressionType.And]: ValuesLength.Binary,
  [ExpressionType.Or]: ValuesLength.Binary,
  [ExpressionType.IsNull]: ValuesLength.Unary,
  [ExpressionType.IsNotNull]: ValuesLength.Unary,
  [ExpressionType.Not]: ValuesLength.Unary,

  //Any
  [ExpressionType.If]: ValuesLength.Ternary,
  [ExpressionType.Signal]: ValuesLength.NotRequired,
  [ExpressionType.Pattern]: ValuesLength.NotRequired,
  [ExpressionType.AnomalyScore]: ValuesLength.NotRequired,
  [ExpressionType.Case]: ValuesLength.NotRequired,
  [ExpressionType.Null]: ValuesLength.NotRequired,
};

const validateValues = (type: keyof typeof ExpressionType, values: any, path: string[]) => {
  const valuesLengthType = valuesLength[type];
  if (valuesLengthType === ValuesLength.NotRequired && !!values) {
    throw new Error(`Unexpected values array at [${path.join(", ")}]`);
  }

  if (valuesLengthType === ValuesLength.NotRequired) {
    return;
  }

  if (!Array.isArray(values)) {
    throw new Error(`Expected array at [${path.join(", ")}]`);
  }

  if (valuesLength === undefined) {
    throw new Error(`Values validation not supported for type ${type}`);
  }
  if (valuesLengthType === ValuesLength.Unary && values.length !== 1) {
    throw new Error(`Expected array at [${path.join(", ")}] contain a single element`);
  }
  if (valuesLengthType === ValuesLength.Binary && values.length !== 2) {
    throw new Error(`Expected array at [${path.join(", ")}] contain 2 elements`);
  }
  if (valuesLengthType === ValuesLength.Ternary && values.length !== 3) {
    throw new Error(`Expected array at [${path.join(", ")}] contain 3 elements`);
  }
  if (valuesLengthType === ValuesLength.Arbitrary && values.length === 0) {
    throw new Error(`Expected array at [${path.join(", ")}] contain at least 1 element`);
  }

  values.forEach((e, i) => validateExpression(e!, [...path, i.toString()]));
};

export const validExpressionFromJsonOrThrow = (expressionDefinition: unknown): Expression => {
  validateExpression(expressionDefinition);
  return expressionDefinition as Expression;
};

const hasExpressionType = (node: unknown): node is Partial<Expression> => {
  return Object.values(ExpressionType).includes((node as any).type);
};

export function validateExpression(node: unknown, path: string[] = []): asserts node is Expression {
  if (typeof node !== "object" || node === null || node === undefined) {
    throw new Error(`Node must be an object at [${path.join(", ")}].`);
  }
  if (!hasExpressionType(node)) {
    throw new Error(`Node missing type at [${path.join(", ")}].`);
  }

  validateValues(node.type!, (node as any).values, [...path, "values"]);

  if (node.type === ExpressionType.Signal) {
    if (!node.value && !node.value) {
      throw new Error("Node signal missing value.");
    }
    if (typeof node.value !== "string") {
      throw new Error("Node signal value must be a string.");
    }
  }
  if (node.type === ExpressionType.AnomalyScore || node.type === ExpressionType.Pattern) {
    if (!node.value) {
      throw new Error(`Node ${node.type} missing value.`);
    }
    if (typeof node.value !== "string") {
      throw new Error(`Node ${node.type} must be a string.`);
    }
  }

  if (node.type === ExpressionType.LiteralNumeric) {
    if (typeof node.value !== "number" || isNaN(node.value)) {
      throw new Error("Node constant invalid value");
    }
  }

  if (node.type === ExpressionType.Null) {
    if (node.value !== null && node.value !== undefined) {
      throw new Error("Node null invalid value");
    }
  }

  if (node.type === ExpressionType.Case) {
    const when = (node as Partial<CaseExpression>).when;
    const then = (node as Partial<CaseExpression>).then;
    const elseExpression = (node as Partial<CaseExpression>).else;

    if (!Array.isArray(when) || when.length < 1) {
      throw new Error("Node case must have at least 1 item in when");
    }
    if (!Array.isArray(then) || then.length < 1) {
      throw new Error("Node case have at least 1 item in then");
    }

    if (when.length !== then.length) {
      throw new Error("Node case must have same number of when and then expressions");
    }

    when.forEach((e, i) => validateExpression(e!, [...path, "when", i.toString()]));
    then.forEach((e, i) => validateExpression(e!, [...path, "then", i.toString()]));
    validateExpression(elseExpression!, [...path, "else"]);
  }

  if (node.type === ExpressionType.Aggregation) {
    if (!Object.values(AggregationType).includes(node.aggregationType as any)) {
      throw new Error("Node aggregation has invalid aggregation type");
    }
    if (!Object.values(ExpressionIntervalType).includes(node.intervalType as any)) {
      throw new Error("Node aggregation has invalid interval type");
    }

    if (typeof node.intervalDuration !== "number" || isNaN(node.intervalDuration)) {
      throw new Error("Node aggregation has invalid interval duration");
    }

    if (node.aggregationType === AggregationType.Percentile) {
      const { percentile } = node;
      if (typeof percentile !== "number" || isNaN(percentile) || percentile <= 0 || percentile > 100) {
        throw new Error(
          "Node aggregation with aggregationType Percentile has invalid percentile. Valid is float > 0 and <= 100"
        );
      }
    }
  }
}
