import { createFileRoute } from "@tanstack/react-router";

import { ClusterHome } from "@/features/cluster/clusterHome/ClusterHome";
import AppMainLayout from "@/layouts/AppMainLayout";

export type ClusterHomeSearch = {
  clustersPage?: number;
};

export const Route = createFileRoute("/cluster/")({
  component: () => (
    <AppMainLayout>
      <ClusterHome />
    </AppMainLayout>
  ),
  validateSearch: (search: Record<string, unknown>): ClusterHomeSearch => {
    return {
      clustersPage: +(search.clustersPage as number) || undefined,
    };
  },
});
