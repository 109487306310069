import { Flex, Layout, Space } from "antd";
import styled from "styled-components";

export const Container = styled(Layout.Content)`
  padding: 32px;
`;

export const PageContentSpace = styled(Space)`
  width: 100%;
`;

export const TitleHeaderContainer = styled(Flex)`
  justify-content: space-between;
  align-items: flex-end;
  gap: 4rem;
  margin-bottom: 16px;
`;
