import { useNavigate, useParams } from "@tanstack/react-router";

import { SavedExpressionEditor } from "@/components/savedExpression/SavedExpressionEditor";

const SavedExpressionEditorPage = () => {
  const { patternId } = useParams({ strict: false });

  const navigate = useNavigate();

  return (
    <SavedExpressionEditor
      expressionId={patternId}
      afterCreate={(pattern) => navigate({ to: "/pattern/$patternId", params: { patternId: pattern.id } })}
    />
  );
};

export default SavedExpressionEditorPage;
