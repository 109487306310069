import { Expression, TimeSeriesExpressionType } from "@lib/src/expression/types";
import l from "lodash";
import { PartialDeep } from "type-fest";

import {
  ExpressionAnomaly,
  SavedExpression,
  useExpressionAnomalyByIdQuery,
  useGetSavedExpressionByIdQuery,
} from "@/api";
import { requireCurrentCustomerId } from "@/utils/customers";

import { useFoundDependenciesWithStatusCalculating } from "./useTimeSeriesOptionsForSavedExpression";
export const useFindUnavailableDefinitions = ({ rootExpression }: { rootExpression: PartialDeep<Expression> }) => {
  /**
   * Check if the expression formula is a pattern
   */
  const isRootExpressionPattern =
    !l.isEmpty(rootExpression) &&
    (rootExpression.type === TimeSeriesExpressionType.Pattern ||
      rootExpression.type === TimeSeriesExpressionType.AnomalyScore);

  const rootExpressionValue = isRootExpressionPattern ? rootExpression.value ?? "" : "";
  /**
   * Fetch the root expression if it is a pattern or anomaly detector
   * to check if the expression is calculating
   */

  const { data: savedExpressionCalculating } = useGetSavedExpressionByIdQuery(
    {
      customerId: requireCurrentCustomerId(),
      expressionId: rootExpressionValue,
    },
    {
      enabled: rootExpressionValue != "" && rootExpression.type === TimeSeriesExpressionType.Pattern,
    }
  );
  const { data: savedADCalculating } = useExpressionAnomalyByIdQuery(
    {
      customerId: requireCurrentCustomerId(),
      id: rootExpressionValue,
    },
    {
      enabled: rootExpressionValue != "" && rootExpression.type === TimeSeriesExpressionType.AnomalyScore,
    }
  );

  /**
   * Get the calculating expression if it exists to put it in the info message
   */
  const patternDefinition: SavedExpression | ExpressionAnomaly =
    (savedExpressionCalculating?.savedExpressions?.savedExpression as SavedExpression) ??
    (savedADCalculating?.expressionAnomalies?.expressionAnomaly as ExpressionAnomaly);

  /**
   * Get pattern or anomaly detector id
   */
  const patternId = patternDefinition ? patternDefinition.id : "";

  /**
   * Find if there are unavailable patterns
   */
  const patternsAreNotAvailable = useFoundDependenciesWithStatusCalculating({
    id: patternId,
  });

  return {
    patternDefinition,
    patternsAreNotAvailable,
  };
};
