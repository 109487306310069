import { PropsWithChildren, useContext } from "react";

import { FeatureId } from "@/api";
import { CustomerContext } from "@/contexts/CustomerProvider";

export interface FeatureFlags {
  isFeatureEnabled(id: FeatureId): boolean;
  // TODO: Would be nice to infer config type with descriminators
  getFeatureConfig<T>(id: FeatureId): T;
}

export function useFeatureFlags(): FeatureFlags {
  const customer = useContext(CustomerContext);
  const features = customer.currentCustomer?.features;
  return {
    isFeatureEnabled(id: FeatureId): boolean {
      return !!features?.some((f) => f.id == id);
    },
    getFeatureConfig<T>(id: FeatureId): T {
      return features?.find((f) => f.id == id)?.config as T;
    },
  };
}

export function RequireFeatureFlag({ id, children }: PropsWithChildren<{ id: FeatureId }>) {
  const featureFlags = useFeatureFlags();

  if (featureFlags.isFeatureEnabled(id)) {
    return <>{children}</>;
  }
  return <></>;
}
