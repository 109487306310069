import React from "react";

import { CalculatedStatus, ExpressionEvent } from "@/api";
import { EventFilter } from "@/api/customerApi";

import EventStats from "../EventStats";

export const EventHeaderExpression: React.FC<{
  eventFilter: EventFilter;
  expression?: ExpressionEvent;
}> = ({ eventFilter, expression }) => {
  const isUnavailable = expression?.status != CalculatedStatus.Available;

  return (
    <>
      <h1 className="heading-normal header-event-label">
        <span>Pattern Event</span>
      </h1>
      <div className="header-event-data">
        <div className="event-info-container">
          <div className="header-data-section">
            <p className="body-small">Name</p>
            <div className="body-medium dark">{expression?.name ?? "-"}</div>
          </div>
        </div>
        <EventStats event={eventFilter} {...{ isUnavailable }} />
      </div>
    </>
  );
};
