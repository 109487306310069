import { Tooltip } from "antd";
import React, { PropsWithChildren } from "react";

type StyledTooltipProps = PropsWithChildren<{
  title: React.ReactNode | string;
  showArrow?: boolean;
}>;

const StyledTooltip = ({ title, children, showArrow = true }: StyledTooltipProps) => {
  return (
    <Tooltip
      title={title}
      arrow={showArrow}
      overlayInnerStyle={{ textAlign: "center", fontWeight: 600, padding: "0.5rem 0.75rem" }}
    >
      {children}
    </Tooltip>
  );
};

export default StyledTooltip;
