import styled from "styled-components";

export const AnomalyDetectorHomeContainer = styled.div.attrs({ className: "card" })`
  margin: 1rem;
  padding: 1rem;
  .anomaly-detectors-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  .anomaly-detectors-body {
    margin: 1rem 0;
  }
`;

export const AnomalyDetectorListContainer = styled.div`
  width: 100%;
  background-color: aliceblue;
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0px 16px 16px #00000012;
`;
