import { Modal } from "antd";
import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { DensityFunction } from "@/api";
import DensityCurveEditor from "@/components/forecast/DensityCurveEditor";
import { ForecastActions } from "@/contexts/ForecastSlice";
import { ForecastSelector, selectDiscountUnit, selectEditingDensityFunction } from "@/contexts/ForecastStore";

import { DensityCurveEditorContainer } from "./Forecast.styled";

const ForecastDensityFunctionEditor = () => {
  const activeDiscountDensityFunction = useSelector((state: ForecastSelector) => selectEditingDensityFunction(state));
  selectDiscountUnit;
  const forecastDiscountUnit = useSelector((state: ForecastSelector) => selectDiscountUnit(state));
  const editedCurveRef = useRef<DensityFunction>({ y0: 0, points: [] });
  const dispatch = useDispatch();

  const modalOpen = !!activeDiscountDensityFunction;
  const densityFunctionKey = `${activeDiscountDensityFunction?.i}_${activeDiscountDensityFunction?.j}`;

  const handleCancel = () => {
    dispatch(ForecastActions.endEditingDensityFn());
  };

  const handleOk = () => {
    dispatch(
      ForecastActions.saveDiscountDensityFn({
        newDistribution: editedCurveRef.current,
        discountCoord: [activeDiscountDensityFunction!.i, activeDiscountDensityFunction!.j],
      })
    );
  };

  const handleEdit = (densityFunction: DensityFunction) => {
    editedCurveRef.current = densityFunction;
  };

  if (modalOpen && activeDiscountDensityFunction.func) {
    editedCurveRef.current = activeDiscountDensityFunction.func.distribution!;
  }

  return (
    <Modal title="Distribute discount" centered open={modalOpen} onOk={handleOk} onCancel={handleCancel}>
      {modalOpen && (
        <DensityCurveEditorContainer>
          <DensityCurveEditor
            densityFunction={activeDiscountDensityFunction.func.distribution!}
            chartKey={densityFunctionKey}
            onEdit={handleEdit}
            functionUnit={forecastDiscountUnit}
          />
        </DensityCurveEditorContainer>
      )}
    </Modal>
  );
};

export default ForecastDensityFunctionEditor;
