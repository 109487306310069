/**
 *
 * @param value Any kind of value to check if is a array
 * @returns a boolean
 */
export const checkIfIsObject = (value: any): boolean => typeof value === "object" && value !== null;

/**
 *
 * @param value Any kind of value to check if is a array
 * @returns a boolean
 */
export const checkIfIssArray = (value: any): boolean => Array.isArray(value);
