import { SearchOutlined } from "@ant-design/icons";
import { Input } from "antd";
import _ from "lodash";
import { useState } from "react";

import { Forecast, useForecastsQuery } from "@/api";
import BlockingLoading from "@/components/ui/BlockLoading";
import { QUERY_SETTINGS, UI_SETTINGS } from "@/constants";
import ForecastList from "@/features/forecast/ForecastList";
import { ForecastPickerContainer } from "@/features/forecast/ForecastPicker.styled";
import { requireCurrentCustomerId } from "@/utils/customers";

interface ForecastPickerProps {
  onForecastSelected: (forecast: Forecast) => void;
}

export default function ForecastPicker({ onForecastSelected }: ForecastPickerProps) {
  const { isLoading, isRefetching, isError, data } = useForecastsQuery(
    { customerId: requireCurrentCustomerId() },
    { staleTime: QUERY_SETTINGS.SHORT_STALE_TIME }
  );

  const [searchTerm, setSearchTerm] = useState("");

  let forecastList = (data?.forecasts?.forecasts || []) as Forecast[];

  const onForecastSearch = _.debounce((searchTerm: string) => {
    setSearchTerm(searchTerm);
  }, UI_SETTINGS.SEARCH_INPUT_DEBOUNCE);

  if (searchTerm.length > 0) {
    forecastList = forecastList.filter((r) => {
      return r.name.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0;
    });
  }

  if (isLoading) return <BlockingLoading className="line" />;

  if (isError) return <p>Forecasts unavailable</p>;

  return (
    <ForecastPickerContainer>
      <Input
        className="search-forecast"
        placeholder="Search for forecasts"
        onChange={(e) => onForecastSearch(e.target.value)}
        suffix={<SearchOutlined />}
      />
      <ForecastList forecasts={forecastList} onElementSelected={onForecastSelected} />
      {isRefetching && <BlockingLoading className="line" />}
    </ForecastPickerContainer>
  );
}
