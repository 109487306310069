import { useHomeQuery } from "@/api";
import { TopHeadingWithButtons } from "@/components/pageContent/TopHeadingWithButtons";
import VehicleTable from "@/components/tables/VehicleTable";
import { BasicWidget } from "@/components/widget/BasicWidget";
import { QUERY_SETTINGS } from "@/constants";
import { getCurrentCustomerId } from "@/utils/customers";

const VehicleHome = () => {
  const customerId = getCurrentCustomerId();
  const query = useHomeQuery({}, { staleTime: QUERY_SETTINGS.SHORT_STALE_TIME });

  const customer = query.data?.userSettings?.customerSettings?.find((customer) => customer.customerId === customerId);

  const recentVehiclesData = customer?.recentDetailVehicles?.items || undefined;

  return (
    <>
      <TopHeadingWithButtons
        title="Vehicles"
        description="Please search for a PVIN or select from table below to see data for selected PVIN"
      />
      <BasicWidget className="table" title="Recently Viewed">
        <VehicleTable data={recentVehiclesData} isLoading={query.isLoading} />
      </BasicWidget>
    </>
  );
};

export default VehicleHome;
