import { EChartsReactProps } from "echarts-for-react";

import { BaseEChart } from "@/components/ui/BaseEChart";
import { buildTimeSeriesChartConfig } from "@/features/chartElementLibrary/ChartLibraryBuildUtils";

type ExpressionTimeSeriesPreviewProps = {
  loading: boolean;
  data?: {
    x: number[];
    y: (number | null)[];
    min: number[];
    max: number[];
    vehicleCount: number[];
  };
};

export const ExpressionTimeSeriesPreview = (props: ExpressionTimeSeriesPreviewProps) => {
  const { loading, data: { x = [], y = [], min = [], max = [], vehicleCount = [] } = {} } = props;
  const chartConfig: EChartsReactProps = buildTimeSeriesChartConfig(x, y, min, max, vehicleCount);
  return (
    <div>
      <h3>
        <b>Time Series (Median + Standard Deviation)</b>
      </h3>
      <BaseEChart {...chartConfig} style={{ height: 500 }} showLoading={loading} notMerge />
    </div>
  );
};
