import { message } from "antd";
import ReactECharts from "echarts-for-react";
import { EChartsInstance } from "echarts-for-react";
import { forwardRef, useImperativeHandle, useRef, useState } from "react";

import { FeatureId } from "@/api";
import { SingleVehicleAnomalyDetector } from "@/api/customerApi";
import { useTimeSeriesFields } from "@/hooks/useTimeSeriesFields";
import { useFeatureFlags } from "@/utils/features";

import { displayableInputFields } from "../displayableInputFields";
import { get90DayHistory } from "../summary/get90DayHistory";
import { getLast90DaysIndex, getLast90DaysIndexFromMostRecentDate } from "../summary/getLast90DaysIndex";
import { buildAnomalySignalsWeightsChartConfig } from "./buildAnomalySignalsWeightsChartConfig";
import { Coord } from "./Coord";
import { labelCodec } from "./labelCodec";
import { LinkButton } from "./LinkButton";
import { WeightChartClickArgs } from "./WeightChartClickArgs";

export const SignalWeightChart = forwardRef(function SignalWeightChart(
  props: {
    anomaly: SingleVehicleAnomalyDetector;
    onPlot?: (anomaly: SingleVehicleAnomalyDetector) => void;
    onSignalSelected?: (fieldId: string) => void;
  },
  ref
) {
  const axisLabelRef = useRef<string>();
  const { anomaly, onPlot, onSignalSelected } = props;
  const [hoverCoord, setHoverCoord] = useState<Coord>();
  const {
    vehicleData: { fieldsWeights },
    detector: { warningThreshold },
  } = anomaly;

  useImperativeHandle(ref, () => {
    return {
      setHoverCoord: (hoverCoord: Coord) => setHoverCoord(hoverCoord),
    };
  });

  const { isFeatureEnabled } = useFeatureFlags();

  const { byId } = useTimeSeriesFields();
  const inputFields = displayableInputFields(anomaly, byId);
  const ninetyDayHistory = get90DayHistory(
    anomaly,
    isFeatureEnabled(FeatureId.AnomalyDetectorHistoryFromLastDataReceived)
      ? getLast90DaysIndexFromMostRecentDate(anomaly)
      : getLast90DaysIndex(anomaly)
  );
  const x = hoverCoord?.x ?? ninetyDayHistory.x.length - 1;

  const inputsWithWeights = inputFields.map((inputField) => ({
    id: inputField.id,
    label: inputField.friendlyName,
    value: fieldsWeights?.find((fw) => inputField.id.toLowerCase().includes(fw.id.toLowerCase()))?.values[x] || 0, //TODO: Verify fw.id is a substring of inputField.id
  }));

  const anomalyScores = ninetyDayHistory.y[0];
  const lastAnomalyScore = anomalyScores[anomalyScores.length - 1];

  const isAnomalous = hoverCoord?.y
    ? hoverCoord.y >= warningThreshold
    : (lastAnomalyScore ?? anomaly.detector.warningThreshold) >= warningThreshold;

  const signalsWeightChartOptions = buildAnomalySignalsWeightsChartConfig(inputsWithWeights, isAnomalous, axisLabelRef);

  const onWeightsChartClick = (params: WeightChartClickArgs) => {
    const weightValueString =
      params.componentType == "series" ? params.name : params.componentType == "yAxis" ? params.value : undefined;
    if (weightValueString && onSignalSelected) {
      const fieldId = labelCodec.decodeId(weightValueString);
      onSignalSelected(fieldId);
    }
  };

  const handlePlotAnomalyFields = (anomaly: SingleVehicleAnomalyDetector) => {
    if (onPlot) {
      message.info(`Plotting below...`);
      onPlot(anomaly);
    }
  };
  return (
    <>
      <ReactECharts
        {...signalsWeightChartOptions}
        notMerge={true}
        onEvents={{
          click: onWeightsChartClick,
          mousemove: (e: { componentType: string; value: string; event: MouseEvent }, c: EChartsInstance) => {
            if (e.componentType === "yAxis" && e.value) {
              const code = e.value.split(",")[0];
              axisLabelRef.current = code;
              c.dispatchAction({
                type: "showTip",
                seriesIndex: 0,
                dataIndex: 0,
                position: [e.event.offsetX, e.event.offsetY - 55],
              });
            } else {
              axisLabelRef.current = undefined;
            }
          },
          mouseout: () => (axisLabelRef.current = undefined),
        }}
      />
      <LinkButton style={{ marginLeft: "3px" }} onClick={() => handlePlotAnomalyFields(anomaly)}>
        Plot All Signals
      </LinkButton>
    </>
  );
});
