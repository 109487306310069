import styled from "styled-components";

export const BaseEChartEmptyWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.defaultWhite};
  z-index: 2;
`;
