import { Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";

import { RepairConcept } from "@/api";
import { ForecastActions } from "@/contexts/ForecastSlice";
import { ForecastSelector } from "@/contexts/ForecastStore";

import { SimpleDensityCurve } from "./ForecastHelper";
import RepairConceptPicker from "./RepairConceptPicker";

interface RepairConceptPickerDialogProps {
  onAddOrRemoveRepairConcept: () => void;
}

const RepairConceptPickerDialog = ({ onAddOrRemoveRepairConcept }: RepairConceptPickerDialogProps) => {
  const forecastState = useSelector((state: ForecastSelector) => state.forecast);
  const selectingRepairConcept = forecastState.selectingRepairConcept;
  const repairConceptsInForecast = (forecastState.forecast?.repairConcepts || []).map((r) => r.repairConcept.id);
  const dispatch = useDispatch();

  const modalOpen = selectingRepairConcept ? true : false;

  const cancelHandler = () => {
    dispatch(ForecastActions.toggleRepairConceptPicker());
  };

  const repairConceptSelectedHandler = (repair: RepairConcept) => {
    const newRepair = { ...repair };
    newRepair.cost.distribution = {
      ...newRepair.cost.distribution,
    };
    const newThreshold = { id: repair.id, value: 0.17 };
    const newDiscount = {
      id: "",
      y0: 1,
      points: JSON.parse(JSON.stringify(SimpleDensityCurve)),
    };
    dispatch(
      ForecastActions.addRepairConcept({
        repair: newRepair,
        threshold: newThreshold,
        discount: newDiscount,
      })
    );
    dispatch(ForecastActions.toggleRepairConceptPicker());
    onAddOrRemoveRepairConcept();
  };

  return (
    <Modal title="Select repair concept" centered open={modalOpen} onCancel={cancelHandler} footer={false}>
      {modalOpen && (
        <RepairConceptPicker
          onRepairConceptSelected={repairConceptSelectedHandler}
          disabledElementIds={repairConceptsInForecast}
        />
      )}
    </Modal>
  );
};

export default RepairConceptPickerDialog;
