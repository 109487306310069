import { uniq } from "lodash";

import { Expression, ExpressionType } from "./types";

type ReferencedTimeSeriesField = { type: keyof typeof ExpressionType; value: string };

export const toFieldIdFromSignalId = (signalId: string) => `t:${signalId}`;
export const toFieldIdFromPatternId = (patternId: string) => `c:${patternId}`;

export const expressionReferencedTimeSeriesFields = (expression: Expression): ReferencedTimeSeriesField[] => {
  const expressionSignalsVisitor = (expression: Expression): ReferencedTimeSeriesField[] => {
    const { type } = expression;

    if (type == ExpressionType.Signal) {
      return [{ type, value: expression.value }];
    } else if (type == ExpressionType.LiteralNumeric || type == ExpressionType.Null) {
      return [];
    } else if (type == ExpressionType.Case) {
      return [...expression.when, ...expression.then, expression.else].flatMap((e) => expressionSignalsVisitor(e));
    } else if (type == ExpressionType.AnomalyScore || type == ExpressionType.Pattern) {
      return [{ type, value: expression.value }];
    } else {
      return [...(expression.values || []).flatMap((v) => expressionSignalsVisitor(v))];
    }
  };
  return uniq(expressionSignalsVisitor(expression));
};
