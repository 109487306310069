import { AnomalySeverity } from "@/api/customerApi";

import { ANOMALY_CAUTION_COLOR, ANOMALY_SAFE_COLOR, ANOMALY_WARNING_COLOR } from "./anomalyColors";

export const anomalySeverityToColor = (severity: AnomalySeverity) => {
  return severity == AnomalySeverity.Warning
    ? ANOMALY_WARNING_COLOR
    : severity == AnomalySeverity.Caution
    ? ANOMALY_CAUTION_COLOR
    : ANOMALY_SAFE_COLOR;
};
