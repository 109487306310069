import styled from "styled-components";

export const HealthCheckHeaderContainer = styled.div`
  margin: 1.5rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
  .health-check-inputs {
    text-align: center;
  }
  .health-check-result {
    font-size: 2rem;
    &.success {
      color: ${({ theme }) => theme.colors.success};
    }
    &.error {
      color: ${({ theme }) => theme.colors.error};
    }
  }
`;

export const HealthCheckBodyContainer = styled.div`
  margin: 1.5rem 0;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;
