import { MutableRefObject } from "react";

type anomalyHistogramPreviewUtilityFunctionsProps = {
  chartRef: MutableRefObject<any>;
  min: number[];
  max: number[];
  pct: number[];
};
export const anomalyHistogramPreviewUtilityFunctions = ({
  chartRef,
  min,
  max,
}: anomalyHistogramPreviewUtilityFunctionsProps) => {
  const getChartValues = () => {
    const chart = chartRef.current;
    if (!chart) return { chartZeroXPixel: 0, chartZeroYPixel: 0, dragBarOffset: 0, laneWidth: 0, finalXPixel: 0 };
    const laneWidth = Math.abs(chart.convertToPixel("grid", [0, 0])[0] - chart.convertToPixel("grid", [1, 0])[0]);
    const [chartZeroXPixel, chartZeroYPixel]: number[] = chart.convertToPixel("grid", [0, 0]);
    const finalXPixel: number = chart.convertToPixel("grid", [max.length - 1, 0])[0];
    const dragBarOffset = laneWidth / 2;
    return { chartZeroXPixel, chartZeroYPixel, dragBarOffset, laneWidth, finalXPixel };
  };

  const getBarStartPos = (barIndex: number) => {
    if (!chartRef.current) return 0;
    const { laneWidth } = getChartValues();
    return chartRef.current.convertToPixel("grid", [barIndex, 0])[0] - laneWidth / 2;
  };

  const getValuePerPixel = (barIndex: number) => {
    if (!chartRef.current) return 0;
    if (max[barIndex] === min[barIndex]) return 0;
    const { laneWidth } = getChartValues();
    const barStart = chartRef.current.convertToPixel("grid", [barIndex, 0])[0] - laneWidth / 2;
    const barEnd = barStart + laneWidth;
    return (max[barIndex] - min[barIndex]) / (barEnd - barStart);
  };

  const getPixelPosOfValue = (value: number) => {
    let barIndex = 0;
    while (max[barIndex] < value) {
      barIndex++;
    }
    barIndex = Math.min(min.length - 1, barIndex);
    const valuePerPixel = getValuePerPixel(barIndex);
    const pixelsFromBarStart = (value - min[barIndex]) / (valuePerPixel || 1);
    return getBarStartPos(barIndex) + pixelsFromBarStart;
  };

  const getBarIndex = (xValue: number) => {
    if (!chartRef.current) return 0;
    const xPixel = getPixelPosOfValue(xValue);
    return chartRef.current.convertFromPixel("grid", [xPixel, 0])[0];
  };

  const findExactValueFromBarName = (barName: string) => {
    if (!chartRef.current) return 0;
    const graphic = chartRef.current.getOption().graphic[0].elements.find((g: any) => g.id === barName);
    const xPos = graphic.position[0];
    return findExactValue(xPos);
  };

  const findExactValue = (xPos: number) => {
    if (!chartRef.current) return 0;
    const { laneWidth } = getChartValues();
    const barIndex = Math.min(Math.max(0, chartRef.current.convertFromPixel("grid", [xPos, 0])[0]), max.length - 1);
    const barStart = chartRef.current.convertToPixel("grid", [barIndex, 0])[0] - laneWidth / 2;
    const valuePerPixel = getValuePerPixel(barIndex);
    return (xPos - barStart) * valuePerPixel + min[barIndex];
  };

  const focusTableInput = (barId: string) => {
    const input = document.getElementById(`threshold-${barId}`);
    if (input) input.focus();
  };

  return {
    getChartValues,
    getBarIndex,
    getBarStartPos,
    getPixelPosOfValue,
    getValuePerPixel,
    findExactValue,
    findExactValueFromBarName,
    focusTableInput,
  };
};

export const constrainHistogramToolTip = (
  point: [number, number],
  size: { contentSize: [number, number]; viewSize: [number, number] }
) => {
  const offsetX = 10;
  const offsetY = 10;
  let x = point[0] + offsetX;
  let y = point[1] + offsetY;

  if (x + size.contentSize[0] > size.viewSize[0]) {
    x = point[0] - size.contentSize[0] - offsetX;
  }

  return [x, y];
};
