import { CloseCircleOutlined, PlusSquareOutlined, RiseOutlined } from "@ant-design/icons";
import { Button, InputNumber } from "antd";
import _ from "lodash";
import { useDispatch, useSelector } from "react-redux";

import { DiscountDensityFunction } from "@/api";
import DensityCurveThumbnail from "@/components/forecast/DensityCurveThumbnail";
import { UI_SETTINGS } from "@/constants";
import { ForecastActions } from "@/contexts/ForecastSlice";
import { ForecastSelector } from "@/contexts/ForecastStore";

import { ForecastMatrixContainer } from "./ForecastMatrix.styled";

const MAX_MATRIX_REPAIR_CONCEPTS = 4;

interface ForecastMatrixProps {
  onAddOrRemoveRepairConcept: () => void;
}

const ForecastMatrix = ({ onAddOrRemoveRepairConcept }: ForecastMatrixProps) => {
  const forecastModel = useSelector((state: ForecastSelector) => state.forecast);

  const dispatch = useDispatch();

  function openEditModal(i: number, j: number, fn: DiscountDensityFunction) {
    dispatch(ForecastActions.startEditingDensityFn({ i, j, fn }));
  }

  const openRepairConceptPicker = () => {
    dispatch(ForecastActions.toggleRepairConceptPicker());
  };

  const removeRepairConcept = (i: number) => {
    dispatch(ForecastActions.removeRepairConcept({ i }));
    onAddOrRemoveRepairConcept();
  };

  const thresholdChangeHandler = _.debounce((newThreshold: number, repairIndex: number) => {
    dispatch(ForecastActions.updateRepairConceptThreshold({ newThreshold, repairIndex }));
  }, UI_SETTINGS.STANDARD_INPUT_DEBOUNCE);

  const optimizeThresholds = () => {
    //TODO optimize action dispatch
  };

  return (
    <ForecastMatrixContainer>
      <div className="forecast-matrix-editor">
        {(forecastModel.forecast?.repairConcepts || []).map((repair, i, repairs) => {
          const firstRow = i === 0;
          const firstRowClass = firstRow ? " first-row" : "";
          const rowClass = `matrix-row repair-concept-row${firstRowClass}`;

          return (
            <div className={rowClass} key={repair.repairConcept.id}>
              <p className="body-small repair-name">{repair.repairConcept.name}</p>
              <div className="row-group">
                {firstRow && <p className="group-header label-normal">Threshold</p>}
                <InputNumber
                  defaultValue={repair.threshold}
                  min={0}
                  max={1}
                  step={0.01}
                  formatter={(value) => `${Math.round((value ?? 0) * 100)}%`}
                  parser={(value) => Number(value!.replace("%", "")) / 100}
                  onChange={(value) => thresholdChangeHandler(value || 0, i)}
                />
              </div>
              <div className="row-group">
                {firstRow && <p className="group-header label-normal">Discount Effects</p>}
                <div className="discount-effects-group">
                  {repairs.map((siblingRepair, j) => {
                    const currentDiscount = forecastModel.forecast.discounts[i][j];
                    const discountkey = `${siblingRepair.repairConcept.id}_${j}`;
                    return (
                      <div className="row-subgroup" key={discountkey}>
                        {firstRow && <p className="group-header body-small">{siblingRepair.repairConcept.name}</p>}
                        <DensityCurveThumbnail
                          densityFunction={currentDiscount.distribution!!}
                          onClick={() => openEditModal(i, j, currentDiscount)}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>

              <CloseCircleOutlined className="remove-repair-concept-btn" onClick={() => removeRepairConcept(i)} />
            </div>
          );
        })}
      </div>
      <div className="matrix-controls-container">
        {forecastModel.forecast?.repairConcepts?.length > 1 && (
          <Button type="text" className="text-button" onClick={optimizeThresholds}>
            Optimize Thresholds
            <RiseOutlined />
          </Button>
        )}
        <Button
          type="text"
          className="text-button"
          onClick={openRepairConceptPicker}
          disabled={forecastModel.forecast?.repairConcepts?.length === MAX_MATRIX_REPAIR_CONCEPTS}
        >
          Add Repair Concept
          <PlusSquareOutlined />
        </Button>
      </div>
    </ForecastMatrixContainer>
  );
};

export default ForecastMatrix;
