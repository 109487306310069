import { Select } from "antd";
import styled from "styled-components";

export const StyledSelect = styled(Select)`
  &.ant-select {
    min-width: 215px;
    font-family: "Inter";
    font-size: 14px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.darkGray};
    .ant-select-selector {
      border-radius: 0.25rem;
      padding: 0.75rem 1rem;
    }
  }
`;
