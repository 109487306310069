import styled from "styled-components";

export const LibraryItemContainer = styled.div`
  border: 1px solid #ccc;
  background-color: #f9fafe;
  margin: 1rem 0;
  :first-child {
    margin-top: 0;
  }
  :last-child {
    margin-bottom: 0;
  }
  /* margin: 0.5rem 0; */
  border-radius: 0.5rem;
  &.expanded {
    .item-signal-header {
      border-radius: 0.5rem 0.5rem 0 0;
    }
  }
`;

export const LibraryItemHeaderContainer = styled.div`
  border-radius: 0.5rem;
  background-color: #fff;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem 1rem;
  .header-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.5rem;
    .plotted-series-icon {
      width: 10px;
      height: 10px;
      border-radius: 50%;
    }
    .signal-plotter-header-icon {
      font-size: 1rem;
    }
    .library-item-switch {
      width: 2rem;
    }
  }
`;

export const LibraryItemBodyContainer = styled.div`
  .field-container {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    // overwrite antd form row and item control
    .ant-form-item-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }
    .ant-form-item-control {
      flex: none;
    }
    .plotter-selector-form-item,
    .plotter-switch-form-item {
      margin: 0;
    }
    .plotter-switch {
      width: 36px;
    }
  }
`;
