import styled from "styled-components";

export const VehicleDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 1rem;
  min-height: calc(100vh - 65px);
  .detail-header-container {
    background-color: white;
    padding: 1rem 0rem;
    border-radius: 12px;
  }
  .categorical-data-container {
    display: flex;
    gap: 1rem;
    align-items: center;
    justify-content: space-evenly;
    .categorical-data-section {
      padding: 0 1rem;
      &:not(:first-of-type) {
        border-left: 2px solid #dcdcdc;
      }
    }
  }
  .vehicle-charts-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .driving-charts-container {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      gap: 1rem;
    }
    .driving-charts-container-charging-disabled {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      gap: 1rem;
    }
  }
`;
