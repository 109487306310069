import { useContext } from "react";

import { useVehicleTemperatureStatsQuery } from "@/api/customerApi";
import GlobalFilterContext from "@/contexts/GlobalFilterContext";
import SCVContext from "@/contexts/SCVContext";

import { BaseEChart } from "../ui/BaseEChart";
import { BasicWidget } from "../widget/BasicWidget";
import { createBoxPlotOptions } from "./BoxPlotHelpers";

export const VehicleAmbientTemperatureBoxPlot = () => {
  const { globalFilter } = useContext(GlobalFilterContext);
  const { pvin } = useContext(SCVContext);
  const { data, isLoading } = useVehicleTemperatureStatsQuery({ vehicleId: pvin, filter: globalFilter });

  return (
    <BasicWidget title="Ambient Temperature vs. Fleet">
      <BaseEChart
        showLoading={isLoading}
        option={createBoxPlotOptions(data?.filteredQuery.vehicleTemperatureStats, isLoading, "ºC")}
      />
    </BasicWidget>
  );
};
