import { DEFAULT_PAGE_SIZE } from "@lib/src/DEFAULT_PAGE_SIZE";
import { Link } from "@tanstack/react-router";
import { Button } from "antd";
import React, { Dispatch } from "react";

import { FeatureId } from "@/api";
import { BasicTable } from "@/components/tables/BasicTable";
import { useFeatureFlags } from "@/utils/features";

import { ClusterModalView } from "./ClusterModal";

type AffectedVehiclesProps = {
  pvins: string[];
  setView: Dispatch<React.SetStateAction<ClusterModalView>>;
};

type AffectedVehicleFooterProps = {
  onCancel: () => void;
  createNewCluster: () => void;
};

export const AffectedVehiclesFooter = ({ onCancel, createNewCluster }: AffectedVehicleFooterProps) => {
  const { isFeatureEnabled } = useFeatureFlags();
  return isFeatureEnabled(FeatureId.Clusters) ? (
    <div style={{ display: "flex", gap: "0.5rem", justifyContent: "flex-end" }}>
      <Button onClick={onCancel}>Cancel</Button>
      <Button type="primary" onClick={createNewCluster}>
        Create New Cluster
      </Button>
    </div>
  ) : null;
};
export const AffectedVehicles = ({ pvins }: AffectedVehiclesProps) => {
  return (
    <>
      <BasicTable
        columns={[
          {
            key: "pvin",
            title: "PVIN",
            dataIndex: "pvin",
            render: (_: any, row: { pvin: string }) => {
              return (
                <Link to="/vehicle/$pvin" params={{ pvin: row.pvin }}>
                  {row.pvin}
                </Link>
              );
            },
          },
        ]}
        rowKey={"pvin"}
        dataSource={pvins.map((pvin) => ({ pvin })) ?? []}
        pagination={
          (pvins.length ?? 0) > DEFAULT_PAGE_SIZE
            ? { pageSize: DEFAULT_PAGE_SIZE, style: { padding: "5px" }, showSizeChanger: false }
            : false
        }
      />
    </>
  );
};
