import styled from "styled-components";

export const GlobalFilterContainer = styled.div`
  div:hover {
    cursor: pointer;
  }
  div {
    width: 160px;
    padding: 0.75rem 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    border: 1px solid #dcdcdc;
    border-radius: 0.75rem;
  }
  .global-filter-active {
    border: 1px solid ${({ theme }) => theme.colors.accentBlue};
  }
`;

export const SelectContainers = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  .form-inputs {
    width: 100%;
    .select-container {
      margin-bottom: 1rem;
      width: 100%;
    }
  }
  .ant-select {
    width: 180px;
    min-height: 45px;
    font-family: "Inter";
    font-size: 14px;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.darkGray};
    .ant-select-selector {
      svg {
        fill: white;
      }
      border-radius: 0.75rem;
      height: 100%;
      .ant-select-selection-item {
        height: 95%;
        background-color: ${({ theme }) => theme.colors.accentBlue};
        color: white;
        border-radius: 4px;
      }
    }
  }
`;

export const FooterWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  button {
    margin-left: 5px;
  }
`;
