import { useLocation } from "@tanstack/react-router";
import { useEffect, useState } from "react";

import { abortRequests, ROUTE_CHANGE_ABORT } from "./AbortController";

export const RouteChageDetector = () => {
  const [previousLocation, setPreviousLocation] = useState<string>();
  const location = useLocation();

  useEffect(() => {
    if (previousLocation && previousLocation !== location.pathname) {
      setPreviousLocation(location.pathname);
      abortRequests(ROUTE_CHANGE_ABORT);
    } else if (!previousLocation) {
      setPreviousLocation(location.pathname);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return <></>;
};
