import React from "react";

import Loading from "../loading";
import { LoadingContainer } from "./LoadingWrapper.styled";

export const LoadingWrapper: React.FC<{ children: React.ReactNode; isLoading: boolean; showLoadingMask?: boolean }> = ({
  children,
  isLoading,
  showLoadingMask = true,
}) => {
  return (
    <LoadingContainer className="loading-wrapper">
      {isLoading && (
        <>
          {showLoadingMask ? <div className="loading-mask" /> : undefined}
          <div className="loading-spinner">
            <Loading />
          </div>
        </>
      )}
      {children}
    </LoadingContainer>
  );
};
