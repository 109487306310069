import styled from "styled-components";

export const ExpressionEventContainer = styled.div`
  margin: 0.5rem 0;
  padding: 0.5rem;
  border-radius: 5px;
  border: 2px solid #dcdcdc;
  cursor: pointer;
  .expression-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .expression-data {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  &:hover {
    background-color: #f9fafe;
  }
  &.selected {
    border: 2px solid #333;
  }
`;
