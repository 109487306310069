import React from "react";

import { Cluster } from "@/api";
import { EventFilter, FaultFingerprint } from "@/api/customerApi";
import { contentWithInlineLoading } from "@/components/ui/JsxUtils";
import { useCustomerIdentifier } from "@/hooks/useCustomerIdentifier";
import { getFaultDescription } from "@/utils/fault/getFaultDescription";

import EventStats from "../EventStats";
import { FaultFingerprintHeaderInfoProps } from "./EventHeaderWidgetTypes";

export const buildFaultAnalysisComposedUrl = (code: string, ecu?: string, troubleCode?: string) => {
  let resultUrl = `/fault/${code}`;
  if (ecu) {
    resultUrl = `${resultUrl}?ecu=${ecu}`;
  }
  if (troubleCode) {
    resultUrl = `${resultUrl}&troubleCode=${troubleCode}`;
  }
  return resultUrl;
};

const FaultFingerprintHeaderInfo = ({
  showCluster,
  clusterLoading,
  cluster,
  eventFilter,
}: FaultFingerprintHeaderInfoProps) => {
  const { ecu, softwareVersion, softwarePartNumber, hardwarePartNumber, hardwareVersion, troubleCode } =
    eventFilter as FaultFingerprint;
  return (
    <div className="event-info-container">
      {ecu && (
        <div className="header-data-section">
          <p className="body-small">ECU</p>
          <p className="body-medium dark">{ecu}</p>
        </div>
      )}
      {softwareVersion && (
        <div className="header-data-section">
          <p className="body-small">Software Version</p>
          <p className="body-medium dark">{softwareVersion}</p>
        </div>
      )}
      {hardwareVersion && (
        <div className="header-data-section">
          <p className="body-small">Hardware Version</p>
          <p className="body-medium dark">{hardwareVersion}</p>
        </div>
      )}
      {softwarePartNumber && (
        <div className="header-data-section">
          <p className="body-small">Software Part Number</p>
          <p className="body-medium dark">{softwarePartNumber}</p>
        </div>
      )}
      {hardwarePartNumber && (
        <div className="header-data-section">
          <p className="body-small">Hardware Part Number</p>
          <p className="body-medium dark">{hardwarePartNumber}</p>
        </div>
      )}
      {troubleCode && (
        <div className="header-data-section">
          <p className="body-small">Trouble Code</p>
          <p className="body-medium dark">{troubleCode}</p>
        </div>
      )}
      {showCluster && (
        <div className="header-data-section">
          <p className="body-small">Cluster</p>
          <div className="body-medium dark">{contentWithInlineLoading(clusterLoading, cluster?.name ?? "")}</div>
        </div>
      )}
    </div>
  );
};

export const EventHeaderFault: React.FC<{
  eventFilter: EventFilter;
  cluster?: Cluster;
}> = ({ eventFilter, cluster }) => {
  const { customerIdentifier } = useCustomerIdentifier();
  const description = getFaultDescription(customerIdentifier, eventFilter.fault?.faultCode ?? "");
  return (
    <>
      <h1 className="heading-normal header-event-label">
        <span>Fault Fingerprint</span>
        <span>{`${eventFilter.fault?.faultCode.toUpperCase()}${
          description?.name ? ` - ${description.name}` : ""
        }`}</span>
      </h1>
      <div className="header-event-data">
        <FaultFingerprintHeaderInfo
          showCluster={!!cluster}
          cluster={cluster}
          clusterLoading={false}
          eventFilter={eventFilter.fault as FaultFingerprint}
        />
        <EventStats event={eventFilter} />
      </div>
    </>
  );
};
