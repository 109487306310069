import React from "react";

import EventStats from "../EventStats";
import { RepairEventDescriptions, RepairEventNames } from "./EventHeaderConstants";
import { EventHeaderWidgetProps } from "./EventHeaderWidgetTypes";

export const EventHeaderRepair: React.FC<EventHeaderWidgetProps> = ({ eventFilter }) => {
  const { repairEventType } = eventFilter;

  return (
    <>
      <h1 className="heading-normal header-event-label">
        <span>Repair Event</span>
      </h1>

      <div className="header-event-data">
        <div className="event-info-container">
          <div className="header-data-section">
            <p className="body-small">{RepairEventNames[repairEventType!]}</p>
            <p className="body-medium dark">{RepairEventDescriptions[repairEventType!]}</p>
          </div>
        </div>
        <EventStats event={eventFilter} />
      </div>
    </>
  );
};
