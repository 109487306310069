import { AnchorHTMLAttributes, DetailedHTMLProps } from "react";
import styled from "styled-components";

export type LinkButtonProps = Omit<
  DetailedHTMLProps<AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement>,
  "ref"
>;
export const LinkButton = ({ children, ...props }: LinkButtonProps) => {
  const StyledButton = styled.a`
    &:hover {
      color: #aaccff;
    }
    color: #0094ff;
    font-size: 12px;
  `;
  return <StyledButton {...props}>{children}</StyledButton>;
};
