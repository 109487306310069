import { validateExpression } from "./expressionValidator";
import { Expression } from "./types";

export const isExpressionValid = (expression: Partial<Expression>) => {
  try {
    validateExpression(expression);
    return true;
  } catch {
    return false;
  }
};
