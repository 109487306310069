import styled from "styled-components";

export const DatabaseStatusStyled = styled.div`
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  .element {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 0.75rem;
    height: 49px;
    padding: 1rem 1.5rem;
    background-color: ${({ theme }) => theme.colors.defaultWhite};
  }
`;
