import { ErrorBoundary } from "@sentry/react";
import { Card } from "antd";
import ReactECharts, { EChartsInstance } from "echarts-for-react";
import { useRef } from "react";

import { FeatureId } from "@/api";
import {
  SingleVehicleAnomalyDetector,
  SingleVehicleAnomalyDetectorHeaders,
  useSingleVehicleAnomalyDetectorQuery,
} from "@/api/customerApi";
import { ErrorFallback } from "@/components/error/ErrorFallBack";
import Loading from "@/components/loading/Loading";
import { useFeatureFlags } from "@/utils/features";

import { AnomalyCardHeadersTitle } from "../../common/AnomalyCardTitle";
import { buildAnomalyChartConfig } from "../../common/buildAnomalyChartConfig";
import { handleAnomalyChartMouseOut } from "../../common/handleAnomalyChartMouseOut";
import { handleAnomalyChartMouseOver } from "../../common/handleAnomalyChartMouseOver";
import { get90DayHistory } from "../expanded/summary/get90DayHistory";
import { getLast90DaysIndex, getLast90DaysIndexFromMostRecentDate } from "../expanded/summary/getLast90DaysIndex";
import { AnomalyDetectorPreviewCardWrapper } from "./AnomalyDetectorPreviewCard.styled";

type AnomalyDetectorPreviewCardProps = {
  detector: SingleVehicleAnomalyDetectorHeaders;
  pvin: string;
  onClick: (detector: SingleVehicleAnomalyDetector) => any;
};

export const AnomalyDetectorPreviewCard = ({ pvin, detector, onClick }: AnomalyDetectorPreviewCardProps) => {
  return (
    <ErrorBoundary fallback={({ error }) => <ErrorFallback error={error} />}>
      <AnomalyDetectorPreviewCardContent {...{ pvin, detector, onClick }} />
    </ErrorBoundary>
  );
};

const AnomalyDetectorPreviewCardContent = ({ pvin, detector, onClick }: AnomalyDetectorPreviewCardProps) => {
  const anomalyChartRef = useRef<EChartsInstance>();

  const { data, isLoading } = useSingleVehicleAnomalyDetectorQuery(
    {
      pvin,
      type: detector.type,
      id: detector.id,
    },
    { retry: false }
  );

  const { isFeatureEnabled } = useFeatureFlags();

  const fullyLoadedDetector = data?.singleVehicleAnomalyDetector;

  const ninetyDayHistory = fullyLoadedDetector
    ? get90DayHistory(
        fullyLoadedDetector,
        isFeatureEnabled(FeatureId.AnomalyDetectorHistoryFromLastDataReceived)
          ? getLast90DaysIndexFromMostRecentDate(fullyLoadedDetector)
          : getLast90DaysIndex(fullyLoadedDetector)
      )
    : undefined;

  const scoreHistoryChartOptions =
    fullyLoadedDetector && ninetyDayHistory
      ? buildAnomalyChartConfig(
          ninetyDayHistory,
          fullyLoadedDetector.detector.cautionThreshold,
          fullyLoadedDetector.detector.warningThreshold,
          true
        )
      : undefined;
  return (
    <AnomalyDetectorPreviewCardWrapper
      onClick={() => {
        if (fullyLoadedDetector) onClick(fullyLoadedDetector);
      }}
    >
      <Card title={<AnomalyCardHeadersTitle anomaly={detector} />} style={{ border: "none" }}>
        {isLoading ? (
          <Loading />
        ) : (
          scoreHistoryChartOptions &&
          fullyLoadedDetector && (
            <ReactECharts
              {...scoreHistoryChartOptions}
              style={{ height: "60px" }}
              ref={anomalyChartRef}
              onChartReady={(c) => (anomalyChartRef.current = c)}
              onEvents={{
                mousemove: (e: any) => {
                  if (fullyLoadedDetector)
                    handleAnomalyChartMouseOver(e, ninetyDayHistory, fullyLoadedDetector, anomalyChartRef);
                },
                mouseout: () => {
                  if (fullyLoadedDetector) handleAnomalyChartMouseOut(anomalyChartRef.current);
                },
              }}
            />
          )
        )}
      </Card>
    </AnomalyDetectorPreviewCardWrapper>
  );
};
