import styled from "styled-components";

export const FallbackPageContainer = styled.div`
  min-height: 100vh;
  background: #f0f0f0;
  padding: 1rem;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .ant-divider-vertical {
    border: 1px solid #a8a8a8;
    height: 78px;
  }
  p {
    margin: 6px 0px;
    text-align: start;

    svg {
      fill: #a8a8a8;
    }
  }
  .fallback-larger-text {
    font-size: larger;
  }
`;

export const FallbackPageReloadContainer = styled.div`
  color: ${(props) => props.theme.colors.accentBlue};
  cursor: pointer;
  &:hover {
    color: ${(props) => props.theme.colors.hoverBlue};
  }
`;
