import EditIcon from "@amayaIcons/icon-edit.svg";
import { Button, Form } from "antd";
import { useCallback, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";

import { useTimeSeriesFieldsQuery } from "@/api";
import { AggregateType, ErrorType, useEventTimeSeriesHistoryQuery } from "@/api/customerApi";
import { QUERY_SETTINGS } from "@/constants";
import { useChartColoringPool } from "@/contexts/ChartColoringPoolContext";
import { EventAnalysisChartActions } from "@/contexts/EventAnalysisChartSlice";
import { selectAnalysisSignals, selectEventFilter, selectZoom } from "@/contexts/EventAnalysisChartStore";
import GlobalFilterContext from "@/contexts/GlobalFilterContext";
import { getSignalFriendlyName } from "@/features/chartElementLibrary/ChartLibraryBuildUtils";
import { PlottedSignalIcon } from "@/features/chartElementLibrary/LibraryChartToolbox";
import { ChartToolboxContainer } from "@/features/chartElementLibrary/LibraryChartToolbox.styled";
import { findIntervalSize } from "@/utils/arrayUtils";
import { getCurrentCustomerId } from "@/utils/customers";
import { toTitleCase } from "@/utils/toTitleCase";

import TimeSeriesChart, { ChartField } from "../timeSeries/TimeSeriesChart";
import { BasicWidget } from "../widget/BasicWidget";
import { EventTimeSeriesContainer } from "./EventAnalysisGraph.styled";
import EventTimeSeriesSettings from "./EventTimeSeriesSettings";

const DEFAULT_MAX = 2000;
const DEFAULT_MIN = DEFAULT_MAX * -1;
const INTERVAL_SIZES = [1, 2, 5, 10, 20];

export const EventAnalysisGraph = () => {
  const dispatch = useDispatch();

  const { globalFilter } = useContext(GlobalFilterContext);

  const coloringPoolCtx = useChartColoringPool();

  const { signals: stateSignals } = useSelector(selectAnalysisSignals);
  const { selectedZoom } = useSelector(selectZoom);
  const { selectedEventFilter } = useSelector(selectEventFilter);

  const eventFilter = selectedEventFilter || {};

  const handleZoom = (zoom?: { min: number; max: number }) => {
    dispatch(EventAnalysisChartActions.setZoom({ zoom }));
  };

  const toggleDrawer = () => {
    dispatch(EventAnalysisChartActions.toggleDrawer());
  };

  const { data: timeSeriesFieldsData } = useTimeSeriesFieldsQuery(
    { customerId: getCurrentCustomerId() ?? "" },
    { staleTime: QUERY_SETTINGS.LONG_STALE_TIME }
  );

  const selectedSignals = stateSignals.map((s) => {
    return {
      ...s,
      name: getSignalFriendlyName(s.id, timeSeriesFieldsData?.timeSeriesFields ?? []),
    };
  });

  const min = selectedZoom?.min ?? DEFAULT_MIN;
  const max = selectedZoom?.max ?? DEFAULT_MAX;
  // Pick the smallest interval size such that the number of intervals is <= 500
  const intervalSize = findIntervalSize(INTERVAL_SIZES, max - min, 500);
  const windowSize = intervalSize * 50;

  const { data: timeSeriesQueryData, isLoading } = useEventTimeSeriesHistoryQuery(
    {
      eventFilter: eventFilter,
      selectors: selectedSignals.map((s) => {
        return {
          fieldId: s.id,
          aggregateType: s.fieldInput.aggregateType,
          errorType: s.fieldInput.errorType,
        };
      }),
      fieldIds: selectedSignals.map((s) => s.fieldInput.id),
      minIndependentValue: min,
      maxIndependentValue: max,
      independentWindowSize: windowSize,
      independentInterval: intervalSize,
      filter: globalFilter,
    },
    { enabled: !!selectedSignals.length && !!selectedEventFilter, staleTime: QUERY_SETTINGS.DEFAULT_STALE_TIME }
  );

  const chartLoading = isLoading && !!selectedSignals.length;

  const data = timeSeriesQueryData?.filteredQuery.eventTimeSeriesHistory;
  const summaryData = timeSeriesQueryData?.filteredQuery.vehicleCohortTimeSeriesSummary;

  const chartFields: ChartField[] = selectedSignals.map((signal) => ({
    fieldId: signal.fieldInput.id,
    displayName: getSignalFriendlyName(signal.fieldInput.id, timeSeriesFieldsData?.timeSeriesFields ?? []),
    color: coloringPoolCtx?.acquireColor(signal.fieldInput.id),
  }));

  const getSummaryData = useCallback(
    (i: number): [number?, number?, number?] => {
      const d = summaryData?.[i];
      const signal = selectedSignals[i];
      if (!d || !signal) {
        return [undefined, undefined, undefined];
      }
      const errorType = signal.fieldInput.errorType;
      const aggregateType = signal.fieldInput.aggregateType;
      let low: number | undefined;
      let mid: number | undefined;
      let high: number | undefined;
      switch (errorType) {
        case ErrorType.Quartile:
          low = d.bottomQuartile ?? undefined;
          high = d.topQuartile ?? undefined;
          break;
        case ErrorType.StdDev:
          if (
            d.median !== null &&
            d.median !== undefined &&
            d.standardDeviation !== null &&
            d.standardDeviation !== undefined
          ) {
            low = d.median - d.standardDeviation;
            high = d.median + d.standardDeviation;
          }
          break;
        case ErrorType.Vigintile:
          low = d.bottomVigintile ?? undefined;
          high = d.topVigintile ?? undefined;
          break;
        case ErrorType.MinMax:
          low = d.min ?? undefined;
          high = d.max ?? undefined;
      }
      switch (aggregateType) {
        case AggregateType.Mean:
          mid = summaryData?.[i].mean ?? undefined;
          break;
        case AggregateType.Median:
          mid = summaryData?.[i].median ?? undefined;
          break;
      }
      return [low, mid, high];
    },
    [summaryData, selectedSignals]
  );

  return (
    <Form layout="vertical">
      <BasicWidget title={toTitleCase("Aggregate time series signals")}>
        <EventTimeSeriesContainer>
          <div className="chart-and-legend">
            <ChartToolboxContainer>
              <div className="plotted-elements-container">
                {selectedSignals.map((s) => {
                  return (
                    <div key={s.id} className="signal-element">
                      <PlottedSignalIcon color={coloringPoolCtx?.acquireColor(s.fieldInput.id)} />
                      <p className="signal-name body-x-small dark">{s.name}</p>
                    </div>
                  );
                })}
              </div>
              <div className="chart-tools-container">
                <Button
                  type="text"
                  className="text-button"
                  onClick={() => handleZoom(undefined)}
                  disabled={!selectedZoom}
                >
                  Reset Zoom
                </Button>
                <Button type="text" className="text-button" onClick={() => toggleDrawer()}>
                  <EditIcon />
                </Button>
              </div>
            </ChartToolboxContainer>
            <TimeSeriesChart
              selectedSignals={selectedSignals}
              fields={chartFields}
              data={data}
              onZoom={handleZoom}
              getSummaryData={getSummaryData}
              isLoading={chartLoading}
              windowSize={windowSize}
            />
          </div>
          <EventTimeSeriesSettings selectedSignals={selectedSignals} />
        </EventTimeSeriesContainer>
      </BasicWidget>
    </Form>
  );
};
