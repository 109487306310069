import styled from "styled-components";

export const ForecastMatrixContainer = styled.div`
  padding: 1rem 0;
  margin: 1rem 0;
  border-bottom: 1px solid #ccc;
  .forecast-matrix-editor {
    max-width: 1360px;
    margin: 0 auto;
    .matrix-row {
      padding: 0.5rem;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      .row-group {
        align-self: unset;
        text-align: center;
        .group-header {
          text-align: center;
          margin-bottom: 1rem;
        }
      }
    }
    .repair-concept-row {
      .discount-effects-group {
        display: flex;
        gap: 0.5rem;
      }
      .repair-name {
        min-width: 175px;
      }
      .remove-repair-concept-btn {
        font-size: 18px;
        cursor: pointer;
        padding: 1rem;
      }
      // TODO better way to align first row elements
      &.first-row {
        .row-group {
          align-self: stretch;
        }
        .repair-name {
          margin-top: 70px;
        }
        .cost-row {
          .density-curve-thumbnail {
            margin-top: 50px;
          }
        }
        .ant-input-number {
          margin-top: 55px;
        }
        .anticon {
          margin-top: 75px;
        }
      }
    }
  }
  .matrix-controls-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 1rem 0;
  }
`;
