import { Input, Radio, Select, SelectProps } from "antd";
import _ from "lodash";
import { useContext, useState } from "react";

import { RepairEventType, useGetExpressionEventsQuery } from "@/api";
import { ExpressionEventListItem } from "@/components/expressionEvent/ExpressionEventListItem";
import BlockingLoading from "@/components/ui/BlockLoading";
import { QUERY_SETTINGS, UI_SETTINGS } from "@/constants";
import EditRepairConceptContext from "@/contexts/EditRepairConceptProvider";
import { requireCurrentCustomerId } from "@/utils/customers";

import { NewRepairConceptDetailsContainer } from "./NewRepairConceptDetails.styled";

const getAvailableRepairTypes = () => {
  let result: SelectProps[] = [];
  result = Object.values(RepairEventType).map((type) => {
    return {
      value: type,
      label: type,
    };
  });
  return result;
};

enum RepairConceptTriggerType {
  eventExpression = "eventExpression",
  repairEvent = "repairEvent",
}

// TODO check selector of repair event inside radio button
const NewRepairConceptDetails = () => {
  const { isLoading, data } = useGetExpressionEventsQuery(
    { customerId: requireCurrentCustomerId() },
    { staleTime: QUERY_SETTINGS.SHORT_STALE_TIME }
  );

  const newRepairCtx = useContext(EditRepairConceptContext);
  const [repairTriggerType, setRepairTriggerType] = useState<RepairConceptTriggerType>(
    newRepairCtx.newRepair.repairEventType
      ? RepairConceptTriggerType.repairEvent
      : RepairConceptTriggerType.eventExpression
  );

  const availableExpressionEvents = data?.expressionEvents?.expressionEvents ?? [];

  const nameChangeHandler = _.debounce((nameStr: string) => {
    newRepairCtx.setNewRepairData({
      ...newRepairCtx.newRepair,
      name: nameStr,
    });
  }, UI_SETTINGS.STANDARD_INPUT_DEBOUNCE);

  const expressionSelectedHandler = (expressionEventId: string) => {
    newRepairCtx.setNewRepairData({
      ...newRepairCtx.newRepair,
      triggerExpressionEventId: expressionEventId,
      repairEventType: undefined,
    });
  };

  const repairEventTypeChangeHandler = (eventType: string) => {
    newRepairCtx.setNewRepairData({
      ...newRepairCtx.newRepair,
      repairEventType: eventType as RepairEventType,
      triggerExpressionEventId: undefined,
    });
  };

  const handleRepairTriggerChange = (type: RepairConceptTriggerType) => {
    setRepairTriggerType(type);
    newRepairCtx.setNewRepairData({
      ...newRepairCtx.newRepair,
      repairEventType: undefined,
      triggerExpressionEventId: undefined,
    });
  };

  return (
    <NewRepairConceptDetailsContainer>
      <div className="repair-concept-name-container repair-input-section">
        <span className="heading-xx-small dark">Name your repair concept </span>
        <Input
          className="repair-concept-name"
          defaultValue={newRepairCtx.newRepair.name}
          onChange={(e) => nameChangeHandler(e.target.value)}
        />
      </div>
      <div className="repair-concept-expression-container repair-input-section">
        <Radio.Group
          className="repair-trigger-option-container"
          onChange={(e) => handleRepairTriggerChange(e.target.value)}
          defaultValue={repairTriggerType}
        >
          <Radio value={RepairConceptTriggerType.eventExpression}>
            <span className="trigger-label heading-xx-small dark">Select an expression Event </span>
          </Radio>
          {isLoading && <BlockingLoading className="line" />}
          {/* TODO move to a separate expression-selector component */}
          {repairTriggerType === RepairConceptTriggerType.eventExpression && (
            <div className="available-expressions-container">
              {availableExpressionEvents.map((event) => {
                return (
                  <ExpressionEventListItem
                    key={event.id}
                    event={event}
                    onClick={() => expressionSelectedHandler(event.id)}
                    selected={newRepairCtx.newRepair.triggerExpressionEventId === event.id}
                  />
                );
              })}
            </div>
          )}
          <br></br>
          <Radio value={RepairConceptTriggerType.repairEvent}>
            <span className="trigger-label heading-xx-small dark">Pick from a repair type </span>
          </Radio>
          <br></br>
          {repairTriggerType === RepairConceptTriggerType.repairEvent && (
            <Select
              className="repair-trigger-selector"
              placeholder="Select a Repair type"
              defaultValue={newRepairCtx.newRepair.repairEventType}
              onChange={repairEventTypeChangeHandler}
              options={getAvailableRepairTypes()}
            />
          )}
        </Radio.Group>
      </div>
    </NewRepairConceptDetailsContainer>
  );
};

export default NewRepairConceptDetails;
