import { TimeSeriesField } from "../../generated/graphql";
import { formatToPermanentId } from "../formatExpressionIds";
import { circularPathsForItem } from "./circularPathsForItem";
import { ItemsWithCircularInputs, ItemsWithInputs, ItemWithInputs, PathsOfCircularInputs } from "./types";

type FindItemsWithCircularInputs = (items: ItemsWithInputs) => ItemsWithCircularInputs;

/**
 * Formats the series inputs based on the provided time series fields, expression ID, and current input IDs.
 *
 * @param {TimeSeriesField[]} timeSeriesFields - The array of time series fields to format.
 * @param {string} expressionId - The ID of the expression.
 * @param {string[]} currentInputIds - The current input IDs.
 * @return {Array<{ id: string, inputIds: string[] }>} The formatted series inputs.
 */
export const formatSeriesInputs = (
  timeSeriesFields: TimeSeriesField[],
  expressionId: string,
  currentInputIds: string[]
) => {
  const getTimeSeriesInputId = (id: string): string => {
    const timeSeriesField = timeSeriesFields.find((field) => field.id.includes(id));
    return timeSeriesField ? timeSeriesField.entityId ?? timeSeriesField.id : "";
  };
  return timeSeriesFields.flatMap((i) => ({
    id: getTimeSeriesInputId(i.id),
    inputIds: (i.id.includes(expressionId) ? currentInputIds : i.inputIds).map((input) =>
      getTimeSeriesInputId(formatToPermanentId(input))
    ),
  }));
};

/**
 * Finds items with circular inputs in the provided list of all items.
 *
 * @param {ItemWithInputs[]} allItems - The list of all items to search for circular inputs.
 * @return {ItemWithCircularInputs[]} The list of items with circular inputs.
 */
export const findItemsWithCircularInputs: FindItemsWithCircularInputs = (allItems) =>
  allItems
    .map((item) => ({ item, circularPaths: circularPathsForItem(item, allItems) }))
    .filter(({ circularPaths }) => circularPaths.length);

export type CircularPathsForItem = (item: ItemWithInputs, allItems: ItemsWithInputs) => PathsOfCircularInputs;
