import { createFileRoute } from "@tanstack/react-router";

import { EventType } from "@/api";
import EventAnalyzer from "@/features/event/EventAnalyzer";
import AppMainLayout from "@/layouts/AppMainLayout";

import { EventAnalysisSearch } from "./event-analysis";

export type FaultEventAnalysisSearch = {
  ecu?: string;
  troubleCode?: string;
  softwareVersion?: string;
  hardwareVersion?: string;
  softwarePartNumber?: string;
  hardwarePartNumber?: string;
};

export const Route = createFileRoute("/fault/$faultCodeId")({
  component: () => (
    <AppMainLayout>
      <EventAnalyzer eventType={EventType.Fault} />
    </AppMainLayout>
  ),
  validateSearch: (search: Record<string, unknown>): FaultEventAnalysisSearch | EventAnalysisSearch => ({
    xEventAnalysisChart: (search.xEventAnalysisChart as string) || undefined,
    ecu: (search.ecu as string) || undefined,
    troubleCode: (search.troubleCode as string) || undefined,
    softwareVersion: (search.softwareVersion as string) || undefined,
    hardwareVersion: (search.hardwareVersion as string) || undefined,
    softwarePartNumber: (search.softwarePartNumber as string) || undefined,
    hardwarePartNumber: (search.hardwarePartNumber as string) || undefined,
    eventOccurrencesPage: (search.eventOccurrencesPage as number) || undefined,
    relatedEventsPage: (search.relatedEventsPage as number) || undefined,
  }),
});
