import { RepairConcept } from "@/api";
import FromNowFormatter from "@/components/ui/FromNowFormatter";

import { RepairConceptListContainer } from "./RepairConceptList.styled";

interface RepairConceptListProps {
  repairs: RepairConcept[];
  onElementSelected: (element: RepairConcept) => void;
  alreadySelectedIds?: string[];
}

const RepairConceptList = ({ repairs, onElementSelected, alreadySelectedIds = [] }: RepairConceptListProps) => {
  if (repairs.length === 0) {
    return <p className="body-medium no-repairs-found">No repairs found!</p>;
  }

  return (
    <RepairConceptListContainer>
      {/* TODO put already selected repair concepts at the bottom of list */}
      {repairs.map((r) => {
        const repairAlreadyPicked = alreadySelectedIds.findIndex((id) => id === r.id) >= 0;
        const itemClass = `repair-list-item${repairAlreadyPicked ? " unavailable" : ""}`;
        return (
          <div key={r.id} className={itemClass} onClick={() => onElementSelected(r)}>
            <div className="concept-header">
              <p className="label-normal">{r.name}</p>
              <p className="body-medium">{r.creator.name}</p>
            </div>
            <div className="concept-data">
              <p className="body-small">Repair cost: {r.cost.totalCost} USD</p>
              <p className="body-small">
                v{r.version} - Created <FromNowFormatter value={new Date(Date.parse(r.created!))} />
              </p>
            </div>
          </div>
        );
      })}
    </RepairConceptListContainer>
  );
};

export default RepairConceptList;
