import { EChartsInstance } from "echarts-for-react";
import { Dispatch, SetStateAction } from "react";

import { Coord } from "../card/expanded/inputs/Coord";

export const handleAnomalyChartMouseOut = (
  chart: EChartsInstance,
  setHoverCoord?: Dispatch<SetStateAction<Coord | undefined>>
) => {
  setHoverCoord?.(undefined);
  chart?.setOption?.({
    graphic: [
      {
        type: "circle",
        position: [-10, -10],
        shape: {
          cx: 0,
          cy: 0,
          r: 5,
        },
        z: 10,
      },
    ],
  });
};
