import { useCreateExpressionAnomalyMutation } from "@/api";
import { requireCurrentCustomerId } from "@/utils/customers";

import { AfterCreate } from "./AfterCreate";
import { AnomalyBarValues } from "./AnomalyHistogramPreview/AnomalyHistogramPreview";

export type CreateExpressionAnomaly = ReturnType<typeof useCreateExpressionAnomalyMutation>;
export const createDetector = (
  barValues: AnomalyBarValues,
  expressionId: string,
  name: string,
  description: string,
  createExpressionAnomaly: CreateExpressionAnomaly,
  setCreatingAnomaly: (newVal: boolean) => any,
  afterCreate?: AfterCreate
) => {
  return new Promise((resolve, reject) => {
    const variables = {
      customerId: requireCurrentCustomerId(),
      expressionAnomaly: {
        threshold_lower: barValues.lowerNormal,
        threshold_upper: barValues.upperNormal,
        threshold_caution: barValues.cautionBar,
        threshold_warning: barValues.warningBar,
        expressionId,
        name,
        description,
      },
    };
    createExpressionAnomaly.mutate(variables, {
      onSettled: () => {
        setCreatingAnomaly(false);
      },
      onError: reject,
      onSuccess: (a) => {
        const savedAnomaly = a.expressionAnomalies?.createExpressionAnomaly;
        if (savedAnomaly) {
          afterCreate?.({ id: savedAnomaly.id, name: savedAnomaly.name });
          //TODO: Cache invalidation
          resolve(savedAnomaly);
        }
      },
    });
  });
};
