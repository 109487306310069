import { DataSourceStatusInfo } from "@/api/customerApi";
import { parseDateToStr } from "@/utils/dateUtils";

import i18next from "../../i18n";
import Loading from "../loading";
import { DatabaseStatusStyled } from "./DatabaseStatus.styled";

interface DatabaseStatusProps {
  data?: DataSourceStatusInfo[];
  isLoading: boolean;
}

export default function DatabaseStatus({ data, isLoading }: DatabaseStatusProps) {
  if (isLoading) {
    return <Loading height={256} />;
  }

  return (
    <DatabaseStatusStyled>
      {data?.map((ds) => {
        // use the i18n mapping for data source when it exists
        const dataSource =
          i18next.t(`dataSource.${ds.dataSource}`) === `dataSource.${ds.dataSource}`
            ? ds.dataSource
            : i18next.t(`dataSource.${ds.dataSource}`);

        return (
          <div key={ds.dataSource} className="element">
            <span className="body-medium">{dataSource}</span>
            <div>{parseDateToStr(new Date(ds.mostRecentDataDate))}</div>
          </div>
        );
      })}
    </DatabaseStatusStyled>
  );
}
