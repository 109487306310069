import { Button, Row, Typography } from "antd";
import styled from "styled-components";

export const LoadingContainer = styled(Row)`
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
`;

export const Container = styled(Row)`
  display: flex;
  justify-content: space-between;
  background: #fff;
  position: sticky;
  top: 0;
  z-index: 1;
  width: 100%;
  padding: 22px 24px;
  box-shadow: 0px 2px 9px 0px #0000000f;
  align-items: center;
`;

styled(Row)`
  align-items: center;

  .anticon {
    font-size: 25px;
    margin-left: 12px;
    cursor: pointer;
  }
`;

styled(Typography)`
  font-size: 20px;
  font-weight: 500;
`;

styled(Button)`
  float: "right";
  border-radius: 15px;
`;
