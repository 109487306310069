import { useContext } from "react";

import AppLogo from "@/assets/Pull_Icon_2.png";
import { CustomerContext } from "@/contexts/CustomerProvider";

export interface CustomerIconProps {
  appLogo?: boolean;
  size: number;
  alt?: string;
}

export default function CustomerIcon({ appLogo, size, alt }: CustomerIconProps) {
  const customer = useContext(CustomerContext);
  const logoSrc = appLogo ? AppLogo : customer.currentCustomer?.logoUri ?? AppLogo;

  return <img src={logoSrc} height={size} alt={alt} style={{ borderRadius: "0.25rem" }} />;
}
