import { createFileRoute } from "@tanstack/react-router";

import AnomalyDetailsPage from "@/features/anomaly/details/AnomalyDetailsPage";
import AppMainLayout from "@/layouts/AppMainLayout";

export type AnomalyDetailSearch = {
  anomalyDetectionsPage?: number;
};

export const Route = createFileRoute("/anomaly-detector/$detectorId")({
  component: () => (
    <AppMainLayout>
      <AnomalyDetailsPage />
    </AppMainLayout>
  ),
  validateSearch: (search: Record<string, unknown>): AnomalyDetailSearch => {
    return {
      anomalyDetectionsPage: +(search.anomalyDetectionsPage as number) || undefined,
    };
  },
});
