import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";

import { setItemInSessionStorage } from "@/utils/sessionStorage";

export const useSessionId = () => {
  const { getIdTokenClaims } = useAuth0();

  useEffect(() => {
    async function saveSessionId() {
      const session = await getIdTokenClaims();

      if (session?.sid) {
        setItemInSessionStorage("sessionId", session.sid);
      }
    }
    saveSessionId();
  }, [getIdTokenClaims]);
};
