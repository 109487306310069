import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { TimeSeriesFieldFamily } from "@/api";
import { ErrorType, EventFilter } from "@/api/customerApi";
import { TimeSeriesFieldInputWithFamilyAndErrorType } from "@/features/chartElementLibrary/types";
import { getCurrentCustomerId } from "@/utils/customers";

import { defaultEventAnalysisTimeSeriesFieldsByCustomerId } from "./CustomerProvider";
import { EventAnalysisState } from "./EventAnalysisChartStore";

type ToggleSignalValue = {
  id: string;
  fieldInput: TimeSeriesFieldInputWithFamilyAndErrorType;
  unit?: string;
};

type SetZoomValue = {
  zoom?: {
    min: number;
    max: number;
  };
};

type SetEventFilterValue = {
  eventFilter: EventFilter;
};

export const getDefaultEventAnalysisChartState = (): EventAnalysisState => {
  const currentCustomerId = getCurrentCustomerId();
  const defaultTimeSeriesFields = currentCustomerId
    ? defaultEventAnalysisTimeSeriesFieldsByCustomerId.get(currentCustomerId) ?? []
    : [];

  return {
    signals: defaultTimeSeriesFields.map((f) => ({
      id: f.id,
      name: "",
      unit: f.unit,
      fieldInput: {
        id: f.id,
        family: TimeSeriesFieldFamily.Signal,
        errorType: ErrorType.Quartile,
        aggregateType: f.aggregateType,
      },
    })),
    coloringPoolRecord: {},
  };
};

const EventAnalysisChartSlice = createSlice({
  name: "eventAnalysisChart",
  initialState: getDefaultEventAnalysisChartState(),
  reducers: {
    toggleDrawer(state) {
      state.drawerOpen = !state.drawerOpen;
    },
    setZoom(state, action: PayloadAction<SetZoomValue>) {
      const { zoom } = action.payload;
      state.zoom = zoom
        ? {
            ...zoom,
          }
        : undefined;
    },
    setEventFilter(state, action: PayloadAction<SetEventFilterValue>) {
      const { eventFilter } = action.payload;
      state.eventFilter = eventFilter;
    },
    toggleSignal(state, action: PayloadAction<ToggleSignalValue>) {
      const { id, unit, fieldInput } = action.payload;
      const signalMatch = state.signals.find((s) => s.id === id);
      // Removes signal
      if (signalMatch) {
        state.signals = state.signals.filter((s) => s.id !== id);
      }
      // Adds signal
      else {
        state.signals.push({
          id,
          name: "",
          fieldInput,
          unit,
        });
      }
    },
    updateSignal(state, action: PayloadAction<ToggleSignalValue>) {
      const { id, unit, fieldInput } = action.payload;
      const index = state.signals.findIndex((s) => s.id === id);
      // Removes signal
      if (index != -1) {
        state.signals[index] = {
          ...state.signals[index],
          unit,
          fieldInput,
        };
      }
    },
    updateColoringPool(state, action: PayloadAction<{ record: Record<string, string> }>) {
      state.coloringPoolRecord = action.payload.record;
    },
  },
});

export const EventAnalysisChartActions = EventAnalysisChartSlice.actions;

export default EventAnalysisChartSlice;
