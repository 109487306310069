import { useContext, useEffect } from "react";

import { CustomerContext } from "@/contexts/CustomerProvider";
import { initI18n } from "@/i18n";

const useI18nInit = () => {
  const { currentCustomer } = useContext(CustomerContext);

  useEffect(() => {
    if (currentCustomer) {
      initI18n(currentCustomer?.i18nFolder);
    }
  }, [currentCustomer]);
};

export default useI18nInit;
