import { createFileRoute } from "@tanstack/react-router";

import Home from "@/features/home/Home";
import AppMainLayout from "@/layouts/AppMainLayout";

export const Route = createFileRoute("/")({
  component: () => (
    <AppMainLayout>
      <Home />
    </AppMainLayout>
  ),
});
