import { PropsWithChildren } from "react";

import { SytledInlineDrawerWrapper } from "./StyledInlineDrawer.styled";

type StyledInlineDrawerProps = {
  open?: boolean;
};

const StyledInlineDrawer = (props: PropsWithChildren<StyledInlineDrawerProps>) => {
  const drawerClassname = `amaya-inline-drawer${props.open ? " open" : " closed"}`;

  return <SytledInlineDrawerWrapper className={drawerClassname}>{props.children}</SytledInlineDrawerWrapper>;
};

export default StyledInlineDrawer;
