import { QueryClient } from "@tanstack/react-query";
import { UseNavigateResult } from "@tanstack/react-router";
import { HookAPI } from "antd/es/modal/useModal";
import { NotificationInstance } from "antd/es/notification/interface";

import { Dependency } from "@/api";
import { showModalDeleteDependencies } from "@/components/dependency/DependencyModal";
import { requireCurrentCustomerId } from "@/utils/customers";

import { DeleteAnomaly } from "./DeleteAnomaly";

export const handleDeleteAnomaly = (
  modal: HookAPI,
  notification: NotificationInstance,
  id: string,
  version: number,
  deleteAnomaly: DeleteAnomaly,
  navigate: UseNavigateResult<string>,
  queryClient: QueryClient
) => {
  if (id && version) {
    deleteAnomaly.mutate(
      {
        customerId: requireCurrentCustomerId() ?? "",
        id,
        version,
      },
      {
        onSuccess: ({ expressionAnomalies }) => {
          const dependencies = expressionAnomalies?.deleteExpressionAnomaly.dependencies ?? [];
          if (dependencies.length) {
            showModalDeleteDependencies(
              modal,
              dependencies as Dependency[],
              "It can't be deleted",
              "Sorry, This Anomaly Detector has dependencies."
            );
          } else {
            queryClient.invalidateQueries({
              queryKey: ["AnomalySummary"],
            });
            navigate({ to: "/anomaly-detector", search: () => ({ anomaliesPage: 1 }) });
          }
        },
      }
    );
  }
};
