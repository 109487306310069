import { AnomalyBarValues } from "./AnomalyHistogramPreview";

type findExactValueFn = (xPos: number) => number;

export const isBarColorSplit = (bound: number, findExactValue: findExactValueFn, barStart: number, barEnd: number) => {
  return barStart <= Number(findExactValue(bound)) && barEnd >= Number(findExactValue(bound));
};
export const isBarTripleColorSplit = (
  lowerBound: number,
  upperBound: number,
  findExactValue: findExactValueFn,
  barStart: number,
  barEnd: number
) => {
  return (
    barStart <= Number(findExactValue(lowerBound)) &&
    barEnd >= Number(findExactValue(lowerBound)) &&
    barStart <= Number(findExactValue(upperBound)) &&
    barEnd >= Number(findExactValue(upperBound))
  );
};

export const isBarQuadColorSplit = (
  lowerNormal: number,
  lowerBound: number,
  upperBound: number,
  findExactValue: findExactValueFn,
  barStart: number,
  barEnd: number
) => {
  return (
    barStart <= Number(findExactValue(lowerNormal)) &&
    barEnd >= Number(findExactValue(lowerBound)) &&
    barStart <= Number(findExactValue(lowerBound)) &&
    barEnd >= Number(findExactValue(lowerBound)) &&
    barStart <= Number(findExactValue(upperBound)) &&
    barEnd >= Number(findExactValue(upperBound))
  );
};

export const isBarQuintColorSplit = (
  lowerNormal: number,
  lowerBound: number,
  upperBound: number,
  upperNormal: number,
  findExactValue: findExactValueFn,
  barStart: number,
  barEnd: number
) => {
  return (
    barStart <= Number(findExactValue(lowerNormal)) &&
    barEnd >= Number(findExactValue(lowerBound)) &&
    barStart <= Number(findExactValue(lowerBound)) &&
    barEnd >= Number(findExactValue(lowerBound)) &&
    barStart <= Number(findExactValue(upperBound)) &&
    barEnd >= Number(findExactValue(upperBound)) &&
    barStart <= Number(findExactValue(upperNormal)) &&
    barEnd >= Number(findExactValue(upperNormal))
  );
};

const GREEN = "#2BC18B";
const lGREEN = "#a4efa4";
const RED = "#DF0000";
const lRED = "#f99b9b";
const YELLOW = "#FFAC3F";

export const createColorObj = (percent: number, startColor: string, endColor: string) => {
  return {
    type: "linear",
    x: 0,
    y: 0,
    x2: 1,
    y2: 0,
    colorStops: [
      { offset: 0, color: startColor },
      { offset: percent, color: startColor },
      { offset: percent, color: endColor },
      { offset: 1, color: endColor, opacty: 0 },
    ],
  };
};

export const createTripleColorObj = (
  lowerBound: number,
  upperBound: number,
  startColor: string,
  midColor: string,
  endColor: string,
  barPixelPos: number,
  laneWidth: number
) => {
  const percent1 = (lowerBound - barPixelPos) / laneWidth;
  const percent2 = (upperBound - barPixelPos) / laneWidth;
  return {
    type: "linear",
    x: 0,
    y: 0,
    x2: 1,
    y2: 0,
    colorStops: [
      { offset: 0, color: startColor },
      { offset: percent1, color: startColor },
      { offset: percent1, color: midColor },
      { offset: percent2, color: midColor },
      { offset: percent2, color: endColor },
      { offset: 1, color: endColor, opacty: 0 },
    ],
  };
};
export const createQuadColorObj = (
  lowerNormal: number,
  lowerBound: number,
  upperBound: number,
  color1: string,
  color2: string,
  color3: string,
  color4: string,
  barPixelPos: number,
  laneWidth: number
) => {
  const percent1 = (lowerNormal - barPixelPos) / laneWidth;
  const percent2 = (lowerBound - barPixelPos) / laneWidth;
  const percent3 = (upperBound - barPixelPos) / laneWidth;
  return {
    type: "linear",
    x: 0,
    y: 0,
    x2: 1,
    y2: 0,
    colorStops: [
      { offset: 0, color: color1 },
      { offset: percent1, color: color1 },
      { offset: percent1, color: color2 },
      { offset: percent2, color: color2 },
      { offset: percent2, color: color3 },
      { offset: percent3, color: color3 },
      { offset: percent3, color: color4 },
      { offset: 1, color: color4, opacty: 0 },
    ],
  };
};

export const createQuintColorObj = (
  lowerNormal: number,
  lowerBound: number,
  upperBound: number,
  upperNormal: number,
  color1: string,
  color2: string,
  color3: string,
  color4: string,
  color5: string,
  barPixelPos: number,
  laneWidth: number
) => {
  const percent1 = (lowerNormal - barPixelPos) / laneWidth;
  const percent2 = (lowerBound - barPixelPos) / laneWidth;
  const percent3 = (upperBound - barPixelPos) / laneWidth;
  const percent4 = (upperNormal - barPixelPos) / laneWidth;
  return {
    type: "linear",
    x: 0,
    y: 0,
    x2: 1,
    y2: 0,
    colorStops: [
      { offset: 0, color: color1 },
      { offset: percent1, color: color1 },
      { offset: percent1, color: color2 },
      { offset: percent2, color: color2 },
      { offset: percent2, color: color3 },
      { offset: percent3, color: color3 },
      { offset: percent3, color: color4 },
      { offset: percent4, color: color4 },
      { offset: percent4, color: color5 },
      { offset: 1, color: color5, opacty: 0 },
    ],
  };
};

type getChartValuesFn = () => {
  chartZeroXPixel: number;
  chartZeroYPixel: number;
  dragBarOffset: number;
  laneWidth: number;
  finalXPixel: number;
};

export const getBarColor = (
  barIndex: number,
  bars: AnomalyBarValues,
  getChartValues: getChartValuesFn,
  getPixelPosOfValue: (value: number) => number,
  getBarStartPos: (barIndex: number) => number,
  findExactValue: (xPos: number) => number,
  min: number[],
  max: number[]
) => {
  const { laneWidth } = getChartValues();
  const { cautionBar, warningBar } = bars;
  if (!laneWidth) return "black";

  const lowerBound = getPixelPosOfValue(Math.min(cautionBar, warningBar));
  const upperBound = getPixelPosOfValue(Math.max(cautionBar, warningBar));
  const lowerNormal = getPixelPosOfValue(bars.lowerNormal);
  const upperNormal = getPixelPosOfValue(bars.upperNormal);
  const barPixelPos = getBarStartPos(barIndex);

  if (barPixelPos < lowerBound) {
    const startColor =
      isBarColorSplit(lowerNormal, findExactValue, min[barIndex], max[barIndex]) || lowerNormal > barPixelPos
        ? cautionBar <= warningBar
          ? lGREEN
          : lRED
        : cautionBar <= warningBar
        ? GREEN
        : RED;
    const endColor = isBarColorSplit(lowerNormal, findExactValue, min[barIndex], max[barIndex])
      ? cautionBar <= warningBar
        ? GREEN
        : RED
      : isBarColorSplit(lowerNormal, findExactValue, min[barIndex], max[barIndex])
      ? isBarTripleColorSplit(lowerBound, upperBound, findExactValue, min[barIndex], max[barIndex])
        ? RED
        : YELLOW
      : YELLOW;
    const percent = isBarColorSplit(lowerNormal, findExactValue, min[barIndex], max[barIndex])
      ? (lowerNormal - barPixelPos) / laneWidth
      : isBarColorSplit(lowerBound, findExactValue, min[barIndex], max[barIndex])
      ? (lowerBound - barPixelPos) / laneWidth
      : 1;
    if (
      isBarQuintColorSplit(
        lowerNormal,
        lowerBound,
        upperBound,
        upperNormal,
        findExactValue,
        min[barIndex],
        max[barIndex]
      )
    ) {
      return createQuintColorObj(
        lowerNormal,
        lowerBound,
        upperBound,
        upperNormal,
        lGREEN,
        GREEN,
        YELLOW,
        RED,
        lRED,
        barPixelPos,
        laneWidth
      );
    }
    // green -> yellow -> red ->lRed
    if (isBarQuadColorSplit(lowerBound, upperBound, upperNormal, findExactValue, min[barIndex], max[barIndex])) {
      return createQuadColorObj(lowerBound, upperBound, upperNormal, GREEN, YELLOW, RED, lRED, barPixelPos, laneWidth);
    }
    // lGreen -> green -> yellow -> Red
    if (isBarQuadColorSplit(lowerNormal, lowerBound, upperBound, findExactValue, min[barIndex], max[barIndex])) {
      return createQuadColorObj(
        lowerNormal,
        lowerBound,
        upperBound,
        lGREEN,
        GREEN,
        YELLOW,
        RED,
        barPixelPos,
        laneWidth
      );
    }
    // green -> yellow -> red
    if (isBarTripleColorSplit(lowerBound, upperBound, findExactValue, min[barIndex], max[barIndex])) {
      return createTripleColorObj(lowerBound, upperBound, GREEN, YELLOW, RED, barPixelPos, laneWidth);
    }
    // lGreen -> green -> yellow
    if (isBarTripleColorSplit(lowerNormal, lowerBound, findExactValue, min[barIndex], max[barIndex])) {
      return createTripleColorObj(lowerNormal, lowerBound, lGREEN, GREEN, YELLOW, barPixelPos, laneWidth);
    }
    return createColorObj(percent, startColor, endColor);
  } else if (barPixelPos >= lowerBound && barPixelPos < upperBound) {
    const endColor = isBarColorSplit(upperBound, findExactValue, min[barIndex], max[barIndex])
      ? cautionBar <= warningBar
        ? RED
        : GREEN
      : RED;
    const percent = (upperBound - barPixelPos) / laneWidth;
    if (isBarQuadColorSplit(lowerBound, upperBound, upperNormal, findExactValue, min[barIndex], max[barIndex])) {
      return createQuadColorObj(
        lowerNormal,
        lowerBound,
        upperBound,
        lGREEN,
        GREEN,
        YELLOW,
        RED,
        barPixelPos,
        laneWidth
      );
    }
    if (isBarTripleColorSplit(upperBound, upperNormal, findExactValue, min[barIndex], max[barIndex])) {
      return createTripleColorObj(upperBound, upperNormal, YELLOW, RED, lRED, barPixelPos, laneWidth);
    }
    return createColorObj(percent, YELLOW, endColor);
  } else {
    const endColor =
      isBarColorSplit(upperNormal, findExactValue, min[barIndex], max[barIndex]) || upperNormal < barPixelPos
        ? cautionBar > warningBar
          ? lGREEN
          : lRED
        : cautionBar > warningBar
        ? GREEN
        : RED;
    const startColor =
      upperNormal < barPixelPos ? (cautionBar > warningBar ? lGREEN : lRED) : cautionBar > warningBar ? GREEN : RED;
    const percent = isBarColorSplit(upperNormal, findExactValue, min[barIndex], max[barIndex])
      ? (upperNormal - barPixelPos) / laneWidth
      : isBarColorSplit(lowerBound, findExactValue, min[barIndex], max[barIndex])
      ? (upperNormal - barPixelPos) / laneWidth
      : 1;
    return createColorObj(percent, startColor, endColor);
  }
};
