import { Expression } from "@lib/src/expression/types";
import _ from "lodash";
import { useState } from "react";
import { PartialDeep } from "type-fest";

export const useExpressionTreeBuilder = () => {
  const [rootExpression, setRootExpression] = useState<PartialDeep<Expression>>({});

  const editExpression = (newValue: PartialDeep<Expression>, path: string[]) => {
    if (path.length === 0) {
      setRootExpression(newValue);
      return;
    }

    const newRootExpression = _.cloneDeep(rootExpression);
    _.set(newRootExpression, path, newValue);

    setRootExpression(newRootExpression);
  };

  return { editExpression, rootExpression };
};
