import React from "react";

import { useExpressionEventForEventHeaderQuery } from "@/api";
import { CalculatedStatus } from "@/api/customerApi";
import { QUERY_SETTINGS } from "@/constants";
import { requireCurrentCustomerId } from "@/utils/customers";

const useExpressionStatus = (expressionEventId: string) => {
  const [status, setStatus] = React.useState<CalculatedStatus | null>(null);

  const { data, isFetching } = useExpressionEventForEventHeaderQuery(
    { customerId: requireCurrentCustomerId(), expressionEventId },
    { staleTime: QUERY_SETTINGS.DEFAULT_STALE_TIME, enabled: expressionEventId != "" }
  );

  React.useEffect(() => {
    if (data && data.expressionEvents) {
      setStatus(data.expressionEvents.expressionEvent.status);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return {
    expression: data,
    isLoading: isFetching,
    isNotAvailable: status !== CalculatedStatus.Available,
    status,
  };
};

export { useExpressionStatus };
