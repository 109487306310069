import styled from "styled-components";

export const HomeContainerStyled = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  gap: 1.5rem;
  .grid-widget-container {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 1.5rem;
  }
`;

export const TableTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
