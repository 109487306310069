import { AggregateType, RepairEventType, TimeSeriesFieldFamily, TimeSeriesIndependentVar } from "@/api";
import { ErrorType, FaultTimelineSeries, TimeSeriesData } from "@/api/customerApi";
import { ChartField, TimeSeriesChartProps } from "@/components/timeSeries/TimeSeriesChart";
import { CustomChartSeries } from "@/contexts/ChartLibraryStore";
import { AnalysisSignal } from "@/contexts/EventAnalysisChartStore";
import { toArray } from "@/utils/arrayUtils";
import { parseDateToStr } from "@/utils/dateUtils";
import { formatNumber } from "@/utils/numberUtils";
import { toPercent } from "@/utils/toPercent";
import { EchartsTooltipFormatterParams } from "@/utils/types/EChartsDefinitions";

import { repairsSeriesName, tooltipXAxisValue } from "./ChartLibraryBuildUtils";
import { FaultSeriesValue, RepairSeriesValue } from "./types";

export const chartLibraryPatternTooltipFormatter = (
  params: {
    value: any;
    name: any;
    dataIndex: number;
  }[],
  min: (number | null)[],
  max: (number | null)[],
  pct: number[]
) => {
  if (!params || params.length == 0) return;
  const index = params[0].dataIndex;
  const percentValue = toPercent(pct[index]);
  const minValue = formatNumber(min[index] ?? 0);
  const maxValue = formatNumber(max[index] ?? 0);
  return `
    <div class="chart-library-tooltip-container rounded">
      <div class="series-container">
        <div class="label-wrapper">
          <span class="bolded label">From ${minValue} to ${maxValue}</span>
        </div>
        <hr/>
        <div class="label-wrapper">
          <span class="percent">${percentValue}</span>
        </div>
      </div>
    </div>
  `;
};

export const chartLibraryEventFaultTooltipFormatter = (
  params: { data: { data: FaultTimelineSeries } },
  rangeUnits: "kms" | "days"
) => {
  if (!params?.data?.data) return;
  const unit = rangeUnits === "kms" ? "kms" : "Days";
  const title = `<div>${formatNumber(params.data.data.xAxis)} ${unit} from First Occurrence</div>`;
  const numOccurrencesTr = `<tr><td style="padding: 0px 24px 0px 0px">Number of Faults</td><td>${params.data.data.faultCount}</td></tr>`;
  const pvinListTr = `
        <tr>
          <td>Number of Vehicles</td>
          <td>${params.data.data.pvinCount}</td>
        </tr>
      `;
  return `<div>${title}<hr/><table>${numOccurrencesTr}${pvinListTr}</table></div>`;
};

export const chartLibraryDefaultTooltipFormatter = (
  params: Object | Array<Object>,
  series: CustomChartSeries[],
  indVar: TimeSeriesIndependentVar
): string | HTMLElement | HTMLElement[] | undefined => {
  let baseSeries: CustomChartSeries | undefined;

  if (params && Array.isArray(params) && params.length) {
    const signalsData = params
      // filter polygon series (comparison series)
      .filter((e: EchartsTooltipFormatterParams) => Array.isArray(e.value) && e.value?.[1] !== undefined)
      .map((tooltipElement: EchartsTooltipFormatterParams) => {
        // TODO better way to match tooltip series with CustomChartSeries
        // The logic below to see which series to use and with base series since the values use different x axis
        // now. Sometimes there would be no main series and the comparison values would be lost
        const currentSeries = series.find((s) => s.name === tooltipElement.seriesName);
        // Build comparison series tooltip data
        // When we get to a new set of data we set base series to undefined
        if (currentSeries?.comparisonSeries && baseSeries && currentSeries?.baseName !== baseSeries?.name) {
          baseSeries = undefined;
        }
        const comparisonSeries = currentSeries
          ? series.filter((s) => s.id.includes(currentSeries.id) && !!s.comparisonSeries)
          : [];
        const comparisonData = comparisonSeries.map((s) => {
          const comparisonChartSeries = params.find((param) => s.name === param.seriesName);
          return {
            label: s.comparisonLabel ?? "-",
            value: comparisonChartSeries
              ? formatNumber(
                  Array.isArray(comparisonChartSeries.value)
                    ? +comparisonChartSeries.value[1]
                    : +comparisonChartSeries.value,
                  true
                ) ?? "-"
              : "-",
          };
        });
        let eventValue = Array.isArray(tooltipElement.value) ? tooltipElement.value[1] : tooltipElement.value;
        // set comparison series or non-matching series rows to undefined
        // Since sometimes we will have no main series and only comparison series we need some logic to know
        // when to allow a comparison sereies to be used to render a tooltip.
        // if there is no current series found do not render anything;
        if (!currentSeries) return;
        // if the current series in not a comparison series then set the base series to the current series
        if (!currentSeries?.comparisonSeries) baseSeries = currentSeries;
        // If the current series is a comparison series but we have data for the main series we return so we do
        // not render the comparison series multiple times
        if (currentSeries?.comparisonSeries && baseSeries) return;
        // If the current series is a comparison series but we do not have a main series, then we set the baseSeries to the
        // first current series to allow rendering of the tool tip
        if (currentSeries?.comparisonSeries && !baseSeries) {
          baseSeries = series.find((s) => s.name === currentSeries.baseName);
          // The event values is supposed to be for the main series but if there is no main serires we set it to undefined.
          eventValue = undefined;
        }
        const seriesToUse = !currentSeries.comparisonSeries ? currentSeries : baseSeries;
        if (!seriesToUse) return;

        const timeSeriesInput = currentSeries.TimeSeriesFieldInput;
        const seriesAgg = getAggregationLabels(timeSeriesInput.aggregateType);
        let seriesTypeLabel =
          timeSeriesInput.family !== TimeSeriesFieldFamily.AnomalyScore
            ? getSignalFamilyLabel(timeSeriesInput.family)
            : getAnomalyScoreLabelBydId(timeSeriesInput.id);

        // TODO buildModelSourceElement (JSX won't work here)
        return `<div class="series-container body-small">
          <div class="series-marker">${tooltipElement.marker}</div>
          <div class="series-name text-overflowing">${
            currentSeries.comparisonSeries ? seriesToUse?.name : tooltipElement.seriesName
          }</div>
          <div class="series-value">
            <span class="value-label">Type</span>
            <span class="value-data capitalize">${seriesTypeLabel}</span>
          </div>
          <div class="series-value">
            <span class="value-label capitalize">${seriesAgg}</span>
            <span class="value-data">${eventValue ? formatNumber(+eventValue, true) : "-"}</span>
          </div>
          <div class="comparison-section">
          ${comparisonData
            .map((comparison) => {
              return `<div class="series-value">
                <span class="value-label capitalize">${comparison.label}</span>
                <span class="value-data">${comparison.value}</span>
              </div>`;
            })
            .join("")}
          </div>
          <div class="series-value unit-container">
            <span class="value-label">Unit</span>
            <span class="value-data">${currentSeries?.unit || "-"}</span>
          </div>
        </div>`;
      })
      // filter comparison series rows
      .filter((e) => !!e);

    const repairsData = params
      .filter((e: EchartsTooltipFormatterParams) => e.seriesName === repairsSeriesName)
      .map((tooltipElement: EchartsTooltipFormatterParams) => {
        const repairData = (tooltipElement.data as RepairSeriesValue)[2];
        const repairLabel = getRepairEventLabel(repairData.type ?? undefined);

        return `<div class="series-container body-small">
          <span style="display: flex; width: 0; height: 0; margin: 4px;
            border-left: 6px solid transparent; border-right: 6px solid transparent;
            border-top: 12px solid ${tooltipElement.color};"></span>
          <div class="series-name text-overflowing">${repairLabel}</div>
        </div>`;
      });

    if (signalsData || repairsData) {
      const axisValue = tooltipXAxisValue(params[0].axisValue, indVar);
      const headerRow = `<p class="body-small header-row">${axisValue}</p>`;
      return `<div class="tooltip-body">${[headerRow, ...signalsData, ...repairsData].join("")}</div>`;
    }
  }
};
export const chartLibraryFaultTooltipFormatter = (params: Object | Array<Object>, indVar: TimeSeriesIndependentVar) => {
  const chartFaultElement = toArray(params)[0] as EchartsTooltipFormatterParams;
  const chartFaultValue = chartFaultElement.value as FaultSeriesValue;
  const faultData = chartFaultValue[2];
  const axisValue = tooltipXAxisValue(chartFaultValue[0], indVar);
  return `<div class="tooltip-body">
    <p class="body-small header-row">${axisValue}</p>
    <div class="fault-data-container body-small">
      <div class="data-section fault-code">
        <p>Fault code</p>
        <p>${faultData.code}</p>
      </div>
      <div class="data-section">
        <p>Trouble code</p>
        <p>${faultData.troubleCode ?? "-"}</p>
      </div>
      <div class="data-section">
        <p>Fault name</p>
        <p>${faultData.name ?? "-"}</p>
      </div>
      <div class="data-section">
        <p>ECU</p>
        <p>${faultData.source ?? "-"}</p>
      </div>
      <div class="data-section">
        <p>Odometer</p>
        <p>${formatNumber(faultData.odometer)} km</p>
      </div>
      <div class="data-section">
        <p>Date${faultData.isDateInferred ? `<span class="error"> (FAP date)</span>` : ""}</p>
        <p>${parseDateToStr(new Date(faultData.startTime!), true)}</p>
      </div>
    </div>
  </div>`;
};

export const chartLibraryTimeSeriesTooltipFormatter = (
  x: number,
  nextX: number | undefined,
  y: number,
  min: number,
  max: number,
  _vehicles: number
) => {
  const header =
    nextX !== undefined ? `From ${formatNumber(x)} km to ${formatNumber(nextX)} km ` : `${formatNumber(x)} km`;
  return `
    <div class='tooltip-body rounded'>
        <div class="label-wrapper">
          <span class="bolded label">${header}</span>
        </div>
        <hr/>
        <div class="series-container">
          <div class="series-value">
            <span class="value-label bolded label">-Dev</span>
            <span class="value-data">${formatNumber(min, true)}</span>
          </div>
          <div class="series-value">
            <span class="value-label bolded label">${describeAggregateType(AggregateType.Median)}</span>
            <span class="value-data">${formatNumber(y, true)}</span>
          </div>
          <div class="series-value">
            <span class="value-label bolded label">+Dev</span>
            <span class="value-data">${formatNumber(max, true)}</span>
          </div>
        </div>
    </div>`;
};

export const chartLibraryEventsTooltipFormatter = (
  params: { data?: { field?: ChartField; value: [number, number] }; name: string; dataIndex: number }[],
  selectedSignals: AnalysisSignal[],
  getSummaryData: TimeSeriesChartProps["getSummaryData"],
  data: TimeSeriesData,
  windowSize: number
) => {
  let xValue: number | undefined = undefined;
  const signalsData = params
    .filter((p) => p.data?.field)
    .map((param) => {
      const field = param.data?.field;
      let signalIndex: number = -1;

      const signal = selectedSignals.find((s, i) => {
        if (s.fieldInput.id === field?.fieldId) {
          signalIndex = i;
          return true;
        }
      });

      if (!signal || signalIndex < 0) return;

      if (xValue === undefined) xValue = data.x[param.dataIndex];
      const [fleetLow, fleetMid, fleetHigh] = getSummaryData(signalIndex);
      return `
      <div class="series-group">
        <div class="series-container body-small">
          <div class="series-marker">
            <span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:${
              param.data?.field?.color
            };"></span>
          </div>
          <div class="series-name text-overflowing">${signal.name}</div>
          <div class="series-value">
            <span class="value-label">${describeErrorTypeLow(signal.fieldInput.errorType)}</span>
            <span class="value-data">${formatNumber(data?.low?.[signalIndex][param.dataIndex] as number, true)}</span>
          </div>
          <div class="series-value">
            <span class="value-label">${describeAggregateType(signal.fieldInput.aggregateType)}</span>
            <span class="value-data">${formatNumber(data?.y?.[signalIndex][param.dataIndex] as number, true)}</span>
          </div>
          <div class="series-value">
            <span class="value-label">${describeErrorTypeHigh(signal.fieldInput.errorType)}</span>
            <span class="value-data">${formatNumber(data?.high?.[signalIndex][param.dataIndex] as number, true)}</span>
          </div>
          <div class="series-value unit-container">
            <span class="value-label">Unit</span>
            <span class="value-data">${signal.unit ?? "-"}</span>
          </div>
          <div class="series-value">
            <span class="value-label">Type</span>
            <span class="value-data capitalize">${getSignalFamilyLabel(signal.fieldInput.family)}</span>
          </div>
        </div>
        <div class="series-container no-border body-small second-row">
          <div class="series-marker">
            <span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:transparent;"></span>
          </div>
          <div class="series-name text-overflowing">Fleet</div>
          <div class="series-value">
            <span class="value-data">${formatNumber(fleetLow, true)}</span>
          </div>
          <div class="series-value">
            <span class="value-data">${formatNumber(fleetMid, true)}</span>
          </div>
          <div class="series-value">
            <span class="value-data">${formatNumber(fleetHigh, true)}</span>
          </div>
        </div>
      </div>
      `;
    })
    .filter((e) => !!e);
  if (signalsData) {
    const interval = xValue ?? 0;
    const headerRow = `<div class="interval">${formatNumber(interval)} to ${formatNumber(
      interval + windowSize
    )} km</div>`;
    return `<div class='chart-library-tooltip-container'><div class="tooltip-body">${[headerRow, ...signalsData].join(
      ""
    )}</div></div>`;
  }
};

export function describeErrorTypeHigh(errorType: ErrorType): string {
  switch (errorType) {
    case ErrorType.Quartile:
      return "75%ile";
    case ErrorType.StdDev:
      return "+StdDev";
    case ErrorType.MinMax:
      return "Max";
    case ErrorType.Vigintile:
      return "95%ile";
  }
}

export function describeErrorTypeLow(errorType: ErrorType): string {
  switch (errorType) {
    case ErrorType.Quartile:
      return "25%ile";
    case ErrorType.StdDev:
      return "-StdDev";
    case ErrorType.MinMax:
      return "Min";
    case ErrorType.Vigintile:
      return "5%ile";
  }
}

export function describeAggregateType(aggregateType: AggregateType): string {
  switch (aggregateType) {
    case AggregateType.Mean:
      return "Mean";
    case AggregateType.Median:
      return "Median";
    case AggregateType.Last:
      return "Last";
    case AggregateType.Min:
      return "Min";
    case AggregateType.Max:
      return "Max";
    case AggregateType.Count:
      return "Count";
    case AggregateType.Std:
      return "Standard Dev";
    case AggregateType.Sum:
      return "Sum";
  }
}

function getAggregationLabels(aggregateType: AggregateType): string {
  return aggregateType == AggregateType.Mean ? "Vehicle Mean" : describeAggregateType(aggregateType);
}

// TODO AMAYA-2766
export const getAnomalyScoreLabelBydId = (id: string) => {
  if (id.includes("m:")) return "ML Anomaly Score";
  return "Pattern Anomaly Score";
};

export const getSignalFamilyLabel = (family: TimeSeriesFieldFamily): string => {
  switch (family) {
    case TimeSeriesFieldFamily.Signal:
      return "Signal";
    case TimeSeriesFieldFamily.Expression:
      return "Pattern";
    case TimeSeriesFieldFamily.AnomalyScore:
      return "Anomaly";
    default:
      return "Unknown";
  }
};

export const getRepairEventLabel = (repairType?: RepairEventType): string => {
  switch (repairType) {
    case RepairEventType.BatteryFullReplacement:
      return "Full Battery Replacement";
    case RepairEventType.BatteryModuleReplacement:
      return "Partial Battery Replacement";
    default:
      return "Unknown";
  }
};
