import { CalendarOutlined } from "@ant-design/icons";
import { Typography } from "antd";

import DropdownWidget from "../ui/DropdownWidget";

// These date formats should comply with day.js formats
// See https://day.js.org/docs/en/parse/string-format
const dateFormats = [
  { key: "", label: "auto" },
  { key: "MM/DD/YY", label: "mm/dd/yy" },
  { key: "DD/MM/YY", label: "dd/mm/yy" },
  { key: "MM/DD/YYYY", label: "mm/dd/yyyy" },
  { key: "DD/MM/YYYY", label: "dd/mm/yyyy" },
];

interface DateFormatDropdownPickerProps {
  onSelected: (dateFormat: string) => void;
}

const DateFormatDropdownPicker = ({ onSelected }: DateFormatDropdownPickerProps) => {
  return (
    <DropdownWidget
      elements={dateFormats}
      onElementSelected={onSelected}
      icon={<CalendarOutlined />}
      label={<Typography.Text>Date Format:</Typography.Text>}
    />
  );
};

export default DateFormatDropdownPicker;
