import { Expression, ExpressionType } from "@lib/src/expression/types";
import { Col, Flex } from "antd";
import _ from "lodash";

import { ExpressionInput, ExpressionInputProps } from "./ExpressionInput";
import { ParameterSeparator, ParametersWrapper } from "./ExpressionParametersInput.styled";

interface ExpressionParametersInputProps extends ExpressionInputProps<Expression> {
  valuesPath: string | string[]; //String will expect the expression field to be an array
}

const getSeparator = (type: keyof typeof ExpressionType, index: number) => {
  if (type === ExpressionType.If) {
    return index === 0 ? "if" : index === 1 ? "then" : "else";
  }
  return null;
};
export const ExpressionParametersInput = (props: ExpressionParametersInputProps) => {
  const { expression, valuesPath, disabled } = props;

  const parametersPaths = Array.isArray(valuesPath)
    ? valuesPath
    : _.range((expression as any)[valuesPath].length).map((i) => `${valuesPath}.${i}`);

  return (
    <Flex vertical>
      {parametersPaths.map((parameterPath, i) => {
        const parameterExpression = _.get(expression, parameterPath.split(".")); //Array indexes have . as separator

        const separator = getSeparator(expression.type!, i);
        return (
          <ParametersWrapper key={parameterPath}>
            {separator && (
              <Col span={2}>
                <ParameterSeparator>{separator}</ParameterSeparator>
              </Col>
            )}
            <Col span={separator ? 22 : 24}>
              <ExpressionInput
                expression={parameterExpression}
                path={[...props.path, ...parameterPath.split(".")]}
                onChange={props.onChange}
                disabled={disabled}
              />
            </Col>
          </ParametersWrapper>
        );
      })}
    </Flex>
  );
};
