import styled from "styled-components";

export const CategoricalDataHeaderContainer = styled.div`
  padding: 1.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1.5rem;
  flex-wrap: wrap;
  .pvin-label {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1.5rem;
    padding-right: 2.375rem;
    text-transform: none;
  }
  .categorical-data-section {
    padding: 0 1rem;
    border-left: 1px solid #dedede;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 0.5rem;
  }
`;

export const CategoricalDataBodyContainer = styled.div`
  padding: 0 1.5rem;
  background-color: ${(props) => props.theme.colors.defaultWhite};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  .categorical-data-section-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 1rem;
    .categorical-data-section {
      padding: 0.5rem 1.5rem 0rem 1rem;
      border-left: 1px solid #dedede;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
  .categorical-data-thumbnail {
    width: 300px;
    img {
      width: 100%;
    }
  }
`;
