import { Input } from "antd";
import _ from "lodash";
import { useContext, useRef } from "react";
import uuid from "react-uuid";

import { DensityFunction } from "@/api";
import DensityCurveEditor from "@/components/forecast/DensityCurveEditor";
import { UI_SETTINGS } from "@/constants";
import EditRepairConceptContext from "@/contexts/EditRepairConceptProvider";

import { NewRepairConceptCostContainer } from "./NewRepairConceptCost.styled";

const NewRepairConceptCost = () => {
  const densityFnIdRef = useRef(uuid());
  const newRepairCtx = useContext(EditRepairConceptContext);

  const densityFunctionEditHandler = (fn: DensityFunction) => {
    newRepairCtx.setNewRepairData({
      ...newRepairCtx.newRepair,
      cost: {
        ...newRepairCtx.newRepair.cost,
        distribution: { points: fn.points, y0: fn.y0 },
      },
    });
  };

  const totalCostChangeHandler = _.debounce((newTotalCost: string) => {
    newRepairCtx.setNewRepairData({
      ...newRepairCtx.newRepair,
      cost: {
        ...newRepairCtx.newRepair.cost,
        totalCost: +newTotalCost,
      },
    });
  }, UI_SETTINGS.STANDARD_INPUT_DEBOUNCE);

  return (
    <NewRepairConceptCostContainer>
      <div className="cost-distribution-header">
        <span className="heading-xx-small dark">Define the cost distribution</span>
      </div>
      <DensityCurveEditor
        densityFunction={{ ...newRepairCtx.newRepair.cost.distribution }}
        onEdit={densityFunctionEditHandler}
        chartKey={densityFnIdRef.current}
        functionUnit={newRepairCtx.newRepair.cost.densityUnit}
        cost={newRepairCtx.newRepair.cost.totalCost}
      />
      <div className="cost-distribution-footer">
        <span className="heading-xx-small dark">Set the total repair cost in USD</span>
        <Input
          className="total-cost-input"
          type="number"
          min={0}
          step={10}
          defaultValue={newRepairCtx.newRepair.cost.totalCost}
          onChange={(e) => totalCostChangeHandler(e.target.value)}
        />
      </div>
    </NewRepairConceptCostContainer>
  );
};

export default NewRepairConceptCost;
