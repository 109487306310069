import { Button, Checkbox, Col, Input, Row } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { ChangeEvent, useState } from "react";
import styled from "styled-components";

import { FaultHistoryColumn } from "@/api/customerApi";
import Loading from "@/components/loading";

type FaultTableFilterByExclusionProps = {
  label: string;
  isLoading: boolean;
  fieldName: FaultHistoryColumn;
  options: { value: string; label: string }[];
  onApplyExclusions: (fieldName: FaultHistoryColumn, values: string[]) => void;
};

export const FaultTableFilterByExclusion = ({
  label,
  isLoading,
  fieldName,
  options,
  onApplyExclusions,
}: FaultTableFilterByExclusionProps) => {
  const [localExcludeds, setLocalExcludeds] = useState<string[]>([]);
  const [search, setSearch] = useState("");

  if (isLoading) {
    return (
      <VisualWrapper>
        <div style={{ margin: "24px" }}>
          <Loading />
        </div>
      </VisualWrapper>
    );
  }

  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    // no need to debounced if everything is already loaded in memory.
    setSearch(event.target.value);
  };

  const handleOptionChangeFn =
    (option: FaultTableFilterByExclusionProps["options"][0]) => (event: CheckboxChangeEvent) => {
      const isExcluding = !event.target.checked;
      if (isExcluding) {
        setLocalExcludeds((prev) => [...prev, option.value]);
      } else {
        setLocalExcludeds((prev) => prev.filter((v) => v !== option.value));
      }
    };

  const handleClearAllBtnClick = () => {
    setLocalExcludeds(options.map((option) => option.value));
  };

  const handleSelectAllBtnClick = () => {
    setLocalExcludeds([]);
  };

  const handleApplyBtnClick = () => {
    onApplyExclusions(fieldName, localExcludeds);
  };

  const filteredOptions = search
    ? options.filter((option) => option.label.toLocaleLowerCase().includes(search.toLocaleLowerCase()))
    : options;

  const isChecked = (option: FaultTableFilterByExclusionProps["options"][0]) => {
    return !localExcludeds.includes(option.value);
  };

  return (
    <VisualWrapper>
      <SearchInput
        type="search"
        aria-label={`Search for ${label}...`}
        placeholder={`Search for ${label}...`}
        value={search}
        onChange={handleSearchChange}
      />
      <Row>
        {filteredOptions.map((option) => {
          return (
            <Col key={option.value} span={24}>
              <Checkbox value={option.label} onChange={handleOptionChangeFn(option)} checked={isChecked(option)}>
                {option.label}
              </Checkbox>
            </Col>
          );
        })}
      </Row>
      <hr />
      <ActionsButtonsWrapper>
        <ActionButton onClick={handleClearAllBtnClick}>Clear All</ActionButton>
        <ActionButton onClick={handleSelectAllBtnClick}>Select All</ActionButton>
        <ActionButton type="primary" onClick={handleApplyBtnClick}>
          Apply
        </ActionButton>
      </ActionsButtonsWrapper>
    </VisualWrapper>
  );
};

const VisualWrapper = styled.div`
  padding: 24px;

  .ant-row {
    max-width: 300px;
    max-height: 500px;
    overflow: auto;
  }

  .ant-col {
    padding: 5px;
    display: flex;
    align-items: center;
  }
`;

const SearchInput = styled(Input)`
  margin-bottom: 8px;
`;

const ActionsButtonsWrapper = styled.div``;

const ActionButton = styled(Button)``;
