import { Layout } from "antd";
import styled from "styled-components";

export const MainLayout = styled(Layout)`
  margin-left: ${({ theme }) => theme.sizes.sidebar};
`;

export const MainContainer = styled(Layout.Content)`
  background: #dedede;
  position: relative;
  width: 100%;
  padding: ${({ theme }) => theme.paddings.mainContent};
  // Calculate with md breakpoint, sidebar width and scrollbar width
  min-width: calc(${({ theme }) => theme.breakpoints.md} - ${({ theme }) => theme.sizes.sidebar} - 0.5rem);
  min-height: calc(100vh - ${({ theme }) => theme.sizes.header});
  overflow-x: auto;
`;
