import _ from "lodash";
import { useEffect } from "react";
import { useSelector } from "react-redux";

import { UI_SETTINGS } from "@/constants";
import { addToLocalStorage } from "@/utils/localStorageUtils";

export const stateParser = (serial: string): unknown => {
  return JSON.parse(atob(serial));
};

export const stateSerializer = (value: unknown): string => {
  return btoa(JSON.stringify(value));
};

type StateToExternalSourceProps = {
  stateKey: string;
  selector: (state: any) => {};
  onStateChange: (serializedState: string) => void;
};

const useStateToExternalSource = ({ stateKey, selector, onStateChange }: StateToExternalSourceProps) => {
  const selectedState = useSelector(selector);

  // Debounced state write to external sources
  const handleStateChange = _.debounce(() => {
    // Serialize state
    const serializedState = stateSerializer(selectedState);
    // Write to localstorage
    addToLocalStorage(stateKey, serializedState);
    // Write to url as queryparam
    onStateChange(serializedState);
  }, UI_SETTINGS.VALIDATION_INPUT_DEBOUNCE);

  useEffect(() => {
    handleStateChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedState]);
};

export default useStateToExternalSource;
