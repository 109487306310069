import { createFileRoute } from "@tanstack/react-router";

import { EventType, RepairEventType } from "@/api";
import EventAnalyzer from "@/features/event/EventAnalyzer";
import AppMainLayout from "@/layouts/AppMainLayout";

import { EventAnalysisSearch } from "./event-analysis";

export const Route = createFileRoute("/repair/partial")({
  component: () => (
    <AppMainLayout>
      <EventAnalyzer eventType={EventType.Repair} repairType={RepairEventType.BatteryModuleReplacement} />
    </AppMainLayout>
  ),
  validateSearch: (search: Record<string, unknown>): EventAnalysisSearch => ({
    xEventAnalysisChart: (search.xEventAnalysisChart as string) || undefined,
    eventOccurrencesPage: (search.eventOccurrencesPage as number) || undefined,
    relatedEventsPage: (search.relatedEventsPage as number) || undefined,
  }),
});
