import { GraphQLError, GraphQLFormattedError } from "graphql";

class ApiError extends Error {
  public cause?: GraphQLFormattedError;

  constructor(message: string, cause?: GraphQLError) {
    // Pass remaining arguments (including vendor specific ones) to parent constructor
    super(message, cause);

    // Maintains proper stack trace for where our error was thrown (only available on V8)
    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ApiError);
    }

    this.name = "ApiError";
    this.message = message;
    this.cause = cause;
  }
}

export default ApiError;
