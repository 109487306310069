import ReactECharts from "echarts-for-react";
import { useMemo } from "react";
import { useSelector } from "react-redux";

import { DensityFunction } from "@/api";
import { ForecastFleetInputDataQuery } from "@/api/generated/graphql.customer";
import { ForecastSelector } from "@/contexts/ForecastStore";
import calculateForecast, {
  calculateRepairConceptRates,
  calculateRepairEventCost,
} from "@/utils/forecast/calculateForecast";

import { buildForecastChartConfig } from "./ForecastHelper";

interface ForecastOutputProps {
  data: ForecastFleetInputDataQuery | undefined;
  isLoading: boolean;
}

export default function ForecastOutput({ data, isLoading }: ForecastOutputProps) {
  // TODO proper selectors for forecast data
  const forecastModel = useSelector((state: ForecastSelector) => state.forecast);
  const smoothness = 0.5; // The smoothness of the bezier segments between the points
  const interval = 100; // 100 km between odometer intervals used in the forecast calculation.

  // Sum up the rows of the production forecast
  const totalProductionForecast: number[] = useMemo(
    () =>
      forecastModel.forecast.productionForecast?.definition?.forecast
        .map((r) => r.unitCounts)
        .reduce((arr: number[], r) => {
          r.forEach((n, i) => {
            while (arr.length <= i) {
              arr.push(0);
            }
            arr[i] += n ?? 0;
          });
          return arr;
        }, [] as number[]),
    [forecastModel]
  );

  const repairConceptRates = useMemo(() => {
    if (!forecastModel.forecast || !data) {
      return [];
    }
    return calculateRepairConceptRates(
      data.eventRateHistory,
      forecastModel.forecast.repairConcepts.map((r) => r.threshold),
      forecastModel.forecast.discounts.map((di) =>
        di.map((dij) => (dij.distribution && toBezierPoints(dij.distribution)) || [])
      ),
      smoothness,
      interval
    );
  }, [data, forecastModel]);
  function toBezierPoints(distribution: DensityFunction): [number, number][] {
    return [[0, distribution.y0], ...distribution.points.map(({ x, y }) => [x, y] as [number, number])];
  }
  const forecastIntervals = useMemo(() => {
    if (!forecastModel.forecast || !data) {
      return [];
    }
    return calculateForecast(
      new Date(),
      forecastModel.forecast.productionForecast.definition?.monthCount || 0,
      data.vehicleAgeHistogram.map((r) => r.vehicleCount),
      data.vehicleMileageHistogram.map((r) => r.vehicleCount),
      totalProductionForecast,
      data.vehicleDrivingDistanceHistograms.map((r) => r.distances.map((d) => d ?? 0)),
      repairConceptRates,
      repairConceptRates.map((r, i) =>
        calculateRepairEventCost(
          forecastModel.forecast.repairConcepts[i].repairConcept.cost.totalCost,
          toBezierPoints(forecastModel.forecast.repairConcepts[i].repairConcept.cost.distribution),
          smoothness,
          100,
          r
        )
      )
    );
  }, [forecastModel, data, totalProductionForecast, repairConceptRates]);

  const forecastTotalCost = useMemo(() => {
    return forecastIntervals.reduce(
      (total, { repairConceptCost }) => total + repairConceptCost.reduce((t, c) => t + c, 0),
      0
    );
  }, [forecastIntervals]);

  const forecastChartOptions = useMemo(
    () => buildForecastChartConfig(forecastModel, forecastIntervals),
    [forecastModel, forecastIntervals]
  );

  return (
    <div>
      <h1>
        Total Cost (USD):{" "}
        {new Intl.NumberFormat(undefined, { style: "currency", currency: "USD" }).format(forecastTotalCost)}
      </h1>
      <ReactECharts className="echarts" {...forecastChartOptions} notMerge showLoading={isLoading} />
    </div>
  );
}
