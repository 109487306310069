import { useContext } from "react";

import { CustomerContext } from "@/contexts/CustomerProvider";

export function useCustomerIdentifier() {
  const customerContext = useContext(CustomerContext);

  return {
    customerIdentifier: customerContext.commonData,
  };
}
