import { configureStore, createSelector } from "@reduxjs/toolkit";

import { EventFilter } from "@/api/customerApi";
import { TimeSeriesFieldInputWithFamilyAndErrorType } from "@/features/chartElementLibrary/types";

import EventAnalysisChartSlice from "./EventAnalysisChartSlice";

export interface EventAnalysisChartSliceState {
  eventAnalysisChart: EventAnalysisState;
}

export type AnalysisSignal = {
  id: string;
  name: string;
  unit?: string | undefined;
  fieldInput: TimeSeriesFieldInputWithFamilyAndErrorType;
};

export type EventAnalysisState = {
  signals: AnalysisSignal[];
  drawerOpen?: boolean;
  zoom?: { min: number; max: number };
  eventFilter?: EventFilter;
  coloringPoolRecord: Record<string, string>;
};

export const selectAnalysisSignals = createSelector(
  [
    (state: EventAnalysisChartSliceState) => state.eventAnalysisChart.signals,
    (state: EventAnalysisChartSliceState) => state.eventAnalysisChart.coloringPoolRecord,
  ],
  (signals, coloringPoolRecord) => {
    return { signals, coloringPoolRecord };
  }
);

export const selectAnalysisSeriesIds = createSelector(
  [(state: EventAnalysisChartSliceState) => state.eventAnalysisChart.signals],
  (signals) => signals.map((signal) => signal.fieldInput.id)
);

export const selectColoringPoolRecord = createSelector(
  [(state: EventAnalysisChartSliceState) => state.eventAnalysisChart.coloringPoolRecord],
  (coloringPoolRecord) => coloringPoolRecord
);

export const selectEventFilter = createSelector(
  [(state: EventAnalysisChartSliceState) => state.eventAnalysisChart.eventFilter],
  (eventFilter) => {
    return { selectedEventFilter: eventFilter };
  }
);

export const selectZoom = createSelector(
  [(state: EventAnalysisChartSliceState) => state.eventAnalysisChart.zoom],
  (zoom) => {
    return { selectedZoom: zoom };
  }
);

export const selectDrawerIsOpen = createSelector(
  [(state: EventAnalysisChartSliceState) => state.eventAnalysisChart.drawerOpen],
  (drawerOpen) => {
    return { drawerIsOpen: !!drawerOpen };
  }
);

const configureEventAnalysisChartStore = (initialState?: EventAnalysisState) => {
  return configureStore({
    reducer: {
      eventAnalysisChart: EventAnalysisChartSlice.reducer,
    },
    preloadedState: initialState ? { eventAnalysisChart: initialState } : undefined,
  });
};

export default configureEventAnalysisChartStore;
