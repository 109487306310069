import { TabsProps } from "antd";
import { useDispatch } from "react-redux";

import { TimeSeriesFieldFamily } from "@/api";
import { EventAnalysisChartActions } from "@/contexts/EventAnalysisChartSlice";
import { AnalysisSignal } from "@/contexts/EventAnalysisChartStore";
import { PlottedSignalsCount } from "@/features/chartElementLibrary/ChartLibrarySettings";
import { StyledTabs } from "@/features/chartElementLibrary/ChartLibraryTabs.styled";

import EventSignalListSelector from "./EventSignalListSelector";

type EventTimeSeriesTabsProps = {
  selectedSignals: AnalysisSignal[];
};

export enum PlotMode {
  UPDATE = "Update",
  TOGGLE = "Toggle",
}

const EventTimeSeriesTabs = ({ selectedSignals }: EventTimeSeriesTabsProps) => {
  const dispatch = useDispatch();

  let plottedCount: PlottedSignalsCount = {
    signal: selectedSignals.filter((s) => s.fieldInput.family === TimeSeriesFieldFamily.Signal).length,
    expression: selectedSignals.filter((s) => s.fieldInput.family === TimeSeriesFieldFamily.Expression).length,
    anomaly: selectedSignals.filter((s) => s.fieldInput.family === TimeSeriesFieldFamily.AnomalyScore).length,
  };

  const totalSignalsPlotted = plottedCount.signal + plottedCount.expression + plottedCount.anomaly;

  const handlePlotOptionsChange = (mode: PlotMode, signalData: AnalysisSignal) => {
    const params = {
      id: signalData.id,
      fieldInput: signalData.fieldInput,
      unit: signalData.unit || "",
    };
    const chartAction =
      mode == PlotMode.UPDATE ? EventAnalysisChartActions.updateSignal : EventAnalysisChartActions.toggleSignal;
    dispatch(chartAction(params));
  };

  const EventTimeSeriesTabs: TabsProps["items"] = [
    {
      key: "active",
      label: (
        <div className="body-medium library-tab-label">
          Active {totalSignalsPlotted ? <div className="signal-counter">{totalSignalsPlotted}</div> : undefined}
        </div>
      ),
      children: (
        <EventSignalListSelector
          plottedSignals={selectedSignals}
          onPlotOptionsChange={handlePlotOptionsChange}
          onlyPlotted
        />
      ),
    },
    {
      key: "signals",
      label: (
        <div className="body-medium library-tab-label">
          Signals {plottedCount.signal ? <div className="signal-counter">{plottedCount.signal}</div> : undefined}
        </div>
      ),
      children: (
        <EventSignalListSelector
          plottedSignals={selectedSignals}
          onPlotOptionsChange={handlePlotOptionsChange}
          signalFamily={TimeSeriesFieldFamily.Signal}
          allowSearch
        />
      ),
    },
    {
      key: "expressions",
      label: (
        <div className="body-medium library-tab-label">
          Patterns{" "}
          {plottedCount.expression ? <div className="signal-counter">{plottedCount.expression}</div> : undefined}
        </div>
      ),
      children: (
        <EventSignalListSelector
          plottedSignals={selectedSignals}
          onPlotOptionsChange={handlePlotOptionsChange}
          signalFamily={TimeSeriesFieldFamily.Expression}
          allowSearch
        />
      ),
    },
    {
      key: "anomalies",
      label: (
        <div className="body-medium library-tab-label">
          Anomalies {plottedCount.anomaly ? <div className="signal-counter">{plottedCount.anomaly}</div> : undefined}
        </div>
      ),
      children: (
        <EventSignalListSelector
          plottedSignals={selectedSignals}
          onPlotOptionsChange={handlePlotOptionsChange}
          signalFamily={TimeSeriesFieldFamily.AnomalyScore}
          allowSearch
        />
      ),
    },
  ];

  return <StyledTabs defaultActiveKey="1" items={EventTimeSeriesTabs} />;
};

export default EventTimeSeriesTabs;
