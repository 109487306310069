import { createContext, useContext } from "react";

export interface ChartColoringPoolContextValue {
  acquireColor: (seriesId: string) => string;
}

export const ChartColoringPoolContext = createContext<ChartColoringPoolContextValue | null>(null);

export function useChartColoringPool(): ChartColoringPoolContextValue | null {
  return useContext(ChartColoringPoolContext);
}
