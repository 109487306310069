import { ReactNode, RefObject, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";

import { SectionModalContainer } from "./SectionModalContainer.styled";

type SectionModalProps = {
  component: ReactNode;
  parentId: string;
};

const getParentsPosition = (parentId: string, target: HTMLElement) => {
  const parent = document.getElementById(parentId);
  if (parent) {
    const rect = parent.getBoundingClientRect();
    const top = window.scrollY + rect.top - target.offsetTop;
    return top;
  }
  return 0;
};

export const SectionModal = ({ component, parentId }: SectionModalProps) => {
  const modalRef = useRef<HTMLDivElement>();
  const observerRef = useRef<ResizeObserver>();
  const [position, setPosition] = useState<{ top: number }>();
  const target = document.getElementById("main-layout") ?? document.body;

  useEffect(() => {
    // Disconnect any old observers
    if (observerRef.current) {
      observerRef.current.disconnect();
    }
    // Create a ResizeObserver instance
    observerRef.current = new ResizeObserver(() => {
      setPosition({ top: getParentsPosition(parentId, target) });
    });

    // observe the target
    if (target) {
      observerRef.current.observe(target);
    }

    // disconnect on unmount
    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [parentId, target]);

  useEffect(() => {
    const parent = document.getElementById(parentId);
    if (parent) {
      parent.scrollIntoView({ behavior: "smooth" });
    }
    setPosition({ top: getParentsPosition(parentId, target) });
  }, [parentId, target]);

  return (
    <>
      {position &&
        createPortal(
          <SectionModalContainer ref={modalRef as RefObject<HTMLDivElement>} style={{ ...position }}>
            {component}
          </SectionModalContainer>,
          target
        )}
    </>
  );
};
