import { useContext } from "react";

import { useVehicleSpeedStatsQuery } from "@/api/customerApi";
import GlobalFilterContext from "@/contexts/GlobalFilterContext";
import SCVContext from "@/contexts/SCVContext";

import { BaseEChart } from "../ui/BaseEChart";
import { BasicWidget } from "../widget/BasicWidget";
import { createBoxPlotOptions } from "./BoxPlotHelpers";

export const VehicleDrivingSpeedBoxPlot = () => {
  const { globalFilter } = useContext(GlobalFilterContext);
  const { pvin } = useContext(SCVContext);
  const { data, isLoading } = useVehicleSpeedStatsQuery({ vehicleId: pvin, filter: globalFilter });

  return (
    <BasicWidget title="Driving Behavior vs. Fleet">
      <BaseEChart
        showLoading={isLoading}
        option={createBoxPlotOptions(data?.filteredQuery.vehicleSpeedStats, isLoading, "km/h")}
      />
    </BasicWidget>
  );
};
