import { createFileRoute } from "@tanstack/react-router";

import VehicleHome from "@/features/vehicleDetails/VehicleHome";
import AppMainLayout from "@/layouts/AppMainLayout";

export const Route = createFileRoute("/vehicle/")({
  component: () => (
    <AppMainLayout>
      <VehicleHome />
    </AppMainLayout>
  ),
});
