import styled from "styled-components";

export const WizardGeneralContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin: 1rem;
`;

export const WizardStepsContainer = styled.div`
  padding: 0 1rem;
  min-width: 200px;
  .step-item {
    margin-bottom: 1.5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
    position: relative;
    .anticon {
      color: ${(props) => props.theme.colors.backgroundHover};
      font-size: 1rem;
    }
    .active-bullet {
      width: 5px;
      height: 5px;
      border-radius: 50%;
      padding: 3px;
      background-color: ${(props) => props.theme.colors.backgroundHover};
      border: 5px solid ${(props) => props.theme.colors.secondary};
    }
    .future-bullet {
      width: 5px;
      height: 5px;
      border-radius: 50%;
      padding: 3px;
      background-color: #a8a8a8;
      border: 5px solid #e9e9e9;
    }
    .step-line {
      width: 1rem;
      border: 1px solid ${(props) => props.theme.colors.backgroundHover};
      position: absolute;
      top: 30px;
      transform: rotate(90deg);
    }
  }
`;

export const WizardContainer = styled.div.attrs({ className: "card" })`
  width: 100%;
  padding: 1rem;
  /* TODO header height should come from theme */
  min-height: calc(100vh - 2rem);
  display: flex;
  flex-direction: column;
  .content-wrapper {
    flex-grow: 1;
    .content-header {
      border-bottom: 1px solid ${(props) => props.theme.colors.defaultGray};
      padding-bottom: 0.5rem;
    }
    .content-body {
      padding: 1rem 0;
    }
  }
  .progress-container {
    padding: 1rem 0;
  }
  .wizard-controls-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .controls-section {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 1rem;
    }
  }
`;
