import styled from "styled-components";

// these styles are used to match the default antd tooltip styles so
// there is seemless transition.
export const AnomalyHistogramTextInputStyled = styled.div`
  position: absolute;
  background-color: white;
  width: 260px;
  padding: 10px;
  box-shadow: rgba(0, 0, 0, 0.2) 1px 2px 10px;
  font: 14px / 21px sans-serif;
  color: rgb(102, 102, 102);
  border-radius: 5px;

  .ant-input {
    width: 100%;
  }
`;
