import styled from "styled-components";

export const ForecastContainer = styled.div`
  margin: 1rem;
  padding: 1rem;
  .chart-thumbnail {
    width: 200px;
    height: 80px;
  }
  .density-curve-edit-modal {
    width: 600px;
    height: 400px;
  }
  .forecast-header-controls {
    padding: 0 0 1rem 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ccc;
    gap: 1rem;
    .forecast-data {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      .production-forecast-select {
        min-width: 350px;
      }
      .cost-independent-variable-select {
        min-width: 350px;
      }
      .forecast-name {
        width: 450px;
      }
    }
  }
  .forecast-share-controls {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    padding: 1rem;
  }
`;

export const DensityCurveEditorContainer = styled.div`
  .editor-header {
    padding-bottom: 1rem;
    border-bottom: 1px solid #ccc;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    .header-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      gap: 1rem;
      .header-control {
        width: 250px;
      }
    }
  }
`;
