import { StyledProgressBar } from "./ProgressBar.styled";

interface ProgressBarProps {
  percent: number;
}

const ProgressBar = ({ percent }: ProgressBarProps) => {
  return <StyledProgressBar percent={percent} showInfo={false} />;
};

export default ProgressBar;
