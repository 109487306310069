import { Tooltip, Typography } from "antd";
import { startCase } from "lodash";
import { PropsWithChildren } from "react";

import { StyledFormItem, StyledInfoCircleOutlined } from "./FormItem.styled";
import { FormItemProps } from "./types";
import { errorMessageFromError } from "./utils";

export default function FormItem(props: PropsWithChildren<FormItemProps>) {
  const { hideLabel, formItemClassName, label, name, tooltip, error } = props;

  const labelNode = hideLabel ? null : (
    <span className="body-small">
      <>
        {label ?? startCase(name)}
        {tooltip && (
          <Tooltip placement="top" title={tooltip}>
            <StyledInfoCircleOutlined />
          </Tooltip>
        )}
      </>
    </span>
  );

  return (
    <StyledFormItem className={formItemClassName} label={labelNode}>
      <div className="form-input-container">
        {props.children}
        {error && (
          <div className="form-input-error">
            <Typography.Text type="danger">{errorMessageFromError(error)}</Typography.Text>
          </div>
        )}
      </div>
    </StyledFormItem>
  );
}
