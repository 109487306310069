import ReactECharts, { EChartsInstance, EChartsReactProps } from "echarts-for-react";

import { DensityFunction, DensityFunctionIndependentVariable } from "@/api";
import { allocateCost } from "@/utils/forecast/calculateForecast";
import { integrateCubicSpline } from "@/utils/mathUtils";

interface DensityCurveEditorProps {
  densityFunction: DensityFunction;
  chartKey: string;
  onEdit: (fn: DensityFunction) => void;
  functionUnit: DensityFunctionIndependentVariable;
  cost?: number;
}

export default function DensityCurveEditor({
  densityFunction,
  chartKey,
  onEdit,
  functionUnit,
  cost,
}: DensityCurveEditorProps) {
  // Avoid mutating the curve property of the density function prop
  const mutableCurve = [{ x: 0, y: densityFunction.y0 }, ...densityFunction.points];
  const symbolSize = 20;
  const smooth = 0.5;

  function toPoints(points: { x: number; y: number }[]): [number, number][] {
    return points.map(({ x, y }) => [x, y]);
  }

  const interval = functionUnit === DensityFunctionIndependentVariable.TimeDays ? 10 : 100;

  function costAxisMax() {
    const total = integrateCubicSpline(toPoints(mutableCurve), smooth, interval);
    // Find the point on the curve for the max y-value and scale to that
    const maxYPoint = mutableCurve.reduce(
      ({ x: maxX, y: maxY }, { x, y }) => (y > maxY ? { x, y } : { x: maxX, y: maxY }),
      mutableCurve[0]
    );
    // Convert it to dollars
    const maxYDollars = (maxYPoint.y / total) * cost!;
    return maxYDollars / maxYPoint.y;
  }

  const chartConfig: EChartsReactProps = {
    option: {
      grid: {
        top: "8%",
        bottom: "12%",
      },
      xAxis: {
        name: functionUnit === DensityFunctionIndependentVariable.TimeDays ? "Days" : "km",
        nameLocation: "center",
        nameGap: 24,
        min: 0,
        max: Math.round(Math.max(...mutableCurve.map(({ x }) => x)) + 10),
        type: "value",
        axisLine: { onZero: false },
      },
      yAxis: [
        {
          min: 0,
          max: 1,
          interval: 0.1,
          axisLabel: {
            formatter: (value: number) => `${value * 100}%`,
          },
          type: "value",
          axisLine: { onZero: false },
          show: !cost,
          alignTicks: false,
        },
        {
          name: `USD / ${interval} ${functionUnit === DensityFunctionIndependentVariable.TimeDays ? "Days" : "km"}`,
          show: !!cost,
          nameLocation: "middle",
          nameGap: 60,
          axisLabel: {
            showMaxLabel: false,
            formatter: function (value: number) {
              return new Intl.NumberFormat(undefined, { style: "currency", currency: "USD" }).format(value);
            },
          },
          min: 0,
          max: !!cost && costAxisMax(),
          type: "value",
          position: "left",
          alignTicks: false,
        },
      ],
      series: [
        {
          id: "a",
          type: "line",
          smooth: smooth,
          symbol: "circle",
          symbolSize: symbolSize,
          data: toPoints(mutableCurve),
          yAxisIndex: 0,
          tooltip: {
            show: false,
          },
        },
        {
          type: "bar",
          show: !!cost,
          // TODO: Reuse the results of the interpolation when we calculated the axis max
          // TODO: Memoize this
          data:
            !!cost &&
            allocateCost(cost!, toPoints(mutableCurve), smooth, interval).map((c, i) => [
              i * interval + interval / 2,
              c,
            ]),
          yAxisIndex: 1,
          barWidth: "100%",
          emphasis: {
            itemStyle: {
              color: "#8888ee",
              opacity: 1,
            },
          },
          itemStyle: {
            color: "#ccccff",
            opacity: 0.2,
          },
        },
      ],
      tooltip: {
        show: !!cost,
        trigger: "axis",
        axisPointer: { type: "none" },
        formatter: ([
          {
            data: [x, y],
          },
        ]: [{ data: [number, number] }]) =>
          `<b>${x - interval / 2} - ${x + interval / 2} ${
            functionUnit === DensityFunctionIndependentVariable.TimeDays ? "Days" : "km"
          }</b><br/>${new Intl.NumberFormat(undefined, { style: "currency", currency: "USD" }).format(y)}`,
      },
    },
  };

  let chart: EChartsInstance;
  function handleChartReady(c: EChartsInstance) {
    chart = c;
    // Add invisible shadow circles to enable dragging.
    chart.setOption({
      graphic: mutableCurve.map(function ({ x, y }, dataIndex) {
        return {
          type: "circle",
          position: chart.convertToPixel("grid", [x, y]),
          shape: {
            cx: 0,
            cy: 0,
            r: (symbolSize * 3) / 4, // Make it slightly bigger than the symbol
          },
          invisible: true,
          draggable: dataIndex == 0 ? "vertical" : dataIndex == mutableCurve.length - 1 ? "horizontal" : true,
          ondrag: function () {
            // @ts-ignore
            onPointDragging(dataIndex, [this.x, this.y]);
          },
          ondragend: function () {
            // Re-sort to account to keep points sequential
            mutableCurve.sort(({ x: x1 }, { x: x2 }) => x1 - x2);
            chart.setOption({
              xAxis: {
                min: 0,
                max: Math.round(Math.max(...mutableCurve.map(({ x }) => x)) + 10),
              },
            });
            onEdit({
              y0: mutableCurve[0].y,
              points: mutableCurve.filter((_, i) => i != 0),
            });
          },
          onmousemove: function () {
            showTooltip(dataIndex);
          },
          onmousout: function () {
            hideTooltip();
          },
          z: 100,
        };
      }),
    });

    chart.on("dataZoom", updatePosition);
    chart.on("finished", updatePosition);

    window.addEventListener("resize", updatePosition);
    function updatePosition() {
      setTimeout(
        () =>
          chart.setOption({
            graphic: mutableCurve.map(function ({ x, y }, _dataIndex) {
              return {
                position: chart.convertToPixel("grid", [x, y]),
                type: "circle",
              };
            }),
          }),
        0
      );
    }

    function showTooltip(dataIndex: number) {
      chart.dispatchAction({
        type: "showTip",
        seriesIndex: 0,
        dataIndex: dataIndex,
      });
    }

    function hideTooltip() {
      chart.dispatchAction({
        type: "hideTip",
      });
    }
  }

  function onPointDragging(dataIndex: number, pos: [number, number]) {
    const coords: [number, number] = constrainCoords(chart.convertFromPixel("grid", pos));
    mutableCurve[dataIndex] = { x: coords[0], y: coords[1] };
    // Update data
    chart.setOption({
      series: [
        {
          id: "a",
          data: mutableCurve.map(({ x, y }) => [x, y]),
        },
      ],
    });
  }

  return (
    <ReactECharts
      className="echarts density-curve-chart"
      {...chartConfig}
      onChartReady={handleChartReady}
      style={{ height: "300px", width: "500px" }}
      key={chartKey}
    />
  );
}

export const constrainCoords = ([x, y]: [number, number]) => {
  // this function is used to prevent the user from moving a data point past
  // the allowed values for the density curve
  // prevents user from dragging x value below 0
  // prevents user from dragging y above 1 or below 0
  return [x < 0 ? 0 : x, y > 1 ? 1 : y < 0 ? 0 : y] as [number, number];
};
