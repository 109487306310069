import "virtual:vite-plugin-sentry/sentry-config";

import * as Sentry from "@sentry/browser";
import { httpClientIntegration } from "@sentry/integrations";

import { HTTP_SETTINGS } from "@/constants";
import createSentryLogger from "@/utils/createSentryLogger";
import Logger from "@/utils/Logger";

const options = {
  dsn: import.meta.env.VITE_SENTRY_DSN,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  enabled: import.meta.env.PROD,
  release: import.meta.env.VITE_PLUGIN_SENTRY_CONFIG.release,
  environment: import.meta.env.VITE_STAGE,
  tracesSampleRate: parseFloat(import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE ?? "1.0"),
  sendDefaultPii: true,
  integrations: [
    Sentry.browserTracingIntegration(),
    httpClientIntegration(),
    Sentry.replayIntegration({
      networkRequestHeaders: [HTTP_SETTINGS.SESSION_ID_HEADER],
    }),
  ],
};

Sentry.init(options);

Logger.registerHandler(createSentryLogger());
