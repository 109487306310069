import styled from "styled-components";

export const NavigationSearchBarContainer = styled.div`
  max-width: 850px;
  flex-grow: 1;
  display: flex;
  flex-direction: row;
  .searchbar-autoselect {
    width: 100%;
    height: initial;
    .searchbar-input {
      padding: 0.75rem 1rem;
      border-radius: 0.5rem;
    }
  }
`;

export const SearchLabelTitle = styled.span`
  text-transform: uppercase;
  font-size: 10px;
  font-weight: semibold;
  color: black;
`;
