import { useParams } from "@tanstack/react-router";

import { RepairConcept, useRepairConceptsQuery } from "@/api";
import BlockingLoading from "@/components/ui/BlockLoading";
import { QUERY_SETTINGS } from "@/constants";
import { EditRepairConceptProvider } from "@/contexts/EditRepairConceptProvider";
import { requireCurrentCustomerId } from "@/utils/customers";

import EditRepairConcept from "./EditRepairConcept";
import { convertToRepairConceptFields } from "./RepairConceptHelper";

export interface EditRepairConceptParams {
  id: string;
}

const EditRepairConceptWrapper = () => {
  const { repairConceptId } = useParams({ strict: false });

  const { data, isLoading } = useRepairConceptsQuery(
    { customerId: requireCurrentCustomerId(), ids: [repairConceptId ?? ""] },
    { staleTime: QUERY_SETTINGS.LONG_STALE_TIME, enabled: !!repairConceptId }
  );

  const fetchedData = convertToRepairConceptFields(data?.repairConcepts?.repairConcepts[0] as RepairConcept);

  if (isLoading && !!repairConceptId) return <BlockingLoading className="line with-margin" />;

  return (
    <EditRepairConceptProvider initialState={fetchedData}>
      <EditRepairConcept />
    </EditRepairConceptProvider>
  );
};

export default EditRepairConceptWrapper;
