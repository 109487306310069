import { Modal, notification } from "antd";
import { useState } from "react";

import {
  CalculatedStatus,
  useCreateExpressionEventMutation,
  useGetExpressionEventForFormQuery,
  useUpdateExpressionEventMutation,
} from "@/api";
import Loading from "@/components/loading";
import { QUERY_SETTINGS } from "@/constants";
import { requireCurrentCustomerId } from "@/utils/customers";

import {
  ExpressionEventModalContent,
  ExpressionEventModalFormContent,
  ExpressionEventModalHeader,
} from "./ExpressionEvent.styled";
import { ExpressionEventForm, FormEventsAnalysisValues } from "./ExpressionEventForm";
import { ExpressionEventPreview } from "./ExpressionEventPreview";

type ExpressionEventModalProps = {
  open?: boolean;
  onCancel: () => void;
  onMutate?: () => void;
  expressionEventId?: string | null;
};

export const ExpressionEventModal = ({ open, onCancel, expressionEventId, onMutate }: ExpressionEventModalProps) => {
  const [eventValues, setEventValues] = useState<FormEventsAnalysisValues>();
  const [notificationApi, notificationContextHolder] = notification.useNotification();

  const { isFetching: fetchingExpressionEvent, data: expressionEventData } = useGetExpressionEventForFormQuery(
    { customerId: requireCurrentCustomerId(), expressionEventId: expressionEventId! },
    { enabled: !!expressionEventId, refetchOnMount: "always", staleTime: QUERY_SETTINGS.SHORT_STALE_TIME }
  );

  const createExpressionEventMutation = useCreateExpressionEventMutation();

  const createExpressionEvent = async (values: FormEventsAnalysisValues) => {
    createExpressionEventMutation.mutate(
      {
        expressionEvent: {
          name: values.name,
          expressionId: values.expression,
          operator: values.operator,
          threshold: Number(values.threshold),
        },
        customerId: requireCurrentCustomerId(),
      },
      {
        onSuccess: () => {
          notificationApi.success({
            message: `Event ${values.name} created.`,
          });
          onMutate?.();
        },
      }
    );
  };

  const updateExpressionEventMutation = useUpdateExpressionEventMutation();
  const updateExpressionEvent = async (values: FormEventsAnalysisValues) => {
    updateExpressionEventMutation.mutate(
      {
        expressionEvent: {
          name: values.name,
          expressionId: values.expression,
          operator: values.operator,
          threshold: Number(values.threshold),
        },
        customerId: requireCurrentCustomerId(),
        updateExpressionEventId: expressionEventId!,
        version: expressionEventData!.expressionEvents!.expressionEvent.version,
      },
      {
        onSuccess: () => {
          notificationApi.success({
            message: `Event ${values.name} updated.`,
          });
          onMutate?.();
        },
      }
    );
  };
  const isMutationLoading = createExpressionEventMutation.isLoading || updateExpressionEventMutation.isLoading;
  return (
    <>
      {notificationContextHolder}
      <Modal
        open={open}
        title={
          <ExpressionEventModalHeader>
            <div className="modal-header-title">{expressionEventId ? "Update" : "Create"} event from pattern</div>
            <div className="modal-header-description">
              The results visualized from this event are affected by global filter settings.
            </div>
          </ExpressionEventModalHeader>
        }
        onCancel={onCancel}
        afterClose={() => setEventValues(undefined)}
        footer={null}
        confirmLoading={isMutationLoading}
        maskClosable={true}
        width={1200}
        destroyOnClose={true}
      >
        <ExpressionEventModalContent>
          {fetchingExpressionEvent ? (
            <Loading />
          ) : (
            <>
              <ExpressionEventModalFormContent>
                <ExpressionEventForm
                  isEditing={!!expressionEventId}
                  disabled={isMutationLoading}
                  onFormChange={(values) => setEventValues(values)}
                  onFormSubmit={expressionEventId ? updateExpressionEvent : createExpressionEvent}
                />
              </ExpressionEventModalFormContent>
              <ExpressionEventPreview
                expressionId={eventValues?.expression ?? ""}
                expressionStatus={eventValues?.status ?? CalculatedStatus.Available}
                operator={eventValues?.operator}
                threshold={Number(eventValues?.threshold ?? 0)}
              />
            </>
          )}
        </ExpressionEventModalContent>
      </Modal>
    </>
  );
};
