import { createContext, Dispatch, ReactNode, SetStateAction, useEffect, useState } from "react";

const defaultState: {
  pvin: string;
  setPvin: Dispatch<SetStateAction<string>>;
} = {
  pvin: "",
  setPvin: () => {},
};

const SCVContext = createContext(defaultState);

interface SCVContextProps {
  initialPvin: string;
  children: ReactNode;
}

export const SCVContextProvider = ({ children, initialPvin }: SCVContextProps) => {
  const [pvin, setPvin] = useState(initialPvin);

  useEffect(() => {
    if (pvin && initialPvin !== pvin) setPvin(initialPvin);
  }, [initialPvin, pvin]);

  return <SCVContext.Provider value={{ pvin, setPvin }}>{children}</SCVContext.Provider>;
};

export default SCVContext;
